import React from "react";
import {MDBSelect, MDBDatePicker,  MDBTableHead,MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';


import {API_CONFIG} from '../../Config/config-env.js';
import axios from 'axios';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class reportArqueo1 extends React.Component {

    state = {

        options:[],
        sectionId: "",
        SKU:"",
         noData:false,
        skuExists:"NO ENCONTRADO",
        skuState:"SIN INFORMACION",
        showResults:false,
        data:[],
        to_date: new Date(),
        from_date: new Date(),
    };


    componentDidMount=()=>{
        this.LoadSections();
    }


    generateReport=async() => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true, showResults:false  });

        let url = API_CONFIG.base_url+'/api/rep_reporte_arqueo1/';
        
        var f = new Date(this.state.from_date);
        var fecha =  ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) +"/" +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + "/"+(f.getFullYear()+"")
        var from_date = new Date( f.getFullYear(),f.getMonth(),f.getDate(), 0,0,0)
        
        f = new Date(this.state.to_date);
        fecha =  ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) +"/" +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + "/"+(f.getFullYear()+"")
        var to_date = new Date( f.getFullYear(),f.getMonth(),f.getDate(), 0,0,0)
        
        let datos = {
            sectionId:  this.state.sectionId,
            from_date :from_date ,
            to_date :  to_date ,
        }
        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'post',
            data: datos
        })
            .then(response => response.data)
            .then(data => {

                if (data.resp==="OK" &&  data.rows.length>0){


                    let	dataObjetct= {
                        columns:data.columns,
                        rows:data.rows
                    };

                    this.setState({ ...this.state, data : dataObjetct, noData:false,  showResults:true });
                    this.setState({ isExecuting: false });

                }else {
                    this.setState({ isExecuting: false, noData:true,  showResults:false , data:[]  });
                }
            })
    }

    LoadSections=async() => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true });

        let url = API_CONFIG.base_url+'/api/admin/getSections/';

        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'get'
        })
            .then(response => response.data)
            .then(data => {
                if (data.rows.length>0){
                    var objSections = [];
                    objSections.push({
                        text: "TODOS",
                        value: "0", checked:true
                    })

                    for (var key in data.rows) {
                        if (data.rows[key].sectionCode!=="NAC01"){
                            let	dataObject={
                                value: data.rows[key].sectionId,
                                text:data.rows[key].sectionCode+" "+data.rows[key].sectionName
                            }
                            objSections.push(dataObject);
                        }
                    }

                    this.setState({ options : objSections });

                    this.setState({ isExecuting: false });

                }else {
                    this.setState({ isExecuting: false });
                }
            })
    }


    selectHandler = event => {

        this.setState({ ...this.state, sectionId: event[0] });

    };

    changeHandler = event => {
        //console.log(event.target.value);
        this.setState({ ...this.state, SKU: event.target.value });
    };
    getPickerValueTo= value=> { this.setState({ to_date: value }); }
    getPickerValueFrom = value=> { this.setState({ from_date: value }); }

    render() {
        return (

            <MDBContainer fluid className="justify-content-left text-left " style={{"marginLeft": "0"}}>
                <Loader idLoading={this.state.isExecuting}/>
                <br></br>
                <h2 key={"h2"}	className={" h2-responsive text-left"}> <strong>Reporte de Pedidos</strong></h2>

                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left">

                        <MDBSelect
                            className="is-invalid"
                            key= "store"
                            id="store" 
                            color="primary"
                            options={this.state.options}
                            getValue= {this.selectHandler}
                            selected="Elija un Tienda"
                            label="Tienda" />
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left" >
                        <MDBDatePicker  key="from"  name="from" id="from" format='DD/MM/YYYY' value={this.state.from_date} cancelLabel="Cancelar" getValue={this.getPickerValueFrom} />
                    </MDBCol>

                </MDBRow>

                <MDBRow>

                    <MDBCol sm="12" md="9" lg="4" className="text-left">
                        <MDBDatePicker  key="to"  name="to" id="to" format='DD/MM/YYYY' value={this.state.to_date}  cancelLabel="Cancelar" getValue={this.getPickerValueTo} />
                    </MDBCol>

                </MDBRow>

                <MDBRow>
                    <MDBCol  sm="12" md="9" lg="4" className="text-right justify-content-right">
                        <MDBBtn href="#reportResult" key="find" onClick={() => {this.generateReport();}} size="sm"
                                color="success"
                                className="text-capitalize"
                        >Generar Reporte</MDBBtn>
                    </MDBCol>
                </MDBRow>

	  							{this.state.noData?
	                	<> <br/> 
	                		<MDBAlert color="warning" dismiss>
											No se encontraron datos para el periodo seleccionado
											</MDBAlert></>:""
											
	                }

                        {this.state.showResults?
                            <>
                                <MDBRow>
                                    <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-right justify-content-right">
                                      <ExcelFile element={
	                                        <MDBBtn key="find"   size="sm"
	                                                color="success"
	                                        >Exportar a Exce l</MDBBtn>
	                                    }>
	                                        <ExcelSheet data={this.state.data.rows} name="pedidos">
	                                        
	                                        {this.state.data.columns.map(event => (
								 
															  						   <ExcelColumn label={event.label} value={event.field}/>
																						))} 
	                                        </ExcelSheet>

	                                    </ExcelFile>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-left justify-content-left">
                                        <MDBDataTable
                                            small
                                            striped
                                            bordered
                                            responsive
                                            responsiveSm
                                            hover
                                            btn
                                            searchLabel={"Buscar"}
                                            paginationLabel={[ "Anterior" , "Siguiente"]}
                                            infoLabel={["Mostrando", "Hasta",  "De",  "registros"]}
                                            paging = {true}
                                            displayEntries={false}
                                            sortable={true}
                                            entries={"20"}
                                            data={this.state.data} />
                                    </MDBCol>
                                </MDBRow>

                            </>
                            :""}


                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={false}
                    position={"bottom-right"}
                    autoClose={3000}
                />

            </MDBContainer>
        )
    }
}
export default reportArqueo1;

/*****components***/
import React, { Component } from "react";
import { Switch } from "antd";
import {
  toast,
  ToastContainer,
  MDBModal,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { OP_SECRET_KEY } from "../../Config/config-env.js";
import { API_CONFIG } from "../../Config/config-env.js";
import axios from "axios";

/*custom components*/
import PageLoading from "../../Components/PageLoading";
import Table from "../../Components/Table";
import Filters from "../../Components/Filters";
import Modal from "../../Components/Modal";
import PageHeader from "../../Components/PageHeader";

/*functions*/
import {
  searchHandler,
  callexternalApi,
  find,
  parseFormData,
  callApi,
} from "../../Config/functions";

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var f = new Date();
var fromDate = new Date();
fromDate.setDate(f.getDate() - 30);

var mes =
  f.getUTCMonth() + 1 < 10 ? "0" + (f.getUTCMonth() + 1) : f.getUTCMonth() + 1;
var dia = f.getUTCDate() < 10 ? "0" + f.getUTCDate() : f.getUTCDate();
var date_label = f.getUTCFullYear() + "." + mes + "." + dia;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //show : null,
      modal1: false,
      isLoading: true,
      api_url: "",
      date_label: date_label,
      modalClass: "modal-full-height modal-lg",
      modalIamgeClass: "modal-lg",
      excelrep1: "",
      excelrep2: "",
      isChecked: true,
      /*table filter*/
      filterConfig: {
        ORDERID: {
          label: "Nro Pedido Desde",
          type: "NUMBER",
          value:
            process.env.REACT_APP_API_CONFIG === "http://localhost:2011/api/v1"
              ? "40818"
              : "",
        },
        ORDERID_TO: {
          label: "Nro Pedido Hasta",
          type: "NUMBER",
          value:
            process.env.REACT_APP_API_CONFIG === "http://localhost:2011/api/v1"
              ? "40818"
              : "",
        },
        CUSTOMER_NAME: { label: "Nombre Cliente", type: "TEXT", value: "" },
        FROMDATE: { label: "Desde", type: "DATE", value: fromDate },
        TODATE: { label: "Hasta", type: "DATE", value: new Date() },
        CITY: {
          label: "Ciudad",
          type: "SELECT",
          values: [{ text: "TODAS", value: "" }],
          value: "",
        },
        STOREID: {
          label: "Tienda",
          type: "MULTIPLE",
          values: [{ text: "TODAS", value: "" }],
          value: "",
        },
        STATUSID: {
          label: "Estado",
          type: "MULTIPLE",
          values: [{ text: "TODOS", value: "" }],
          value: "",
        },
        PAYMENTTYPEID: {
          label: "Tipo Pago",
          type: "MULTIPLE",
          values: [{ text: "TODOS", value: "" }],
          value: "",
        },
        SHIPPINGTYPEID: {
          label: "Typo Env\u00EDo",
          type: "MULTIPLE",
          values: [{ text: "TODOS", value: "" }],
          value: "",
        },
        MIN_PRICE: { label: "Monto Max", type: "NUMBER", value: "0" },
        MAX_PRICE: { label: "Monto Min", type: "NUMBER", value: "999999" },
        HAVE_COUPON: {
          label: "Tiene Convenio",
          type: "SELECT",
          value: "",
          values: [
            { text: "TODOS", value: "" },
            { text: "SI", value: "YES" },
            { text: "NO", value: "NO" },
          ],
        },
        COUPONNAME: {
          label: "Nombre del Cup\u00F3n",
          type: "SELECT",
          value: "",
          values: [{ text: "TODOS", value: "" }],
        },
      },
      showMore: false,
      /*table data*/
      tableData: {
        columns: [],
        rows: [],
      },

      tableData2: {
        columns: [],
        rows: [],
      },

      defCols: [
        { value: "ORDERID", enabled: true, title: "Pedido" },
        { value: "STORENAME", enabled: true, title: "Tienda" },
        { value: "STORENAME2", enabled: true, title: "Tienda Registro" },

        { value: "TASKNAME", enabled: true, title: "Tarea" },

        { value: "SKU_PRODUCTID", enabled: true, title: "Id Producto" },
        {
          value: "SKU_PRODUCTNAME",
          enabled: true,
          title: "Nombre Gen\u00E9rico",
        },
        {
          value: "SKU_CATEGORYNAME",
          enabled: true,
          title: "Categor\u00EDa Madre",
        },
        { value: "SKU_KEYVARIANT", enabled: true, title: "Key Variante" },
        { value: "SKU", enabled: true, title: "SKU" },
        { value: "SKU_NAME", enabled: true, title: "Nombre Espec\u00EDfico" },
        { value: "SKU_COLOR", enabled: true, title: "Color" },
        { value: "SKU_SIZE", enabled: true, title: "Talla" },
        { value: "SKU_REGULAR_PRICE", enabled: true, title: "Precio Regular" },
        { value: "SKU_PRICE", enabled: true, title: "Precio" },
        { value: "SKU_TOTAL", enabled: true, title: "Total SKU" },
        { value: "SKU_QUANTITY", enabled: true, title: "Cantidad" },

        { value: "SUBTOTAL_ORDER", enabled: true, title: "Subtotal" },
        { value: "SHIPPINGCOST", enabled: true, title: "Costo de Env\u00EDo" },
        { value: "DISCOUNT", enabled: true, title: "Descuento" },
        { value: "TOTAL_ORDER", enabled: true, title: "Total" },
        { value: "STATUSNAME", enabled: true, title: "Estado" },
        { value: "FIRSTNAME", enabled: true, title: "Nombre(s) Invitado" },
        { value: "LASTNAME", enabled: true, title: "Apellido(s) Invitado" },
        { value: "EMAIL", enabled: true, title: "Email Invitado" },
        { value: "PHONE", enabled: true, title: "Tel\u00E9fono Invitado" },

        { value: "EVENTTYPE", enabled: true, title: "Tipo evento" },
        { value: "EVENTNAME", enabled: true, title: "Nombre Evento" },

        { value: "EVENTFIRSTNAME", enabled: true, title: "Nombre Organizador" },
        {
          value: "EVENTLASTNAME",
          enabled: true,
          title: "Apellido Organizador",
        },
        { value: "EVENTEMAIL", enabled: true, title: "Email Organizador" },
        { value: "EVENTPHONE", enabled: true, title: "Telefono Organizador" },
        {
          value: "EVENTADDRESS",
          enabled: true,
          title: "Direccion de Envio (Evento)",
        },
        {
          value: "EVENTCOMMENT",
          enabled: true,
          title: "Instrucciones de Envio (Evento)",
        },
        { value: "EVENTCODE", enabled: true, title: "Codigo de Zona" },
        { value: "EVENTSHIPPINGTYPE", enabled: true, title: "Tipo de Envio" },

        { value: "HAVECOUPON", enabled: true, title: "Nombre Cup\u00F3n" },
        { value: "COUPONCODE", enabled: true, title: "C\u00F3digo Cup\u00F3n" },
        {
          value: "COUPONADMINCODE",
          enabled: true,
          title: "C\u00F3digo Interno",
        },
        { value: "COUPONVALUE", enabled: true, title: "Valor Cup\u00F3n" },

        { value: "EVENTRACK", enabled: true, title: "POSICION RACK" },
        { value: "EVENTINVOICE", enabled: true, title: "NRO FACTURA" },
        { value: "EVENTTRX", enabled: true, title: "TRX" },
        { value: "EXTORDERTYPE", enabled: true, title: "Opción cliente" },

        { value: "EVENTNOTASAP", enabled: true, title: "NOTASAP" },

        { value: "DATE_ADDED", enabled: true, title: "Fecha de Pedido" },
        {
          value: "DATE_MODIFIED",
          enabled: true,
          title: "Fecha de Actualizaci\u00F3n",
        },
      ],
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    require("antd/dist/antd.css");

    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");

    await axios({
      url: API_CONFIG.xflowurl + "/components/get-single-by-code",
      data: { component: "dashboards", code: "REP001" },
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        let compinfo = data.data.DATA;
        // console.log(compinfo);
        this.setState({ compinfo: compinfo });
      });

    try {
      let resp = await callApi(
        "/engine-users/decryptToken",
        "post",
        { token: token },
        token
      );

      //var api_url = "http://localhost:8080"
      var api_url = API_CONFIG.casaideas;

      let resp1 = await callexternalApi(
        api_url,
        "/api/frontend/getPaymentTypes",
        "post",
        { languageid: 3 }
      );
      let resp2 = await callexternalApi(
        api_url,
        "/api/frontend/getShippingTypes",
        "post",
        { languageid: 3 }
      );
      let resp3 = await callexternalApi(
        api_url,
        "/api/frontend/getStores",
        "post"
      );
      let resp4 = await callexternalApi(
        api_url,
        "/api/frontend/getStatusOrders",
        "post",
        { languageid: 3 }
      );
      let resp5 = await callexternalApi(
        api_url,
        "/api/frontend/getPolygons",
        "post"
      );

      let resp6 = await callexternalApi(
        api_url,
        "/externalapi/execute/getCoupons/key/" + OP_SECRET_KEY,
        "post",
        { username: "admin" }
      );

      const filterConfig = this.state.filterConfig;
      resp1.rows.map(function (item) {
        filterConfig.PAYMENTTYPEID.values.push({
          text: item.PAYMENTNAME,
          value: item.PAYMENTTYPEID,
        });
      });
      resp2.rows.map(function (item) {
        filterConfig.SHIPPINGTYPEID.values.push({
          text: item.SHIPPINGNAME,
          value: item.SHIPPINGTYPEID,
        });
      });
      resp3.rows.map(function (item) {
        filterConfig.STOREID.values.push({
          text: item.STRING_VALUE,
          value: item.DETAILDOMAINID,
        });
      });
      resp4.rows.map(function (item) {
        filterConfig.STATUSID.values.push({
          text: item.STATUSNAME,
          value: item.STATUSID,
        });
      });

      var cities = [];
      resp5.rows.map(function (item) {
        if (cities.indexOf(item.CITY) < 0) {
          cities.push(item.CITY);
        }
      });
      cities.map(function (item) {
        filterConfig.CITY.values.push({ text: item, value: item });
      });

      resp6.response.map(function (item) {
        filterConfig.COUPONNAME.values.push({
          text: item.COUPONNAME,
          value: item.COUPONNAME,
        });
      });

      this.setState({ api_url: api_url });
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toggleModal = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  ///Search Manager
  searchHandler = async (event) => {
    this.setState({ isLoading: true });
    let resp = await searchHandler(
      event,
      this.state.filterConfig,
      this.state.showMore,
      this.state.api_url + "/externalapi/execute/repOrdersEvent/key/12345678"
    );

    if (resp.filterConfig) {
      this.setState({
        filterConfig: resp.filterConfig,
        showMore: resp.showMore,
      });
    }

    var excelrep1, excelrep2;

    if (resp.tableData) {
      var formData = parseFormData(this.state.filterConfig);
      //let respW={}
      formData["from"] = formData.fromdate;
      formData["to"] = formData.todate;
      formData["flowid"] = null;
      let params = new URLSearchParams(this.props.location.search);
      let token = params.get("token");
      /*
      let respW = await callApi(
        "/engine-users/get-history-flows",
        "post",
        formData,
        token
      );
      */
      let components = this.state.compinfo.data;

      let filterDefinition = [
        {
          name: "INSERT_DATE",
          type: "daterange",
          value: [
            this.state.filterConfig.FROMDATE.value,
            this.state.filterConfig.TODATE.value,
          ],
        },
        {
          name: "AGENCIA",
          type: "select",
          value: "",
        },
        {
          name: "METODO_PAGO",
          type: "select",
          value: "",
        },
        {
          name: "TIPODEENVIO",
          type: "select",
          value: "",
        },
        {
          name: "usuario",
          type: "select",
          value: "",
        },
      ];

      let datos = {
        components: components,
        filterDefinition: filterDefinition,
        filters: {
          INSERT_DATE: [
            this.state.filterConfig.FROMDATE.value,
            this.state.filterConfig.TODATE.value,
          ],
          AGENCIA: "",
          METODO_PAGO: "",
          TIPODEENVIO: "",
          usuario: "",
        },
      };
      let dataOrders = [];
      let dataOrdersExt = [];
      let url = API_CONFIG.xflowurl + "/components/get-component-report";

      await axios({
        url: url,
        headers: {
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "post",
        data: datos,
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.status) {
            dataOrders = data.results[0];
            dataOrdersExt = data.results[1];
          }
        });

      //  console.log("resp.tableData", resp.tableData);
      //console.log("respW",respW)
      //console.log("dataOrders",dataOrders)

      let orders = [];
      let rows = [];
      for (var r in resp.tableData.orders) {
        let field = resp.tableData.orders[r];
        let pos = orders.indexOf(field.ORDERID + "");

        let tipo_pedido_v = "PEDIDO ONLINE";

        try {
          let orderInfo = JSON.parse(field.ORDERINFO);
          if (orderInfo.dedicationcheck) {
            //     console.log(orderInfo.dedicationcheck);

            if (orderInfo.dedicationcheck == "NO") {
              tipo_pedido_v = "TIENDA COMPRA Y ENTREGA";
            } else if (orderInfo.dedicationcheck == "YES") {
              tipo_pedido_v = "TIENDA SOLO COMPRA";
            } else if (orderInfo.dedicationcheck == "CLIENTE") {
              tipo_pedido_v = "Cliente llevará el regalo (No xFlow)";
            }
          }
        } catch (Exc) {
          tipo_pedido_v = "NO DEF";
          console.log("NO ingo", Exc);
        }

        field["EXTORDERTYPE"] = tipo_pedido_v;

        if (pos < 0) {


          orders.push(field.ORDERID + "");
          let eventinfo = JSON.parse(field.EVENTINFO);
          let customerinfo = JSON.parse(field.CUSTOMERINFO);
          let orderInfo = JSON.parse(field.ORDERINFO);

          if (orderInfo.dedicationcheck) {
            field["STORENAME2"] = orderInfo.storename;
          } else {
            field["STORENAME2"] = field["STORENAME"];
          }
          //console.log("eventinfo", eventinfo);

          let add = "";
          /*
          try {
            add =
              eventinfo.directions.address +
              " " +
              eventinfo.directions.reference;
          } catch (e) {
            add = eventinfo.directions;
            eventinfo.directions;
          }
*/

          field["EVENTNAME"] = eventinfo.eventname;
          field["EVENTFIRSTNAME"] = customerinfo[0].firstname;
          field["EVENTLASTNAME"] = customerinfo[0].lastname;
          field["EVENTEMAIL"] = customerinfo[0].email;
          field["EVENTPHONE"] = customerinfo[0].phone;
          field["EVENTADDRESS"] =
            eventinfo.directions.address + " " + eventinfo.directions.reference;
          field["EVENTCOMMENT"] = eventinfo.additionalshippinginstructions;
          field["EVENTCODE"] = eventinfo.directions.zonecode;
          field["EVENTSHIPPINGTYPE"] = "EVENTO";

          let pos = find(dataOrders, field.ORDERID, "ID_PEDIDO");
          if (pos >= 0) {
            let data = dataOrders[pos];
            //   console.log("encotrado", data)

            field["EVENTTRX"] = data.TRX || "";
            field["EVENTINVOICE"] = data.NROFACTURA || "";
            //field["EXTORDERTYPE"] = "----------";

            field["EVENTRACK"] = data.POSICION_RACK || "";
            field["EVENTNOTASAP"] = data.NOTA_SAP || "";
          }

          let pos2 = find(dataOrdersExt, field.ORDERID, "ID_PEDIDO");
          console.log("pos2:::::::..", pos2)
          if (pos2 >= 0) {
            let data = dataOrdersExt[pos2];
               console.log("encotrado", data)

            field["EVENTTRX"] = data.TRX || "";
            field["EVENTINVOICE"] = data.NROFACTURA || "";
            //field["EXTORDERTYPE"] = "----------";

            field["EVENTRACK"] = data.POSICION_RACK || "";
            field["EVENTNOTASAP"] = data.NOTA_SAP || "";
          }


         
          console.log( field)
          rows.push(field);
        }
      }
      //console.log(orders)

      if (rows) {
        for (var r in rows) {
          var row = rows[r];
          this.state.defCols.map(function (item) {
            if (!item.enabled) {
              delete row[item.value];
            }
          });
        }
      }

      const tableData = this.state.tableData;
      tableData.rows = rows ? rows : [];

      let rowsO = []; // resp.tableData.orderskus

      for (var r in resp.tableData.orderskus) {
        let field = resp.tableData.orderskus[r];
        let pos = orders.indexOf(field.ORDERID + "");

        if (pos >= 0) {
          orders.push(field.ORDERID + "");
          Object.assign(field, rows[pos]);
          console.log("field", field)
          rowsO.push(field);
        }
      }

      if (rowsO) {
        for (var r in rowsO) {
          let row = rowsO[r];
          let _couponcode;
          let _couponvalue;
          tableData.rows.map(function (item) {
            if (item.ORDERID == row.ORDERID) {
              //						 	console.log("es su orden ")
              row["COUPONCODE"] = item.COUPONCODE ? item.COUPONCODE : "";
              row["COUPONADMINCODE"] = item.COUPONADMINCODE
                ? item.COUPONADMINCODE
                : "";
              row["COUPONVALUE"] = item.COUPONVALUE ? item.COUPONVALUE : "";
              row["HAVECOUPON"] = item.HAVECOUPON ? item.HAVECOUPON : "";
            }
          });
          this.state.defCols.map(function (item) {
            if (!item.enabled) {
              delete row[item.value];
            }
          });
        }
      }

      const tableData2 = this.state.tableData2;
      tableData2.rows = rowsO ? rowsO : [];

      var _columns = [];
      var _defCols = [];
      this.state.defCols.map(function (item) {
        if (item.enabled && item.value.indexOf("SKU") < 0) {
          _columns.push({
            dataIndex: item.value,
            title: item.title,
          });
          _defCols.push(item);
        }
      });
      tableData.columns = _columns;

      excelrep1 = (
        <ExcelSheet data={rows} name="Pedidos">
          {_defCols.map((item, index) => (
            <ExcelColumn label={item.title} value={item.value} />
          ))}
        </ExcelSheet>
      );
      //console.log(excelrep1)

      _defCols = [];
      _columns = [];
      this.state.defCols.map(function (item) {
        if (item.enabled) {
          _columns.push({
            dataIndex: item.value,
            title: item.title,
          });
          _defCols.push(item);
        }
      });
      tableData2.columns = _columns;

      excelrep2 = (
        <ExcelSheet data={rowsO} name="Pedidos SKUs">
          {_defCols.map((item, index) => (
            <ExcelColumn label={item.title} value={item.value} />
          ))}
        </ExcelSheet>
      );
      //console.log(excelrep2)

      window.location.hash = "#results";
    }

    this.setState({
      isLoading: false,
      excelrep1: excelrep1,
      excelrep2: excelrep2,
    });
  };

  selectItem = (item) => {
    if (item != "ORDERID" && item != "SKU") {
      var defCols = this.state.defCols;
      defCols.map(function (row) {
        if (row.value == item) {
          row["enabled"] = !row["enabled"];
        }
      });
      this.setState({ defCols: defCols });
    }
  };

  selectAllItems = (item) => {
    var defCols = this.state.defCols;
    defCols.map(function (row) {
      if (row.value != "ORDERID" && row.value != "SKU") {
        row["enabled"] = true;
      }
    });
    this.setState({ defCols: defCols, isChecked: true });
  };

  removeAllItem = (item) => {
    var defCols = this.state.defCols;
    defCols.map(function (row) {
      if (row.value != "ORDERID" && row.value != "SKU") {
        row["enabled"] = false;
      }
    });
    this.setState({ defCols: defCols, isChecked: false });
  };

  render() {
    return (
      <>
        <PageLoading isLoading={this.state.isLoading} />

        <MDBModal
          cascading
          isOpen={this.state.modal1}
          toggle={this.toggleModal(1)}
          size="lg"
        >
          <MDBModalBody className="text-center">
            <span className="h4-responsive text-center text-uppercase">
              <b> Campos a Exportar </b>{" "}
            </span>
            <hr />

            <MDBRow className="text-left">
              {this.state.defCols.map((row, index) => (
                <MDBCol key={"col" + index} md="4">
                  <MDBInput
                    size="sm"
                    onClick={() => {
                      this.selectItem(row.value);
                    }}
                    key={"check" + row.value}
                    className="nomargin"
                    name={"check" + row.value}
                    label={row.title}
                    filled
                    checked={row.enabled}
                    type="checkbox"
                    id={"check" + row.value}
                  />
                </MDBCol>
              ))}
            </MDBRow>
            <hr />
            <MDBRow className="text-right">
              <MDBCol sm="12">
                {this.state.isChecked ? (
                  <button
                    className="no-button"
                    color="secondary"
                    size="sm"
                    onClick={this.removeAllItem}
                  >
                    {" "}
                    <MDBIcon far icon="square" /> Desmarcar Todos
                  </button>
                ) : (
                  <button
                    className="no-button"
                    color="secondary"
                    size="sm"
                    onClick={this.selectAllItems}
                  >
                    {" "}
                    <MDBIcon icon="check-square" />
                    Marcar Todos
                  </button>
                )}
              </MDBCol>
            </MDBRow>

            <MDBRow className="text-center">
              <MDBCol sm="12">
                <MDBBtn
                  color="dark"
                  size="sm"
                  onClick={() => {
                    this.setState({ modal1: false });
                  }}
                >
                  Cerrar
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-4">
                <h2 className={"h2-responsive text-left"}>
                  {" "}
                  Export Orders - Regalables
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-4 col-xl-2">
                <Filters
                  filterConfig={this.state.filterConfig}
                  showMore={this.state.showMore}
                  searchHandler={this.searchHandler}
                />

                <div className="row mt-2">
                  <div className="col-12">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn  btn-sm  btn-dark btn-block"
                        name="setFields"
                        onClick={() => {
                          this.setState({ modal1: true });
                        }}
                      >
                        {" "}
                        Campos a Exportar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-10">
                <div
                  id="results"
                  className="col-12 col-lg-12 text-right mb-2"
                  style={{ padding: 0 }}
                >
                  <ExcelFile
                    filename={"export-orders." + this.state.date_label}
                    element={
                      <button
                        disabled={this.state.tableData.rows.length == 0}
                        type="button"
                        className="btn btn-sm btn-success"
                        name="set"
                      >
                        {" "}
                        Exportar
                      </button>
                    }
                  >
                    {this.state.excelrep1}
                    {this.state.excelrep2}
                  </ExcelFile>
                </div>

                <div className="col-12 col-lg-12" style={{ padding: 0 }}>
                  <div className="card card-table flex-fill">
                    <h4 className="p-1"> Pedidos </h4>
                    <Table
                      tableData={this.state.tableData}
                      scroll={{ x: 1650, y: 300 }}
                    />
                  </div>

                  <div className="card card-table flex-fill">
                    <h4 className="p-1"> Pedidos SKUs </h4>
                    <Table
                      tableData={this.state.tableData2}
                      scroll={{ x: 1650, y: 300 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;

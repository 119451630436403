import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";
import React, { Component } from "react";


class DatePicker extends Component {
	 constructor(props) {
    super(props);
    this.state = {
			 date: this.props.date,
			 enableTime:false,
			 show:true
		    };
  	}
  	
  	updateDatePicker = () => {
  		this.setState({ 
			show:false
		});
		this.setState({ 
			show:true
		});
	};
	
  render() {
  	var { date } = this.state;
  	/*console.log(this.props.label)
  	console.log("----------")
  	console.log(this.props.date)
  	console.log(date)
  	console.log(this.state.enableTime)*/
	  return ( 
	  <>
	  	{this.props.isUpdatable?
	   	<Flatpickr
        data-enable-time
        value={this.props.date}
        options={ this.props.options }
       	name={this.props.name}
        onChange={([date]) => {
       	this.updateDatePicker();
        this.props.updateDate({ target:{value: date, name:this.props.name}})
        }}
      />
    :""}
    </>
	  );
	}
};
export default DatePicker;
				 
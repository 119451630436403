import React from "react";
import { MDBIcon } from "mdbreact";

const loadMessage = props => {
	if (props.isLoading){
	  return ( 
	   
	   	<>
	   		<div  className="loadingBG">
			 	</div>
				<div  className="loadingBG2">
					<div  className="loading" > 
						<div className="spinner-border text-loading" role="status">
	        		<span className="sr-only">Loading...</span>
	     			</div> 
					</div>
				</div>
			</> 
	  );
	}else{
		return ""
	}
};
export default loadMessage;
				 
import React from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBSelect,
  MDBNotification,
  MDBInputGroup,
  toast,
  MDBFormInline,
  MDBBadge,
  MDBAnimation,
  ToastContainer,
  MDBIcon,
  MDBDataTable,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";
import { API_OP_CONFIG } from "../../Config/config-env.js";
import { API_CONFIG, casaideas_credentials } from "../../Config/config-env.js";
import { OP_SECRET_KEY } from "../../Config/config-env.js";
//import HelloWorld from '../../Components/HelloWorld/HelloWorld.js';
import axios from "axios";

class externalForm06 extends React.Component {
  state = {
    modal5: false,
    id_producto: -1,
    mensaje: "",
    modal6: false,
    username: "",
    accion: "",
    isDeleted: false,
    showProductsList: true,
    productQty: 1,
    formButtons: [],
    productName: "",
    SKUcode: "", //"3212567000066",
    productPrice: 0,
    productImage: "",
    existingPreviousOrder: false,
    isExecuting: false,
    searchResults: [],
    shippingTypes: [],
    paymentTypes: [],
    processing: "Procesando",
    pedido_parcial: "",
    ordersPre: [
      {
        id: 0,
        product_id: 111,
        productName: "Manta",
        productPrice: "50Bs.",
        productQty: "1",
        state: "loaded",
      },
      {
        id: 1,
        product_id: 112,
        productName: "Juego de Tazas",
        productPrice: "50Bs.",
        productQty: "1",
        state: "loaded",
      },
    ],
    orders: [],
    isSupervisor: -1,
    isAdmin: -1,
    shippId: 1,
    WO_Id: "",
    WO_TaskId: "",
    payId: "",
    store_url: "",
    isLoadedTable: false,
    loadingData: true,
    modal4: false,
    modal3: false,
    order_id: "",
    orderName: "",
    orderPrice: "Bs.50",
    orderQty: "1",
    customerName: "",
    customerAdd: "",
    customerZone: "",
    productMaxQty: 0,
    disabledAdd: true,
    costo_envio: 0,
    descuento: 0,

    pre_costo_envio: 0,
    pre_descuento: 0,

    sub_total: 0,
    org: "",
    store_id: 0,
    read_sku: "",
    observacion: "",
    TRX: "",
    posicion_rack: "",
    numero_factura: "",
    razon_social: "",
    nit: "",
    direccion_1: "",
    direccion_2: "",
    latitude: "",
    longitude: "",
    telefono: "",
    customer_id: "",
    reload: false,
    mixed_skus: [],
    skus: [],
  };

  componentDidMount = async () => {
    await this.LoadTable();
  };

  selectHandler1 = (event) => {
    if (event[0]) {
      this.setState({ ...this.state, shippId: event[0] });
    } else {
      this.setState({ ...this.state, shippId: "" });
    }
  };

  selectHandler2 = (event) => {
    if (event[0]) {
      this.setState({ ...this.state, payId: event[0] });
    } else {
      this.setState({ ...this.state, payId: "" });
    }
  };

  changeHandler = (event) => {
    //	console.log(event.target.id)
    //	console.log(event.target.value)
    var targetname = event.target.name ? event.target.name : event.target.id;

    if (targetname == "pre_descuento" || targetname == "pre_costo_envio") {
      this.setState({
        ...this.state,
        [targetname]: event.target.value
          .replace(/[^0-9,.]/g, "")
          .replace(/,/g, "."),
      });
    } else {
      this.setState({ ...this.state, [targetname]: event.target.value });
    }
  };

  updateOrdenesyPagos = async (event) => {
    let _FECHADEPAGO = this.state["fecha_pago"]
      ? this.state["fecha_pago"]
      : null;
    let FECHADEPAGO = null;
    if (_FECHADEPAGO) {
      let _date = _FECHADEPAGO.split("/");
      FECHADEPAGO = new Date(_date[2] + "-" + _date[1] + "-" + _date[0]);
    }

    let Xdata = {
      ID_PEDIDO: this.state.order_id,
      METODO_PAGO: this.state.metodo_pago,
      MONTO: this.state.monto_real || 0,
      METODODEPAGO2: this.state.metododepago2,
      MONTO2: this.state.monto_2 || 0,
      MONTOREAL: this.state.monto_total,
      NROFACTURA: this.state.numero_factura,
      FECHADEPAGO: FECHADEPAGO,
      FECHAINICIO: this.state.fechainicio,
      FECHADEEJECUCION: this.state.fechadeejecucion,
      ESTADO: this.state.estado,
      USUARIO: this.state.username,
      TIPODEENVIO: this.state.tipo_envio_etiqueta,
      AGENCIA: this.state.officecode,
      IDOT: this.state.WO_Id,
      OBSERVACIONES: this.state.observaciones,
      TRX: this.state.TRX,
      POSICION_RACK: this.state.posicion_rack,
      NOTA_SAP: this.state.nota_sap,
      USERNAME: this.state.username,
    };
    console.log("Xdata:", Xdata, this.state);

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      url: API_CONFIG.xflowurl + "/customcomponents/update/resumen_ordenes",
      data: Xdata,
      method: "put",
    })
      .then((response) => response.data)
      .then((data) => {
        console.log("response update", data);
      })
      .catch((error) => {
        console.log("error transaction log execution" + error);
      });
  };

  handleChangePrice = (event) => {
    //console.log(event.target.name)
    //console.log(event.target.value)

    let oldOrders = this.state.orders;

    //	console.log(oldOrders)

    for (var key in oldOrders) {
      let obj = oldOrders[key];

      if (oldOrders[key].productSKU == event.target.name) {
        //obj["state"]= "deleting"
        //console.log(oldOrders[key])
        oldOrders[key].productPrice = event.target.value
          .replace(/[^0-9,.]/g, "")
          .replace(/,/g, ".");
      }
    }

    this.setState({
      ...this.state,
      orders: oldOrders,
    });
  };

  changePaymentType = async (event) => {
    //console.log("change",this.state.payId);

    var dataDelOrder = {
      order_id: this.state.order_id,
      paymentTypeId: this.state.payId,
      user: this.state.username,
    };

    var tipo_pago = "";
    var tipo_pago_id = "directo";
    //console.log( this.state.payId)

    var payId = this.state.payId;

    this.state.paymentTypes.map(function (s) {
      if (s.value == payId) {
        tipo_pago = s.text;
        if (s.value == 4) {
          tipo_pago_id = "punto_fisico";
        }
      }
    });

    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        this.state.store_url +
        "externalapi/execute/editPaymentTypeOrder/key/" +
        OP_SECRET_KEY,
      data: dataDelOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log(data)
        if (data.success === "Order has been modified successfully") {
          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "tipo_pago", value: tipo_pago },
                { var: "metodo_pago", value: tipo_pago },
                { var: "tipo_pago_id", value: tipo_pago_id },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });

          this.setState({ isExecuting: false, metodo_pago: tipo_pago });
          this.updateOrdenesyPagos();
          toast.success("OK:" + data.success, {
            closeButton: true,
          });
        } else {
          this.setState({ isExecuting: false });
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });
        }
      });
  };

  changeShippingType = async (event) => {
    //console.log("change",this.state.shippId);

    var dataDelOrder = {
      order_id: this.state.order_id,
      shippingTypeId: this.state.shippId,
      user: this.state.username,
    };

    var tipo_envio_etiqueta, tipo_envio_code, tipo_envio;
    //console.log(this.state.shippingTypes)

    tipo_envio_code = this.state.shippId;
    var shippId = this.state.shippId;

    let es_express = "DELETE_es_express";
    this.state.shippingTypes.map(function (s) {
      if (s.value == shippId) {
        tipo_envio_etiqueta = s.text;
        if (s.type == "PICKUP") {
          tipo_envio = "xdelslot";
        } else {
          tipo_envio = "weight";
        }
        if (tipo_envio_etiqueta === "Envío Express") {
          es_express = "SI";
        }
      }
    });

    //console.log(tipo_envio_etiqueta, tipo_envio_code,tipo_envio)
    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        this.state.store_url +
        "externalapi/execute/editShippingTypeOrder/key/" +
        OP_SECRET_KEY,
      data: dataDelOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log(data)
        if (data.success === "Order has been modified successfully") {
          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";

          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "es_express", value: es_express },

                { var: "tipo_envio", value: tipo_envio },
                { var: "tipo_envio_code", value: tipo_envio_code },
                { var: "tipo_envio_etiqueta", value: tipo_envio_etiqueta },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });

          this.setState({
            isExecuting: false,
            tipo_envio: tipo_envio,
            tipo_envio_etiqueta: tipo_envio_etiqueta,
          });

          this.updateOrdenesyPagos();

          toast.success("OK:" + data.success, {
            closeButton: true,
          });
        } else {
          this.setState({ isExecuting: false });
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });
        }
      });
  };

  changeShippingCost = async (event) => {
    //console.log("change",this.state.pre_costo_envio);

    var dataDelOrder = {
      order_id: this.state.order_id,
      shippingCost: this.state.pre_costo_envio,
      user: this.state.username,
    };

    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        this.state.store_url +
        "externalapi/execute/editShippingCostOrder/key/" +
        OP_SECRET_KEY,
      data: dataDelOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log(data)
        if (data.success === "Order has been modified successfully") {
          let oldOrders = this.state.orders;
          let monto_total = 0;
          let sub_total = 0;
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];
            let productPrice = oldOrders[key].productPrice;
            let productQty = oldOrders[key].productQty;
            let total = productQty * productPrice;
            monto_total = monto_total + total;
            sub_total = sub_total + total;
          }
          monto_total = (
            parseFloat(monto_total) +
            parseFloat(this.state.pre_costo_envio) -
            parseFloat(this.state.descuento)
          ).toFixed(2);
          this.setState({
            ...this.state,
            monto_total: monto_total,
            costo_envio: this.state.pre_costo_envio,
            sub_total: parseFloat(sub_total, 2).toFixed(2),
          });

          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";

          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "monto_total", value: monto_total },
                {
                  var: "shipping_cost",
                  value: parseFloat(this.state.pre_costo_envio, 2).toFixed(2),
                },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });
          this.updateOrdenesyPagos();
          this.setState({ isExecuting: false });

          toast.success("OK:" + data.success, {
            closeButton: true,
          });
        } else {
          this.setState({ isExecuting: false });
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });
        }
      });
  };

  changeDiscount = async (event) => {
    //console.log("change",this.state.pre_descuento);

    var dataDelOrder = {
      order_id: this.state.order_id,
      discount: this.state.pre_descuento,
      user: this.state.username,
    };

    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        this.state.store_url +
        "externalapi/execute/editDiscountOrder/key/" +
        OP_SECRET_KEY,
      data: dataDelOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log(data)
        if (data.success === "Order has been modified successfully") {
          let oldOrders = this.state.orders;
          let monto_total = 0;
          let sub_total = 0;
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];
            let productPrice = oldOrders[key].productPrice;
            let productQty = oldOrders[key].productQty;
            let total = productQty * productPrice;
            monto_total = monto_total + total;
            sub_total = sub_total + total;
          }
          monto_total = (
            parseFloat(monto_total) +
            parseFloat(this.state.costo_envio) -
            parseFloat(this.state.pre_descuento)
          ).toFixed(2);
          this.setState({
            ...this.state,
            monto_total: monto_total,
            descuento: this.state.pre_descuento,
            sub_total: parseFloat(sub_total, 2).toFixed(2),
          });

          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";

          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "monto_total", value: monto_total },
                {
                  var: "discount",
                  value: parseFloat(this.state.pre_descuento, 2).toFixed(2),
                },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });
          this.updateOrdenesyPagos();
          this.setState({ isExecuting: false });

          toast.success("OK:" + data.success, {
            closeButton: true,
          });
        } else {
          this.setState({ isExecuting: false });
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });
        }
      });
  };

  handleCommitPrice = async (eventId, type) => {
    let neworders = [];
    let product_id;
    let productQty = 0;
    let productPrice;
    let productSKU;
    let oldOrders = this.state.orders;
    for (var key in oldOrders) {
      let obj = oldOrders[key];

      if (oldOrders[key].id === eventId) {
        //obj["state"]= "deleting"
        //console.log(oldOrders[key])

        product_id = oldOrders[key].product_id;
        productQty = parseInt(oldOrders[key].productQty);
        productSKU = oldOrders[key].productSKU;
        productPrice = oldOrders[key].productPrice;
        //obj["productQty"]=  type==="Add"? productQty+1:productQty-1
      }
      neworders.push(obj);
    }

    var dataDelOrder = {
      order_id: this.state.order_id,
      customer_id: this.state.customer_id,
      product_id: product_id,
      sku: productSKU,
      user: this.state.username,
      price: productPrice,
    };

    this.setState({ isExecuting: true });

    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        this.state.store_url +
        "externalapi/execute/editPriceOrderProduct/key/" +
        OP_SECRET_KEY,
      data: dataDelOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.success === "Order has been modified successfully") {
          let monto_total = 0;
          let sub_total = 0;
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];
            let productPrice = oldOrders[key].productPrice;
            let productQty = oldOrders[key].productQty;
            let total = productQty * productPrice;
            monto_total = monto_total + total;
            sub_total = sub_total + total;
          }
          monto_total = (
            parseFloat(monto_total) +
            parseFloat(this.state.costo_envio) -
            parseFloat(this.state.descuento)
          ).toFixed(2);
          this.setState({
            ...this.state,
            monto_total: monto_total,
            sub_total: parseFloat(sub_total, 2).toFixed(2),
          });

          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";
          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "monto_total", value: monto_total },
                { var: "subtotal", value: parseFloat(sub_total, 2).toFixed(2) },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });

          this.updateOrdenesyPagos();
          this.setState({ isExecuting: false });
          //this.setState({ orders:neworders });
          //console.log(this.state.orders);
          toast.success("OK:" + data.success, {
            closeButton: true,
          });
        } else {
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];

            if (this.state.orders[key].id === eventId) {
              obj["state"] = "error";
              //console.log ("set err")
            }
            neworders.push(obj);
          }
          this.setState({ orders: neworders, isExecuting: false });
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });
        }
      });
  };

  changeHandlerSKU = async (event) => {
    //console.log(event.target.name, event.target.value)
    let value = event.target.value;
    this.setState({ read_sku: value });
    let validSKU = false;
    if (value.length > 12) {
      for (var key in this.state.orders) {
        if (this.state.orders[key].product_id == value) {
          let obj = this.state.orders[key];
          //console.log("este es", obj, key)
          let qty = 1;

          if (obj["qtyPicked"]) {
            qty = obj["qtyPicked"] + 1;
          }

          if (qty <= obj.productQty) {
            await this.handlePick(key, qty);

            this.setState({ read_sku: "" });
            toast.success("OK:" + "Lectura del sku:" + value + " correcta", {
              closeButton: true,
            });
          } else {
            this.setState({ read_sku: "" });
            toast.error("NOK:" + "Cantidad del SKU" + value + " superada", {
              closeButton: true,
            });
          }
          validSKU = true;
        }
      }

      if (!validSKU) {
        toast.error("NOK:" + "SKU: " + value + " no encontrado", {
          closeButton: true,
        });
      }
    }
  };

  handlePick = async (producto, qty) => {
    //console.log(producto, this.state.orders)
    let neworders = [];
    let product_ids = "";
    let totalProd = 0;
    let totalPicked = 0;

    this.setState({ isExecuting: true });
    for (var key in this.state.orders) {
      let obj = this.state.orders[key];
      //console.log(this.state.orders[key])

      if (this.state.orders[key].id == producto) {
        if (qty) {
          obj["qtyPicked"] = qty;
          if (qty === this.state.orders[key].productQty) {
            obj["isPicked"] = 1;
          } else {
            obj["isPicked"] = -1;
          }
        } else {
          obj["qtyPicked"] =
            this.state.orders[key].isPicked === -1
              ? this.state.orders[key].productQty
              : 0;
          obj["isPicked"] = this.state.orders[key].isPicked === -1 ? 1 : -1;
        }
        //obj["qtyPicked"]= this.state.orders[key].isPicked===-1?1:-1;
      }
      neworders.push(obj);
    }

    for (var key in neworders) {
//      console.log(neworders[key]);
      if (neworders[key].isValid) {
      //  console.log("valido va")
        totalProd++;
      }
      if (neworders[key].qtyPicked > 0) {
        product_ids =
          product_ids +
          "|" +
          neworders[key].product_id +
          "," +
          neworders[key].qtyPicked +
          "," +
          neworders[key].productQty;
      }

      if (neworders[key].isPicked > 0 && neworders[key].isValid) {
        totalPicked++;
      }
    }
    if (product_ids.length > 1) {
      product_ids = product_ids.substr(1, product_ids.length);
    }

    let progress_i = Math.ceil((totalPicked / totalProd) * 100);
    //console.log("progress_i", progress_i, totalPicked, totalProd);
    let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
    //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";

    await axios({
      url: url,
      method: "put",
      data: {
        WO_flowID: this.state.WO_Id,
        WO_TaskID: this.state.WO_TaskId,
        params: [
          { var: "picking_products_i", value: product_ids },
          { var: "progress_i", value: progress_i },
          //{ var :"pedido_completo2", value : progress_i},
        ],
      },
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ isExecuting: false });
      });

    this.setState({ orders: neworders, progress_i: progress_i });
  };
  //DEPRECATED
  deleteShipping = async (eventId) => {
    let url = API_CONFIG.base_url + "/externalApi/testRestApi/";
    this.setState({ isExecuting: true });
    await axios
      .post(url, { params: 1 })
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
        if (data.resp === "OK") {
          this.setState({ isDeleted: true });
          toast.success(
            "OK:" + "Se elimino el cobro de envio del pedido:" + eventId,
            {
              closeButton: true,
            }
          );
        }
        this.setState({ isExecuting: false });
      });
  };

  updateOtherOrder = async (eventId) => {
    if (this.state.pedido_parcial === "SI") {
      let url = API_CONFIG.xflowurl + "/engine-users/get-var-by-flow";
      axios({
        url: url,
        method: "post",
        data: {
          varname: "order_id",
          varvalue: this.state.order_id,
          flowcode:
            this.state.flowcode === "flowBolCI07"
              ? "flowBolCI01"
              : "flowBolCI07",
        },
        headers: {
          crossdomain: true,
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
      })
        .then((response) => response.data)
        .then((data) => {
          
          if (data.result.length === 1) {
            let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
            axios({
              url: url,
              method: "put",
              data: {
                WO_flowID: data.result[0].WFPFLOWID,
                WO_TaskID: -10,
                params: [
                  { var: "skus", value: JSON.stringify(this.state.skus) },
                  {
                    var: "mixed_skus",
                    value: JSON.stringify(this.state.mixed_skus),
                  },
                ],
              },
              headers: {
                crossdomain: true,
                "Access-Control-Allow-Origin": "*",
                origin: "x-requested-with",
                "Access-Control-Allow-Headers":
                  "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.state.token,
              },
            })
              .then((response) => response.data)
              .then((data) => {
                //console.log("res:", data);
              });
          }
        });
    }
  };

  /*CHECK HERE*/
  addOrder = async () => {
    let newOrders = this.state.orders;
    let newId = newOrders.length + 1;

    const skus = this.state.skus;
    const mixed_skus = this.state.mixed_skus;

    let dataNewOrder = {
      order_id: this.state.order_id,
      customer_id: this.state.customer_id,
      product_id: this.state.product_id,
      sku: this.state.productSKU,
      user: this.state.username,
      quantity: Array.isArray(this.state.productQty)
        ? this.state.productQty[0]
        : this.state.productQty,
    };
    console.log("dataNewOrder", dataNewOrder, this.state);

    this.setState({ isExecuting: true });

    await axios({
      method: "post",
      crossdomain: true,
      // headers: { 'Content-Type': 'application/json' },
      url:
        this.state.store_url +
        "externalapi/execute/addOrderProduct/key/" +
        OP_SECRET_KEY,
      data: dataNewOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log(data);
        //console.log(newId);
        if (data.success === "Order has been modified successfully") {
          //	console.log(this.props.match.params.WO_Id);

          newOrders.push({
            id: newId,
            product_id: this.state.product_id,
            productName: this.state.productName,
            //productPrice:this.state.productPrice,
            //productQty:this.state.productQty,
            productPrice: parseFloat(this.state.productPrice).toFixed(2),
            productQty: Array.isArray(this.state.productQty)
              ? this.state.productQty[0]
              : this.state.productQty,
            productSKU: this.state.productSKU,
            isPicked: -1,
            isValid: true,
            productModel: this.state.productModel,
            productImage: this.state.productImage,
            state: "loaded",
          });

          let storeCode = this.state.officecode;

          if (this.state.flowcode === "flowBolCI07") {
            storeCode = this.state.officecodeONLINE;
          }
          skus.push({
            NAME: this.state.productSKU,
            VALUE: Array.isArray(this.state.productQty)
              ? this.state.productQty[0]
              : this.state.productQty,
          });
          mixed_skus.push({
            store: storeCode,
            sku: this.state.productSKU,
            qty: Array.isArray(this.state.productQty)
              ? this.state.productQty[0]
              : this.state.productQty,
          });

          console.log("storeCode", storeCode);

          console.log("skus", skus);
          console.log("mixed_skus", mixed_skus);

          let monto_total = 0;
          let sub_total = 0;
          //console.log(this.state.orders)
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];
            let productPrice = parseFloat(newOrders[key].productPrice);
            let productQty = newOrders[key].productQty;
            let total = parseFloat(productQty) * parseFloat(productPrice);
            monto_total = monto_total + total;
            sub_total = sub_total + total;
          }
          monto_total = (
            parseFloat(monto_total) +
            parseFloat(this.state.costo_envio) -
            parseFloat(this.state.descuento)
          ).toFixed(2);

          var totalProd = 0;
          var totalPicked = 0;
          var product_ids = "";
          for (var key in newOrders) {
            totalProd++;
            if (newOrders[key].isPicked > 0) {
              product_ids =
                product_ids +
                "|" +
                newOrders[key].product_id +
                "," +
                newOrders[key].productQty +
                "," +
                newOrders[key].productQty;
              totalPicked++;
            }
          }

          if (product_ids.length > 1) {
            product_ids = product_ids.substr(1, product_ids.length);
          }
          let progress_i = Math.ceil((totalPicked / totalProd) * 100);

          this.setState({
            ...this.state,
            monto_total: monto_total,
            mixed_skus: mixed_skus,
            skus: skus,
            sub_total: parseFloat(sub_total, 2).toFixed(2),
          });
          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "monto_total", value: monto_total },
                { var: "picking_products_i", value: product_ids },
                { var: "progress_i", value: progress_i },
                { var: "subtotal", value: parseFloat(sub_total, 2).toFixed(2) },
                { var: "skus", value: JSON.stringify(skus) },
                { var: "mixed_skus", value: JSON.stringify(mixed_skus) },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });
          //CHECK!!!
          /*
          url = API_CONFIG.base_url + "/externalApi/updateProductsByOrderID/";
          axios({
            url: url,
            method: "post",
            data: {
              flowId: this.props.match.params.WO_Id,
              order_id: this.state.order_id,
              SKU: this.state.productSKU,
              quantity: Array.isArray(this.state.productQty)
                ? this.state.productQty[0]
                : this.state.productQty,
              org: this.state.org,
            },
            headers: { apikey: "xflow1506103c404d6dccaf787e44cf123456789" },
          });
          */

          toast.success("OK:" + data.success, {
            closeButton: true,
          });
          this.updateOrdenesyPagos();
        } else {
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });
        }

        this.setState({ isExecuting: false, orders: newOrders, modal3: false });
      });

    this.updateOtherOrder();
  };

  handleDelete = (producto) => {
    //console.log(producto)
    this.setState({
      accion: "eliminar",
      modal5: true,
      mensaje: "Eliminar este producto?",
      id_producto: producto,
    });
  };

  handlePrice = (producto) => {
    //console.log(producto)
    this.setState({
      accion: "cambiar",
      modal5: true,
      mensaje: "Cambiar el precio al producto?",
      id_producto: producto,
    });
  };

  handleEdit = (producto, type) => {
    //console.log(producto)
    this.setState({
      accion: type, //  "editar"+type,
      modal5: true,
      mensaje: type + " la cantidad de este producto?",
      id_producto: producto,
    });
  };

  /*CHECK HERE*/
  handleCommitEdit = async (eventId, type) => {
    //console.log("edit"+eventId+"type:"+type);

    let neworders = [];
    let product_id;
    let productQty = 0;
    let productSKU;
    let oldOrders = this.state.orders;
    let newSkus = [];
    const mixed_skus = this.state.mixed_skus;
    for (var key in oldOrders) {
      let obj = oldOrders[key];

      if (oldOrders[key].id === eventId) {
        //obj["state"]= "deleting"
        //console.log(oldOrders[key])

        product_id = oldOrders[key].product_id;
        productQty = parseInt(oldOrders[key].productQty);
        productSKU = oldOrders[key].productSKU;

        obj["productQty"] = type === "Add" ? productQty + 1 : productQty - 1;
      }
      neworders.push(obj);

      //NUEVA LOGICA POR PICKUP

      let pos = mixed_skus.findIndex(
        (item) => item.sku.toString() === oldOrders[key].productSKU.toString()
      );
      console.log("pos", pos);
      if (pos >= 0) {
        console.log("es!!!!", mixed_skus[pos]);
        mixed_skus[pos]["qty"] = obj["productQty"];
        this.setState({ mixed_skus: mixed_skus });
      }
      newSkus.push({ NAME: productSKU, VALUE: obj["productQty"] });

      //NUEVA LOGICA POR PICKUP
    }

    var dataDelOrder = {
      order_id: this.state.order_id,
      customer_id: this.state.customer_id,
      product_id: product_id,
      sku: productSKU,
      user: this.state.username,
      quantity: type === "Add" ? productQty + 1 : productQty - 1,
    };

    this.setState({ isExecuting: true });
    console.log("dataDelOrder", dataDelOrder);

    console.log("skus", this.state);
    console.log("nwe skus", JSON.stringify(newSkus));
    console.log("new mixed_skus", JSON.stringify(mixed_skus));

    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        this.state.store_url +
        "externalapi/execute/editOrderProduct/key/" +
        OP_SECRET_KEY,
      data: dataDelOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.success === "Order has been modified successfully") {
          let monto_total = 0;
          let sub_total = 0;
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];
            let productPrice = oldOrders[key].productPrice;
            let productQty = oldOrders[key].productQty;
            let total = productQty * productPrice;
            monto_total = monto_total + total;
            sub_total = sub_total + total;
          }
          monto_total = (
            parseFloat(monto_total) +
            parseFloat(this.state.costo_envio) -
            parseFloat(this.state.descuento)
          ).toFixed(2);
          this.setState({
            ...this.state,
            monto_total: monto_total,
            sub_total: parseFloat(sub_total, 2).toFixed(2),
          });
          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";

          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "monto_total", value: monto_total },
                { var: "subtotal", value: parseFloat(sub_total, 2).toFixed(2) },
                { var: "skus", value: JSON.stringify(newSkus) },
                { var: "mixed_skus", value: JSON.stringify(mixed_skus) },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });
          /*
          url = API_CONFIG.base_url + "/externalApi/updateProductsByOrderID/";
          axios({
            url: url,
            method: "post",
            data: {
              flowId: this.props.match.params.WO_Id,
              order_id: this.state.order_id,
              SKU: productSKU,
              quantity: type === "Add" ? productQty + 1 : productQty - 1,
              org: this.state.org,
            },
            headers: { apikey: "xflow1506103c404d6dccaf787e44cf123456789" },
          });
          */
          this.updateOrdenesyPagos();
          this.setState({ isExecuting: false });
          //this.setState({ orders:neworders });
          //console.log(this.state.orders);
          toast.success("OK:" + data.success, {
            closeButton: true,
          });
        } else {
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];

            if (this.state.orders[key].id === eventId) {
              obj["state"] = "error";
              //console.log ("set err")
            }
            neworders.push(obj);
          }
          this.setState({ orders: neworders, isExecuting: false });
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });
        }
      });
    this.updateOtherOrder();
  };
  /*CHECK HERE*/
  handleCommitDelete = async (eventId) => {
    console.log("del" + eventId);
    let neworders = [];
    let product_id;
    let productQty;
    let productSKU;
    const skus = this.state.skus;
    const mixed_skus = this.state.mixed_skus;
    for (var key in this.state.orders) {
      let obj = this.state.orders[key];
      //console.log(obj);
      if (this.state.orders[key].id === eventId) {
        obj["state"] = "deleting";
        product_id = this.state.orders[key].product_id;
        productQty = this.state.orders[key].productQty;
        productSKU = this.state.orders[key].productSKU;
      }
      neworders.push(obj);
    }

    var dataDelOrder = {
      order_id: this.state.order_id,
      customer_id: this.state.customer_id,
      product_id: product_id,
      user: this.state.username,
      sku: productSKU,
      quantity: productQty,
    };

    //console.log(dataDelOrder)
    this.setState({ isExecuting: true });
    await axios({
      method: "post",
      crossdomain: true,
      // headers: { 'Content-Type': 'application/json' },
      url:
        this.state.store_url +
        "externalapi/execute/deleteOrderProduct/key/" +
        OP_SECRET_KEY,
      data: dataDelOrder, //JSON.stringify(dataDelOrder)
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.success === "Order has been modified successfully") {
          const orders = this.state.orders.filter((e) => e.id !== eventId);
          this.setState({ orders });

          let oldOrders = this.state.orders;
          let monto_total = 0;
          let sub_total = 0;
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];
            let productPrice = oldOrders[key].productPrice;
            let productQty = oldOrders[key].productQty;
            let total = productQty * productPrice;
            monto_total = monto_total + total;
            sub_total = sub_total + total;
          }
          monto_total = (
            parseFloat(monto_total) +
            parseFloat(this.state.costo_envio) -
            parseFloat(this.state.descuento)
          ).toFixed(2);
          var totalProd = 0;
          var totalPicked = 0;
          var product_ids = "";
          for (var key in orders) {
            totalProd++;
            if (orders[key].isPicked > 0) {
              product_ids =
                product_ids +
                "|" +
                orders[key].product_id +
                "," +
                orders[key].productQty +
                "," +
                orders[key].productQty;
              totalPicked++;
            }
          }
          if (product_ids.length > 1) {
            product_ids = product_ids.substr(1, product_ids.length);
          }

          //          console.log(totalPicked);
          //          console.log(totalProd);
          let progress_i = Math.ceil((totalPicked / totalProd) * 100);

          if (totalProd === 0) {
            progress_i = 100;
          }

          this.setState({
            ...this.state,
            monto_total: monto_total,
            sub_total: parseFloat(sub_total, 2).toFixed(2),
          });

          //NUEVA LOGICA POR PICKUP

          let pos = mixed_skus.findIndex(
            (item) => item.sku.toString() === productSKU.toString()
          );

          let pos2 = skus.findIndex(
            (item) => item.NAME.toString() === productSKU.toString()
          );
          console.log(pos, pos2);
          if (pos >= 0) {
            mixed_skus.splice(pos, 1);
          }

          if (pos2 >= 0) {
            skus.splice(pos2, 1);
          }

          //NUEVA LOGICA POR PICKUP

          let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
          //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";

          axios({
            url: url,
            method: "put",
            data: {
              WO_flowID: this.state.WO_Id,
              WO_TaskID: this.state.WO_TaskId,
              params: [
                { var: "pedido_modificado", value: "SI" },
                { var: "monto_total", value: monto_total },
                { var: "picking_products_i", value: product_ids },
                { var: "progress_i", value: progress_i },
                { var: "subtotal", value: parseFloat(sub_total, 2).toFixed(2) },
                { var: "skus", value: JSON.stringify(skus) },
                { var: "mixed_skus", value: JSON.stringify(mixed_skus) },
              ],
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          });
          //check!!!
          /*
          url = API_CONFIG.base_url + "/externalApi/updateProductsByOrderID/";
          axios({
            url: url,
            method: "post",
            data: {
              flowId: this.props.match.params.WO_Id,
              order_id: this.state.order_id,
              SKU: productSKU,
              quantity: 0,
              org: this.state.org,
            },
            headers: { apikey: "xflow1506103c404d6dccaf787e44cf123456789" },
          });
          */
          this.updateOrdenesyPagos();
          this.setState({
            isExecuting: false,
            mixed_skus: mixed_skus,
            skus: skus,
          });

          toast.success("OK:" + data.success, {
            closeButton: true,
          });
        } else {
          neworders = [];
          for (var key in this.state.orders) {
            let obj = this.state.orders[key];
            //console.log(obj);
            if (this.state.orders[key].id === eventId) {
              obj["state"] = "error";
              //console.log ("set err")
            }
            neworders.push(obj);
          }
          this.setState({ orders: neworders });
          toast.error("ERROR:" + data.descResp, {
            closeButton: true,
          });

          this.setState({ isExecuting: false });
        }
      });
    this.updateOtherOrder();
  };

  LoadTable = async () => {
    var store_id = 0;
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    let WO_Id = params.get("wfpflowid");
    let WO_TaskId = params.get("wfptaskid");

    let officecode = params.get("officecode");
    let mixed_skus = [];
    let skus = [];
    let pedido_parcial = "NO";
    let options;
    let formButtons = [];
    //console.log(params)
    if (params.get("options")) {
      options = params.get("options").split(",");
      for (let o in options) {
        formButtons.push({ optioncode: "op" + o, optionname: options[o] });
      }
    } else {
      this.setState({ readOnly: true });
    }
    let popup = params.get("popup");

    if (popup) {
      console.log("popup", popup);
      this.setState({ reload: true });
    }

    formButtons.sort(function (a, b) {
      return b.optionname > a.optionname
        ? -1
        : b.optionname < a.optionname
        ? 1
        : 0;
    });
    //console.log("WO_TaskId", WO_TaskId);
    this.setState({
      isLoadedTable: false,
      WO_Id: WO_Id,
      WO_TaskId: WO_TaskId,
      loadingData: true,
      token: token,
      formButtons: formButtons,
      officecode: officecode,
    });

    //console.log("sta", this.state.WO_TaskId);
    //console.log("sta", this.state.WO_Id);

    var order_id = 0;
    var customer_id = 0;
    var nombre_cliente = "";
    var pedido_modificado = "";
    var costo_envio = "";
    var descuento = "";
    var direccion_1 = "";
    var zona = "";

    var paymentName = "";
    var picking_products_i = "";
    var store_url = API_CONFIG.casaideas + "/";
    var tipo_envio_code = "";
    var org = "CASAIDEAS";
    var tipo_pago = "";
    var progress_i = 0;
    var userId = -1;
    let source = "";
    var flowcode;
    let taskIdDef= 0
    await axios({
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/get-task-details",
      //url : "http://localhost:4042/api/v1"+"/engine-users/get-task-details",
      data: {
        wfptaskid: WO_TaskId,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("data", data);
        if (data.status) {
          flowcode = data.task.FLOWCODE;

          //si es un de pickup o regalables marcamos
          if (flowcode ==="flowBolCI07" || flowcode==="flowBolCI08" ||  flowcode=== "flowBolCI06"){
            //console.log("SI valido")
            taskIdDef = data.task.TASKID	
          }
          let taskName = data.task.TASK;
          if (taskName === "Pedido Completado") {
            this.setState({ hideSection: true });
          }
          for (let v in data.flowvars) {
            let flowVar = data.flowvars[v];
            if (flowVar.VARNAME === "order_id") {
              order_id = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "customer_id") {
              customer_id = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "nombre_cliente") {
              nombre_cliente = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "pedido_modificado") {
              pedido_modificado = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "shipping_cost") {
              costo_envio = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "discount") {
              descuento = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "direccion_1") {
              direccion_1 = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "zona") {
              zona = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "tipo_envio_code") {
              tipo_envio_code = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "tipo_pago") {
              tipo_pago = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "picking_products_i") {
              picking_products_i = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "progress_i") {
              progress_i = parseInt(flowVar.VARVALUE);
            }

            if (flowVar.VARNAME === "pedido_parcial") {
              pedido_parcial = flowVar.VARVALUE;
            }

            if (flowVar.VARNAME === "officecodeONLINE") {
              source = flowVar.VARVALUE;
            }

            if (flowVar.VARNAME === "mixed_skus") {
              try {
                mixed_skus = JSON.parse(flowVar.VARVALUE);
              } catch (ex) {
                console.log("EX", ex);
              }
            }

            if (flowVar.VARNAME === "skus") {
              try {
                skus = JSON.parse(flowVar.VARVALUE);
              } catch (ex) {
                console.log("EX", ex);
              }
            }

            this.setState({ [flowVar.VARNAME]: flowVar.VARVALUE });
          }
        }
      });

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      url:
        API_CONFIG.xflowurl +
        "/customcomponents/get-single/resumen_ordenes/id_pedido",
      data: { id: order_id },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("response transaction log execution", data);
        let fechainicio = data.data.FECHAINICIO;
        let fechadeejecucion = data.data.FECHADEEJECUCION;
        let estado = data.data.ESTADO;
        let observaciones = data.data.OBSERVACIONES;
        let nota_sap = data.data.NOTA_SAP;
        let metododepago2 = data.data.METODODEPAGO2;

        this.setState({
          ...this.state,
          fechainicio,
          fechadeejecucion,
          estado,
          observaciones,
          nota_sap,
          metododepago2,
        });
      })
      .catch((error) => {
        console.log("error transaction log execution" , error);
      });

    var paymentName = tipo_pago;
    //console.log(tipo_envio_code, paymentName);
    this.setState({ shippId: tipo_envio_code, pedido_parcial: pedido_parcial });
    var isSupervisor, isAdmin;
    await axios({
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/decrypt-token",
      //url : "http://localhost:4042/api/v1"+"/engine-users/decrypt-token",
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("data", data);
        if (data.status) {
          userId = data.token.USERID;

          isSupervisor = data.token.USERGROUP === "SINGLEUSER" ? -1 : 1;
          isAdmin = data.token.USERGROUP === "ADMIN" ? 1 : -1;

          this.setState({
            ...this.state,
            username: data.token.USERNAME,
            organization: data.token.ORGANIZATIONCODE,
          });
        }
      });

    let url = API_CONFIG.casaideas + "/externalapi/execute/getorderById";
    await axios({
      crossdomain: true,
      url: url,
      method: "post",
      data: {
        username: casaideas_credentials.user,
        password: casaideas_credentials.password,
        order_id: order_id,
        customer_id: customer_id,
        adminpath: "matrix",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        //   console.log("data", data);
        var arr = [];
        var i = 0;

        if (data.success) {
          data = {
            resp: "OK",
            rows: data.response[0].products,
          };
        }
        //   console.log("source", source);
        let skusDup=[]
        if (data.resp === "OK" && data.rows.length > 0) {
          //console.log(data.resp);
          var arrayPicked = [];

          if (picking_products_i !== null) {
            arrayPicked = picking_products_i.split("|");
          }

          /*      
          console.log("data.rows:::", data.rows);
          console.log("mixed_skus", mixed_skus);
          console.log("skus", skus, flowcode, pedido_parcial);
          */
          
          for (var key in data.rows) {
            store_id = data.rows[0].store_id;
          //  console.log(key, data.rows[key].sku, flowcode);

            let rep= 0;

            for (let k in mixed_skus) {
            //  console.log("comp", key.sku , mixed_skus[k].sku)
              if (data.rows[key].sku == mixed_skus[k].sku ){
                rep++
              }
            }

            if (rep>1){
              let nextId = skusDup.length
              skusDup.push({
                id: 1000+nextId,
              product_id: data.rows[key].product_id,
              productName: data.rows[key].name,
              productModel: data.rows[key].model,
              productPrice: parseFloat(
                data.rows[key].price
                  .replace("Bs.", "")
                  .replace(".", "")
                  .replace(",", ".")
              ).toFixed(2),
              productQty: 0,
              productImage: data.rows[key].image, //data.store_url+"image/cache/"+img,
              productSKU: data.rows[key].sku,
              state: "loaded",
              isPicked: false,
              qtyPicked: 0,
              isValid:  false,
              })
            }

            if (store_id == 62) {
              store_id = 59;
            }

            let isValid = false;
            let _productQty = 0;
            if (pedido_parcial == "SI") {
              //console.log("PARCIAL!!!!!");
              for (let k in mixed_skus) {
                let sku = mixed_skus[k];

                //console.log(this.state)
                if (sku.sku.toString() === data.rows[key].sku.toString()) {
                  if (flowcode === "flowBolCI07") {
                    //console.log("tienda extra");
                    if (source === sku.store) {
                      _productQty = sku.qty;
                      isValid = true;
                    }
                  } else {
                //    console.log("tienda orig", officecode, sku.store);
                    if (officecode === sku.store) {
                      _productQty = sku.qty;
                      isValid = true;
                    }else{
                      console.log("CLONAMOS CON INVALID.................")
                    }
                  }
                }
              }
            } else {
              //quitamos _productQty = data.rows[key].quantity;

              //aumentamos
              if (flowcode === "flowBolCI01") {
                _productQty = data.rows[key].quantity;
                isValid = true;
              } else {
                for (let k in mixed_skus) {
                  let sku = mixed_skus[k];

                  if (sku.sku.toString() === data.rows[key].sku.toString()) {
                    if (flowcode === "flowBolCI07") {
                      //console.log("tienda extra");
                      if (source === sku.store) {
                        _productQty = sku.qty;
                        isValid = true;
                      }
                    } else {
                      //console.log("tienda orig");

                      if (officecode === sku.store) {
                        _productQty = sku.qty;
                        isValid = true;
                      }
                    }
                  }
                }
              }
            }

            if (_productQty === 0) {
              _productQty = data.rows[key].quantity;
            }

            var arrPicked = arrayPicked.filter(function (e) {
              return e.substr(0, e.indexOf(",")) == data.rows[key].product_id;
            });

            var isPicked = -1;
            var qtyPicked = 0;
            if (arrPicked.length > 0) {
              let data = arrPicked[0].split(",");
              if (data[1] == data[2]) {
                isPicked = 1;
              }
              qtyPicked = parseInt(data[1]);
            }
            //console.log("sku", data.rows[key].sku, isValid);

            arr.push({
              id: key,
              product_id: data.rows[key].product_id,
              productName: data.rows[key].name,
              productModel: data.rows[key].model,
              productPrice: parseFloat(
                data.rows[key].price
                  .replace("Bs.", "")
                  .replace(".", "")
                  .replace(",", ".")
              ).toFixed(2),
              productQty: parseInt(_productQty),
              productImage: data.rows[key].image, //data.store_url+"image/cache/"+img,
              productSKU: data.rows[key].sku,
              state: "loaded",
              isPicked: isPicked,
              qtyPicked: qtyPicked,
              isValid:  taskIdDef===7?true: isValid,
            });
          }
        }

        

        skusDup.map(function (a) {
         // console.log(a)

          let skuO = data.rows.find(
            (item) => item.sku.toString() === a.productSKU.toString()
          );

          let skuM = mixed_skus.find(
            (item) => item.sku.toString() === a.productSKU.toString()
          );
          let fixedQty = skuO.quantity - parseInt(skuM.qty)
       //   console.log(skuO, skuM, fixedQty)
          a["productQty"] = fixedQty
          a["green"] = true
        });


        arr = arr.sort(function (a, b) {
          return b.isValid - a.isValid;
        });
 
        arr = arr.concat(skusDup);
        console.log("arr", arr,  taskIdDef)

        var fcosto_envio = costo_envio
          ? parseFloat(costo_envio, 2).toFixed(2)
          : 0;

        var fdescuento = descuento ? parseFloat(descuento, 2).toFixed(2) : 0;
        this.setState({
          ...this.state,
          orders: arr,
          org: org,
          mixed_skus: mixed_skus,
          skus: skus,
          store_id: store_id,
          store_url: store_url,
          isLoadedTable: true,
          customerName: nombre_cliente,
          pedido_parcial: pedido_parcial,
          customerAdd: direccion_1,
          customerZone: zona,
          customer_id: customer_id,
          existingPreviousOrder: false,
          isDeleted: false,
          order_id: order_id,
          costo_envio: fcosto_envio,
          descuento: fdescuento,
          pre_costo_envio: fcosto_envio,
          pre_descuento: fdescuento,
          isSupervisor: isSupervisor,
          isAdmin: isAdmin,
          progress_i: progress_i,
          userId: userId,
          flowcode: flowcode,
          //loadingData:false,
        });

        let monto_total = 0;
        let sub_total = 0;
        for (var key in this.state.orders) {
          let obj = this.state.orders[key];
          let productPrice = this.state.orders[key].productPrice;
          let productQty = this.state.orders[key].productQty;
          let total = productQty * productPrice;
          monto_total = monto_total + total;
          sub_total = sub_total + total;
        }
        //console.log("monto_total", monto_total, fcosto_envio, fdescuento);
        monto_total = (
          parseFloat(monto_total) +
          parseFloat(fcosto_envio) -
          parseFloat(fdescuento)
        ).toFixed(2);
        this.setState({
          ...this.state,
          monto_total: monto_total,
          sub_total: parseFloat(sub_total, 2).toFixed(2),
        });

        //console.log(this.state.store_url, _store_url)
        var dataSH = {
          language_id: 3,
          username: this.state.username,
        };

        axios({
          method: "post",
          // headers: {  'Access-Control-Allow-Origin':'*' },
          crossdomain: true,

          url:
            store_url +
            "externalapi/execute/getShippingTypes/key/" +
            OP_SECRET_KEY,
          data: dataSH, //JSON.stringify(dataDelOrder)
        })
          .then((response) => response.data)
          .then((data) => {
            var shippingTypes = [];
            //console.log(data)
            for (var s in data.response) {
              if (data.response[s].SHIPPINGTYPEID == tipo_envio_code) {
                shippingTypes.push({
                  value: data.response[s].SHIPPINGTYPEID,
                  text: data.response[s].SHIPPINGNAME,
                  type: data.response[s].TYPE,
                  checked: true,
                });
              } else {
                shippingTypes.push({
                  value: data.response[s].SHIPPINGTYPEID,
                  text: data.response[s].SHIPPINGNAME,
                  type: data.response[s].TYPE,
                });
              }
            }
            this.setState({
              ...this.state,
              shippingTypes: shippingTypes,
            });

            axios({
              method: "post",
              // headers: {  'Access-Control-Allow-Origin':'*' },
              crossdomain: true,
              url:
                store_url +
                "externalapi/execute/getPaymentTypes/key/" +
                OP_SECRET_KEY,
              data: dataSH, //JSON.stringify(dataDelOrder)
            })
              .then((response) => response.data)
              .then((data) => {
                var paymentTypes = [];
                //console.log("paymentName",paymentName)
                var payId = 1;
                let metodo_pago = "";
                for (var s in data.response) {
                  if (data.response[s].PAYMENTNAME == paymentName) {
                    payId = data.response[s].PAYMENTTYPEID;
                    paymentTypes.push({
                      value: data.response[s].PAYMENTTYPEID,
                      text: data.response[s].PAYMENTNAME,
                      type: data.response[s].TYPE,
                      checked: true,
                    });
                    metodo_pago = data.response[s].PAYMENTNAME;
                  } else {
                    paymentTypes.push({
                      value: data.response[s].PAYMENTTYPEID,
                      text: data.response[s].PAYMENTNAME,
                      type: data.response[s].TYPE,
                    });
                  }
                }
                this.setState({
                  ...this.state,
                  paymentTypes: paymentTypes,
                  payId: payId,
                  metodo_pago: metodo_pago,
                  loadingData: false,
                });
              });
          });
      });
  };

  setProduct = async (event) => {
    var id = event.target.id.substr(5, 10);

    var task = this.state.searchResults[id];

    //console.log(task)

    this.setState({
      ...this.state,
      product_id: this.state.searchResults[id].product_id,
      productName: this.state.searchResults[id].name,
      productImageBig: this.state.searchResults[id].productImage,
      productPrice: parseFloat(this.state.searchResults[id].price),
      productImage: this.state.searchResults[id].thumb,
      productModel: this.state.searchResults[id].productModel,
      productSKU: this.state.searchResults[id].productSKU,
      productQty: 1,
      productMaxQty: this.state.searchResults[id].quantity,
      isExecuting: false,
      showProductsList: false,
      disabledAdd: false,
    });
  };

  searchProduct = async () => {
    this.setState({
      showProductsList: true,
      isExecuting: true,
      searchResults: [],
      disabledAdd: true,
    });
    let url =
      this.state.store_url +
      "customerapi/getFilterProductsElasticByKey/" +
      this.state.store_id +
      "/" +
      this.state.SKUcode;

    await axios
      .get(
        this.state.store_url +
          "customerapi/getFilterProductsElasticByKey/" +
          this.state.store_id +
          "/" +
          this.state.SKUcode

        //{crossdomain: true }
      )
      /*
          await axios({
                 url: url,
                 method: 'get',
                 headers: {
                      'Access-Control-Allow-Origin': '*'
                    },
                     crossdomain: true

            })*/
      .then((response) => response.data)
      .then((data) => {
        if (data.status === "success") {
          var results = [];

          for (var i = 0; i < data.response.length; i++) {
            results.push({
              id: i,
              key: "key" + data.response[i].product_id,
              product_id: data.response[i].product_id,
              name: data.response[i].name,
              quantity: data.response[i].quantity,
              thumb: data.response[i].thumb,
              productSKU:
                data.response[i].productSKU === undefined
                  ? "-"
                  : data.response[i].productSKU,
              productModel:
                data.response[i].productModel === undefined
                  ? "-"
                  : data.response[i].productModel,
              productImage: data.response[i].thumb.replace("60x60", "400x400"),
              price: parseFloat(data.response[i].price),
              special: data.response[i].special,
              selected: (
                <>
                  <span
                    key={"table_" + i}
                    id={"table_" + i}
                    onClick={this.setProduct}
                    className="tableLink"
                  >
                    <MDBIcon
                      icon="check-circle"
                      key={"icon_" + i}
                      id={"icon_" + i}
                      size="2x"
                      style={{ cursor: "pointer" }}
                      className="green-text"
                    />
                  </span>
                </>
              ),
            });
          }

          this.setState({
            ...this.state,
            searchResults: results,
            isExecuting: false,
          });
          //console.log(this.state.searchResults)
        } else {
          this.setState({
            ...this.state,
            product_id: 0,
            productName: "NO DATA",
            productPrice: 0,
            productQty: 10,
            productMaxQty: 0,
            isExecuting: false,
            disabledAdd: true,
          });
        }
      });
  };
  toggleModal = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  executeTask = async (option, optionName) => {
    var responseValidation = true;

    this.setState({ isExecuting: true });

    //console.log("op", option);
    //console.log("op", optionName);
    //console.log("state", this.state);

    let params = {
      comment_external: this.state.observacion,
    };
    //console.log(this.state.progress_i)

    //console.log("this.state.pedido_parcial ", this.state, optionName);
    if (
      optionName == "Pedido Armado" ||
      optionName == "Pedido listo para entrega" ||
      optionName == "Pedido listo" ||
      optionName == "Listo para entrega" ||
      (optionName == "Picking completado" && this.state.tipo_almacen === "nulo")
    ) {
      //console.log("VALIDA PROGRESS")
      if (this.state.progress_i < 100) {
        responseValidation = false;
        toast.warning("Debe pickear todos los productos", {
          closeButton: true,
        });
      }

      if (this.state.TRX === "" && this.state.pedido_parcial !== "SI") {
        responseValidation = false;
        toast.warning("Debe completar la TRX", { closeButton: true });
      }
      if (
        this.state.posicion_rack === "" &&
        this.state.pedido_parcial !== "SI"
      ) {
        responseValidation = false;
        toast.warning("Debe completar la Posición Rack", {
          closeButton: true,
        });
      }
      if (
        this.state.numero_factura === "" &&
        this.state.pedido_parcial !== "SI"
      ) {
        responseValidation = false;
        toast.warning("Debe completar la factura", { closeButton: true });
      }
      if (this.state.razon_social === "") {
        responseValidation = false;
        toast.warning("Debe completar la razon social", { closeButton: true });
      }
      if (this.state.nit === "") {
        responseValidation = false;
        toast.warning("Debe completar el Nit", { closeButton: true });
      }
      if (this.state.direccion_1 === "") {
        responseValidation = false;
        toast.warning("Debe completar la direccion", { closeButton: true });
      }
      /*
			if (this.state.direccion_2===""){
				responseValidation=false
				toast.warning("Debe completar la referencia", { closeButton: true });
			}
			*/
      if (this.state.latitude === "") {
        responseValidation = false;
        toast.warning("Debe completar la latitud", { closeButton: true });
      }
      if (this.state.longitude === "") {
        responseValidation = false;
        toast.warning("Debe completar la longitud", { closeButton: true });
      }
      if (this.state.telefono === "") {
        responseValidation = false;
        toast.warning("Debe completar el telefono", { closeButton: true });
      }
      params = {
        TRX: this.state.TRX,
        posicion_rack: this.state.posicion_rack,
        numero_factura: this.state.numero_factura,
        razon_social: this.state.razon_social,
        direccion_1: this.state.direccion_1,

        direccion_2: this.state.direccion_2,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        telefono: this.state.telefono,
        nit: this.state.nit,
      };
    } else if (optionName == "Picking completado") {
      //console.log("VALIDA PROGRESS2")

      if (this.state.progress_i < 100) {
        responseValidation = false;
        toast.warning("Debe pickear todos los productos", {
          closeButton: true,
        });
      }
    } else {
      if (this.state.observacion === "") {
        responseValidation = false;
        toast.warning("Debe completar la observacion", { closeButton: true });
      }
    }

    if (!responseValidation) {
      this.setState({
        ...this.state,
        responseTask: "NOK",
        isExecuting: false,
        modal6: false,
      });
    } else {
      this.setState({ responseTask: null });

      //console.log("ok", this.state);

      await axios({
        headers: {
          crossdomain: true,
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        url: API_CONFIG.xflowurl + "/engine-users/execute-task",
        //url : "http://localhost:4042/api/v1"+"/engine-users/execute-task",
        data: {
          parentid: this.state.WO_TaskId,
          id: this.state.WO_TaskId,
          isParent: this.state.isParent,
          optionValue: optionName,
          params: params,
        },
        method: "put",
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.status) {
            toast.success("Procesado con exito", {
              closeButton: true,
            });

            if (this.state.reload) {
              //console.log("reload parent");
              try {
                window.opener.location.reload();
              } catch (ex) {
                //console.log("error closing");
              }
              window.close();
            }
          } else {
            toast.error("Error ejecutando tareas, mensaje:" + data.message, {
              closeButton: true,
            });
          }
          this.setState({
            ...this.state,
            isExecuting: false,
            modal6: false,
          });
        });
    }
  };

  render() {
    return (
      <>
        <MDBContainer style={{ "max-width": "1024px", width: "100%" }}>
          {this.state.loadingData ? (
            <Loader idLoading={this.state.loadingData} />
          ) : (
            <>
              <Loader idLoading={this.state.isExecuting} />

              <h3
                style={{
                  zIndex: "1000",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                {" "}
                {"Pedido #" + this.state.order_id}
              </h3>

              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  SubTotal
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  <h6
                    style={{
                      zIndex: "1000",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.sub_total + " Bs."}
                  </h6>{" "}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  Descuento
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  <h6
                    style={{
                      zIndex: "1000",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.descuento + " Bs."}
                  </h6>{" "}
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  Costo de Envio
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  <h6
                    style={{
                      zIndex: "1000",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.costo_envio + " Bs."}
                  </h6>{" "}
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  {" "}
                  <h5
                    style={{
                      zIndex: "1000",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Total:
                  </h5>
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  {" "}
                  <h5
                    style={{
                      zIndex: "1000",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.monto_total + " Bs."}
                  </h5>
                </MDBCol>
              </MDBRow>

              {this.state.existingPreviousOrder ? (
                <MDBCard style={{ width: "98%" }} className="mb-3">
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <MDBAlert className="text-left align-top">
                        <h3 className="h3-responsive font-weight-bold mr-3">
                          <MDBIcon icon="exclamation-circle" className="mr-1" />{" "}
                          El cliente {this.state.customerName} tiene un pedido
                          aun no entregado
                        </h3>
                      </MDBAlert>
                    </MDBCol>
                  </MDBRow>

                  <hr />
                  <MDBRow className="text-right">
                    <MDBCol md="12">
                      <MDBBtn
                        size="sm"
                        color="primary"
                        onClick={() => {
                          this.setState({ modal4: true });
                        }}
                      >
                        {" "}
                        Revisar Pedido
                      </MDBBtn>

                      <MDBBtn
                        size="sm"
                        disabled={this.state.isDeleted}
                        color="danger"
                        onClick={() => {
                          //	this.deleteShipping("1616");
                          this.setState({
                            modal5: true,
                            mensaje: "eliminar la tarifa?",
                            accion: "eliminar tarifa",
                          });
                        }}
                      >
                        {" "}
                        Eliminar Tarifa de envio
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              ) : (
                ""
              )}

              {!this.state.hideSection && (
                <>
                  {this.state.isSupervisor > 0 ? (
                    <MDBRow className="text-right mt-1 mb-3">
                      <MDBCol size="12" sm="12" md="6" lg="4">
                        <MDBBtn
                          size="sm"
                          color="success"
                          onClick={() => {
                            this.setState({
                              modal3: true,
                              disabledAdd: true,
                              /*SKUcode:"" , */ productName: "",
                              productPrice: "",
                              productQty: "1",
                            });
                          }}
                        >
                          {" "}
                          <MDBIcon icon="cart-plus" /> Agregar Producto
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    ""
                  )}

                  <MDBRow
                    className="text-left mt-1 mb-3"
                    style={{
                      borderBottom: "1px solid #0062cc",
                      paddingBottom: 10,
                    }}
                  >
                    <MDBCol
                      col="7"
                      sm="7"
                      md="6"
                      lg="4"
                      className="col-7 ml-2 mr-2 text-left left no-border"
                    >
                      <div
                        style={{
                          fontSize: "1rem",
                          fontWeight: "600",
                          color: "#0062cc",
                        }}
                      >
                        Leer SKU
                      </div>
                      <MDBInput
                        id={"read_sku"}
                        key={"read_sku"}
                        name={"read_sku"}
                        className="input-sku"
                        style={{ border: "1px solid #0062cc", borderRadius: 8 }}
                        value={this.state.read_sku}
                        onChange={this.changeHandlerSKU}
                        size="sm"
                      />
                    </MDBCol>
                    {/**
                  <MDBCol  col= "3" sm="3" md="3" lg="1"  className="col-3 ml-2 mr-2 text-left left no-border"> 
                  <div style={{
                      fontSize:"1rem",
                      fontWeight:"600",
                      color:"#0062cc"
                    }}>Cantidad</div>
                  <MDBInput
                      
                      id={"qty_sku" }
                      key={"qty_sku" }
                      name={"qty_sku"}
                      className="qty-sku"
                      style={{border: "1px solid #0062cc" , borderRadius: 8  }}
                        value={this.state.qty_sku}
                        onChange={this.changeHandler}
                        size="sm"
                          />
                  </MDBCol>
                  */}
                  </MDBRow>

                  {this.state.isAdmin > 0 ? (
                    <>
                      <MDBRow className="my-4">
                        <MDBCol
                          col="7"
                          sm="7"
                          md="6"
                          lg="4"
                          className="col-7 ml-2 mr-2 text-left left"
                        >
                          <MDBInput
                            style={{ margin: "0" }}
                            id={"pre_descuento"}
                            key={"pre_descuento"}
                            name={"pre_descuento"}
                            value={this.state.pre_descuento}
                            onChange={this.changeHandler}
                            size="sm"
                            label="Descuento"
                          />
                        </MDBCol>

                        <MDBCol
                          col="3"
                          sm="3"
                          md="3"
                          lg="1"
                          className="col-3 ml-2 mr-2 text-left left"
                        >
                          <MDBBtn
                            style={{ marginLeft: "-40px" }}
                            size="sm"
                            color="primary"
                            disabled={this.state.readOnly}
                            onClick={() => {
                              this.setState({
                                modal5: true,
                                mensaje: "Actualizar descuento?",
                                accion: "actualizar descuento",
                              });
                            }}
                          >
                            {" "}
                            Actualizar
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="my-4">
                        <MDBCol
                          col="7"
                          sm="7"
                          md="6"
                          lg="4"
                          className="col-7 ml-2 mr-2 text-left left"
                        >
                          <MDBSelect
                            key="store"
                            style={{ margin: 0 }}
                            id="paymentType"
                            color="primary"
                            getValue={this.selectHandler2}
                            options={this.state.paymentTypes}
                            label="Tipo de Pago"
                          />
                        </MDBCol>

                        <MDBCol
                          col="3"
                          sm="3"
                          md="3"
                          lg="1"
                          className="col-3 ml-2 mr-2 text-left left"
                        >
                          <MDBBtn
                            style={{ marginLeft: "-40px" }}
                            size="sm"
                            color="primary"
                            disabled={this.state.readOnly}
                            onClick={() => {
                              this.setState({
                                modal5: true,
                                mensaje: "Cambiar Tipo de Pago?",
                                accion: "cambiar pago",
                              });
                            }}
                          >
                            {" "}
                            Actualizar
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="my-4">
                        <MDBCol
                          col="7"
                          sm="7"
                          md="6"
                          lg="4"
                          className="col-7 ml-2 mr-2 text-left left"
                        >
                          <MDBSelect
                            key="store"
                            style={{ margin: 0 }}
                            id="shippingType"
                            color="primary"
                            getValue={this.selectHandler1}
                            options={this.state.shippingTypes}
                            label="Tipo de Envio"
                          />
                        </MDBCol>

                        <MDBCol
                          col="3"
                          sm="3"
                          md="3"
                          lg="1"
                          className="col-3 ml-2 mr-2 text-left left"
                        >
                          <MDBBtn
                            style={{ marginLeft: "-40px" }}
                            size="sm"
                            color="primary"
                            disabled={this.state.readOnly}
                            onClick={() => {
                              this.setState({
                                modal5: true,
                                mensaje: "Cambiar Tipo de Envio?",
                                accion: "cambiar envio",
                              });
                            }}
                          >
                            {" "}
                            Actualizar
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="my-4">
                        <MDBCol
                          col="7"
                          sm="7"
                          md="6"
                          lg="4"
                          className="col-7 ml-2 mr-2 text-left left"
                        >
                          <MDBInput
                            id={"pre_costo_envio"}
                            key={"pre_costo_envio"}
                            name={"pre_costo_envio"}
                            value={this.state.pre_costo_envio}
                            onChange={this.changeHandler}
                            size="sm"
                            label="Costo de envio"
                          />
                        </MDBCol>

                        <MDBCol
                          col="3"
                          sm="3"
                          md="3"
                          lg="1"
                          className="col-3 ml-2 mr-2 text-left left"
                        >
                          <MDBBtn
                            style={{ marginLeft: "-40px" }}
                            size="sm"
                            color="primary"
                            disabled={this.state.readOnly}
                            onClick={() => {
                              this.setState({
                                modal5: true,
                                mensaje: "Actualizar costo de envio?",
                                accion: "actualizar costo de envio",
                              });
                            }}
                          >
                            {" "}
                            Actualizar
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.loadingData
                    ? ""
                    : this.state.orders.map((event) => (
                        <p key={"event1" + event.id} style={{ width: "98%" }}>
                          {this.state.readOnly ? (
                            <Event
                              id={event.id}
                              product_id={event.product_id}
                              productName={event.productName}
                              productQty={event.productQty}
                              productPrice={event.productPrice}
                              productModel={event.productModel}
                              productImage={event.productImage}
                              productSKU={event.productSKU}
                              onPrice={this.handlePrice}
                              handleChangePrice={this.handleChangePrice}
                              state={event.state}
                              isPicked={event.isPicked}
                              onDelete={null}
                              onEdit={null}
                              onPick={null}
                              isSupervisor={false}
                              isAdmin={false}
                            />
                          ) : (
                            <Event
                              id={event.id}
                              product_id={event.product_id}
                              productName={event.productName}
                              productQty={event.productQty}
                              productPrice={event.productPrice}
                              productModel={event.productModel}
                              productImage={event.productImage}
                              productSKU={event.productSKU}
                              onPrice={this.handlePrice}
                              handleChangePrice={this.handleChangePrice}
                              state={event.state}
                              isPicked={event.isPicked}
                              onDelete={
                                event.isValid ? this.handleDelete : null
                              }
                              green={event.green}
                              onEdit={event.isValid ? this.handleEdit : null}
                              onPick={event.isValid ? this.handlePick : null}
                              isSupervisor={
                                event.isValid ? this.state.isSupervisor : false
                              }
                              isAdmin={
                                event.isValid ? this.state.isAdmin : false
                              }
                              isValid={event.isValid}
                            />
                          )}
                        </p>
                      ))}
                </>
              )}
              {this.state.hideSection && (
                <>
                  <MDBRow>
                    <MDBCol size="12" className="text-left">
                      <h4
                        style={{
                          zIndex: "1000",
                          fontWeight: "400",
                          color: "#000",
                        }}
                      >
                        {" "}
                        Detalle de la orden{" "}
                      </h4>
                    </MDBCol>
                  </MDBRow>
                  {this.state.orders.map((event) => (
                    <p
                      className="ml-1"
                      key={"event1" + event.id}
                      style={{ width: "98%" }}
                    >
                      <Event
                        id={event.id}
                        isValid={event.isValid}
                        product_id={".000" + event.product_id}
                        productName={event.productName}
                        productQty={event.productQty}
                        productPrice={event.productPrice}
                        productModel={event.productModel}
                        productImage={event.productImage}
                        productSKU={event.productSKU}
                        state={event.state}
                        onDelete={null}
                        onEdit={null}
                        onPick={null}
                      />
                    </p>
                  ))}
                </>
              )}

              <MDBRow>
                <MDBCol
                  size="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="text-right mt-4"
                >
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"posicion_rack"}
                    key={"posicion_rack"}
                    name={"posicion_rack"}
                    value={this.state.posicion_rack}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Posición Rack"
                  />
                </MDBCol>

                {this.state.pedido_parcial !== "SI" && (
                  <>
                    <MDBCol
                      size="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      className="text-right mt-4"
                    >
                      <MDBInput
                        style={{ margin: "0" }}
                        id={"TRX"}
                        key={"TRX"}
                        name={"TRX"}
                        value={this.state.TRX}
                        onChange={this.changeHandler}
                        size="sm"
                        label="TRX"
                      />
                    </MDBCol>

                    <MDBCol
                      size="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      className="text-right mt-4"
                    >
                      <MDBInput
                        style={{ margin: "0" }}
                        id={"numero_factura"}
                        key={"numero_factura"}
                        name={"numero_factura"}
                        value={this.state.numero_factura}
                        onChange={this.changeHandler}
                        size="sm"
                        label="Número Factura"
                      />
                    </MDBCol>

                    <MDBCol
                      size="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      className="text-right mt-4"
                    >
                      <MDBInput
                        style={{ margin: "0" }}
                        id={"razon_social"}
                        key={"razon_social"}
                        name={"razon_social"}
                        value={this.state.razon_social}
                        onChange={this.changeHandler}
                        size="sm"
                        label="Razón Social"
                      />
                    </MDBCol>
                  </>
                )}

                <MDBCol
                  size="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="text-right mt-4"
                >
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"direccion_1"}
                    key={"direccion_1"}
                    name={"direccion_1"}
                    value={this.state.direccion_1}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Dirección"
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="text-right mt-4"
                >
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"direccion_2"}
                    key={"direccion_2"}
                    name={"direccion_2"}
                    value={this.state.direccion_2}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Referencia"
                  />
                </MDBCol>

                <MDBCol
                  size="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="text-right mt-4"
                >
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"latitude"}
                    key={"latitude"}
                    name={"latitude"}
                    value={this.state.latitude}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Latitud"
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="text-right mt-4"
                >
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"longitude"}
                    key={"longitude"}
                    name={"longitude"}
                    value={this.state.longitude}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Longitud"
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="text-right mt-4"
                >
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"telefono"}
                    key={"telefono"}
                    name={"telefono"}
                    value={this.state.telefono}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Teléfono"
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="text-right mt-4"
                >
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"nit"}
                    key={"nit"}
                    name={"nit"}
                    value={this.state.nit}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Nit"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow
                className="text-right"
                style={{ marginTop: 15, marginBottom: 15 }}
              >
                <MDBCol md="12">
                  <MDBInput
                    style={{ margin: "0" }}
                    id={"observacion"}
                    rows="2"
                    key={"observacion"}
                    name={"observacion"}
                    value={this.state.observacion}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Observación"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow
                className="text-right"
                style={{ marginTop: 15, marginBottom: 15 }}
              >
                <MDBCol md="12">
                  {this.state.formButtons.map((item) => (
                    <MDBBtn
                      color="primary"
                      size="md"
                      onClick={() => {
                        this.setState({
                          modal6: true,
                          optionMessage:
                            "Confirma la opcion " + item.optionname + "?",
                          optionCode: item.optioncode,
                          optionName: item.optionname,
                        });
                      }}
                      key={item.optioncode}
                    >
                      {" "}
                      {item.optionname}
                    </MDBBtn>
                  ))}
                </MDBCol>
              </MDBRow>

              <MDBModal
                cascading
                centered
                isOpen={this.state.modal6}
                toggle={this.toggleModal(6)}
                size="md"
              >
                <MDBModalBody className="text-center">
                  <span className="h4-responsive text-center text-uppercase">
                    {" "}
                    {this.state.optionMessage}
                  </span>
                  <hr />
                  <MDBRow className="text-center">
                    <MDBCol md="12">
                      <MDBBtnGroup size="sm">
                        <MDBBtn
                          outline
                          onClick={() => {
                            this.setState({ modal6: false });
                          }}
                          color="warning"
                        >
                          {" "}
                          <span className="h4-responsive">Cancelar</span>
                        </MDBBtn>
                        <MDBBtn
                          onClick={() => {
                            this.setState({ modal6: false });
                            this.executeTask(
                              this.state.optionCode,
                              this.state.optionName
                            );
                          }}
                          color="primary"
                        >
                          {" "}
                          <span className="h5-responsive">Aceptar</span>{" "}
                        </MDBBtn>
                      </MDBBtnGroup>
                    </MDBCol>
                  </MDBRow>
                </MDBModalBody>
              </MDBModal>

              <MDBModal
                cascading
                centered
                isOpen={this.state.modal4}
                toggle={this.toggleModal(4)}
                size="lg"
              >
                <MDBModalBody>
                  <span className="h2-responsive mb-2">
                    {" "}
                    <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle
                    del pedido Previo{" "}
                  </span>

                  <p>Nombre: {this.state.customerName}</p>
                  <p>Direccion: {this.state.customerAdd}</p>
                  <p>Zona: {this.state.customerZone}</p>
                  {this.state.loadingData
                    ? ""
                    : this.state.ordersPre.map((event) => (
                        <p key={"event2" + event.id} style={{ width: "98%" }}>
                          <Event
                            id={event.id}
                            product_id={event.product_id}
                            productName={event.productName}
                            productQty={event.productQty}
                            productPrice={event.productPrice}
                            productModel={event.productModel}
                            productImage={event.productImage}
                            productSKU={event.productSKU}
                            state={event.state}
                            onDelete={null}
                            isSupervisor={this.state.isSupervisor}
                            isAdmin={this.state.isAdmin}
                          />
                        </p>
                      ))}
                </MDBModalBody>
              </MDBModal>

              <MDBModal
                cascading
                centered
                isOpen={this.state.modal3}
                toggle={this.toggleModal(3)}
                size="lg"
              >
                <MDBModalBody>
                  <span className="h2-responsive mb-4">
                    {" "}
                    <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar
                    Producto
                  </span>

                  <>
                    <MDBRow className="text-right mt-3">
                      <MDBCol md="9">
                        <MDBInput
                          id="SKUcode"
                          key="SKUcode"
                          name="SKUcode"
                          value={this.state.SKUcode}
                          onChange={this.changeHandler}
                          size="sm"
                          label="Escriba el nombre o SKU"
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <MDBBtn
                          color="secondary"
                          onClick={() => {
                            this.searchProduct();
                          }}
                          className="mt-4"
                          size="sm"
                        >
                          Buscar
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>

                    {this.state.showProductsList ? (
                      <MDBTable
                        responsive
                        className="customTable"
                        bordered
                        striped
                      >
                        <MDBTableHead>
                          <tr>
                            <th>Imagen</th>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th>Precio Promocional</th>
                            <th>Cantidad Disponible</th>
                            <th>Seleccionar</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {this.state.searchResults.map((item) => (
                            <tr key={"tr" + item.key}>
                              <td key={"td" + item.key}>
                                <img
                                  src={item.thumb}
                                  height="60px"
                                  width="60px"
                                  alt="thumbnail"
                                  className="img-thumbnail"
                                />
                              </td>
                              <td>{item.name}</td>
                              <td>{item.price}</td>
                              <td>
                                {!item.special ? item.price : item.special}
                              </td>
                              <td>{item.quantity}</td>
                              <td style={{ textAlign: "center" }}>
                                {item.selected}
                              </td>
                            </tr>
                          ))}
                        </MDBTableBody>
                      </MDBTable>
                    ) : (
                      <>
                        <MDBRow className="text-left">
                          <MDBCol md="12">
                            <span className="h4-responsive">
                              {" "}
                              <b>Detalle del producto seleccionado</b>{" "}
                            </span>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow className="text-right">
                          <MDBCol md="7">
                            <img
                              src={this.state.productImageBig}
                              alt="thumbnail"
                              className="img-thumbnail"
                            />
                          </MDBCol>

                          <MDBCol md="5">
                            <MDBInput
                              id="productName"
                              label="Producto"
                              readOnly={true}
                              name="productName"
                              value={this.state.productName}
                              onChange={this.changeHandler}
                            />

                            <MDBInput
                              id="productPrice"
                              label="Precio"
                              readOnly={true}
                              name="productPrice"
                              value={this.state.productPrice}
                              onChange={this.changeHandler}
                            />

                            <MDBInput
                              label="Cantidad"
                              id="productQty"
                              name="productQty"
                              readonly
                              type="number"
                              min="1"
                              max={this.state.productMaxQty}
                              value={this.state.productQty}
                              onChange={this.changeHandler}
                            />

                            <MDBBtn
                              size="sm"
                              outline
                              color="danger"
                              onClick={() => {
                                this.setState({
                                  showProductsList: true,
                                  /*SKUcode:"" , */ productName: "",
                                  productPrice: "",
                                  productQty: "1",
                                });
                              }}
                            >
                              {" "}
                              <MDBIcon icon="arrow-alt-circle-left" /> Volver
                            </MDBBtn>

                            <MDBBtn
                              size="sm"
                              color="success"
                              disabled={this.state.disabledAdd}
                              onClick={() => {
                                this.setState({
                                  modal5: true,
                                  mensaje: "Agregar este producto?",
                                  accion: "agregar",
                                });
                              }}
                            >
                              {" "}
                              <MDBIcon icon="cart-plus" /> Agregar
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                  </>
                </MDBModalBody>
              </MDBModal>

              <MDBModal
                cascading
                centered
                isOpen={this.state.modal5}
                toggle={this.toggleModal(5)}
                size="md"
              >
                <MDBModalBody className="text-center">
                  <span className="h4-responsive text-center">
                    Esta seguro de {this.state.mensaje}
                  </span>
                  <hr />

                  <MDBRow className="text-center">
                    <MDBCol md="12">
                      <MDBBtnGroup size="sm">
                        <MDBBtn
                          outline
                          onClick={() => {
                            this.setState({ modal5: false });
                          }}
                          color="danger"
                          className="text-capitalize"
                        >
                          {" "}
                          <span className="h4-responsive">Cancelar</span>
                        </MDBBtn>
                        <MDBBtn
                          onClick={() => {
                            this.setState({ modal5: false });
                            if (this.state.accion === "agregar") {
                              this.addOrder();
                            } else if (
                              this.state.accion === "eliminar tarifa"
                            ) {
                              this.deleteShipping(this.state.order_id);
                            } else if (this.state.accion === "aumentar") {
                              this.handleCommitEdit(
                                this.state.id_producto,
                                "Add"
                              );
                            } else if (this.state.accion === "disminuir") {
                              this.handleCommitEdit(
                                this.state.id_producto,
                                "Rem"
                              );
                            } else if (this.state.accion === "cambiar") {
                              this.handleCommitPrice(this.state.id_producto);
                            } else if (this.state.accion === "cambiar envio") {
                              this.changeShippingType();
                            } else if (
                              this.state.accion === "actualizar costo de envio"
                            ) {
                              this.changeShippingCost();
                            } else if (
                              this.state.accion === "actualizar descuento"
                            ) {
                              this.changeDiscount();
                            } else if (this.state.accion === "cambiar pago") {
                              this.changePaymentType();
                            } else {
                              this.handleCommitDelete(this.state.id_producto);
                            }
                          }}
                          color="success"
                          className="text-capitalize"
                        >
                          {" "}
                          <span className="h4-responsive">
                            {this.state.accion}
                          </span>{" "}
                        </MDBBtn>
                      </MDBBtnGroup>
                    </MDBCol>
                  </MDBRow>
                </MDBModalBody>
              </MDBModal>
            </>
          )}

          <ToastContainer
            hideprogress_Bar={true}
            newestOnTop={false}
            position={"bottom-right"}
            autoClose={3000}
          />
        </MDBContainer>
      </>
    );
  }
}

class Event extends React.Component {
  render() {
    return this.props.state === "deleting" ? (
      <MDBCard
        style={{
          opacity: "0.4",
          backgroundColor: "#999",
        }}
        className="align-items-center justify-content-center"
      >
        <p></p>
        <div className="media mt-4 align-middle">
          <div className="media-body mb-3 mb-lg-3">
            <MDBAnimation type="rotateOut" infinite>
              <MDBIcon
                style={{
                  zIndex: "1050",
                }}
                size="2x"
                far
                icon="trash-alt"
              />
            </MDBAnimation>
          </div>
        </div>
        <p></p>
      </MDBCard>
    ) : (
      <MDBCard className="align-items-left justify-content-left">
        <div
          className="media mt-1"
          style={{ background: this.props.isValid ? "auto" : this.props.green? "#c6e2ff": "#ffc6c6" }}
        >
          <span className="h4-responsive font-weight-bold mr-3">
            {this.props.isSupervisor > 0 ? (
              <MDBBadge
                color="danger"
                style={{ cursor: "pointer" }}
                className="ml-2 mt-1 mr-2 ml-4"
                onClick={() =>
                  this.props.productQty === 1
                    ? this.props.onDelete(this.props.id)
                    : this.props.onEdit(this.props.id, "disminuir")
                }
              >
                {" "}
                -
              </MDBBadge>
            ) : (
              ""
            )}
            {this.props.productQty}

            {this.props.isSupervisor > 0 ? (
              <MDBBadge
                color="success"
                style={{ cursor: "pointer" }}
                className="ml-2 mt-2"
                onClick={() => this.props.onEdit(this.props.id, "aumentar")}
              >
                {" "}
                +
              </MDBBadge>
            ) : (
              ""
            )}
            <br />

            <img
              src={this.props.productImage}
              alt="thumbnail"
              height="60px"
              width="60px"
              className="img-thumbnail"
            />
            {this.props.onDelete === null ? (
              ""
            ) : (
              <p className="ml-1">
                <MDBBadge
                  color="danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.props.onDelete(this.props.id)}
                >
                  Eliminar
                </MDBBadge>{" "}
              </p>
            )}
          </span>
          <div className="media-body mb-3 mb-lg-3">
            {this.props.onPick === null ? (
              ""
            ) : (
              <>
                <MDBBadge
                  color={this.props.isPicked === -1 ? "grey" : "success"}
                  style={{ cursor: "pointer" }}
                  className="mr-1 float-right"
                  onClick={() => this.props.onPick(this.props.id)}
                >
                  <MDBIcon
                    far={this.props.isPicked === -1 ? false : true}
                    size="2x"
                    icon={
                      this.props.isPicked === -1
                        ? "check-square"
                        : "check-square"
                    }
                  />
                  <span> Pick</span>
                </MDBBadge>
              </>
            )}
            <span className="h6 mt-0 font-weight-bold">
              {this.props.productName}{" "}
            </span>{" "}
            <hr className="hr-bold my-2" />
            {this.props.product_id && (
              <React.Fragment>
                <p className="font-smaller mb-0">
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <MDBIcon icon="shopping-basket" /> Codigo:{" "}
                      {this.props.productModel}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <MDBIcon icon="barcode" /> SKU: {this.props.productSKU}
                    </MDBCol>
                  </MDBRow>
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
        {this.props.productQty && (
          <>
            <p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
              {"Cantidad: " +
                this.props.productQty +
                ", Precio unitario: " +
                this.props.productPrice +
                "Bs. Total " +
                parseFloat(
                  this.props.productPrice * this.props.productQty,
                  2
                ).toFixed(2) +
                "Bs."}
            </p>
            {this.props.isAdmin > 0 ? (
              <MDBRow>
                <MDBCol
                  size="6"
                  sm="6"
                  md="3"
                  lg="3"
                  style={{
                    display: "flex",
                    "align-items": "center",
                    width: "100%",
                  }}
                >
                  <MDBInput
                    style={{ width: "100%" }}
                    id={"SKUPRICE_" + this.props.productSKU}
                    key={"SKUPRICE_" + this.props.productSKU}
                    name={"" + this.props.productSKU}
                    value={this.props.productPrice}
                    onChange={this.props.handleChangePrice}
                    size="sm"
                    label="Actualizar Precio"
                  />
                </MDBCol>
                <MDBCol
                  size="6"
                  sm="6"
                  md="6"
                  lg="6"
                  style={{
                    display: "flex",
                    "align-items": "center",
                  }}
                >
                  <MDBBtn
                    disabled={this.props.productPrice == "" ? true : false}
                    color="success"
                    onClick={() => this.props.onPrice(this.props.id, "cambiar")}
                    size="sm"
                  >
                    Actualizar
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            ) : (
              ""
            )}
          </>
        )}
      </MDBCard>
    );
  }
}

export default externalForm06;

import React from "react";
import {MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_CONFIG} from '../../Config/config-env.js';
import {OP_SECRET_KEY} from '../../Config/config-env.js';
import axios from 'axios';
import Table from '../../Components/Table';
import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class externalForm01 extends React.Component {
	
	state = {
		id_producto:-1,
		mensaje:"",
		montoEvento:0,
		accion:"",
		isDeleted:false,
		quantity:1,
		name:"",
		SKUcode:"3212567000066",
		productPrice:0,
		existingPreviousOrder:false,
		isExecuting:false,
		processing: "Procesando",
		ordersPre:[
			{id:0,
				product_id: 111,
				name:"Manta",
				productPrice:"50Bs.",
				quantity:"1",
			  state: "loaded" },
			{id:1,
				product_id: 112,
				name:"Juego de Tazas",
				productPrice:"50Bs.",
				quantity:"1",
				state: "loaded" 
			}
		],
		orders:[],
		WO_Id:"",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true,
		 
		tableData: {
				columns : [
				 
				  { dataIndex:"ORDERID"                       , enabled:true, title: "Pedido" }, 
					{ dataIndex:"SKU"                           , enabled:true, title: "SKU" },
					{ dataIndex:"QUANTITY"                      , enabled:true, title: "Cantidad" }, 
					{ dataIndex:"PRICE"                      , enabled:true, title: "Precio Unitario" }, 
					{ dataIndex:"TOTAL"                     , enabled:true, title: "Monto Total" },
				],
		    rows: [], 
		}, 
	};
	
	componentDidMount=()=>{
		require("antd/dist/antd.css");
	 	this.LoadTable();
	}
	  
  changeHandler = event => { 
  	console.log(event.target.name)
  	console.log(event.target.value)
		this.setState({ ...this.state, [event.target.name]: [event.target.value] });
	};
	 
 
  	
	handlePick =async (order) => { 
		 
		let neworders =[]
		let order_ids="";
		let totalProd = this.state.orders.length
		let totalPicked =0
		this.setState({ isExecuting:true });
		for (var key in  this.state.orders){
 		
 			let obj = this.state.orders[key];
 			//console.log(this.state.orders[key])
 	
 			if (this.state.orders[key].orderid===order){  
				obj["isPicked"]= this.state.orders[key].isPicked===-1?1:-1;
 			} 
 			neworders.push(obj)
 		} 
 		 
 		for (var key in neworders){
 			//console.log(neworders[key])
 			if (neworders[key].isPicked===1){  
				order_ids=order_ids+","+neworders[key].orderid
				totalPicked++
 			} 
 		} 
 		let progress = Math.ceil(totalPicked/totalProd*100)
 		
 		let url = API_CONFIG.base_url+'/externalApi/setParamFlowByTask/';
			await	axios({
					url: url,
					method: 'post',
					
					data: {
						org: this.state.org,
						taskId:this.props.match.params.WOTaskId,
						isFather:"YES",
						params:[
							{ "ordersPicked": order_ids  }
						]
					},
					headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
				}).then(response => response.data)
		.then(data => {
			
		})
 	  this.setState({ isExecuting:false });
 		this.setState({ orders:neworders  });
	} 
	 
	   
	LoadTable=async() => { 		
		let WO_Id = this.props.match.params.WO_Id; 
		let WOTaskId = this.props.match.params.WOTaskId; 
		
		var eventId = 0
		var montoEvento = 0
		var ordersPicked = []
		var arr=[]
		var orderskus=[]
		var  isSupervisor, isAdmin, org , userId;
		await axios({
			url: API_CONFIG.base_url+'/api/getTaskFields/0/'+WO_Id+'/'+WOTaskId,
			method: 'get',
			headers: { 'Authorization': 'Bearer ' + this.props.match.params.token }
		}).then(response => response.data)
			.then(data => {
				for (var k in data.formWorkflowData ){
					let key= data.formWorkflowData[k]
			 	
					this.setState({   [key.NAME] : key.VALUE})
					
					if ( key.NAME.toUpperCase() == "eventId".toUpperCase() ){
						eventId = key.VALUE
					}
					if ( key.NAME.toUpperCase() == "ordersPicked".toUpperCase() ){
						 
						ordersPicked = key.VALUE.split(",")
					}
				}
		})
		
		
		 
		await axios({
			url: API_CONFIG.base_url+'/externalApi/decryptToken',
			method: 'post',
			data: { 'token': this.props.match.params.token },
		}) 
			.then(response => response.data)
			.then(data => {
				
				org = data.token.userOrg;
				userId= data.token.userId;
				
				isSupervisor  = data.token.userType==="SINGLEUSER"?-1:1; 
				isAdmin  = data.token.userType==="ADMINISTRATOR"?1:-1;
				
				this.setState({ ...this.state, username: data.token.username})
		})
		 
		var store_url
		let url = API_CONFIG.base_url+'/externalApi/getOrderProductsV2/'+WO_Id+"/"+WOTaskId+"/"+org+"/"+userId;
		await axios({
			url: url,
			method: 'get',
			headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
		})
			.then(response => response.data)
			.then(data => {
				console.log(data)
				
				if (data.resp=="OK"){
				   
		 				for (var key in data.rows){
		 					let ordersku = {}
						 	ordersku["ORDERID"]  = data.order_id
							ordersku["SKU"]      = data.rows[key].sku
							ordersku["QUANTITY"] = data.rows[key].quantity								 
							ordersku["PRICE"]    = data.rows[key].price
							ordersku["TOTAL"]    = data.rows[key].total
								 
							orderskus.push(ordersku)
						}
						
					 
				} 
				
			})
			  
			orderskus= orderskus.sort(function (a, b){
				return ( parseInt( a.ORDERID) - parseInt( b.ORDERID))
			})
			
			const tableData = this.state.tableData
	 		tableData.rows = orderskus
	 		
		 this.setState({
					  	...this.state,
					  	loadingData:false,  
					  	store_url:store_url
					  }); 
	
	}
 
	toggleModal = nr => () => {

	let modalNumber = 'modal' + nr
	this.setState({
		[modalNumber]: !this.state[modalNumber]
	});
	}
	
	render() {
		return (
		<>
			
			   
			   
			   
			   {this.state.loadingData?
			   
			   	 <Loader idLoading={this.state.loadingData}/>
			     :
	 				 <>  
	 				 <Loader idLoading={this.state.isExecuting}/>
	 				  
	 				  <h3 style={{
				   	 	zIndex:"1000",
				   	 	fontWeight:"600",
				   	color:"#000",
				   	rginLeft: "0.5rem"
				   	}}> Detalle de la orden </h3>
				   	
            <MDBRow>
              <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-right justify-content-right">
              <ExcelFile filename={"export-orders-evento."+this.state.eventId}  element={
                  <MDBBtn key="find"   size="sm"
                          color="success"
                  >Exportar a Excel</MDBBtn>
              }>
                  <ExcelSheet  data={this.state.tableData.rows} name="Lista de Pedidos">
                      <ExcelColumn label="Pedido" value="ORDERID"/> 
                      <ExcelColumn label="SKU" value="SKU"/>
                      <ExcelColumn label="Cantidad" value="QUANTITY"/>
                      <ExcelColumn label="Precio Unitario" value="PRICE"/>
                      <ExcelColumn label="Monto Total" value="TOTAL"/>
                  </ExcelSheet>

              </ExcelFile>
              </MDBCol>
            </MDBRow>  
            
            <MDBRow>
              <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-left justify-content-left">
              <Table tableData={this.state.tableData}  scroll={{ x: 950, y: 300 }} />
              </MDBCol>
          </MDBRow>
                                
					 </>
					
					 
				 }
				 
				 <ToastContainer
					        hideProgressBar={true}
					        newestOnTop={false}
					      position={"bottom-right"}
					        autoClose={3000}
					      />	
			 
			</>
		);
	}
}

	class Event extends React.Component {
    render() {
	    return (
	    		<MDBCard className="">
			        <div className="media m-2">
			            <div className="media-body mb-3 mb-lg-3">
			             
			            <hr className="hr-bold my-2" />
			            {this.props.event.orderid && (
			                <>
			                <div className="font-smaller mb-0">
			               
			                   <MDBRow > 
													<MDBCol sm="11" className="align-items-left justify-content-left">
													
														<h5 style={{
																	   	 	zIndex:"1000",
																	   	 	fontWeight:"600",
																	   	color:"#000"
																	   	}}>
																	   	
																	   		<MDBIcon icon="shopping-basket" />  
																	   	 <span>Pedido: {this.props.event.orderid} 
																	   		</span>
																	   	 
														</h5>
													</MDBCol>  
													
													<MDBCol style={{textAlign : "center"}} sm="1" >
													 	<MDBBadge
			                					color= {this.props.event.isPicked=== -1 ?"grey":"success"  }  
									                style={{"cursor":"pointer"}} 
									                onClick={() => this.props.handlePick(this.props.event.orderid)} 
									            >
									               <MDBIcon far={this.props.event.isPicked=== -1 ?false:true}  size="2x" icon={this.props.event.isPicked=== -1 ?"check-square":"check-square"} />
									            </MDBBadge>
													</MDBCol>  
													   
												</MDBRow>
												<MDBRow className="align-items-left justify-content-left"> 
													<MDBCol md="12">
														
														<h5 style={{
																	   	 	zIndex:"1000",
																	   	 	fontWeight:"600",
																	   	color:"#000"
																	   	}}> Nombre Cliente: {this.props.event.nombre+" "+this.props.event.apellido}  </h5>
																	   		
														  
													</MDBCol>     
												</MDBRow>
			            
			                </div>
			                
			                	<MDBCard className="m-2">
					                <MDBRow className="align-items-left justify-content-left"> 
														<MDBCol md="12">
																	  <h6 style={{
																	   	 	zIndex:"1000",
																	   	 	fontWeight:"600",
																	   	color:"#000"
																	   	}}> Productos</h6>
														</MDBCol>     
													</MDBRow>
													
													{this.props.event.products.map(item => (
														 
														 
														 <>
															<div className="media m-1 p-1">
												       	<span className="h4-responsive font-weight-bold mr-3">
																
																	{this.props.quantity}
																	<br/>
											            <img src={item.image} alt="thumbnail" height="60px" width="60px" className="img-thumbnail" />
									             	</span>
																<div className="media-body mb-3 mb-lg-3">
																 
																	<span className="h6 mt-0 font-weight-bold">{item.name} </span>{" "}
																	<hr className="hr-bold my-2" />
																	{item.product_id && (
																		<React.Fragment>
																			<p className="font-smaller mb-0">

																				<MDBRow className="align-items-left justify-content-left">
																					<MDBCol md="12" >
																						<MDBIcon icon="shopping-basket" /> Codigo: {item.sku}
																					</MDBCol>
																				</MDBRow>
																				<MDBRow className="align-items-left justify-content-left">
																					<MDBCol md="12" >
																						<MDBIcon icon="barcode" /> SKU: {item.sku}
																					</MDBCol>
																				</MDBRow>

																			</p>
																		</React.Fragment>
																	)}
																</div>
															</div>
														
															{item.quantity && (
																<>
																<p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
																	{"Cantidad: "+item.quantity+", Precio unitario: "+ item.price  +"Bs. Total "+ item.total }
																</p> 
																</>
															)}
														</>
														 
														 
														))
													}
													
													
												</MDBCard>
			                <MDBRow > 
													<MDBCol sm="12"style={{textAlign: "right" }}>
													
														<h6 style={{
																	   	 	zIndex:"1000",
																	   	 	fontWeight:"600",
																	   		color:"#000",
																	   		marginRight: 15
																	   	}}>
																	   	
																	   		 
																	   		<span>
														 	Total Pedido: {this.props.event.total?this.props.event.total: 0} 			   		
														 	</span>
														</h6>
													</MDBCol>  
												</MDBRow > 
			                </>
			            )}
			            </div>
			        </div> 
			         
			      </MDBCard>
	        
	    );
    }
	}

export default externalForm01;

import React from "react";
import {
  MDBNotification,
  MDBInputGroup,
  toast,
  MDBFormInline,
  MDBBadge,
  MDBAnimation,
  ToastContainer,
  MDBIcon,
  MDBDataTable,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";
import { API_CONFIG } from "../../Config/config-env.js";
import { OP_SECRET_KEY } from "../../Config/config-env.js";
import axios from "axios";
import Table from "../../Components/Table";
/*
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
*/
class externalForm01 extends React.Component {
  state = {
    id_producto: -1,
    mensaje: "",
    montoEvento: 0,
    accion: "",
    isDeleted: false,
    quantity: 1,
    name: "",
    SKUcode: "3212567000066",
    productPrice: 0,
    existingPreviousOrder: false,
    isExecuting: false,
    processing: "Procesando",
    arrItems: [],
    ordersPre: [
      {
        id: 0,
        product_id: 111,
        name: "Manta",
        productPrice: "50Bs.",
        quantity: "1",
        state: "loaded",
      },
      {
        id: 1,
        product_id: 112,
        name: "Juego de Tazas",
        productPrice: "50Bs.",
        quantity: "1",
        state: "loaded",
      },
    ],
    orders: [],
    ordersPicked: [],
    customerinfo: [],
    addinfo: [],
    WO_Id: "",
    isLoadedTable: false,
    loadingData: true,
    modal4: false,
    modal3: false,
    order_id: "",
    orderName: "",
    orderPrice: "Bs.50",
    orderQty: "1",
    customerName: "",
    customerAdd: "",
    customerZone: "",
    productMaxQty: 0,
    disabledAdd: true,

    /*entrega*/
    modal5: false,
    executedTask: false,
    formButtons: {
      type: "",
      buttonsList: [],
    },
    validOrders: [],
    /*entrega*/
    tableData: {
      columns: [
        { dataIndex: "EVENTID", enabled: true, title: "Codigo Evento" },
        { dataIndex: "ORDERID", enabled: true, title: "Pedido" },
        { dataIndex: "ORDERTYPE", enabled: true, title: "Tipo Pedido" },
        { dataIndex: "DATEADDED", enabled: true, title: "Fecha Pedido" },
        { dataIndex: "FIRSTNAME", enabled: true, title: "Nombre" },
        { dataIndex: "LASTNAME", enabled: true, title: "Apellido" },
        { dataIndex: "TOTAL", enabled: true, title: "Monto Pedido" },
        { dataIndex: "SKU", enabled: true, title: "SKU" },
        { dataIndex: "QUANTITY", enabled: true, title: "Cantidad" },
        { dataIndex: "PRICE", enabled: true, title: "Precio Unitario" },
      ],
      rows: [],
    },
  };
  /*entrega*/
  executeTask = async (option) => {
    var responseValidation = true;
    console.log("option", option);

    console.log("option", this.state.validOrders);
    console.log("option", this.state.ordersPicked);

    var isValid = true;
    for (let i in this.state.validOrders) {
      let _order = this.state.validOrders[i];

      if (this.state.ordersPicked.indexOf(_order) < 0) {
        isValid = false;
      }
    }

    console.log("valid", isValid);
    if (isValid) {
      let WOTaskIds = [];
      let WOflowIds = [];

      for (let key in this.state.arrItems) {
        let obj;

        //arrItemsChecked.push(this.state.arrItems[key])
        WOTaskIds.push(this.state.arrItems[key].ID_HTAREA);
        WOflowIds.push(this.state.arrItems[key].ID_HFLUJO);
      }

      let token = this.props.match.params.token;
      this.setState({ isExecuting: true, modal5: true });

      this.setState({ responseTask: null });
      let url = API_CONFIG.base_url + "/api/executeTask/"; //executeTask
      let url_val = API_CONFIG.base_url + "/api/validateTask/";
      var fields = [];

      fields.push({ WOTaskId: WOTaskIds });
      fields.push({ flowId: WOflowIds });
      fields.push({ option: option });
      fields.push({ actorId: 0 });
      fields.push({ observation: "Validado" });

      await axios
        .post(
          url_val,
          { params: fields, tasks: this.state.arrItemsChecked },
          { headers: { Authorization: "Bearer " + token } }
        )
        .then((response) => response.data)
        .then((data) => {
          if (data.state) {
            axios
              .post(
                url,
                { params: fields, tasks: this.state.arrItemsChecked },
                { headers: { Authorization: "Bearer " + token } }
              )
              .then((response) => response.data)
              .then((data) => {
                let colorMessage;
                let message;
                if (data.response === "OK") {
                  //if (data.state){
                  toast.success("Tarea ejecutada con exito", {
                    closeButton: true,
                  });
                  this.setState({ isExecuting: false, modal5: false });

                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage("OK");
                  } else {
                    window.parent.location.reload();
                    console.log("no");
                  }
                } else {
                  this.setState({ isExecuting: false, modal5: false });
                  toast.error("Error ejecutando tarea", {
                    closeButton: true,
                  });
                }
              });
          } else {
            toast.warning(data.message, {
              closeButton: true,
            });
            this.setState({ isExecuting: false, modal5: false });
          }
        });
    } else {
      var msj_validacion = "Debe verificar todas las ordenes";
      toast.warning(msj_validacion, {
        closeButton: true,
      });
      this.setState({
        ...this.state,
        responseTask: "NOK",
        isExecuting: false,
        modal5: false,
      });
    }

    /*
		let WO_Id = parseInt(this.props.match.params.WO_Id) 
		let WOTaskId =parseInt(this.props.match.params.WOTaskId)
		let token = this.props.match.params.token 
		
		var f = new Date(this.state.from_date);
		var fecha =  ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) +"/" +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + "/"+(f.getFullYear()+"")
		
		console.log(fecha)		
		//var fecha = res [2]+"/"+res[1]+"/"+res[0] 
		this.setState({isExecuting: true, modal5:true});
		
		
		var monto_real     =  this.state.monto_real
		var monto_2        = this.state.monto_2
		var metodo_pago    =  this.state.metodo_pago
		var metodo_pago_2  =  this.state.metodo_pago_2
		var motivo_no_entrega =  this.state.motivo_no_entrega
		//console.log(this.state.formButtons.buttonsList)
		
		 
		
		if (option=="op1" && this.state.formButtons.buttonsList.length>1 ){
			if (motivo_no_entrega==""){
					var msj_validacion="Seleccione el motivo de no Entrega";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
			}
		}else{
			
			if (metodo_pago==""  ){
				var msj_validacion="Seleccion el campo metodo de pago";
				toast.warning(msj_validacion, {
							closeButton: true
						});
				responseValidation=false
			}
		
			if (monto_real==""){
					var msj_validacion="Complete el campo monto pagado";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
			}else{
				if (isNaN(monto_real)){
					var msj_validacion="El campo monto pagado debe ser numero";
					toast.warning(msj_validacion, {
								closeButton: true
							});
					responseValidation=false
				}
			}
			if (metodo_pago_2!=""  ){ 
				if (monto_2==""){
					var msj_validacion="Complete el campo monto pagado 2";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
				}else{
					if (isNaN(monto_2)){
						var msj_validacion="El campo monto pagado 2 debe ser numero";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
					} 
				}
			}
		}
		
		if (!responseValidation){
			 this.setState({
							...this.state,
							responseTask: "NOK",
							isExecuting: false
						})
						 
		}else {
				this.setState({responseTask: null});
				let url = API_CONFIG.base_url+'/api/executeTask/';//executeTask
				let url_val = API_CONFIG.base_url+'/api/validateTask/';
				var fields=[]

				fields.push({"WOTaskId": WOTaskId})
				fields.push({"flowId": WO_Id})
				fields.push({"option": option})
				fields.push({"actorId": 0})


				fields.push({"observation": this.state.observation})
				fields.push({"fecha_pago": fecha})
				
				fields.push({"monto_real": this.state.monto_real})
				fields.push({"monto_2": this.state.monto_2})
				
				fields.push({"metodo_pago": this.state.metodo_pago})
				fields.push({"metodo_pago_2": this.state.metodo_pago_2})
	 			fields.push({"motivo_no_entrega": this.state.motivo_no_entrega})
	 

			
						await axios.post(url_val,	{ params: fields, tasks: this.state.arrItemsChecked} ,
							{	headers: { 'Authorization': 'Bearer ' + token }},
						)
					.then(response => response.data)
					.then(data => {
						
						if (data.state){

						  axios.post(url,	{ params: fields, tasks: this.state.arrItemsChecked} ,
							{	headers: { 'Authorization': 'Bearer ' + token }},
						)
							.then(response => response.data)
							.then(data => {

								let colorMessage;
								let message;
								if (data.response==="OK"){
								//if (data.state){
									toast.success("Tarea ejecutada con exito", {
										closeButton: true
									});
									this.setState({isExecuting: false}); 
						
									if(window.ReactNativeWebView){
									
										window.ReactNativeWebView.postMessage("OK");
									  
									}else{
										window.parent.location.reload();
										console.log("no") 
									}
									
									 
								}else {
									this.setState({isExecuting: false}); 
									toast.error("Error ejecutando tarea", {
														closeButton: true
													});
								}
								
							})
						}else{ 							
							toast.warning(data.message, {
														closeButton: true
													});
							this.setState({isExecuting: false});							
						}
					})
			 
		}
		*/
  };
  /*entrega*/

  componentDidMount = () => {
    require("antd/dist/antd.css");
    this.LoadTable();
  };

  changeHandler = (event) => {
    console.log(event.target.name);
    console.log(event.target.value);
    this.setState({ ...this.state, [event.target.name]: [event.target.value] });
  };

  handlePick = async (order) => {
    let neworders = [];
    let order_ids = "";
    let totalProd = this.state.orders.length;
    let totalPicked = 0;
    this.setState({ isExecuting: true });
    for (var key in this.state.orders) {
      let obj = this.state.orders[key];
      //console.log(this.state.orders[key])

      if (this.state.orders[key].orderid === order) {
        obj["isPicked"] = this.state.orders[key].isPicked === -1 ? 1 : -1;
      }
      neworders.push(obj);
    }

    for (var key in neworders) {
      //console.log(neworders[key])
      if (neworders[key].isPicked === 1) {
        order_ids = order_ids + "," + neworders[key].orderid;
        totalPicked++;
      }
    }
    let progress = Math.ceil((totalPicked / totalProd) * 100);

    let url = API_CONFIG.base_url + "/externalApi/setParamFlowByTask/";
    await axios({
      url: url,
      method: "post",

      data: {
        org: this.state.org,
        taskId: this.props.match.params.WOTaskId,
        isFather: "YES",
        params: [{ ordersPicked: order_ids }],
      },
      headers: { apikey: "wayslo1506103c404d6dccaf787e44cf123456789" },
    })
      .then((response) => response.data)
      .then((data) => {});
    this.setState({ isExecuting: false });
    this.setState({ orders: neworders });
    this.setState({ ordersPicked: (order_ids + "").split(",") });
  };

  handleChange = async (event) => {
    let neworders = [];
    let order_ids = "";
    let totalProd = this.state.orders.length;
    let totalPicked = 0;

    for (var key in this.state.orders) {
      let obj = this.state.orders[key];
      if (obj.orderid === event.target.name) {
        obj["posicion_rack"] = event.target.value;
      }
      neworders.push(obj);
    }

    this.setState({ orders: neworders });
  };

  handleSetRack = async (orderid) => {
    this.setState({ isExecuting: true });
    var posicion_rack = "";
    var flowId = "";
    for (var key in this.state.orders) {
      if (this.state.orders[key].orderid == orderid) {
        posicion_rack = this.state.orders[key]["posicion_rack"];
        flowId = this.state.orders[key]["flowId"];
      }
    }

    let url = API_CONFIG.base_url + "/externalApi/setParamFlow/";
    await axios({
      url: url,
      method: "post",
      data: {
        flowId: flowId,
        org: this.state.org,
        params: [{ posicion_rack: posicion_rack }],
      },
      headers: { apikey: "wayslo1506103c404d6dccaf787e44cf123456789" },
    })
      .then((response) => response.data)
      .then((data) => {});
    this.setState({ isExecuting: false });
  };

  LoadTable = async () => {
    let WO_Id = this.props.match.params.WO_Id;
    let WOTaskId = this.props.match.params.WOTaskId;

    var eventId = 0;
    var montoEvento = 0;
    var ordersPicked = [];
    var nombre_recepcion = "";
    var arr = [];
    var orderskus = [];
    var validOrders = [];
    var customerinfo = [];
    var addinfo = [];
    var regalos = "";
    var isSupervisor, isAdmin, org, userId;
    await axios({
      url:
        API_CONFIG.base_url + "/api/getTaskFields/0/" + WO_Id + "/" + WOTaskId,
      method: "get",
      headers: { Authorization: "Bearer " + this.props.match.params.token },
    })
      .then((response) => response.data)
      .then((data) => {
        for (var k in data.formWorkflowData) {
          let key = data.formWorkflowData[k];

          this.setState({ [key.NAME]: key.VALUE });

          if (key.NAME.toUpperCase() == "eventId".toUpperCase()) {
            eventId = key.VALUE;
          }
          if (key.NAME.toUpperCase() == "ordersPicked".toUpperCase()) {
            ordersPicked = (key.VALUE + "").split(",");
          }
          if (key.NAME.toUpperCase() == "nombre_recepcion".toUpperCase()) {
            nombre_recepcion = key.VALUE;
          }
        }
      });
    /*entrega*/

    await axios({
      url: API_CONFIG.base_url + "/api/getTaskOptions/" + WOTaskId + "/true",
      headers: { Authorization: "Bearer " + this.props.match.params.token },
      method: "get",
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ formButtons: data });
        // console.log(this.state.formButtons, data);
      });

    /*entrega*/
    await axios({
      url: API_CONFIG.base_url + "/externalApi/decryptToken",
      method: "post",
      data: { token: this.props.match.params.token },
    })
      .then((response) => response.data)
      .then((data) => {
        org = data.token.userOrg;
        userId = data.token.userId;

        isSupervisor = data.token.userType === "SINGLEUSER" ? -1 : 1;
        isAdmin = data.token.userType === "ADMINISTRATOR" ? 1 : -1;

        this.setState({ ...this.state, username: data.token.username });
      });

    var formdata = {
      taskId: WOTaskId,
      fieldname: "order_id",
      isFather: "YES",
    };
    var ordenes = [];
    var arrItems = [];
    await axios({
      url: API_CONFIG.base_url + "/externalApi/getMemoryValuesByTaskId",
      method: "post",
      data: formdata,
      headers: { Authorization: "Bearer " + this.props.match.params.token },
    })
      .then((response) => response.data)
      .then((data) => {
        ordenes = data.values;
        arrItems = data.values;

        data.values.map(function (o) {
          validOrders.push(o.VALOR);
        });
      });

    formdata = {
      taskId: WOTaskId,
      fieldname: "tipo_pedido",
      isFather: "YES",
    };
    var tipopedidos = [];
    await axios({
      url: API_CONFIG.base_url + "/externalApi/getMemoryValuesByTaskId",
      method: "post",
      data: formdata,
      headers: { Authorization: "Bearer " + this.props.match.params.token },
    })
      .then((response) => response.data)
      .then((data) => {
        tipopedidos = data.values;
      });

    formdata = {
      taskId: WOTaskId,
      fieldname: "posicion_rack",
      isFather: "YES",
    };
    var posicionracks = [];

    await axios({
      url: API_CONFIG.base_url + "/externalApi/getMemoryValuesByTaskId",
      method: "post",
      data: formdata,
      headers: { Authorization: "Bearer " + this.props.match.params.token },
    })
      .then((response) => response.data)
      .then((data) => {
        posicionracks = data.values;
      });

    var store_url;
    let url =
      API_CONFIG.base_url +
      "/externalApi/getOrderProductsV2/" +
      WO_Id +
      "/" +
      WOTaskId +
      "/" +
      org +
      "/" +
      userId;
    await axios({
      url: url,
      method: "get",
      headers: { apikey: "wayslo1506103c404d6dccaf787e44cf123456789" },
    })
      .then((response) => response.data)
      .then((data) => {
        //				console.log(data)
        store_url = data.store_url;
      });
    //console.log("ordersPicked",ordersPicked)

    var dataE = {
      eventId: eventId,
      username: this.state.username,
    };

    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        store_url +
        "externalapi/execute/getOrderByEventId/key/" +
        OP_SECRET_KEY,
      data: dataE,
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.success) {
          var d = data.response.customer_info[0];
          customerinfo.push({ name: "nombre", value: d.firstname });
          customerinfo.push({ name: "apellido", value: d.alstname });
          customerinfo.push({ name: "Email", value: d.email });
          customerinfo.push({ name: "Telefono", value: d.phone });
          customerinfo.push({ name: "Documento", value: d.numdoc });

          var add = data.response.event_info.eventInfo.directions;
          regalos = data.response.event_info.eventInfo.regalosenvueltos;

          addinfo.push({ name: "Ciudad", value: add.city });
          addinfo.push({ name: "Direccion", value: add.address });
          addinfo.push({ name: "Indicaciones", value: add.reference });
          addinfo.push({ name: "Latitud", value: add.latitude });
          addinfo.push({ name: "Longitud", value: add.longitude });

          for (var key in data.response.order) {
            let order = data.response.order[key];
            let isPicked =
              ordersPicked.indexOf(order.ORDERID + "") >= 0 ? 1 : -1;
            let products = [];
            montoEvento = montoEvento + (order.TOTAL ? order.TOTAL : 0);
            data.response.products.map(function (prod) {
              if (order.ORDERID == prod.order_id) {
                products.push(prod);
              }
            });
            if (validOrders.indexOf(order.ORDERID + "") >= 0) {
              arr.push({
                id: key,
                orderid: order.ORDERID + "",
                fecha: order.DATEADDED,
                total: order.TOTAL,
                nombre: order.FIRSTNAME ? order.FIRSTNAME : "-",
                apellido: order.LASTNAME ? order.LASTNAME : "-",
                isPicked: isPicked,
                products: products,
              });
            }
          }

          data.response.orderDetails.map(function (sku) {
            let ordersku = sku;
            for (var key in data.response.order) {
              if (sku.ORDERID == data.response.order[key].ORDERID) {
                ordersku["DATEADDED"] = data.response.order[key].DATEADDED;
                ordersku["FIRSTNAME"] = data.response.order[key].FIRSTNAME;
                ordersku["LASTNAME"] = data.response.order[key].LASTNAME;
                ordersku["TOTAL"] = data.response.order[key].TOTAL;
                ordersku["EVENTID"] = eventId;
              }
            }
            if (validOrders.indexOf(sku.ORDERID + "") >= 0) {
              orderskus.push(ordersku);
            }
          });
        }
      });
    //console.log(arr)

    arr = arr.sort(function (a, b) {
      return parseInt(a.orderid) - parseInt(b.orderid);
    });

    orderskus = orderskus.sort(function (a, b) {
      return parseInt(a.ORDERID) - parseInt(b.ORDERID);
    });
    var datosOrdenes = [];
    var datosRackOrdenes = [];
    tipopedidos.map(function (tipo) {
      var orderid;
      var tipopedido = tipo.VALOR;
      var flujo = tipo.ID_HFLUJO;

      ordenes.map(function (orden) {
        if (flujo == orden.ID_HFLUJO) {
          orderid = orden.VALOR;
        }
      });
      datosOrdenes.push({
        flujo: flujo,
        orderid: orderid,
        tipopedido: tipopedido,
      });
    });

    posicionracks.map(function (tipo) {
      var orderid;
      var posicion_rack = tipo.VALOR;
      var flujo = tipo.ID_HFLUJO;

      ordenes.map(function (orden) {
        if (flujo == orden.ID_HFLUJO) {
          orderid = orden.VALOR;
        }
      });
      datosRackOrdenes.push({
        flujo: flujo,
        orderid: orderid,
        posicion_rack: posicion_rack,
      });
    });

    //console.log("orderskus",orderskus,tipopedidos, ordenes )
    //console.log("datosOrdenes",datosOrdenes)
    orderskus.map(function (orden) {
      var tipoPedido = "";
      datosOrdenes.map(function (dorden) {
        if (orden.ORDERID == dorden.orderid) {
          tipoPedido = dorden.tipopedido;
        }
      });
      orden["ORDERTYPE"] = tipoPedido;
    });

    console.log("ordenes", ordenes);

    arr.map(function (orden) {
      var tipoPedido = "";
      datosOrdenes.map(function (dorden) {
        if (orden.orderid == dorden.orderid) {
          tipoPedido = dorden.tipopedido;
        }
      });
      orden["tipopedido"] = tipoPedido;

      var posicion_rack = "";
      datosRackOrdenes.map(function (dorden) {
        if (orden.orderid == dorden.orderid) {
          posicion_rack = dorden.posicion_rack;
        }
      });
      orden["posicion_rack"] = posicion_rack;

      var flowId = "";
      ordenes.map(function (dorden) {
        if (orden.orderid == dorden.VALOR) {
          flowId = dorden.ID_HFLUJO;
        }
      });
      orden["flowId"] = flowId;
    });

    const tableData = this.state.tableData;
    tableData.rows = orderskus;

    this.setState({
      ...this.state,
      loadingData: false,
      orders: arr,
      validOrders: validOrders,
      ordersPicked: ordersPicked,
      store_url: store_url,
      montoEvento: montoEvento,
      customerinfo: customerinfo,
      addinfo: addinfo,
      arrItems: arrItems,
      arrItemsChecked: arrItems,
      regalos: regalos,
    });
  };

  toggleModal = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  render() {
    return (
      <>
        {this.state.loadingData ? (
          <Loader idLoading={this.state.loadingData} />
        ) : (
          <>
            <Loader idLoading={this.state.isExecuting} />

            <h3
              style={{
                zIndex: "1000",
                fontWeight: "600",
                color: "#000",
                rginLeft: "0.5rem",
              }}
            >
              {" "}
              Datos del Evento
            </h3>

            <MDBRow className="pl-2">
              <MDBCol
                sm="12"
                style={{ fontSize: "1.25rem", fontWeight: "bold" }}
              >
                Datos del Cliente
              </MDBCol>
            </MDBRow>
            <MDBRow className="pl-2">
              {this.state.customerinfo.map((c) => (
                <MDBCol sm="6">
                  <MDBInput
                    value={c.value}
                    disabled={true}
                    label={c.name}
                  ></MDBInput>
                </MDBCol>
              ))}
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                C&oacute;digo Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventId}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Nombre Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventName}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Fecha del Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventDate}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Tipo de Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventType}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Fecha Fin Recepcion Regalos:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventEndDate}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Cantidad de Pedidos:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.orders.length}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Monto Total:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.montoEvento + "Bs."}
                </span>
              </MDBCol>
            </MDBRow>

            {/*
				   	<MDBRow className="pl-2" > 
				   		 
							<MDBCol sm="6" md="3" lg="2" style={{fontSize:"1.25rem"}} >
								Nombre de quien recibe los regalos:
							</MDBCol>  
							<MDBCol sm="6" md="3" lg="4" style={{fontSize:"1.25rem"}} >
							 
								<span style={{ 
											   	 	fontWeight:"600", 
											   	}}> 
								 {this.state.nombre_recepcion}
								</span>
							</MDBCol>  

							<MDBCol sm="6" md="3" lg="2" style={{fontSize:"1.25rem"}} >
								Regalos envueltos:
							</MDBCol>  
							<MDBCol sm="6" md="3" lg="4" style={{fontSize:"1.25rem"}} >
							 
								<span style={{ 
											   	 	fontWeight:"600", 
											   	}}> 
								 {this.state.regalos}
								</span>
							</MDBCol>  
						 
				   	</MDBRow> 
				   	*/}

            <MDBRow className="pl-2">
              <MDBCol
                sm="12"
                style={{ fontSize: "1.25rem", fontWeight: "bold" }}
              >
                Datos de Direccion
              </MDBCol>
            </MDBRow>
            <MDBRow className="pl-2">
              {this.state.addinfo.map((c) => (
                <MDBCol sm="12">
                  <MDBInput
                    value={c.value}
                    disabled={true}
                    label={c.name}
                  ></MDBInput>
                </MDBCol>
              ))}
            </MDBRow>

            {this.state.orders.map((event) => (
              <p key={"event_" + event.id} className="mt-3 mx-2">
                <Event
                  event={event}
                  handlePick={this.handlePick}
                  handleChange={this.handleChange}
                  handleSetRack={this.handleSetRack}
                />
              </p>
            ))}

            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="text-right justify-content-right"
              >
                {/* <ExcelFile filename={"export-orders-evento."+this.state.eventId}  element={
                  <MDBBtn key="find"   size="sm"
                          color="success"
                  >Exportar a Excel</MDBBtn>
              }>
                  <ExcelSheet  data={this.state.tableData.rows} name="Lista de Pedidos">
                    	<ExcelColumn label="Codigo Evento" value="EVENTID"/>
                      <ExcelColumn label="Pedido" value="ORDERID"/>
                      <ExcelColumn label="Fecha Pedido" value="DATEADDED"/>
                      <ExcelColumn label="Nombre" value="FIRSTNAME"/> 
                      <ExcelColumn label="Apellido" value="LASTNAME"/> 
                      <ExcelColumn label="Monto Pedido" value="TOTAL"/> 
                      <ExcelColumn label="SKU" value="SKU"/>
                      <ExcelColumn label="Cantidad" value="QUANTITY"/>
                      <ExcelColumn label="Precio Unitario" value="PRICE"/>
                  </ExcelSheet>

              </ExcelFile>
              */}
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="text-left justify-content-left"
              >
                {/*  <Table tableData={this.state.tableData}  scroll={{ x: 950, y: 300 }} />
                 */}
              </MDBCol>
            </MDBRow>

            {/*entrega*/}
            <MDBRow className="text-center">
              <MDBCol md="12">
                {/*this.state.formButtons.buttonsList.map(item => (

									<MDBBtn
											
											color="primary" 
											disabled={this.state.executedTask}
											size="sm"


											onClick={() => {
												this.setState({modal5:true, optionMessage:   "Confirma la opcion "+item.OPTIONNAME+"?", optionCode: item.OPTIONCODE });
											}} 
											key={item.OPTIONCODE}
										>		{item.OPTIONNAME}
										</MDBBtn>
									))
										*/}
              </MDBCol>
            </MDBRow>

            <MDBModal
              cascading
              centered
              isOpen={this.state.modal5}
              toggle={this.toggleModal(5)}
              size="md"
            >
              <MDBModalBody className="text-center">
                <span className="h4-responsive text-center text-uppercase">
                  {" "}
                  {this.state.optionMessage}
                </span>
                <hr />
                <MDBRow className="text-center">
                  <MDBCol md="12">
                    <MDBBtnGroup size="sm">
                      <MDBBtn
                        outline
                        onClick={() => {
                          this.setState({ modal5: false });
                        }}
                        color="warning"
                      >
                        {" "}
                        <span className="h4-responsive">Cancelar</span>
                      </MDBBtn>
                      <MDBBtn
                        onClick={() => {
                          this.setState({ modal5: false });
                          this.executeTask(this.state.optionCode);
                        }}
                        color="primary"
                      >
                        {" "}
                        <span className="h5-responsive">Aceptar</span>{" "}
                      </MDBBtn>
                    </MDBBtnGroup>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>

            <ToastContainer
              hideProgressBar={true}
              newestOnTop={false}
              position={"bottom-right"}
              autoClose={3000}
            />

            {/*entrega*/}
          </>
        )}

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={false}
          position={"bottom-right"}
          autoClose={3000}
        />
      </>
    );
  }
}

class Event extends React.Component {
  render() {
    return (
      <MDBCard className="">
        <div className="media m-2">
          <div className="media-body mb-3 mb-lg-3">
            <hr className="hr-bold my-2" />
            {this.props.event.orderid && (
              <>
                <div className="font-smaller mb-0">
                  <MDBRow>
                    <MDBCol
                      sm="11"
                      className="align-items-left justify-content-left"
                    >
                      <h5
                        style={{
                          zIndex: "1000",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        <MDBIcon icon="shopping-basket" />
                        <span>Pedido: {this.props.event.orderid}</span>
                      </h5>
                    </MDBCol>

                    <MDBCol style={{ textAlign: "center" }} sm="1">
                      <MDBBadge
                        color={
                          this.props.event.isPicked === -1 ? "grey" : "success"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.props.handlePick(this.props.event.orderid)
                        }
                      >
                        <MDBIcon
                          far={this.props.event.isPicked === -1 ? false : true}
                          size="2x"
                          icon={
                            this.props.event.isPicked === -1
                              ? "check-square"
                              : "check-square"
                          }
                        />
                      </MDBBadge>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <h5
                        style={{
                          zIndex: "1000",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {" "}
                        Nombre Cliente:{" "}
                        {this.props.event.nombre +
                          " " +
                          this.props.event.apellido}{" "}
                      </h5>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol sm="9" md="2">
                      <MDBInput
                        value={this.props.event.posicion_rack}
                        name={this.props.event.orderid}
                        onChange={this.props.handleChange}
                        type="text"
                        label={"Posici\u00F3n Rack"}
                      ></MDBInput>
                    </MDBCol>

                    <MDBCol sm="3" md="2">
                      <MDBBtn
                        color="secondary"
                        onClick={() => {
                          this.props.handleSetRack(this.props.event.orderid);
                        }}
                        className="mt-4"
                        size="sm"
                      >
                        Guardar
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>

                <MDBCard className="m-2">
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <h6
                        style={{
                          zIndex: "1000",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {" "}
                        Productos
                      </h6>
                    </MDBCol>
                  </MDBRow>

                  {this.props.event.products.map((item) => (
                    <>
                      <div className="media m-1 p-1">
                        <span className="h4-responsive font-weight-bold mr-3">
                          {this.props.quantity}
                          <br />
                          <img
                            src={item.image}
                            alt="thumbnail"
                            height="60px"
                            width="60px"
                            className="img-thumbnail"
                          />
                        </span>
                        <div className="media-body mb-3 mb-lg-3">
                          <span className="h6 mt-0 font-weight-bold">
                            {item.name}{" "}
                          </span>{" "}
                          <hr className="hr-bold my-2" />
                          {item.product_id && (
                            <React.Fragment>
                              <p className="font-smaller mb-0">
                                <MDBRow className="align-items-left justify-content-left">
                                  <MDBCol md="12">
                                    <MDBIcon icon="shopping-basket" /> Codigo:{" "}
                                    {item.sku}
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="align-items-left justify-content-left">
                                  <MDBCol md="12">
                                    <MDBIcon icon="barcode" /> SKU: {item.sku}
                                  </MDBCol>
                                </MDBRow>
                              </p>
                            </React.Fragment>
                          )}
                        </div>
                      </div>

                      {item.quantity && (
                        <>
                          <p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
                            {"Cantidad: " +
                              item.quantity +
                              ", Precio unitario: " +
                              item.price +
                              "Bs. Total " +
                              item.total}
                          </p>
                        </>
                      )}
                    </>
                  ))}
                </MDBCard>
                <MDBRow>
                  <MDBCol sm="12" style={{ textAlign: "right" }}>
                    <h6
                      style={{
                        zIndex: "1000",
                        fontWeight: "600",
                        color: "#000",
                        marginRight: 15,
                      }}
                    >
                      <span>
                        Total Pedido:{" "}
                        {this.props.event.total ? this.props.event.total : 0}
                      </span>
                    </h6>
                  </MDBCol>
                </MDBRow>
              </>
            )}
          </div>
        </div>
      </MDBCard>
    );
  }
}

export default externalForm01;

import React from "react";
import {
  toast,
  MDBBadge,
  MDBAnimation,
  ToastContainer,
  MDBIcon,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBCard,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";
import { API_CONFIG, casaideas_credentials } from "../../Config/config-env.js";
import axios from "axios";
import Pdf from "react-to-pdf";

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ref = React.createRef();
class externalForm01 extends React.Component {
  state = {
    id_producto: -1,
    mensaje: "",
    accion: "",
    isDeleted: false,
    productQty: 1,
    productName: "",
    modal5: false,
    modal6: false,
    formButtons: [],
    SKUcode: "3212567000066",
    productPrice: 0,
    existingPreviousOrder: false,
    isExecuting: false,
    isParent: false,
    progress: 0,
    processing: "Procesando",
    ordersPre: [
      {
        id: 0,
        product_id: 111,
        productName: "Manta",
        productPrice: "50Bs.",
        productQty: "1",
        state: "loaded",
      },
      {
        id: 1,
        product_id: 112,
        productName: "Juego de Tazas",
        productPrice: "50Bs.",
        productQty: "1",
        state: "loaded",
      },
    ],
    flowcode: "",
    fechaNota: "",
    orders: [],
    orders2: [],
    WO_Id: "",
    isLoadedTable: false,
    nota_sap: "",
    loadingData: true,
    exportData:[],
    modal4: false,
    modal3: false,
    order_id: "",
    orderName: "",
    orderPrice: "Bs.50",
    orderQty: "1",
    customerName: "",
    customerAdd: "",
    customerZone: "",
    productMaxQty: 0,
    disabledAdd: true,
    read_sku: "",
    WO_Id: "",
    WOTaskId: "",
  };

  componentDidMount = () => {
    this.LoadTable();
  };

  changeHandler = (event) => {
    //console.log(event.target.name)
    //console.log(event.target.value)
    this.setState({ ...this.state, [event.target.name]: [event.target.value] });
  };

  changeHandlerSKU = async (event) => {
    //console.log(event.target.name, event.target.value)
    let value = event.target.value;
    this.setState({ read_sku: value });
    let validSKU = false;
    if (value.length > 12) {
      for (let key in this.state.orders) {
        if (this.state.orders[key].product_id === value) {
          let obj = this.state.orders[key];
          //console.log("este es", obj, key)
          let qty = 1;

          if (obj["qtyPicked"]) {
            qty = obj["qtyPicked"] + 1;
          }

          if (qty <= obj.productQty) {
            await this.handlePick(key, qty);

            this.setState({ read_sku: "" });
            toast.success("OK:" + "Lectura del sku:" + value + " correcta", {
              closeButton: true,
            });
          } else {
            this.setState({ read_sku: "" });
            toast.error("NOK:" + "Cantidad del SKU" + value + " superada", {
              closeButton: true,
            });
          }
          validSKU = true;
        }
      }

      if (!validSKU) {
        toast.error("NOK:" + "SKU: " + value + " no encontrado", {
          closeButton: true,
        });
      }
    }
  };

  handlePick = async (producto, qty) => {
    //	console.log(producto)
    let neworders = [];
    let product_ids = "";
    let totalProd = this.state.orders.length;
    let totalPicked = 0;

    this.setState({ isExecuting: true });
    console.log(this.state.orders);
    for (let key in this.state.orders) {
      let obj = this.state.orders[key];
      //console.log(this.state.orders[key])

      if (this.state.orders[key].id === producto) {
        if (qty) {
          obj["qtyPicked"] = qty;
          if (qty === this.state.orders[key].productQty) {
            obj["isPicked"] = 1;
          } else {
            obj["isPicked"] = -1;
          }
        } else {
          obj["qtyPicked"] =
            this.state.orders[key].isPicked === -1
              ? this.state.orders[key].productQty
              : 0;
          obj["isPicked"] = this.state.orders[key].isPicked === -1 ? 1 : -1;
        }

        //obj["isPicked"]= this.state.orders[key].isPicked===-1?1:-1;
      }
      neworders.push(obj);
    }
    //console.log("news", neworders)
    for (let key in neworders) {
      //console.log(neworders[key])
      if (neworders[key].qtyPicked > 0) {
        product_ids =
          product_ids +
          "|" +
          neworders[key].product_id +
          "," +
          neworders[key].qtyPicked +
          "," +
          neworders[key].productQty;
      }

      if (neworders[key].isPicked > 0) {
        totalPicked++;
      }
    }
    if (product_ids.length > 1) {
      product_ids = product_ids.substr(1, product_ids.length);
    }
    var progress = Math.ceil((totalPicked / totalProd) * 100);
    let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
    //let url = "http://localhost:4042/api/v1"+"/engine-users/save-flow-field";

    await axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      url: url,
      method: "put",
      data: {
        WO_flowID: this.state.WO_Id,
        WO_TaskID: this.state.WOTaskId,
        params: [
          { var: "picking_products", value: product_ids },
          { var: "progress", value: progress },
        ],
      },
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ isExecuting: false, progress: progress });
      });

    this.setState({ orders: neworders });
  };

  LoadTable = async () => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    let WO_Id = params.get("wfpflowid");

    let officecode = params.get("officecode");
    let isParent = false;

    let officecode2 = "";
    
    if (officecode == "LP02") {
      officecode2 = "LP01";
    }

    if (officecode == "SC02") {
      officecode2 = "SC04";
    }

    var mixed_skus = [];
    var flowcode;
    let formButtons = [];
    let nota_sap = "";

    if (params.get("options")) {
      let options = params.get("options").split(",");
      console.log("options", options);
      for (let o in options) {
        formButtons.push({ optioncode: "op" + o, optionname: options[o] });
      }
    }

    let _WOTaskId = params.get("wfptaskid").split("___");

    let WOTaskId = _WOTaskId[0];

    if (_WOTaskId.length === 2) {
      console.log("es multiple");
      isParent = true;
    }

    var customer_id = 0;
    var picking_products = "";
    var progress = 0;
    let orders = [];
    let source = "";
    this.setState({
      isLoadedTable: false,
      WO_Id: WO_Id,
      loadingData: true,
      isParent: isParent,
    });

    await axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/get-task-details",
      //url : "http://localhost:4042/api/v1"+"/engine-users/get-task-details",
      data: {
        wfptaskid: WOTaskId,
        isParent: isParent,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        console.log("data", data);

        if (data.task) {
          flowcode = data.task.FLOWCODE;
        }

        if (data.tasks) {
          flowcode = data.tasks[0].FLOWCODE;
        }

        if (data.status) {
          console.log("flowvars ", data.flowvars);

          for (let v in data.flowvars) {
            let flowVar = data.flowvars[v];
            let order_id = "";
            let flowid = flowVar.WFPFLOWID;
            if (flowVar.VARNAME === "order_id") {
              order_id = flowVar.VARVALUE;

              let customer_id = "";
              let pedido_parcial = "";
              let mixed_skus = [];
              for (let v2 in data.flowvars) {
                let flowVar2 = data.flowvars[v2];

                if (flowVar2.WFPFLOWID == flowid) {
                  if (flowVar2.VARNAME === "customer_id") {
                    customer_id = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME === "pedido_parcial") {
                    pedido_parcial = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME === "officecodeONLINE") {
                    source = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME === "mixed_skus") {
                    try {
                      mixed_skus = JSON.parse(flowVar2.VARVALUE);
                    } catch (ex) {
                      console.log("EX", ex);
                    }
                  }
                }
              }
              orders.push({
                order_id,
                customer_id,
                pedido_parcial,
                mixed_skus,
              });
            }
            if (flowid == WO_Id) {
              if (flowVar.VARNAME === "picking_products") {
                picking_products = flowVar.VARVALUE;
              }
              if (flowVar.VARNAME === "progress") {
                progress = parseInt(flowVar.VARVALUE);
              }

              if (flowVar.VARNAME === "nota_sap") {
                console.log("sap", flowVar.VARVALUE);
                nota_sap = flowVar.VARVALUE;
              }
            }
          }

          if ( data.task.TASK ==="Recepcionar Pedidos"){
            progress=100
          }

        }
      });
    let url = API_CONFIG.casaideas + "/externalapi/execute/getorderById";
    var prodList = [];
    var strProd = [];
    var store_url = "";

  

    console.log("progress", progress);
    console.log("orders", orders);
    for (let o in orders) {
      let rowV = orders[o];
      await axios({
        crossdomain: true,
        url: url,
        method: "post",
        data: {
          username: casaideas_credentials.user,
          password: casaideas_credentials.password,
          order_id: rowV.order_id,
          customer_id: rowV.customer_id,
          adminpath: "matrix",
        },
      })
        .then((response) => response.data)
        .then((data) => {
          console.log(data);

          if (data.response[0].products.length > 0) {
            store_url = data.response[0].order_info.store_url;
            for (let key in data.response[0].products) {
              let prod = data.response[0].products[key];
              let isValid = false;
              let _productQty = 0;
              if (rowV.pedido_parcial) {
                //console.log("es parcial")
                for (let k in rowV.mixed_skus) {
                  let sku = rowV.mixed_skus[k];
                  //	console.log("validando", sku.sku, data.response[0].products[key].sku )
                  if (sku.sku === data.response[0].products[key].sku) {
                    //		console.log("sies")
                    if (flowcode === "flowBolCI07") {
                      //			console.log("tienda extra")
                      if (source === sku.store) {
                        _productQty = sku.qty;
                        isValid = true;
                      }
                    } else {
                      if (officecode === sku.store) {
                        _productQty = sku.qty;
                        isValid = true;
                      }
                    }
                  }
                }
              } else {
                _productQty = data.response[0].products[key].quantity;
                //	console.log("pasa")
              }
              if (isValid) {
                var productStep = parseFloat(
                  data.response[0].products[key].decimal_step
                );
                var esStepDecimal =
                  typeof productStep === "number" &&
                  isFinite(productStep) &&
                  Math.floor(productStep) !== productStep;
                var productQty = 0;

                if (esStepDecimal === true) {
                  productQty = parseFloat(_productQty);
                } else {
                  productQty = parseInt(_productQty);
                }

                if (strProd.indexOf(data.response[0].products[key].sku) < 0) {
                  strProd.push(data.response[0].products[key].sku);
                  var minimum = data.response[0].products[key].minimum;
                  if (
                    minimum === undefined ||
                    minimum === null ||
                    minimum == "0.0" ||
                    minimum == "0"
                  ) {
                    if (esStepDecimal === true) {
                      minimum = 0.1;
                    } else {
                      minimum = 1;
                    }
                  }
                  prodList.push({
                    product_id: data.response[0].products[key].sku,
                    name: data.response[0].products[key].name,
                    model: data.response[0].products[key].model,
                    sku: data.response[0].products[key].sku,
                    image: data.response[0].products[key].image,
                    ean: data.response[0].products[key].ean,
                    price: data.response[0].products[key].price,
                    orders:
                      "Orden " + rowV.order_id + " - cantidad: " + productQty,
                    orderIds: rowV.order_id,
                    decimal_step: data.response[0].products[key].decimal_step,
                    minimum: minimum,
                    quantity: productQty,
                  });
                } else {
                  let tmpNewArray = [];
                  for (let key2 in prodList) {
                    let newObj;
                    newObj = prodList[key2];
                    if (
                      prodList[key2].sku === data.response[0].products[key].sku
                    ) {
                      let productQtyNew = 0;
                      if (esStepDecimal === true) {
                        productQtyNew = parseFloat(prodList[key2].quantity);
                        productQty = parseFloat(
                          data.response[0].products[key].quantity
                        );

                        newObj.quantity = (
                          parseFloat(productQtyNew) + parseFloat(productQty)
                        ).toFixed(3);
                      } else {
                        productQtyNew = parseInt(prodList[key2].quantity);
                        productQty = parseInt(
                          data.response[0].products[key].quantity
                        );
                        newObj.quantity =
                          parseInt(productQtyNew) + parseInt(productQty);
                      }

                      newObj.orders =
                        newObj.orders +
                        ", Orden " +
                        rowV.order_id +
                        " - cantidad: " +
                        productQty;
                      newObj.orderIds = newObj.orderIds + "," + rowV.order_id;
                    }
                    tmpNewArray.push(newObj);
                  }
                  prodList = tmpNewArray;
                }
              } else {
                console.log("producto para la otra tienda", prod);
              }
            }
          }
        });
    }
    var data = {
      resp: "OK",
      rows: prodList,
    };
    /*
		console.log("prodList",prodList)
		console.log("WO_Id",WO_Id)
		console.log("WOTaskId",WOTaskId)
		*/

    var arr = [];
    var arr2 = [];
    var i = 0;

    if (data.resp === "OK" && data.rows.length > 0) {
      //console.log(data);
      var arrayPicked = [];

      if (picking_products !== null) {
        arrayPicked = picking_products.split("|");
      }
      for (let key in data.rows) {
        var arrPicked = arrayPicked.filter(function (e) {
          return e.substr(0, e.indexOf(",")) == data.rows[key].product_id;
        });

        var isPicked = -1;
        var qtyPicked = 0;
        if (arrPicked.length > 0) {
          let data = arrPicked[0].split(",");
          if (data[1] === data[2]) {
            isPicked = 1;
          }
          qtyPicked = parseInt(data[1]);
        }
        arr.push({
          id: key,
          product_id: data.rows[key].product_id,
          productName: data.rows[key].name,
          productModel: data.rows[key].model,
          orders: data.rows[key].orders,
          productQty: data.rows[key].quantity,
          productImage: data.rows[key].image,
          productSKU: data.rows[key].sku,
          state: "loaded",
          isPicked: isPicked,
          qtyPicked: qtyPicked,

          //NOT USED
        });
      }
    }

    /*ahora.toLocaleString("es-ES" ,
	{day: "2-digit", month: "short", year: "numeric"});*/
    let ahora = new Date();
    let fechaNota = ahora.toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    let exportData= [
      ["Fecha", fechaNota, "DOC.SAP",nota_sap],
      ["","SKU", "Descripción", "Cantidad"],
    ]
    for (let i in arr){
      exportData.push([
        "",
        arr[i].productSKU,
        arr[i].productName,
        arr[i].productQty
      ])
    }
   
                            

    this.setState({
      ...this.state,
      orders: arr,
      orders2: arr2,
      isLoadedTable: true,
      exportData:exportData,
      fechaNota: fechaNota,
      loadingData: false,
      WOTaskId: WOTaskId,
      flowcode: flowcode,
      officecode2: officecode2,
      officecode: officecode,
      WO_Id: WO_Id,
      token: token,
      nota_sap: nota_sap,
      formButtons: formButtons,
      progress: progress,
    });
  };

  toggleModal = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  executeTask = async (option, optionName) => {
    var responseValidation = true;

    this.setState({ isExecuting: true });

    console.log("op", this.state.progress, this.state)
    if (option == "op0") {
      if (this.state.progress !== 100) {
        responseValidation = false;
        var msj_validacion = "Debe pickear todos los productos";

        toast.warning(msj_validacion, {
          closeButton: true,
        });
      }
    }

    if (!responseValidation) {
      this.setState({
        ...this.state,
        responseTask: "NOK",
        isExecuting: false,
        modal6: false,
      });
    } else {
      this.setState({ responseTask: null });

      console.log("ok");

      await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        url: API_CONFIG.xflowurl + "/engine-users/execute-task",
        //url: "http://localhost:4042/api/v1" + "/engine-users/execute-task",
        data: {
          parentid: this.state.WOTaskId,
          id: this.state.WOTaskId,
          isParent: this.state.isParent,
          optionValue: optionName,
        },
        method: "put",
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.status) {
            toast.success("Procesado con exito", {
              closeButton: true,
            });
          } else {
            toast.error("Error ejecutando tareas, mensaje:" + data.message, {
              closeButton: true,
            });
          }
          this.setState({
            ...this.state,
            isExecuting: false,
            modal6: false,
          });
        });
    }
  };

  render() {
    return (
      <>
        {this.state.loadingData ? (
          <Loader idLoading={this.state.loadingData} />
        ) : (
          <>
            <Loader idLoading={this.state.isExecuting} />

            <h3
              style={{
                zIndex: "1000",
                fontWeight: "600",
                color: "#000",
              }}
            >
              {" "}
              Listado de Productos de los pedidos agrupados
            </h3>

            {this.state.existingPreviousOrder ? (
              <MDBCard style={{ width: "98%" }} className="mb-3">
                <MDBRow className="align-items-left justify-content-left">
                  <MDBCol md="12">
                    <MDBAlert className="text-left align-top">
                      <h3 className="h3-responsive font-weight-bold mr-3">
                        <MDBIcon icon="exclamation-circle" className="mr-1" />{" "}
                        El cliente {this.state.customerName} tiene un pedido aun
                        no entregado
                      </h3>
                    </MDBAlert>
                  </MDBCol>
                </MDBRow>

                <hr />
                <MDBRow className="text-right">
                  <MDBCol md="12">
                    <MDBBtn
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ modal4: true });
                      }}
                    >
                      {" "}
                      Revisar Pedido
                    </MDBBtn>

                    <MDBBtn
                      size="sm"
                      disabled={this.state.isDeleted}
                      color="danger"
                      onClick={() => {
                        //	this.deleteShipping("1616");
                        this.setState({
                          modal5: true,
                          mensaje: "eliminar la tarifa?",
                          accion: "eliminar tarifa",
                        });
                      }}
                    >
                      {" "}
                      Eliminar Tarifa de envio
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            ) : (
              ""
            )}

            {this.state.orders.map((event) => (
              <p key={"event1" + event.id} style={{ width: "98%" }}>
                <Event
                  id={event.id}
                  product_id={".000" + event.product_id}
                  productName={event.productName}
                  productQty={event.productQty}
                  orders={event.orders}
                  productModel={event.productModel}
                  productImage={event.productImage}
                  productSKU={event.productSKU}
                  isPicked={event.isPicked}
                  state={event.state}
                  onDelete={null}
                  onEdit={null}
                  onPick={null}
                />
              </p>
            ))}

            <MDBRow
              className="text-right"
              style={{
                padding: 15,
                borderTop: "1px solid #4285f4",
                borderBottom: "1px solid #4285f4",
              }}
            >
              <MDBCol md="12">
                {this.state.formButtons.map((item) => (
                  <MDBBtn
                    color="primary"
                    onClick={() => {
                      this.setState({
                        modal6: true,
                        optionMessage:
                          "Confirma la opcion " + item.optionname + "?",
                        optionCode: item.optioncode,
                        optionName: item.optionname,
                      });
                    }}
                    key={item.optioncode}
                  >
                    {" "}
                    {item.optionname}
                  </MDBBtn>
                ))}
              </MDBCol>
            </MDBRow>

            {this.state.flowcode === "flowBolCI07" && (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div ref={ref} className="pt-5 pl-5">
                    <MDBTable
                      id="table1"
                      small
                      responsive
                      style={{
                        width: "680px",
                      }}
                      className="tableBlue"
                      bordered
                    >
                      <MDBTableHead>
                        <tr>
                          <th
                            className="text-center"
                            style={{ background: "#fdbe00" }}
                            colSpan="4"
                          >
                            <h5>
                              TRASPASO PICK UP {this.state.officecode2} A{" "}
                              {this.state.officecode}
                            </h5>
                          </th>
                        </tr>

                        <tr>
                          <th style={{ background: "#fdbe00" }}>Fecha</th>
                          <th style={{ background: "#fdbe00" }}>
                            {this.state.fechaNota}
                          </th>
                          <th
                            className="text-right"
                            style={{ background: "#fdbe00" }}
                          >
                            DOC.SAP
                          </th>
                          <th style={{ background: "#fdbe00" }}>
                            {this.state.nota_sap}
                          </th>
                        </tr>

                        <tr>
                          <th style={{ background: "#fff2cd" }}></th>
                          <th
                            className="text-center"
                            style={{ background: "#fff2cd" }}
                          >
                            SKU
                          </th>
                          <th
                            className="text-center"
                            style={{ background: "#fff2cd" }}
                          >
                            Descripción
                          </th>
                          <th
                            className="text-center"
                            style={{ background: "#fff2cd" }}
                          >
                            Cantidad
                          </th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {this.state.orders.map((key) => (
                          <tr>
                            <td></td>
                            <td>{key.productSKU}</td>
                            <td>{key.productName}</td>
                            <td className="text-center">{key.productQty}</td>
                          </tr>
                        ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 15,
                  }}
                >
                  <ExcelFile
                    filename={"export-nota-traspaso"}
                    element={
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        name="set"
                      >
                        {" "}
                        Exportar a Excel
                      </button>
                    }
                  >
                    <ExcelSheet
                      dataSet={[
                        {
                          columns: [
                            { value: "", widthPx: 60,widthCh: 60, }, // width in pixels FFFFAA00
                            { value: "", widthCh: 160 }, 
                            { value: "TRASPASO PICK UP "
                              
                              +this.state.officecode2 +  " "
                              +this.state.officecode

                              , style: { font: { sz: "24", bold: true } , fill: {bgColor:"#fdbe0000", fgColor:"#fdbe0000"} }, widthPx: 260  },  
                            { value: "", widthPx: 120  },  
                          ],
                          data: this.state.exportData,
                        },
                      
                      ]}
                      name="Organization"
                    />
                  </ExcelFile>
{/*
                  <Pdf targetRef={ref} filename="Rotulo.pdf">
                    {({ toPdf }) => (
                      <MDBBtn
                        key="find"
                        size="sm"
                        color="success"
                        onClick={toPdf}
                      >
                        Exportar a Pdf
                      </MDBBtn>
                    )}
                  </Pdf>
                  */}
                </div>
              </>
            )}

            <MDBModal
              cascading
              centered
              isOpen={this.state.modal6}
              toggle={this.toggleModal(6)}
              size="md"
            >
              <MDBModalBody className="text-center">
                <span className="h4-responsive text-center text-uppercase">
                  {" "}
                  {this.state.optionMessage}
                </span>
                <hr />
                <MDBRow className="text-center">
                  <MDBCol md="12">
                    <MDBBtnGroup size="sm">
                      <MDBBtn
                        outline
                        onClick={() => {
                          this.setState({ modal6: false });
                        }}
                        color="warning"
                      >
                        {" "}
                        <span className="h4-responsive">Cancelar</span>
                      </MDBBtn>
                      <MDBBtn
                        onClick={() => {
                          this.setState({ modal6: false });
                          this.executeTask(
                            this.state.optionCode,
                            this.state.optionName
                          );
                        }}
                        color="primary"
                      >
                        {" "}
                        <span className="h5-responsive">Aceptar</span>{" "}
                      </MDBBtn>
                    </MDBBtnGroup>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>

            <MDBModal
              cascading
              centered
              isOpen={this.state.modal4}
              toggle={this.toggleModal(4)}
              size="lg"
            >
              <MDBModalBody>
                <span className="h2-responsive mb-2">
                  {" "}
                  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle
                  del pedido Previo{" "}
                </span>

                <p>Nombre: {this.state.customerName}</p>
                <p>Direccion: {this.state.customerAdd}</p>
                <p>Zona: {this.state.customerZone}</p>
                {this.state.ordersPre.map((event) => (
                  <p key={"event2" + event.id} style={{ width: "98%" }}>
                    <Event
                      id={event.id}
                      product_id={event.product_id}
                      productName={event.productName}
                      productQty={event.productQty}
                      productModel={event.productModel}
                      productImage={event.productImage}
                      productSKU={event.productSKU}
                      isPicked={event.isPicked}
                      state={event.state}
                      onDelete={null}
                      onEdit={null}
                      onPick={null}
                    />
                  </p>
                ))}
              </MDBModalBody>
            </MDBModal>

            <MDBModal
              cascading
              centered
              isOpen={this.state.modal3}
              toggle={this.toggleModal(3)}
              size="md"
            >
              <MDBModalBody>
                <span className="h2-responsive mb-4">
                  {" "}
                  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar
                  Producto
                </span>

                <MDBRow className="text-right">
                  <MDBCol md="9">
                    <MDBInput
                      id="SKUcode"
                      key="SKUcode"
                      name="SKUcode"
                      value={this.state.SKUcode}
                      onChange={this.changeHandler}
                      size="sm"
                      label="SKU"
                    />
                  </MDBCol>
                  <MDBCol md="3">
                    <MDBBtn
                      color="secondary"
                      onClick={() => {
                        this.searchProduct();
                      }}
                      className="mt-4"
                      size="sm"
                    >
                      Buscar
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="text-right">
                  <MDBCol md="12">
                    <MDBInput
                      id="productName"
                      label="Producto"
                      readOnly={true}
                      name="productName"
                      value={this.state.productName}
                      onChange={this.changeHandler}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow className="text-right">
                  <MDBCol md="6">
                    <MDBInput
                      id="productPrice"
                      label="Precio"
                      readOnly={true}
                      name="productPrice"
                      value={this.state.productPrice}
                      onChange={this.changeHandler}
                    />
                  </MDBCol>

                  <MDBCol md="6">
                    <MDBInput
                      label="Cantidad"
                      id="productQty"
                      name="productQty"
                      readonly
                      type="number"
                      min="1"
                      max={this.state.productMaxQty}
                      value={this.state.productQty}
                      onChange={this.changeHandler}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow className="text-right">
                  <MDBCol md="12">
                    <MDBBtn
                      size="sm"
                      color="success"
                      disabled={this.state.disabledAdd}
                      onClick={() => {
                        this.setState({
                          modal5: true,
                          mensaje: "Agregar este producto?",
                          accion: "agregar",
                        });
                      }}
                    >
                      {" "}
                      <MDBIcon icon="cart-plus" /> Agregar
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>

            <MDBModal
              cascading
              centered
              isOpen={this.state.modal5}
              toggle={this.toggleModal(5)}
              size="md"
            >
              <MDBModalBody className="text-center">
                <span className="h4-responsive text-center">
                  Esta seguro de {this.state.mensaje}
                </span>
                <hr />

                <MDBRow className="text-center">
                  <MDBCol md="12">
                    <MDBBtnGroup size="sm">
                      <MDBBtn
                        outline
                        onClick={() => {
                          this.setState({ modal5: false });
                        }}
                        color="danger"
                        className="text-capitalize"
                      >
                        {" "}
                        <span className="h4-responsive">Cancelar</span>
                      </MDBBtn>
                      <MDBBtn
                        onClick={() => {
                          this.setState({ modal5: false });
                          if (this.state.accion === "agregar") {
                            this.addOrder();
                          } else if (this.state.accion === "eliminar tarifa") {
                            this.deleteShipping(this.state.order_id);
                          } else if (this.state.accion === "aumentar") {
                            this.handleCommitEdit(
                              this.state.id_producto,
                              "Add"
                            );
                          } else if (this.state.accion === "disminuir") {
                            this.handleCommitEdit(
                              this.state.id_producto,
                              "Rem"
                            );
                          } else {
                            this.handleCommitDelete(this.state.id_producto);
                          }
                        }}
                        color="success"
                        className="text-capitalize"
                      >
                        {" "}
                        <span className="h4-responsive">
                          {this.state.accion}
                        </span>{" "}
                      </MDBBtn>
                    </MDBBtnGroup>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
            </MDBModal>
          </>
        )}

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={false}
          position={"bottom-right"}
          autoClose={3000}
        />
      </>
    );
  }
}

class Event extends React.Component {
  render() {
    return this.props.state === "deleting" ? (
      <MDBCard
        style={{
          opacity: "0.4",
          backgroundColor: "#999",
        }}
        className="align-items-center justify-content-center"
      >
        <p></p>
        <div className="media mt-4 align-middle">
          <div className="media-body mb-3 mb-lg-3">
            <MDBAnimation type="rotateOut" infinite>
              <MDBIcon
                style={{
                  zIndex: "1050",
                }}
                size="2x"
                far
                icon="trash-alt"
              />
            </MDBAnimation>
          </div>
        </div>
        <p></p>
      </MDBCard>
    ) : (
      <MDBCard className="">
        <div className="media mt-1">
          <span className="h4-responsive font-weight-bold mr-3 align-items-right justify-content-right">
            <img
              src={this.props.productImage}
              height="60px"
              width="60px"
              alt="thumbnail"
              className="img-thumbnail"
            />
          </span>
          <div className="media-body mb-3 mb-lg-3">
            {this.props.onPick === null ? (
              ""
            ) : (
              <MDBBadge
                color={this.props.isPicked === -1 ? "grey" : "success"}
                style={{ cursor: "pointer" }}
                className="mr-1 float-right"
                onClick={() => this.props.onPick(this.props.id)}
              >
                <MDBIcon
                  far={this.props.isPicked === -1 ? false : true}
                  size="2x"
                  icon={
                    this.props.isPicked === -1 ? "check-square" : "check-square"
                  }
                />
              </MDBBadge>
            )}
            <span className="h6 mt-0 font-weight-bold">
              {this.props.productName}{" "}
            </span>{" "}
            <hr className="hr-bold my-2" />
            {this.props.product_id && (
              <React.Fragment>
                <p className="font-smaller mb-0">
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <MDBIcon icon="shopping-basket" /> Codigo:{" "}
                      {this.props.productModel}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <MDBIcon icon="barcode" /> SKU: {this.props.productSKU}
                    </MDBCol>
                  </MDBRow>
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
        {this.props.productQty && (
          <p className="h4-responsive p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
            Cantidad: <b> {this.props.productQty}</b>
          </p>
        )}

        {this.props.orders && (
          <p className="h4-responsive p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
            Ordenes: <b> {this.props.orders}</b>
          </p>
        )}
      </MDBCard>
    );
  }
}

export default externalForm01;

import React  from 'react';
import {API_CONFIG} from './config-env.js';
const axios = require('axios');

export async function callexternalApi( base_url, apiname, apimethod, _data, token ) {
	var resp={}
	await axios({
			 url: base_url+apiname,
			 headers:  token?  { 'Authorization': 'Bearer ' +token } : null ,
			 data : _data,
			 method: apimethod
	}).then(response => response.data).then(data => {
		resp = data
	}).catch(function(error) {
    console.log(">>>>>>>>", error)
  })
	return resp    
}
export async function callApi( apiname, apimethod, _data,  token ) {
	var resp={}
	await axios({
			 url: API_CONFIG.base_url+apiname,
			 headers:  token?  { 'Authorization': 'Bearer ' +token } : null ,
			 data : _data,
			 method: apimethod
	}).then(response => response.data).then(data => {
		resp = data
	}).catch(function(error) {
    console.log(">>>>>>>>", error)
  })
	return resp    
}


export function findObjName ( _event  ){
	
	var continueSerching = true
	var objName= "";
	 
  var event = _event.target
  
	while (continueSerching){
		if (event.name ||  event.attributes.name ){
		
	//	console.log("encontramos",event.name ,  event.attributes.name )
			continueSerching= false
			objName = event.name ? event.name : event.attributes.name.nodeValue
			
		}else{ 
			event = event.parentNode
			  
		}
	}
 
	return objName
}

export function parseFormData(_formData) {
	var parsedFormData={}
	try{
		for (var key in Object.keys(_formData) ){
	  	var field = Object.keys(_formData)[key]
	  	
	  	if (_formData[field].value !== undefined ){
	  		let fieldValue = _formData[field].value
	  		parsedFormData[field.toLowerCase()] = fieldValue	
	  	}else{
	  		parsedFormData[field.toLowerCase()] =  _formData[field].values
	  	}
	  	
		}

	}catch(err){
		console.log(">>>>>>>>>>"+err)
	}finally{
		return parsedFormData
	}
	 
}

export function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a href="#0">Previous</a>;
  }
  if (type === "next") {
    return <a href="#0">Next</a>;
  }
  return originalElement;
}

export function onShowSizeChange(obj) {
	
}
export function find(_json, _value, _key){
	var pos = -1

	_json.map(function (key, item) {
		if ( key[_key] ==_value ){
				pos= item
			}
		});
	return pos
}
export async function searchHandler(event, _filterConfig,  _showMore, _apiName){
	//console.log("hola")
	//console.log(event.target.name)
	 
	var filterConfig = _filterConfig
	var showMore = _showMore
	
	
	
	var option =  findObjName(event)
	  
	 
	if (_filterConfig[option]){
		
		
		var options = event.target.options;
	   
		var value = event.target.value;
		if (options){
			value= []
			for (var i = 0, l = options.length; i < l; i++) {
	    	if (options[i].selected) {
	    		
	    		if (isNaN( parseInt(options[i].value)  )){
	    			value.push( (options[i].value));
	    		}else{
	    			value.push( parseInt(options[i].value));	
	    		}
	    		
		      
	    	}
	  	}
	  	if (value.length===1){
	  		value= value[0]+""
	  	}
	  	
		}
			//console.log(event.target.name, "val", value)
		
		
		filterConfig[event.target.name].value= value
		return {
			filterConfig:_filterConfig,
			showMore : _showMore
		}
	}else if(option==="showMore") {
		showMore = _showMore?false:true
		return {
			filterConfig:_filterConfig,
			showMore : showMore
		}
	}else if(option==="search") {
		 
	 
		var formData = parseFormData(_filterConfig)
		formData["username"] ="admin"
		let resp = await callexternalApi ( "", _apiName, "post", formData )
		console.log("resp", resp)
		
		 
		var reports ={}
		if (resp.success){
			Object.keys(resp).map(function (item) {
				//console.log(item)
				if(Array.isArray(resp[item])){
					//console.log("es reporte", resp[item])
					reports[item] = (resp[item])
				}
			}); 
		}
		 
		return { 
			tableData:reports
		}
	}else if(event.target.name==="refresh") {
		
		Object.keys(_filterConfig).map(function (key) {
			let item = _filterConfig[key]
			item.value=""
		}); 
		
		return { 
			filterConfig:_filterConfig,
			tableData:{}, 
			showMore:showMore
		}
	}
}

export function parseCurrencyToNumber(currencyString) {
	// Elimina todo excepto los dígitos y el separador decimal
	const numericString = currencyString.replace(/[^0-9,.-]/g, '').replace(',', '.');
	
	// Convierte el resultado en número flotante
	let number = parseFloat(numericString);
  
	// Verifica si la conversión fue exitosa y redondea a 1 decimal
	return isNaN(number) ? null : parseFloat(number.toFixed(1));
  }
export async function filterHandler(event, _filterConfig, _tableData){
	//console.log("hola")
	//console.log(event.target.name)
	
	var filterConfig = _filterConfig
	
	if (_filterConfig[event.target.name]){
		//console.log("existe")
		//console.log("val", event.target.value)
		filterConfig[event.target.name].value= event.target.value
		return {
			filterConfig:_filterConfig,
		}
	}else if(event.target.name=="search") {
		
		//console.log("actu", _tableData.rows.length)
		var tableData = { columns : _tableData.columns, rows:[] }
		var newRows =[]
		
		var fieldsToEval=[]
		Object.keys(_filterConfig).map(function (key) {
			let item = _filterConfig[key]
			if (item.value!=""){
				fieldsToEval.push({ field: key, value: item.value, type: item.type } )
			}
		}); 
 
		if (fieldsToEval.length>0){
			_tableData.rows.map(function (item) {
				let isValid = true
				for (var key in fieldsToEval){
					Object.keys(item).map(function (col) {
						if (isValid){
							if (fieldsToEval[key].field == col){
								
								if ((item[col])){
									if ( fieldsToEval[key].type =="TEXT"){
										if ( (item[col]).toUpperCase().indexOf( (fieldsToEval[key].value).toUpperCase() ) < 0)  {
											isValid=false
										}
									}else if  ( fieldsToEval[key].type =="NUMBER"){
										if ( item[col] != fieldsToEval[key].value )  {
											isValid=false
										}
									}else if  ( fieldsToEval[key].type =="SELECT"){
										if ( item[col] != fieldsToEval[key].value )  {
											isValid=false
										}
									}
								}else{
									isValid=false
								}
								 
							}
						}
					})
				}
				if (isValid){
					newRows.push(item)
				}
			}); 
			tableData["rows"] = newRows
			
		}else{
			 tableData = { columns : _tableData.columns, rows: 	_tableData.rows }	
		}
		return { 
			tableData:tableData
		}
	}else if(event.target.name=="refresh") {
		
		Object.keys(_filterConfig).map(function (key) {
			let item = _filterConfig[key]
			item.value=""
		}); 
		
		return { 
			filterConfig:_filterConfig,
			tableData:_tableData
		}
	}
}
import React from "react";
import {MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_CONFIG} from '../../Config/config-env.js';
import axios from 'axios';

class externalForm01 extends React.Component {
	
	state = {
		id_producto:-1,
		mensaje:"",
		accion:"",
		isDeleted:false,
		productQty:1,
		productName:"",
		SKUcode:"3212567000066",
		productPrice:0,
		existingPreviousOrder:false,
		isExecuting:false,
		processing: "Procesando",
		ordersPre:[
			{id:0,
				product_id: 111,
				productName:"Manta",
				productPrice:"50Bs.",
				productQty:"1",
			  state: "loaded" },
			{id:1,
				product_id: 112,
				productName:"Juego de Tazas",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded" 
			}
		],
		orders:[],
		WO_Id:"",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true,
		read_sku:"",
		
	};
	
	componentDidMount=()=>{
	 		this.LoadTable();
	}
	  
	changeHandler = event => { 
  		//console.log(event.target.name)
  		//console.log(event.target.value)
		this.setState({ ...this.state, [event.target.name]: [event.target.value] });
	};
	
	changeHandlerSKU  = async (event) => { 
		//console.log(event.target.name, event.target.value)
		let value= event.target.value
		this.setState({ read_sku:value  });
		let validSKU=false
		if (value.length> 12){
			for (var key in  this.state.orders){
			 
				if (this.state.orders[key].product_id===value){					
					let obj = this.state.orders[key];
					//console.log("este es", obj, key)					
					let qty = 1
					
					if (obj["qtyPicked"]){
						qty=obj["qtyPicked"]+1
					}

					if ( qty <= obj.productQty ){

						await this.handlePick(key, qty)

						this.setState({ read_sku:""  });
						toast.success("OK:"+"Lectura del sku:"+value+ " correcta", {
							closeButton: true
						});

					}else{
						this.setState({ read_sku:""  });
						toast.error("NOK:"+"Cantidad del SKU"+value+ " superada", {
							closeButton: true
						});
					}
					validSKU=true					
				} 
				
			}

			if (!validSKU){
				toast.error("NOK:"+"SKU: "+value+ " no encontrado", {
					closeButton: true
				});
			}
		}
			 

	}
  	
	handlePick = async (producto, qty) => { 
	//	console.log(producto)
		let neworders =[]
		let product_ids="";
		let totalProd = this.state.orders.length
		let totalPicked =0
		
		this.setState({ isExecuting:true });	
		console.log(this.state.orders)
		for (var key in  this.state.orders){
 		
 			let obj = this.state.orders[key];
 			//console.log(this.state.orders[key])
 	
 			if (this.state.orders[key].id===producto){  

				if (qty){
					obj["qtyPicked"] = qty
					if (qty === this.state.orders[key].productQty ){
						obj["isPicked"]=1;	
					}else{
						obj["isPicked"]=-1;	
					}
				}else{
					obj["qtyPicked"]= this.state.orders[key].isPicked===-1?this.state.orders[key].productQty:0
					obj["isPicked"]= this.state.orders[key].isPicked===-1?1:-1;
				}

				//obj["isPicked"]= this.state.orders[key].isPicked===-1?1:-1;

 			} 
 			neworders.push(obj)
 		} 
 		console.log("news", neworders)
 		for (var key in neworders){ 		 
 			//console.log(neworders[key])
 			if (neworders[key].qtyPicked>0){   				
				product_ids=product_ids+"|"+neworders[key].product_id+","+(neworders[key].qtyPicked)+","+neworders[key].productQty
 			} 

			if (neworders[key].isPicked>0){   				
				totalPicked++
 			} 

 		} 
		if (product_ids.length>1){
			product_ids= product_ids.substr(1,product_ids.length);
		}
		let progress = Math.ceil(totalPicked/totalProd*100)
		let url = API_CONFIG.base_url+'/externalApi/setParamFlowByTask/';
			await	axios({
					url: url,
					method: 'post',
					
					data: {
						org: this.state.org,
						taskId:this.props.match.params.WOTaskId,
						isFather:"YES",
						params:[
							{ "productsAddedToBag": product_ids  }, {"progress":progress}
						]
					},
					headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
				}).then(response => response.data)
		.then(data => {
				
			this.setState({ isExecuting:false });
		})
		
 		
 	 
 	this.setState({ orders:neworders  });
 		 
	} 
	 
	   
	LoadTable=async() => { 		
		let WO_Id = this.props.match.params.WO_Id; 
		let WOTaskId = this.props.match.params.WOTaskId; 
		
		let CUSTOM_PARAM = this.props.match.params.CUSTOM_PARAM; 
		 
		if (CUSTOM_PARAM!==undefined){
			if (CUSTOM_PARAM==="ALLTASKS"){
				WO_Id=0;
			}
		}
		
		this.setState({ isLoadedTable: false,WO_Id:WO_Id, loadingData:true });
		//let idTask = this.props.match.params.idTask;
		let url = API_CONFIG.base_url+'/externalApi/getOrderProductsByFather/'+WO_Id+"/"+WOTaskId;
		
		let nombre_cliente;
	  let direccion_1;
	  let zona;
  	await axios({
			 url: url, 
			 method: 'get',
			 headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
		})
			.then(response => response.data)
			.then(data => {
				 
				 var arr=[]
				 var i=0
			 
				 if (data.resp==="OK"  && data.rows.length >0){ 
				 	//console.log(data); 
				 		var arrayPicked = []
				 		
					 		if (data.productsAddedToBag!==null  ){
					 			arrayPicked = data.productsAddedToBag.split("|");
					 		}
					 		for (var key in data.rows){
					 			
								
								//let isPicked = arrayPicked.indexOf(data.rows[key].product_id)
					 			//let img = (data.rows[key].productImage).replace(".jpg","-60x60.jpg")

								 
								var arrPicked = arrayPicked.filter(function (e) {
								return e.substr(0, e.indexOf(","))  == (data.rows[key].product_id);
								});		
								
								
								var isPicked = -1
								var qtyPicked = 0
								if (arrPicked.length>0){
									let data= arrPicked[0].split(",")
									if ( data[1] === data[2]){
										isPicked= 1
									}
									qtyPicked = parseInt( data[1] )
								}

					 			let img = data.rows[key].image===null?"-": (data.rows[key].image).replace(".jpg","-60x60.jpg")
					 			arr.push( 
								  	{ 	id:key,
								  		product_id: data.rows[key].product_id,
								  		productName:data.rows[key].name,
								  		productModel:data.rows[key].model,
								  	  	orders :data.rows[key].orders,
								  		productQty: data.rows[key].quantity,
								  		productImage: data.rows[key].image, //data.store_url+"image/cache/"+img,
								  		productSKU : data.rows[key].sku,
								  	  	state: "loaded" ,
								  	  	isPicked:isPicked,
										qtyPicked: qtyPicked
								 		});
				 			} 
				 } 
				 this.setState({
					  	...this.state,
					  	orders: arr ,
					  	isLoadedTable:true,
					  	customerName:data.nombre_cliente,
					  	customerAdd:data.direccion_1,
					  	customerZone:data.zone,
					  	customer_id:data.customer_id,
					  	loadingData:false,
					  	existingPreviousOrder: false,/*revisar*/
					  	isDeleted: false, /*revisar*/ 
					  	order_id:data.order_id /*revisar*/
					  }); 
				  
				
			})
	}
 
	toggleModal = nr => () => {

	let modalNumber = 'modal' + nr
	this.setState({
		[modalNumber]: !this.state[modalNumber]
	});
	}
	
	render() {
		return (
		<>
			
			   
			   {this.state.loadingData?
			   
			   	 <Loader idLoading={this.state.loadingData}/>
			     :
	 				 <>  
	 				 <Loader idLoading={this.state.isExecuting}/>
	 				  
	 				  <h3 style={{
			   	 	zIndex:"1000",
			   	 	fontWeight:"600",
			   	color:"#000"
			   	}}> Listado de Productos de los pedidos agrupados</h3>
			   	 		
	 				  {this.state.existingPreviousOrder?
	 				  	 
	 				 <MDBCard  style={{width: "98%"}} className="mb-3">
	 				 
	 				
			        <MDBRow className="align-items-left justify-content-left"> 
								<MDBCol md="12" >
								   <MDBAlert      className="text-left align-top">
			            <h3 className="h3-responsive font-weight-bold mr-3"> 
				          <MDBIcon icon="exclamation-circle" className="mr-1"/>   El cliente {this.state.customerName} tiene un pedido aun no entregado
				            </h3> 
				             </MDBAlert> 
				             </MDBCol>     
				      </MDBRow>
				   
				        <hr/>
				        <MDBRow className="text-right">
			        		<MDBCol md="12" > 
			            	<MDBBtn 
											size="sm"
											color="primary"
											onClick={() => {
											this.setState({modal4:true })
											}} 
											>		Revisar Pedido
											</MDBBtn> 
											
											<MDBBtn 
											size="sm"
											disabled={this.state.isDeleted}
											color="danger"
											onClick={() => {
										//	this.deleteShipping("1616");
											this.setState({modal5:true, mensaje:"eliminar la tarifa?", accion:"eliminar tarifa" })
											}} 
											>		Eliminar Tarifa de envio
											</MDBBtn> 
				           </MDBCol> 
				        </MDBRow>
				   
			     </MDBCard>
	 				  
	 				  :""}

					<MDBRow className="text-left mt-3 mb-3" style={{
								  borderBottom: "1px solid #0062cc",
								  paddingBottom: 10,
								}}>
								<MDBCol  col= "7" sm="7" md="6" lg="4"  className="col-7 ml-2 mr-2 text-left left">
									<div style={{
										fontSize:"1rem",
										fontWeight:"600",
										color:"#0062cc"
									}}>Leer SKU</div>
									<MDBInput
										
										id={"read_sku" }
										key={"read_sku" }
										name={"read_sku"}
										className="input-sku"
										style={{border: "1px solid #0062cc" , borderRadius: 8  }}
											value={this.state.read_sku}
											onChange={this.changeHandlerSKU}
											size="sm"
											  />
 
								</MDBCol>
								{/**
								<MDBCol  col= "3" sm="3" md="3" lg="1"  className="col-3 ml-2 mr-2 text-left left no-border"> 
								<div style={{
										fontSize:"1rem",
										fontWeight:"600",
										color:"#0062cc"
									}}>Cantidad</div>
								<MDBInput
										
										id={"qty_sku" }
										key={"qty_sku" }
										name={"qty_sku"}
										className="qty-sku"
										style={{border: "1px solid #0062cc" , borderRadius: 8  }}
											value={this.state.qty_sku}
											onChange={this.changeHandler}
											size="sm"
											  />
								</MDBCol>
								 */}
							</MDBRow>
	 				 
	 				  
					 	{this.state.orders.map(event => (
					 	<p  key ={"event1"+event.id} style={{width: "98%"}}>
			                    <Event
			                    
			                    id={event.id}
			                    product_id={".000"+event.product_id}
			                    productName={event.productName}
			                    productQty={event.productQty}
			                    orders={event.orders}
			                    productModel={event.productModel}
			                    productImage={event.productImage}
			                    productSKU={event.productSKU}
			                    isPicked={event.isPicked}
			                    state={event.state}
			                    onDelete={null}
			                    onEdit={null}
			                    onPick={this.handlePick}
			                    />
			                    </p>
			                ))}
			                
			      
					 <MDBModal cascading centered isOpen={this.state.modal4} toggle={this.toggleModal(4)} size="lg">
						 
							<MDBModalBody >
								 
								 <span className="h2-responsive mb-2">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle del pedido Previo </span>
							
								 <p>Nombre: {this.state.customerName}</p>
								 <p>Direccion: {this.state.customerAdd}</p>
								 <p>Zona: {this.state.customerZone}</p>
								{this.state.ordersPre.map(event => (
					 	<p  key={"event2"+event.id} style={{width: "98%"}}>
			                    <Event
			                    
			                    id={event.id}
			                    product_id={event.product_id}
			                    productName={event.productName}
			                    productQty={event.productQty}
			                    
			                    productModel={event.productModel}
			                    productImage={event.productImage}
			                    productSKU={event.productSKU}
			                    isPicked={event.isPicked}
			                    state={event.state}
			                  	onDelete={null}
			                    onEdit={null}
			                  	onPick={null}
			                    />
			                    </p>
			                ))} 
			                
							</MDBModalBody> 
					</MDBModal>       
					
					  <MDBModal cascading centered isOpen={this.state.modal3} toggle={this.toggleModal(3)} size="md">
							 
							<MDBModalBody > 
							
							 <span className="h2-responsive mb-4">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar Producto</span>
							
							
							 <MDBRow className="text-right">
			        		<MDBCol md="9" > 
			            	   <MDBInput 
			            	    id="SKUcode"
								          key="SKUcode"
								          name="SKUcode"
								          value={this.state.SKUcode}
													onChange={this.changeHandler}
													 size="sm"
			            	   label="SKU"  />
			            	  
			            	  
				           </MDBCol> 
				           <MDBCol md="3" > 
				            <MDBBtn
								              color="secondary"
								              onClick={() => {
															this.searchProduct();
															}} 
								              className="mt-4"
								              size="sm"
								            >
								              Buscar
								            </MDBBtn>
								             </MDBCol> 
				        </MDBRow> 
				        
				        <MDBRow className="text-right">
			        		<MDBCol md="12" > 
			            	 <MDBInput
			            	  id="productName"
			            	  label="Producto"
			            	  readOnly={true}
											name="productName"
			            	  value={this.state.productName}
													 onChange={this.changeHandler} />
				           </MDBCol> 
				        </MDBRow> 
				        
				         <MDBRow className="text-right">
			        		<MDBCol md="6" > 
			            	 <MDBInput
			            	  id="productPrice"
			            	  label="Precio"
			            	  readOnly={true}
											name="productPrice"
			            	  value={this.state.productPrice}
													 onChange={this.changeHandler} />
				           </MDBCol> 
				         
			        		<MDBCol md="6" > 
			            	 <MDBInput
			            	  label="Cantidad"
			            	  id="productQty"
											name="productQty"
											readonly
											type="number"
											min="1"
											max={this.state.productMaxQty}
			            	  value={this.state.productQty}
													 onChange={this.changeHandler} />
				           </MDBCol> 
				       
				       </MDBRow> 
				        
				         <MDBRow className="text-right">
				         
			        		<MDBCol md="12" > 
			            	<MDBBtn 
											size="sm"
											color="success"
											disabled={this.state.disabledAdd}
											onClick={() => {
												this.setState({modal5:true, mensaje:"Agregar este producto?", accion: "agregar" });
											}} 
											>	<MDBIcon icon="cart-plus" />	Agregar  
											</MDBBtn>  
				           </MDBCol> 
				        </MDBRow> 
							
							 
							</MDBModalBody> 
					</MDBModal>  


		    <MDBModal cascading centered isOpen={this.state.modal5} toggle={this.toggleModal(5)} size="md">
						<MDBModalBody className="text-center"> 
							<span className="h4-responsive text-center" >Esta seguro de {this.state.mensaje}</span>
							<hr/>
							
							 <MDBRow className="text-center">
			        		<MDBCol md="12" > 
			            	  
			            	  <MDBBtnGroup size="sm">
								        <MDBBtn outline  
								        onClick={() => {
												this.setState({modal5:false })
												}} 
								        color="danger" className="text-capitalize" > <span className="h4-responsive">Cancelar</span></MDBBtn>					        
								        <MDBBtn 
								        onClick={() => {
												this.setState({modal5:false });
												if (this.state.accion==="agregar"){
													this.addOrder();
												}else if (this.state.accion==="eliminar tarifa"){
													this.deleteShipping(this.state.order_id);
												}else if (this.state.accion==="aumentar"){
													this.handleCommitEdit(this.state.id_producto, "Add");
												}else if (this.state.accion==="disminuir"){
													this.handleCommitEdit(this.state.id_producto, "Rem");
												} else{
													this.handleCommitDelete(this.state.id_producto);
												}
												
												
												
												}} 
										   	color="success" className="text-capitalize"> <span className="h4-responsive">{this.state.accion}</span> </MDBBtn>
								      </MDBBtnGroup>
				           </MDBCol> 
				        </MDBRow> 
							
							 
							</MDBModalBody> 
					</MDBModal>      
					 </>
					
					 
				 }
				 
				 <ToastContainer
					        hideProgressBar={true}
					        newestOnTop={false}
					      position={"bottom-right"}
					        autoClose={3000}
					      />	
			 
			</>
		);
	}
}

	class Event extends React.Component {
    render() {
	    return (
	    		this.props.state === "deleting"?
	    			<MDBCard
	    			style={{
								opacity: "0.4",
									   backgroundColor: "#999"
									   
									   
						  }}
	    			 className="align-items-center justify-content-center">
	    				 <p></p>
	    				<div className="media mt-4 align-middle">
			           
			             <div className="media-body mb-3 mb-lg-3">
			             	 
			              <MDBAnimation type="rotateOut" infinite >
			              <MDBIcon style={{
								zIndex:"1050"
									   
						  }} size="2x" far icon="trash-alt" /> 
			               </MDBAnimation>
			                
			             </div>
			         
			        </div> 
	    			 <p></p>
	    			</MDBCard>
	        	:<MDBCard className="">
			        <div className="media mt-1">
			            <span className="h4-responsive font-weight-bold mr-3 align-items-right justify-content-right">
			               
			           
			             <img src={this.props.productImage} height="60px" width="60px" alt="thumbnail" className="img-thumbnail" />
			         
			           
			            </span>
			            <div className="media-body mb-3 mb-lg-3">
			            {this.props.onPick===null?"": 
			            <MDBBadge
			                color= {this.props.isPicked=== -1 ?"grey":"success"  } 
			               
			                style={{"cursor":"pointer"}}
			                className="mr-1 float-right"
			                onClick={() => this.props.onPick(this.props.id)} 
			            >
			               <MDBIcon far={this.props.isPicked=== -1 ?false:true}  size="2x" icon={this.props.isPicked=== -1 ?"check-square":"check-square"} />
			            </MDBBadge>}
			            <span className="h6 mt-0 font-weight-bold">{this.props.productName} </span>{" "}
			            <hr className="hr-bold my-2" />
			            {this.props.product_id && (
			                <React.Fragment>
			                <p className="font-smaller mb-0">
			               
			                   <MDBRow className="align-items-left justify-content-left"> 
													<MDBCol md="12" >
														<MDBIcon icon="shopping-basket" /> Codigo: {this.props.productModel} 
													</MDBCol>     
												</MDBRow>
												<MDBRow className="align-items-left justify-content-left"> 
													<MDBCol md="12" >
														<MDBIcon icon="barcode" /> SKU: {this.props.productSKU} 
													</MDBCol>     
												</MDBRow>
			            
			                </p>
			                </React.Fragment>
			            )}
			            </div>
			        </div> 
			        {this.props.productQty && (
			            <p className="h4-responsive p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
			            Cantidad:  <b> {this.props.productQty}</b>
			            </p>
			        )} 
			        
			        {this.props.orders && (
			            <p className="h4-responsive p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
			            Ordenes:  <b> {this.props.orders}</b>
			            </p>
			        )} 
			        
			      </MDBCard>
	        
	    );
    }
	}

export default externalForm01;

import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker'; 
 
const Filters = props => {
	
 
	  return (  
		  	<div className="row">
					<div className="col-12">
			    	<div className="col-12">
				    	<span className="h3-responsive "> 
				    		<i className="fas fa-filter"/>  Criterios de Búsqueda
				    	</span>
				    	
				    	<span className="or-line"/> 
			    	</div>
		    		{ Object.keys(props.filterConfig).map((field, index) => (index>4?"":
		    			
		    			<div className="col-12">
								<div key={"div_form_"+field} className="form-group card-label">
									<label key={"label_form_"+field}>{  props.filterConfig[field].label}  </label>
									{props.filterConfig[field].type=="SELECT"?
										
									<select className="browser-default custom-select"
														 name={field} 
														 defaultValue={props.filterConfig[field].value} 
														 onChange={props.searchHandler} 
														  >
			              				{props.filterConfig[field].values.map(item => (
			              		 			<option key={"field_"+field+"_i"+item.value} value={item.value}>{item.text}</option>
			              		 		))
			                  		} 
			      				</select>
		      				:""}
		      				
		      				{props.filterConfig[field].type=="MULTIPLE"?
										
										<select className="browser-default custom-select"
														 name={field} 
														 multiple={true}
														 defaultValue={props.filterConfig[field].value} 
														 onChange={props.searchHandler} 
														  >
			              				{props.filterConfig[field].values.map(item => (
			              		 			<option key={"field_"+field+"_i"+item.value} value={item.value}>{item.text}</option>
			              		 		))
			                  		} 
			      				</select>
		      				:""}
		      				
		      				
									{props.filterConfig[field].type=="DATE"?
											<DateTimePicker 
											key={"field_"+field}
											date={props.filterConfig[field].value?props.filterConfig[field].value: new Date()} 
											name={field}
											label={props.filterConfig[field].label} 
											options={field.options} 
											isUpdatable= {field.isUpdatable===undefined?true: field.isUpdatable }
											updateDate={props.searchHandler} 
										/>
									:""}
									
									{props.filterConfig[field].type=="TEXT" || props.filterConfig[field].type=="NUMBER"?
										<input
											key={"field_"+field} type="text" 
											className="form-control" 
										 	value={props.filterConfig[field].value}
											name={field} 
											onChange={props.searchHandler} />
									:""}		 
									
	          		</div>
	          	</div> 	 
		    		))
						}
						{Object.keys(props.filterConfig).length>=4? 	<button className="col-12 text-right no-button" name="showMore" onClick={props.searchHandler} >  <span style={{ color:"#909090", fontSize:14, textDecoration:"underline" }}> {props.showMore?"Ocultar ":"Mostrar "} mas filtros <i className= {"fas fa-angle-"+ (props.showMore?"up":"down") }   /></span> </button>:""}
						
						{ Object.keys(props.filterConfig).map((field, index) => ( props.showMore && index>4?
		    			
		    			<div className="col-12">
								<div key={"div_form_"+field} className="form-group card-label">
									<label key={"label_form_"+field}>{  props.filterConfig[field].label}  </label>
									{props.filterConfig[field].type=="SELECT"?
										
										<select  key={"field_"+field}
														 name={field} 
														 defaultValue={props.filterConfig[field].value} 
														 onChange={props.searchHandler} 
														 className="browser-default custom-select" >
			              				{props.filterConfig[field].values.map(item => (
			              		 			<option key={"field_"+field+"_i"+item} value={item.value}>{item.text}</option>
			              		 		))
			                  		} 
			      				</select>
		      				:""}
		      				{props.filterConfig[field].type=="MULTIPLE"?
										
										<select className="browser-default custom-select"
														 name={field} 
														 multiple={true}
													 	 defaultValue={props.filterConfig[field].value} 
														 onChange={props.searchHandler} 
														  >
			              				{props.filterConfig[field].values.map(item => (
			              		 			<option key={"field_"+field+"_i"+item.value} value={item.value}>{item.text}</option>
			              		 		))
			                  		} 
			      				</select>
		      				:""}
		      				{props.filterConfig[field].type=="DATE"?
											<DateTimePicker 
											key={"field_"+field}
											date={props.filterConfig[field].value?props.filterConfig[field].value: new Date()} 
											name={field}
											label={props.filterConfig[field].label} 
											options={field.options} 
											isUpdatable= {field.isUpdatable===undefined?true: field.isUpdatable }
											updateDate={props.searchHandler} 
										/>
									:""} 
									{props.filterConfig[field].type=="TEXT" || props.filterConfig[field].type=="NUMBER"?
									 <input
											key={"field_"+field} type="text" 
											className="form-control" 
										 	value={props.filterConfig[field].value}
											name={field} 
											onChange={props.searchHandler} />
									:""}
	          		</div>
	          	</div> 	
	          	:""
		    		))
		    		
						}
						
						<div className="col-12 mt-4"> 
          	 <button className="btn btn-success btn-block" name="search"
			         onClick={props.searchHandler}
			          type="submit"> {props.buttonLabel?props.buttonLabel: "Generar Reporte"}</button> 
          	</div>
				</div>
			</div>	 
	  );
	 
};
export default Filters;
				 
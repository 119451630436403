import React from "react";
import {MDBTable, MDBTableBody, MDBTableHead,MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_OP_CONFIG} from '../../Config/config-env.js';
import {API_CONFIG} from '../../Config/config-env.js';
import {OP_SECRET_KEY} from '../../Config/config-env.js';

import axios from 'axios';

class externalForm01 extends React.Component {

	state = {
		modal5:false,
		id_producto:-1,
		mensaje:"",
		accion:"",
		isDeleted:false,
		showProductsList:true,
		productQty:1,
		productName:"",
		SKUcode:"", //"3212567000066",
		productPrice:0,
		productImage:"",
		existingPreviousOrder:false,
		isExecuting:false,
		searchResults:[],
		processing: "Procesando",
		ordersPre:[
			{id:0,
				product_id: 111,
				productName:"Manta",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded" },
			{id:1,
				product_id: 112,
				productName:"Juego de Tazas",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded"
			}
		],
		orders:[],
		WO_Id:"",
		store_url: "",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true,
		costo_envio:0,
		descuento:0,
		sub_total:0,

	};

	componentDidMount=()=>{
		this.LoadTable();
	}

	changeHandler = event => {
		//	console.log(event.target.name)
		//	console.log(event.target.value)
		this.setState({ ...this.state, [event.target.name]: [event.target.value] });
	};

	deleteShipping=async (eventId)   => {
		let url =  API_CONFIG.base_url+'/externalApi/testRestApi/';
		this.setState({ isExecuting:true });
		await axios.post(url,	{ params: 1}
		)
			.then(response => response.data)
			.then(data => {
				console.log(data);
				if (data.resp==="OK"){
					this.setState({isDeleted:true})
					toast.success("OK:"+"Se elimino el cobro de envio del pedido:"+eventId, {
						closeButton: true
					});
				}
				this.setState({ isExecuting:false });
			})
	}

	addOrder=async ()   => {
		let newOrders = this.state.orders;

		let newId= newOrders.length+1

		let dataNewOrder =
			{
				"order_id": this.state.order_id,
				"customer_id": this.state.customer_id,
				"product_id": this.state.product_id,
				"user": "WAYLSO",
				"quantity": Array.isArray(this.state.productQty)?this.state.productQty[0]:this.state.productQty
			}
		console.log(dataNewOrder)

		this.setState({ isExecuting:true });

		await axios({
			method: 'post',
			crossdomain: true ,
			// headers: { 'Content-Type': 'application/json' },
			url: this.state.store_url+'/api/addOrderProduct/key/'+OP_SECRET_KEY,
			data: JSON.stringify(dataNewOrder)
		})

			.then(response => response.data)
			.then(data => {
				console.log(data);
				console.log(newId);
				if (data.success==="Order has been modified successfully"){
					console.log(this.props.match.params.WO_Id);

					newOrders.push(
						{ id:newId, 
							product_id: this.state.product_id,
							productName:this.state.productName,
							//productPrice:this.state.productPrice,
							//productQty:this.state.productQty,
							productPrice:parseFloat(this.state.productPrice.replace("Bs.","").replace(",",".")).toFixed(2),
							productQty: parseInt(this.state.productQty),
							productSKU:this.state.productSKU,
							productModel:this.state.productModel,
							productImage:this.state.productImage,
							state: "loaded"
						}
					);

					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice  = (newOrders[key].productPrice);
						let productQty = (newOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					});
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							params:[
								{ "pedido_modificado": "SI"  }, {"monto_total":monto_total},{"subtotal":parseFloat(sub_total,2).toFixed(2)}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})



					toast.success("OK:"+data.success, {
						closeButton: true
					});

				}else{
					toast.error("ERROR:"+data.error.warning, {
						closeButton: true
					});
				}

				this.setState({ isExecuting:false, orders:newOrders, modal3:false,  });
			})

	}

	handleDelete = (producto) => {
		//console.log(producto)
		this.setState({
			accion: "eliminar",
			modal5:true,
			mensaje:"Eliminar este producto?",
			id_producto: producto
		});
	}

	handleEdit = (producto, type) => {
		//console.log(producto)
		this.setState({
			accion:  type ,//  "editar"+type,
			modal5:true,
			mensaje: type+" la cantidad de este producto?",
			id_producto: producto
		});
	}


	handleCommitEdit = async (eventId, type)   => {
		console.log("edit"+eventId+"type:"+type);

		let neworders=[]
		let product_id;
		let productQty=0;
		let oldOrders = this.state.orders
		for (var key in  oldOrders){

			let obj = oldOrders[key];

			if (oldOrders[key].id===eventId){
				//obj["state"]= "deleting"
				console.log(oldOrders[key])

				product_id = oldOrders[key].product_id
				productQty =  parseInt(oldOrders[key].productQty)

				obj["productQty"]=  type==="Add"? productQty+1:productQty-1

			}
			neworders.push(obj)
		}

		var dataDelOrder= {

			"order_id": this.state.order_id,
			"customer_id": this.state.customer_id,
			"product_id": product_id,
			"user": "WAYSLO",
			"quantity": type==="Add"? productQty+1:productQty-1
		}

		this.setState({ isExecuting:true });


		await axios({
			method: 'post',
			// headers: {  'Access-Control-Allow-Origin':'*' },
			crossdomain: true ,

			url: this.state.store_url+'api/editOrderProduct/key/'+OP_SECRET_KEY,
			data: JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {

				if (data.success==="Order has been modified successfully" ){

					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice   =  (oldOrders[key].productPrice);
						let productQty =  (oldOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					});
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							params:[
								{ "pedido_modificado": "SI"  } , {"monto_total":monto_total},{"subtotal":parseFloat(sub_total,2).toFixed(2)}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})

					this.setState({ isExecuting:false });
					//this.setState({ orders:neworders });
			//		console.log(this.state.orders);
					toast.success("OK:"+data.success, {
						closeButton: true
					});

				} else{

					for (var key in this.state.orders){
						let obj = this.state.orders[key];

						if (this.state.orders[key].id===eventId){
							obj["state"]= "error"
							console.log ("set err")
						}
						neworders.push(obj)
					}
					this.setState({ orders:neworders, isExecuting:false });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					});


				}

			})


	};

	handleCommitDelete =async (eventId)   => {
		console.log("del"+eventId);
		let neworders=[]
		let product_id;
		let productQty;
		for (var key in this.state.orders){
			let obj = this.state.orders[key];
			//	console.log(obj);
			if (this.state.orders[key].id===eventId){
				obj["state"]= "deleting"
				product_id = this.state.orders[key].product_id
				productQty = this.state.orders[key].productQty
			}
			neworders.push(obj)
		}

		var dataDelOrder= {

			"order_id": this.state.order_id,
			"customer_id": this.state.customer_id,
			"product_id": product_id,
			"user": "WAYSLO",
			"quantity": productQty

		}
		console.log(dataDelOrder)
		this.setState({ isExecuting:true });
		await axios({
			method: 'post',
			crossdomain: true ,
			// headers: { 'Content-Type': 'application/json' },
			url: this.state.store_url+'/api/deleteOrderProduct/key/'+OP_SECRET_KEY,
			data: JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {

				if (data.success==="Order has been modified successfully" ){

					const orders = this.state.orders.filter(e => e.id !== eventId);
					this.setState({ orders });

					this.setState({ isExecuting:false });

					let oldOrders = this.state.orders
					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice   =  (oldOrders[key].productPrice);
						let productQty =  (oldOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					});
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							params:[
								{ "pedido_modificado": "SI"  }, {"monto_total":monto_total},{"subtotal":parseFloat(sub_total,2).toFixed(2)}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})



					toast.success("OK:"+data.success, {
						closeButton: true
					});



				} else{

					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						console.log(obj);
						if (this.state.orders[key].id===eventId){

							obj["state"]= "error"
							console.log ("set err")
						}
						neworders.push(obj)
					}
					this.setState({ orders:neworders });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					});


				}

			})

	};

	LoadTable=async() => {

		let WO_Id = this.props.match.params.WO_Id;
		let WOTaskId = this.props.match.params.WOTaskId;

		this.setState({ isLoadedTable: false,WO_Id:WO_Id, loadingData:true });
		//let idTask = this.props.match.params.idTask;
		let url = API_CONFIG.base_url+'/externalApi/getOrderProducts/'+WO_Id+"/"+WOTaskId;

		let nombre_cliente;
		let direccion_1;
		let zona;
		await axios({
			url: url,
			method: 'get',
			headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
		})
			.then(response => response.data)
			.then(data => {
				var arr=[]
				var i=0

				if (data.resp==="OK"  && data.rows.length >0){
					console.log(data.resp);
					
					var arrayPicked = []

					if (data.productsAddedToBag!==null  ){
						arrayPicked = data.productsAddedToBag.split(",");
					}
					for (var key in data.rows){
						let isPicked = arrayPicked.indexOf(data.rows[key].product_id)
						let img = data.rows[key].image===null?"-": (data.rows[key].image).replace(".jpg","-60x60.jpg")
 
						arr.push(
							{ id:key,
								product_id: data.rows[key].product_id,
								productName:data.rows[key].name,
								productModel:data.rows[key].model,
								productPrice:parseFloat(data.rows[key].price.replace("Bs.","").replace(",",".")).toFixed(2),
								productQty: parseInt(data.rows[key].quantity),
								productImage: data.store_url+"image/cache/"+img,
								productSKU : data.rows[key].sku,
								state: "loaded" ,
								isPicked:isPicked 
							});
					}
				}
				var costo_envio = data.costo_envio? parseFloat(data.costo_envio ,2).toFixed(2):0;
				var descuento = data.descuento? parseFloat(data.descuento ,2).toFixed(2):0;

				this.setState({
					...this.state,
					orders: arr ,
					store_url: data.store_url,
					isLoadedTable:true,
					customerName:data.nombre_cliente,
					customerAdd:data.direccion_1,
					customerZone:data.zone,
					customer_id:data.customer_id,
					loadingData:false,
					existingPreviousOrder: false,/*revisar*/
					isDeleted: false, /*revisar*/
					order_id:data.order_id /*revisar*/,
					costo_envio:costo_envio,
				 	descuento:descuento
				});

				let monto_total=0;
				let sub_total=0;
				for (var key in this.state.orders){
					let obj = this.state.orders[key];
					let productPrice  = (this.state.orders[key].productPrice);
					let productQty = (this.state.orders[key].productQty);
					let total = productQty*productPrice;
					monto_total=monto_total+total;
					sub_total=sub_total+total;
				}
				
				monto_total = (parseFloat( monto_total) + parseFloat(costo_envio) -  parseFloat(descuento) ).toFixed(2);
				this.setState({
					...this.state,
					monto_total: monto_total,
					sub_total: parseFloat(sub_total,2).toFixed(2)
				});
			})
	}

	setProduct =  async(event) => {

		var id = (event.target.id).substr(5, 10);

		var task = this.state.searchResults[id];

		console.log(task)

		this.setState({
			...this.state,
			product_id:this.state.searchResults[id].product_id,
			productName: this.state.searchResults[id].name,
			productImageBig: this.state.searchResults[id].productImage,
			productPrice:this.state.searchResults[id].price,
			productImage: this.state.searchResults[id].thumb,
			productModel:this.state.searchResults[id].productModel,
			productSKU:this.state.searchResults[id].productSKU,
			productQty:1,
			productMaxQty:this.state.searchResults[id].quantity,
			isExecuting:false,
			showProductsList:false,
			disabledAdd:false
		})

	};


	searchProduct=async() => {
		this.setState({ showProductsList:true, isExecuting:true, searchResults:[], disabledAdd:true});
		let url =  this.state.store_url+'/index.php?route=journal3/search&search='+this.state.SKUcode;


		await axios.get (
			this.state.store_url+'/index.php?route=journal3/search&search='+this.state.SKUcode,


			//{crossdomain: true }

		)
			/*
          await axios({
                 url: url,
                 method: 'get',
                 headers: {
                      'Access-Control-Allow-Origin': '*'
                    },
                     crossdomain: true

            })*/
			.then(response => response.data)
			.then(data => {
				if (data.status==="success" && data.response[0].no_results===undefined ){

					var results=[]

					for (var i=0; i<data.response.length-1; i++){
						results.push( {
								id: i,
								key:"key"+data.response[i].product_id,
								product_id:data.response[i].product_id,
								name:data.response[i].name,
								quantity:data.response[i].quantity,
								thumb:data.response[i].thumb,
								productSKU:data.response[i].sku===undefined?"-":data.response[i].sku,
								productModel:data.response[i].model===undefined?"-":data.response[i].model,
								productImage: data.response[i].thumb.replace("60x60","400x400"),
								price:data.response[i].price,
								special:data.response[i].special,
								selected:
									<>

									  											 <span  key={"table_"+i} id={"table_"+i}
																						onClick={this.setProduct} className="tableLink">
								<MDBIcon icon="check-circle" key={"icon_"+i} id={"icon_"+i}  size="2x" style={{"cursor":"pointer"}} className="green-text" />

									  											 </span></>
							}
						)

					}

					this.setState({
						...this.state,
						searchResults: results,
						isExecuting:false,
					});
					console.log(this.state.searchResults)


				}else{
					this.setState({
						...this.state,
						product_id:0,
						productName: data.response[0].name,
						productPrice:0,
						productQty:10,
						productMaxQty:0,
						isExecuting:false,
						disabledAdd:true
					});

				}
			})
	}
	toggleModal = nr => () => {

		let modalNumber = 'modal' + nr
		this.setState({
			[modalNumber]: !this.state[modalNumber]
		});
	}

	render() {
		return (
			<>




				{this.state.loadingData?

					<Loader idLoading={this.state.loadingData}/>
					:
					<>
						<Loader idLoading={this.state.isExecuting}/>






						<h3 style={{
							zIndex:"1000",
							fontWeight:"600",
							color:"#000"
						}}>  {"Pedido #"+this.state.order_id}</h3>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">SubTotal</MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2" className="text-right"><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.sub_total+" Bs."}</h6> </MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">Descuento</MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2" className="text-right"><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.descuento+" Bs."}</h6> </MDBCol>
						</MDBRow>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">Costo de Envio</MDBCol>
							<MDBCol size="12" sm="6"  md="3" lg="2" xl="2" className="text-right" ><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.costo_envio+" Bs."}</h6> </MDBCol>
						</MDBRow>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">	<h5 style={{
								zIndex:"1000",
								fontWeight:"500",
								color:"#000"
							}}>  Total:</h5></MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2"  className="text-right">	<h5    style={{
								zIndex:"1000",
								fontWeight:"500",
								color:"#000"
							}}>  {this.state.monto_total+" Bs."}</h5></MDBCol>
						</MDBRow>



						{this.state.existingPreviousOrder?

							<MDBCard  style={{width: "98%"}} className="mb-3">


								<MDBRow className="align-items-left justify-content-left">
									<MDBCol md="12" >
										<MDBAlert      className="text-left align-top">
											<h3 className="h3-responsive font-weight-bold mr-3">
												<MDBIcon icon="exclamation-circle" className="mr-1"/>   El cliente {this.state.customerName} tiene un pedido aun no entregado
											</h3>
										</MDBAlert>
									</MDBCol>
								</MDBRow>

								<hr/>
								<MDBRow className="text-right">
									<MDBCol md="12" >
										<MDBBtn
											size="sm"
											color="primary"
											onClick={() => {
												this.setState({modal4:true })
											}}
										>		Revisar Pedido
										</MDBBtn>

										<MDBBtn
											size="sm"
											disabled={this.state.isDeleted}
											color="danger"
											onClick={() => {
												//	this.deleteShipping("1616");
												this.setState({modal5:true, mensaje:"eliminar la tarifa?", accion:"eliminar tarifa" })
											}}
										>		Eliminar Tarifa de envio
										</MDBBtn>
									</MDBCol>
								</MDBRow>

							</MDBCard>

							:""}

						<MDBRow className="text-right mt-1 mb-3 mr-1">
							<MDBCol md="12" >


								<MDBBtn
									size="sm"
									color="success"
									onClick={() => {
										this.setState({modal3:true, disabledAdd:true, /*SKUcode:"" , */ productName:"", productPrice:"", productQty:"1" })
									}}
								>	<MDBIcon icon="cart-plus" />	Agregar Producto
								</MDBBtn>
							</MDBCol>
						</MDBRow>

						{this.state.orders.map(event => (
							<p  key ={"event1"+event.id} style={{width: "98%"}}>
								<Event

									id={event.id}
									product_id={event.product_id}
									productName={event.productName}
									productQty={event.productQty}
									productPrice={event.productPrice}
									productModel={event.productModel}
									productImage={event.productImage}
									productSKU={event.productSKU}
									state={event.state}
									onDelete={this.handleDelete}
									onEdit= {this.handleEdit}
								/>
							</p>
						))}


						<MDBModal cascading centered isOpen={this.state.modal4} toggle={this.toggleModal(4)} size="lg">

							<MDBModalBody >

								<span className="h2-responsive mb-2">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle del pedido Previo </span>

								<p>Nombre: {this.state.customerName}</p>
								<p>Direccion: {this.state.customerAdd}</p>
								<p>Zona: {this.state.customerZone}</p>
								{this.state.ordersPre.map(event => (
									<p  key={"event2"+event.id} style={{width: "98%"}}>
										<Event

											id={event.id}
											product_id={event.product_id}
											productName={event.productName}
											productQty={event.productQty}
											productPrice={event.productPrice}
											productModel={event.productModel}
											productImage={event.productImage}
											productSKU={event.productSKU}
											state={event.state}
											onDelete={null}

										/>
									</p>
								))}

							</MDBModalBody>
						</MDBModal>

						<MDBModal cascading centered isOpen={this.state.modal3} toggle={this.toggleModal(3)} size="lg">

							<MDBModalBody >

								<span className="h2-responsive mb-4">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar Producto</span>

								<>
									<MDBRow className="text-right">
										<MDBCol md="9" >
											<MDBInput
												id="SKUcode"
												key="SKUcode"
												name="SKUcode"
												value={this.state.SKUcode}
												onChange={this.changeHandler}
												size="sm"
												label="Escriba el nombre o SKU"  />


										</MDBCol>
										<MDBCol md="3" >
											<MDBBtn
												color="secondary"
												onClick={() => {
													this.searchProduct();
												}}
												className="mt-4"
												size="sm"
											>
												Buscar
											</MDBBtn>
										</MDBCol>
									</MDBRow>


									{this.state.showProductsList?
										<MDBTable
											responsive
											className="customTable" bordered striped
										>
											<MDBTableHead >
												<tr>

													<th>Imagen</th>
													<th>Producto</th>
													<th>Precio</th>
													<th>Precio Promocional</th>
													<th>Cantidad Disponible</th>
													<th>Seleccionar</th>
												</tr>
											</MDBTableHead>
											<MDBTableBody>

												{this.state.searchResults.map(item => (
													<tr key={"tr"+item.key}>
														<td key={"td"+item.key}>

															<img src={item.thumb} alt="thumbnail" className="img-thumbnail" />

														</td>
														<td>
															{item.name}
														</td>
														<td>
															{item.price}
														</td>
														<td>
															{!item.special?item.price:item.special}
														</td>
														<td>
															{item.quantity}
														</td>
														<td style={{"textAlign":"center"}}>
															{item.selected}
														</td>

													</tr>
												))
												}

											</MDBTableBody>
										</MDBTable>
										:

										<>

											<MDBRow className="text-left">
												<MDBCol md="12" >
													<span className="h4-responsive"> <b>Detalle del producto seleccionado</b> </span>
												</MDBCol>
											</MDBRow>

											<MDBRow className="text-right">
												<MDBCol md="7" >
													<img src={this.state.productImageBig} alt="thumbnail" className="img-thumbnail" />
												</MDBCol>


												<MDBCol md="5" >
													<MDBInput
														id="productName"
														label="Producto"
														readOnly={true}
														name="productName"
														value={this.state.productName} onChange={this.changeHandler} />

													<MDBInput
														id="productPrice"
														label="Precio"
														readOnly={true}
														name="productPrice"
														value={this.state.productPrice} onChange={this.changeHandler} />

													<MDBInput
														label="Cantidad"
														id="productQty"
														name="productQty"
														readonly
														type="number"
														min="1"
														max={this.state.productMaxQty}
														value={this.state.productQty} onChange={this.changeHandler} />



													<MDBBtn
														size="sm"
														outline
														color="danger"


														onClick={() => {
															this.setState({showProductsList:true,  /*SKUcode:"" , */ productName:"", productPrice:"", productQty:"1" })
														}}
													>	<MDBIcon icon="arrow-alt-circle-left" />	Volver
													</MDBBtn>

													<MDBBtn
														size="sm"
														color="success"
														disabled={this.state.disabledAdd}
														onClick={() => {
															this.setState({modal5:true, mensaje:"Agregar este producto?", accion: "agregar" });
														}}
													>	<MDBIcon icon="cart-plus" />	Agregar
													</MDBBtn>
												</MDBCol>

											</MDBRow>

										</>
									}

								</>
							</MDBModalBody>
						</MDBModal>


						<MDBModal cascading centered isOpen={this.state.modal5} toggle={this.toggleModal(5)} size="md">
							<MDBModalBody className="text-center">
								<span className="h4-responsive text-center" >Esta seguro de {this.state.mensaje}</span>
								<hr/>

								<MDBRow className="text-center">
									<MDBCol md="12" >

										<MDBBtnGroup size="sm">
											<MDBBtn outline
													onClick={() => {
														this.setState({modal5:false })
													}}
													color="danger" className="text-capitalize" > <span className="h4-responsive">Cancelar</span></MDBBtn>
											<MDBBtn
												onClick={() => {
													this.setState({modal5:false });
													if (this.state.accion==="agregar"){
														this.addOrder();
													}else if (this.state.accion==="eliminar tarifa"){
														this.deleteShipping(this.state.order_id);
													}else if (this.state.accion==="aumentar"){
														this.handleCommitEdit(this.state.id_producto, "Add");
													}else if (this.state.accion==="disminuir"){
														this.handleCommitEdit(this.state.id_producto, "Rem");
													} else{
														this.handleCommitDelete(this.state.id_producto);
													}



												}}
												color="success" className="text-capitalize"> <span className="h4-responsive">{this.state.accion}</span> </MDBBtn>
										</MDBBtnGroup>
									</MDBCol>
								</MDBRow>


							</MDBModalBody>
						</MDBModal>
					</>


				}

				<ToastContainer
					hideProgressBar={true}
					newestOnTop={false}
					position={"bottom-right"}
					autoClose={3000}
				/>

			</>
		);
	}
}

class Event extends React.Component {
	render() {
		return (
			this.props.state === "deleting"?
				<MDBCard
					style={{
						opacity: "0.4",
						backgroundColor: "#999"


					}}
					className="align-items-center justify-content-center">
					<p></p>
					<div className="media mt-4 align-middle">

						<div className="media-body mb-3 mb-lg-3">

							<MDBAnimation type="rotateOut" infinite >
								<MDBIcon style={{
									zIndex:"1050"

								}} size="2x" far icon="trash-alt" />
							</MDBAnimation>

						</div>

					</div>
					<p></p>
				</MDBCard>
				:<MDBCard className="align-items-left justify-content-left">
					<div className="media mt-1">
			            <span className="h4-responsive font-weight-bold mr-3">

			             <MDBBadge
							 color="danger"
							 style={{"cursor":"pointer"}}
							 className="ml-2 mt-1 mr-2 ml-4"

							 onClick={() =>
								 this.props.productQty==="1"?
									 this.props.onDelete(this.props.id):
									 this.props.onEdit (this.props.id, "disminuir")

							 }


						 >  -
			            </MDBBadge>
							{this.props.productQty}
							<MDBBadge
								color="success"
								style={{"cursor":"pointer"}}
								className="ml-2 mt-2"
								onClick={() => this.props.onEdit (this.props.id, "aumentar")}
							>  +
			            </MDBBadge>
			           <br/>

			             <img src={this.props.productImage} alt="thumbnail" className="img-thumbnail" />
			            </span>
						<div className="media-body mb-3 mb-lg-3">
							{this.props.onDelete===null?"":
								<MDBBadge
									color="danger"
									style={{"cursor":"pointer"}}
									className="mr-2 mt-1 float-right"
									onClick={() => this.props.onDelete(this.props.id)}
								>
									x
								</MDBBadge>}
							<span className="h6 mt-0 font-weight-bold">{this.props.productName} </span>{" "}
							<hr className="hr-bold my-2" />
							{this.props.product_id && (
								<React.Fragment>
									<p className="font-smaller mb-0">

										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" >
												<MDBIcon icon="shopping-basket" /> Codigo: {this.props.productModel}
											</MDBCol>
										</MDBRow>
										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" >
												<MDBIcon icon="barcode" /> SKU: {this.props.productSKU}
											</MDBCol>
										</MDBRow>

									</p>
								</React.Fragment>
							)}
						</div>
					</div>
					{this.props.productQty && (
						<p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
							{"Cantidad: "+this.props.productQty+", Precio unitario: "+this.props.productPrice +"Bs. Total "+ parseFloat ( this.props.productPrice * (this.props.productQty),2 ).toFixed(2) +"Bs." }
						</p>
					)}
				</MDBCard>

		);
	}
}

export default externalForm01;

import React from "react";
import {MDBSelect, MDBDatePicker, MDBAlert, MDBTableHead,MDBTable, ToastContainer,  MDBTableBody,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
 
import {API_CONFIG} from '../../Config/config-env.js';
import Pdf from "react-to-pdf";
import axios from 'axios';
const ref = React.createRef();

class reportArqueo2 extends React.Component {

    state = {

        optionsT:[
         {value: 47, checked:true,
       		text:"Envio a Domicilio"},
       	 {value: 85,
       		text:"Recojo en Tienda"}
        ],
        options:[],
        sectionId: "",
        userType:47,
        SKU:"",
        rows1:[],
        noData:false,
        rows2:[],
        skuExists:"NO ENCONTRADO",
        skuState:"SIN INFORMACION",
        showResults:false,
        data:[],
        to_date: new Date(),
        from_date: new Date(),
        fecha:""
    };


    componentDidMount=()=>{
        this.LoadSections(47);
        
        let f = new Date()
        let fecha=   ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() ))  +"/"+ ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + "/"+  (f.getFullYear()+"") 
        
        var hora =f.getHours()
				var minuto = (f.getMinutes() )<10? "0"+(f.getMinutes()): (f.getMinutes() )
				var segundo = (f.getSeconds() )<10? "0"+(f.getSeconds()): (f.getSeconds() )
			 
				if (hora >12){
					hora = (hora-12) +":"+minuto+" PM"
				}else if (hora ===0){
					hora = "12"+":"+minuto+":"+segundo+ "AM"
				}else if (hora ===12){
					hora = "12"+":"+minuto+":"+segundo+ "PM"
				}else{
					hora = hora +":"+minuto+":"+segundo+"AM"
				}
        
        this.setState({ fecha : fecha+" "+hora });
    }


    generateReport=async() => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true, showResults:false  });

        let url = API_CONFIG.base_url+'/api/rep_reporte_arqueo2/';
        
        var f = new Date(this.state.from_date);
        var fecha =  ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) +"/" +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + "/"+(f.getFullYear()+"")
        var from_date = new Date( f.getFullYear(),f.getMonth(),f.getDate(), 0,0,0)
        
        f = new Date(this.state.to_date);
        fecha =  ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) +"/" +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + "/"+(f.getFullYear()+"")
        var to_date = new Date( f.getFullYear(),f.getMonth(),f.getDate(), 0,0,0)
        
        let datos = {
            sectionId:  this.state.sectionId,
            from_date :from_date ,
            to_date :  to_date ,
            userType: this.state.userType
        }
        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'post',
            data: datos
        })
            .then(response => response.data)
            .then(data => {

                if (data.resp==="OK" &&  data.rows2[0].PEDIDOS_ENTREGADOS>0){


                    this.setState({ ...this.state, showResults:true,noData:false, rows1: data.rows1, rows2: data.rows2, rows3: data.rows3 , username:data.username });
                    this.setState({ isExecuting: false });

                }else {
                    this.setState({ isExecuting: false, noData:true, showResults:false , data:[]  });
                }
            })
    }

    LoadSections=async(userType) => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true });

        let url = API_CONFIG.base_url+'/api/getUsersByTaskAndSection/'+0+"/"+userType;

        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'get'
        })
            .then(response => response.data)
            .then(data => {
                if (data.rows.length>0){
                    var objSections = [];
                    objSections.push({
                        text: "TODOS",
                        value: "0", checked:true
                    })

                    for (var key in data.rows) {
                         
                            let	dataObject={
                                value: data.rows[key].USERNAME,
                                text:data.rows[key].USERNAME
                            }
                            objSections.push(dataObject);
                        
                    }

                    this.setState({ options : objSections });

                    this.setState({ isExecuting: false });

                }else {
                    this.setState({ isExecuting: false });
                }
            })
    }



		selectHandlerT = event => {
			
			if(event[0]){
				this.setState({ ...this.state, userType: event[0], sectionId:"" });
				this.LoadSections(event[0]);
			}
    };
    
    
    selectHandler = event => {

        this.setState({ ...this.state, sectionId: event[0] });

    };

    changeHandler = event => {
        //console.log(event.target.value);
        this.setState({ ...this.state, SKU: event.target.value });
    };
    getPickerValueTo= value=> { this.setState({ to_date: value }); }
    getPickerValueFrom = value=> { this.setState({ from_date: value }); }

    render() {
        return (

            <MDBContainer fluid className="justify-content-left text-left " style={{"marginLeft": "0"}}>
                <Loader idLoading={this.state.isExecuting}/>
                <br></br>
                <h2 key={"h2"}	className={" h2-responsive text-left"}> <strong>Arqueo de Ruta</strong></h2>

                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left">

                        <MDBSelect
                            className="is-invalid"
                            key= "store"
                            id="store"
                            color="primary"
                            options={this.state.optionsT}
                            getValue= {this.selectHandlerT}
                            selected="Elija el tipo de Envio"
                            label="Tipo de Envio" />
                    </MDBCol>
                </MDBRow>
                
                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left">

                        <MDBSelect
                            className="is-invalid"
                            key= "store"
                            id="store"
                            color="primary"
                            options={this.state.options}
                            getValue= {this.selectHandler}
                            selected="Elija un Usuario"
                            label="Usuarios" />
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left" >
                        <MDBDatePicker  key="from"  name="from" id="from" format='DD/MM/YYYY' value={this.state.from_date} cancelLabel="Cancelar" getValue={this.getPickerValueFrom} />
                    </MDBCol>

                </MDBRow>

                <MDBRow>

                    <MDBCol sm="12" md="9" lg="4" className="text-left">
                        <MDBDatePicker  key="to"  name="to" id="to" format='DD/MM/YYYY' value={this.state.to_date}  cancelLabel="Cancelar" getValue={this.getPickerValueTo} />
                    </MDBCol>

                </MDBRow>

                <MDBRow>
                    <MDBCol  sm="12" md="9" lg="4" className="text-right justify-content-right">
                        <MDBBtn href="#reportResult" key="find" onClick={() => {this.generateReport();}} size="sm"
                                color="success"
                                className="text-capitalize"
                        >Generar Reporte</MDBBtn>
                    </MDBCol>
                </MDBRow>

   {this.state.noData?
                	<> <br/> 
                		<MDBAlert color="warning" dismiss>
										No se encontraron datos para el periodo seleccionado
										</MDBAlert></>:""
										
                }
                {this.state.showResults?
                    <>
                    <br/><hr/>
                        <MDBRow>
                            <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-right justify-content-right">
                                <div  style={{
                                    width:"650px"
                                }} className="justify-content-right text-right">
                                    
                                    <Pdf targetRef={ref} filename="ReporteArqueo.pdf">
															        {({ toPdf }) => 
															        	
															        	   <MDBBtn key="find"   size="sm"
                                                    color="success" onClick={toPdf}
                                            >Exportar a Pdf</MDBBtn>
                                            
                                            
															        	}
															      </Pdf>
      
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-left justify-content-left">

															<div ref={ref} className="pt-5 pl-5">
                                <MDBTable
                                    id="table1"
                                    small
                                    responsive
                                    style={{
                                        width:"680px"
                                    }}
                                    className="tableBlue"   bordered    >
                                    <MDBTableHead >
                                    		<tr>
                                              <td className="text-right" colSpan="4"> Reporte Generado por: {this.state.username} </td>
                                        </tr>
                                        <tr>
                                              <th className="text-center" colSpan="4"><h5><strong> Arqueo de Ruta</strong>  </h5></th>
                                        </tr>
                                        <tr>
                                            <th  colSpan="4"></th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        <tr>
                                            <td colSpan="4">
                                                <h6><strong>A. Datos Generales</strong>  </h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> Fecha</td>
                                            <td> {this.state.fecha}</td>
                                            <td>Zona designada</td>
                                            <td> </td>
                                        </tr>

                                        <tr>
                                            <td> Responsable de Ruta</td>
                                            <td></td>
                                            <td>Caja Chica</td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td> Total pedidos en Ruta</td>
                                            <td> {this.state.rows1[0].CANTIDAD_PEDIDOS}</td>
                                            <td>PEDIDOS A COBRAR {this.state.rows1[0].CANTIDAD_PEDIDOS_A_COBRAR}</td>
                                            <td>Total pedidos entregados {this.state.rows2[0].PEDIDOS_ENTREGADOS}</td>
                                        </tr>
                                        <tr>
                                            <td  colSpan="4">  </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4"> <h6><strong>B. Cuadratura</strong>  </h6> </td>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>COLUMNA TOTAL Bs.</td>
                                        </tr>
                                        <tr>
                                            <td>Efectivo a cobrar:</td>
                                            <td>Bs. {this.state.rows2[0].MONTO_EFECTIVO.toFixed(2)} </td>
                                            <td>TRX: {this.state.rows2[0].TOTAL_EFECTIVO} </td>
                                            <td>  {this.state.rows2[0].MONTO_EFECTIVO.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Atc/lote</td>
                                            <td> </td>
                                            <td>Vouchers {this.state.rows2[0].TOTAL_ATC}</td>
                                            <td> {this.state.rows2[0].MONTO_ATC.toFixed(2)} </td>
                                        </tr>
                                        <tr>
                                            <td>Linkser/lote</td>
                                            <td> </td>
                                            <td>Vouchers {this.state.rows2[0].TOTAL_LINKSER}</td>
                                            <td> {this.state.rows2[0].MONTO_LINKSER.toFixed(2)} </td>
                                        </tr>
                                        <tr>
                                            <td>Deposito a cuenta:</td>
                                            <td>Bs. {this.state.rows2[0].MONTO_DEPOSITO.toFixed(2)} </td>
                                            <td>TRX: {this.state.rows2[0].TOTAL_DEPOSITO} </td>
                                            <td> {this.state.rows2[0].MONTO_DEPOSITO.toFixed(2) }</td>
                                        </tr>
                                        <tr>
                                            <td>Pago QR:</td>
                                            <td>Bs. {this.state.rows2[0].MONTO_QR.toFixed(2)} </td>
                                            <td>TRX: {this.state.rows2[0].TOTAL_QR} </td>
                                            <td>{this.state.rows2[0].MONTO_QR.toFixed(2)} </td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td>DIFERENCIA </td>
                                            <td>TOTAL A COBRAR </td>
                                            <td>TOTAL RENDIDO </td>
                                        </tr>
                                        <tr>
                                            <td> </td>
                                            <td>Bs. </td>
                                            <td>Bs. {(this.state.rows2[0].MONTO_EFECTIVO+this.state.rows2[0].MONTO_ATC+this.state.rows2[0].MONTO_LINKSER+
                                                     this.state.rows2[0].MONTO_DEPOSITO+this.state.rows2[0].MONTO_QR).toFixed(2) } </td>
                                            <td>Bs. </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4"></td>
                                        </tr>
                                        <tr>
                                            <th className="text-center" colSpan="4"><h6><strong> Pedidos no entregados</strong>  </h6></th>
                                        </tr>
                                        <tr>
                                            <td>Efectivo (no entregados)</td>
                                            <td>Bs. {this.state.rows3[0].PEDIDOS_NO_ENTREGADOS} </td>
                                            <td colSpan="2">TRX: {this.state.rows3[0].MONTO_NO_ENTREGADO!=null?this.state.rows3[0].MONTO_NO_ENTREGADO.toFixed(2):0}</td>

                                        </tr>
                                        <tr style={{ "height": "6rem" }}>
                                            <td  colSpan="4"> <h6><strong>Observaciones</strong>  </h6> </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="4"></td>
                                        </tr>

                                        <tr style={{ "height": "5rem" }} >
                                            <th className="text-center"  style={{ "height": "5rem", "verticalAlign": "bottom" }} colSpan="2">Recibí conforme (Firma y Nombre)</th>
                                            <th className="text-center"  style={{ "height": "5rem", "verticalAlign": "bottom" }} colSpan="2">Entregue conforme (Firma y Nombre)</th>
                                        </tr>



                                    </MDBTableBody>
                                </MDBTable>
															</div>
	
                            </MDBCol>
                        </MDBRow>

                    </>
                    :""}


                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={false}
                    position={"bottom-right"}
                    autoClose={3000}
                />

            </MDBContainer>
        )
    }
}
export default reportArqueo2;

import React from "react";
import {MDBTable, MDBTableBody, MDBTableHead,MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBSelect, MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_CONFIG} from '../../Config/config-env.js';
import {OP_SECRET_KEY_KETAL} from '../../Config/config-env.js';
import axios from 'axios';

class externalForm04 extends React.Component {
	
	state = {
		org : "KETAL_USER",
		isMultiple: false,
		product_id:-1,
		showQuatity:false,
		mensaje:"",
		accion:"",
		isDeleted:false,
		productQty:1,
		productName:"",
		showProductsList:true,
		SKUcode:"",
		productPrice:0,
		searchResults:[],
		existingPreviousOrder:false,
		isExecuting:false,
		currentOrderId:0,
		processing: "Procesando",
		ordersPre:[
			{id:0,
				product_id: 111,
				productName:"Manta",
				productPrice:"50Bs.",
				productQty:"1",
			  state: "loaded" },
			{id:1,
				product_id: 112,
				productName:"Juego de Tazas",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded" 
			}
		],
		orders:[],
		WO_Id:"",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true
		
	};
	
	componentDidMount=()=>{
	 		this.LoadTable();
	}
	  
  changeHandler = event => { 
  	//console.log(event.target.name)
  	//console.log(event.target.value)
  	
  	if (event.target.name==="productQtyNew" || event.target.name=== "productQty"){
  		var valor= event.target.value;
  		valor = valor.replace(/[^0-9,.]/g, '').replace(/,/g, '.'); 
  		var varValor = valor.split(".") 
  		if (varValor.length<=2){
  			if (varValor.length===2){
  				if (varValor[1].length>4){
  					toast.error("Maximo 4 decimales", {
						closeButton: true
					}); 
  				}else{
  					this.setState({ ...this.state, [event.target.name]: valor });
  				}
  			}else{
  				this.setState({ ...this.state, [event.target.name]: valor });	
  			}
  			
  		}
  		
  		
  	}else{
  		this.setState({ ...this.state, [event.target.name]: event.target.value });
  	}
		
	};
	 
	selectHandlerDel = event => { 
		console.log( event[0] )
			this.setState({ ...this.state, currentOrderIdToDel: event[0]  }); 
	 };
	selectHandler = event => { 
			this.setState({ ...this.state, currentOrderId: event[0]  }); 
	 };
	 
 	searchProduct=async() => {
		this.setState({ showProductsList:true, isExecuting:true, searchResults:[], disabledAdd:true});
		let url =  this.state.store_url+'/index.php?route=journal3/search&search='+this.state.SKUcode;


		await axios.get (
			this.state.store_url+'/index.php?route=journal3/search&search='+this.state.SKUcode,
 
		)
		 
			.then(response => response.data)
			.then(data => {
				if (data.status==="success" && data.response[0].no_results===undefined ){

					var results=[]

					for (var i=0; i<data.response.length-1; i++){
						
						
						var minimun = 1;
						if (data.response[i].minimum && data.response[i].minimum!=null  ){
							
							minimun = parseFloat(data.response[i].minimum)
						}
								
						results.push( {
								id: i,
								key:"key"+data.response[i].product_id,
								product_id:data.response[i].product_id,
								name:data.response[i].name,
								quantity:data.response[i].quantity,
								thumb:data.response[i].thumb,
								productSKU:data.response[i].sku===undefined?"-":data.response[i].sku,
								productModel:data.response[i].model===undefined?"-":data.response[i].model,
								productImage: data.response[i].thumb.replace("60x60","400x400"),
								price:data.response[i].price,
								special:data.response[i].special,
								ean: data.response[i].ean,
								productMin: minimun,
								productStep:parseFloat(data.response[i].decimal_step),
								
								selected:
									<>

									  											 <span  key={"table_"+i} id={"table_"+i}
																						onClick={this.setProduct} className="tableLink">
								<MDBIcon icon="check-circle" key={"icon_"+i} id={"icon_"+i}  size="2x" style={{"cursor":"pointer"}} className="green-text" />

									  											 </span></>
							}
						)

					}

					this.setState({
						...this.state,
						searchResults: results,
						isExecuting:false,
					});
					 
				}else{
					this.setState({
						...this.state,
						product_id:0,
						productName: data.response[0].name,
						productPrice:0,
						productQty:10,
						productMaxQty:0,
						isExecuting:false,
						disabledAdd:true
					});

				}
			})
	}
  	 
  setProduct =  async(event) => {

		var id = (event.target.id).substr(5, 10);

		var task = this.state.searchResults[id];
 
		this.setState({
			...this.state,
			product_id:this.state.searchResults[id].product_id,
			productName: this.state.searchResults[id].name,
			productImageBig: this.state.searchResults[id].productImage,
			productPrice:this.state.searchResults[id].price,
			productImage: this.state.searchResults[id].thumb,
			productModel:this.state.searchResults[id].productModel,
			productSKU:this.state.searchResults[id].productSKU,
			productQty:1,
			productMaxQty:parseFloat(this.state.searchResults[id].quantity),
			productEan:this.state.searchResults[id].ean,
						
			productMin: parseFloat(this.state.searchResults[id].productMin),
		  productStep:parseFloat(this.state.searchResults[id].productStep),

			isExecuting:false,
			showProductsList:false,
			disabledAdd:false
		})

	};
	handlePick =async (producto) => { 
	//	console.log(producto)
		let neworders =[]
		let product_ids="";
		let totalProd = this.state.orders.length
		let totalPicked =0
		let flowIds=this.state.flowIds;
		for (var key in  this.state.orders){
 		
 			let obj = this.state.orders[key];
 			 
 			if (this.state.orders[key].product_id===producto){  
				obj["isPicked"]= this.state.orders[key].isPicked===-1?1:-1;
 			} 
 			neworders.push(obj)
 		} 
 		
 		for (var key in neworders){ 
 			if (neworders[key].isPicked>0){  
				product_ids=product_ids+"|"+neworders[key].product_id+","+neworders[key].productQty+","+neworders[key].productQty
				totalPicked++
				
 			} 
 		}
 		
 		if (product_ids.length>1){
 			product_ids= product_ids.substr(1,product_ids.length);
 		}
 		
 		let progress = Math.ceil(totalPicked/totalProd*100) 	 
 		this.setState({ orders:neworders  });
 		
 	 
 		
 		this.setState({ isExecuting:true });
 		let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
 		 
 			for (var key in flowIds){ 				
				await	axios({
						url: url,
						method: 'post',						
						data: {
							org: this.state.org,
							flowId:flowIds[key].flowId,
							params:[
								{ "picking_products": product_ids  }, {"progress":progress}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					}).then(response => response.data)
				.then(data => {
					console.log("upds:"+flowIds[key].flowId)
				})
 			}  
			this.setState({ isExecuting:false });
 		 
	} 
	
	
	
	addOrder=async ()   => {
		var newOrders = this.state.orders;

		let newId= newOrders.length
		let currentCustomerId=0;
		
		for (var key in  this.state.flowIds){
 		
 			let obj = this.state.flowIds[key];
 			 
 			if (this.state.flowIds[key].orderId==this.state.currentOrderId){  
				currentCustomerId = this.state.flowIds[key].customerId;
 			}  
 		} 
 		var valido=true;
 		var productMaxQty = this.state.productMaxQty
		var productMin = this.state.productMin 
		var productQty = parseFloat(this.state.productQty)
		
 		if ( productQty>productMaxQty ||  productQty< productMin ){
			toast.error('Valor fuera de rango min:'+productMin+" max:"+productMaxQty, {
							closeButton: true
						});
		 	valido=false;
		}
		
		if (currentCustomerId===0){
				toast.error("Elija una orden", {
						closeButton: true
					});
				valido=false;
		}
			
		if (valido){
			
		 
			console.log(currentCustomerId)	
			console.log(this.state.currentOrderId)	
		
			let dataNewOrder =
			{
				"order_id": this.state.currentOrderId,
				"customer_id": currentCustomerId,
				"product_id": this.state.product_id,
				"user": "WAYLSO",
				"quantity": Array.isArray(this.state.productQty)?this.state.productQty[0]:this.state.productQty 
			}
		//console.log(dataNewOrder)

		this.setState({ isExecuting:true });

		await axios({
			method: 'post',
			crossdomain: true ,
			// headers: { 'Content-Type': 'application/json' },
			 
			url: this.state.store_url+'/api/addOrderProduct/key/'+OP_SECRET_KEY_KETAL,
			data: JSON.stringify(dataNewOrder)
		})

			.then(response => response.data)
			.then(async(data) => {
			
				console.log(data);
				
				//console.log(newId);
				if (data.success==="Order has been modified successfully"){
					//console.log(this.props.match.params.WO_Id);
					
					var curOrders = "Orden "+this.state.currentOrderId+" - cantidad: "+this.state.productQty
					var curOrderIds = this.state.currentOrderId
					var productQty=parseFloat(this.state.productQty )
					var isNewProduct=true;
					var newObj=
				 	{ id:newId,
							product_id: this.state.product_id,
							productName:this.state.productName,
							//productPrice:this.state.productPrice,
							//productQty:this.state.productQty,
							productPrice:parseFloat(this.state.productPrice.replace("Bs.","").replace(",",".")).toFixed(2),
							productQty: parseFloat( productQty),
							productSKU:this.state.productSKU,
							productModel:this.state.productModel,
							productImage:this.state.productImage,
							isPicked:-1,
							productMin : this.state.productMin,
		    			productStep :this.state.productStep, 
		    			productEan: this.state.productEan,
		    			orderIds: curOrderIds, 
							orders: curOrders, 
							state: "loaded"
						}
					
					for (var key in newOrders){ 		 
			 			if (newOrders[key].product_id==this.state.product_id){  
			 				isNewProduct=false;
			 				curOrders = newOrders[key].orders+", "+curOrders
							curOrderIds= newOrders[key].orderIds+", "+curOrderIds
							productQty = productQty+parseFloat(newOrders[key].productQty)							
							newOrders[key].orders = curOrders
							newOrders[key].orderIds = curOrderIds
							newOrders[key].newOrders = productQty
							newOrders[key].isPicked = -1							
			 			} 			 			
			 		} 					
					if (isNewProduct){
						newOrders.push(newObj);
					}
					
					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice  = (newOrders[key].productPrice);
						let productQty = (newOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = parseFloat( monto_total + parseFloat(this.state.costo_envio) - parseFloat(this.state.descuento) ).toFixed(2);
					
					
					var totalProd=0;
					var totalPicked=0;
					var product_ids=""
					for (var key in newOrders){ 		 
		 				totalProd++;
			 			if (newOrders[key].isPicked>0){  			 				 
							product_ids=product_ids+"|"+newOrders[key].product_id+","+newOrders[key].productQty+","+newOrders[key].productQty
							totalPicked++
			 			} 
			 		} 
			 		if (product_ids.length>1){
			 			product_ids= product_ids.substr(1,product_ids.length);
			 		}
				 	let progress = Math.ceil(totalPicked/totalProd*100)
 		 
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					});
					let flowIds=this.state.flowIds;
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
				 
		 			for (var key in flowIds){ 				
						await	axios({
								url: url,
								method: 'post',						
								data: {
									org: this.state.org,
									flowId:flowIds[key].flowId,
									params:[
										{ "picking_products": product_ids  }, {"progress":progress}
									]
								},
								headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
							}).then(response => response.data)
						.then(data => {
							console.log("upds:"+flowIds[key].flowId)
						})
		 			} 
					toast.success("OK:"+data.success, {
						closeButton: true
					});

				}else{
					toast.error("ERROR:"+data.error.warning, {
						closeButton: true
					});
				}
				 
				this.setState({ isExecuting:false, orders:newOrders, modal3:false,  });
			})
		}
	}
	 
	handleDelete = (producto) => {
		
		 
		var product_id=0;
		var orderIdsArr=0;
		for (var key in this.state.orders){
			let obj = this.state.orders[key];
			//	console.log(obj);
			if (this.state.orders[key].product_id===producto){
			//	obj["state"]= "deleting"
				product_id = this.state.orders[key].product_id
			  orderIdsArr = this.state.orders[key].orderIds.split(",")
				  
			}
			//newOrders.push(obj)
		}
		  
		if (orderIdsArr.length>1){ 
				this.setState({
					...this.state,
					accion: "eliminar",
					modal5:true,
					mensaje:"Elija la Orden",
					showQuatity:false,
					isMultiple:true,
					product_id: product_id,
				//	currentOrderIdToDel:0
				});
		}else{
			 var orderIds=[]
			 for (var key in this.state.flowIds){  
						 	 
				 	orderIds.push({
				 		checked: this.state.flowIds[key].orderId== orderIdsArr[0]?true:false,
					 	text:  this.state.flowIds[key].orderId,
		        value: this.state.flowIds[key].orderId
			 		})
			 }  
			 this.setState({ 
							product_id: product_id, 
							orderIdsToDel:orderIds,
							isMultiple:false,
							currentOrderIdToDel: orderIdsArr[0]
							});
			 this.handleCommitDelete(product_id, false,orderIdsArr[0]);
			 
		}
		 
	 
	}
	
	
	handleCommitDelete =async (eventId,  isMultiple,orderIds)   => {
	 
		 
		var currentOrderIdToDel=0
		
		var isSingle=false;
		if(orderIds!=undefined){
			isSingle=true;
			currentOrderIdToDel= orderIds
		}else{
			currentOrderIdToDel= this.state.currentOrderIdToDel;
			isSingle=false  ;
		}
		  
		let newOrders=[]
		let product_id;
		let productQty;
		for (var key in this.state.orders){
			let obj = this.state.orders[key];
			//	console.log(obj);
			if (this.state.orders[key].product_id===eventId){
				obj["state"]= "deleting"
				product_id = this.state.orders[key].product_id
				productQty = this.state.orders[key].productQty
			}
			newOrders.push(obj)
		}

		let currentCustomerId=0;
		
		for (var key in  this.state.flowIds){
 		
 			let obj = this.state.flowIds[key];
 			 
 			if (this.state.flowIds[key].orderId== currentOrderIdToDel){  
				currentCustomerId = this.state.flowIds[key].customerId;
 			}  
 		} 
 		
		if (currentCustomerId===0){
			
				toast.error("Elija una orden", {
						closeButton: true
					});
		}else{
 
			var dataDelOrder= {

				"order_id":  currentOrderIdToDel,
				"customer_id": currentCustomerId,
				"product_id": product_id,
				"user": "WAYSLO",
				"quantity": productQty

			} 
			this.setState({ isExecuting:true });
			await axios({
				method: 'post',
				crossdomain: true ,
				// headers: { 'Content-Type': 'application/json' },
				url: this.state.store_url+'/api/deleteOrderProduct/key/'+OP_SECRET_KEY_KETAL,
				data: JSON.stringify(dataDelOrder)
			})

				.then(response => response.data)
				.then(async(data) => {

				 //if (data.error){
			   	if (data.success==="Order has been modified successfully" ){
							if(isSingle){
								console.log("simp")
 							  
								const orders = this.state.orders.filter(e => e.product_id !== eventId);
								this.setState({ orders });
								this.setState({ isExecuting:false });
								let oldOrders = this.state.orders
								let monto_total=0;
								let sub_total=0;
								for (var key in this.state.orders){
									console.log(this.state.orders[key])
									let obj = this.state.orders[key];
									let productPrice   =  (oldOrders[key].productPrice);
									let productQty =  (oldOrders[key].productQty);
									let total = productQty*productPrice;
									monto_total=monto_total+total;
									sub_total=sub_total+total;
								}
								monto_total = parseFloat( monto_total + parseFloat(this.state.costo_envio) - parseFloat(this.state.descuento) ).toFixed(2);
								this.setState({
									...this.state,
									monto_total: monto_total,
									sub_total: parseFloat(sub_total,2).toFixed(2)
								});
								
								var totalProd=0;
								var totalPicked=0;
								var product_ids=""
								for (var key in orders){ 		 
					 				totalProd++;
						 			if (orders[key].isPicked>0){   
										//product_ids=product_ids+","+orders[key].product_id
										product_ids=product_ids+"|"+orders[key].product_id+","+orders[key].productQty+","+orders[key].productQty
										totalPicked++
						 			} 
						 		} 
						 		if (product_ids.length>1){
						 			product_ids= product_ids.substr(1,product_ids.length);
						 		}
							 	let progress = Math.ceil(totalPicked/totalProd*100)
								
								let flowIds=this.state.flowIds;
								let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
							 
					 			for (var key in flowIds){
									await	axios({
											url: url,
											method: 'post',						
											data: {
												org: this.state.org,
												flowId:flowIds[key].flowId,
												params:[
													{ "picking_products": product_ids  }, {"progress":progress}
												]
											},
											headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
										}).then(response => response.data)
									.then(data => {
										console.log("upds:"+flowIds[key].flowId)
									})
					 			} 
 							}else{
 							  console.log("mul")
 								const orders = this.state.orders; 
 								for (var key in this.state.orders){
									let obj = this.state.orders[key];
									//console.log(obj);
									if (this.state.orders[key].product_id===eventId){
										 
										obj["state"]= "loaded"
										 
										var flowIds = this.state.flowIds 
										var orderIds =""
										for (var key2 in flowIds){ 
												if (flowIds[key2].orderId != currentOrderIdToDel){
													orderIds=orderIds+","+flowIds[key2].orderId
												} 
										}
										orderIds = orderIds.substr(1,orderIds.length ) 
										var ordersD = this.state.orders[key].orders.split(",")				
										var newOrdersD=""						
										var newQty=0;
										for (var key2 in ordersD){
											 
												if(ordersD[key2].indexOf(currentOrderIdToDel)<0){
													newOrdersD = newOrdersD+","+ordersD[key2];
												 	var curQty = ordersD[key2].split(":")[1];
													console.log("new qty"+curQty)
													newQty =  parseFloat(newQty) + parseFloat(curQty)
												}
										}
										newOrdersD = newOrdersD.substr(1,newOrdersD.length ) 
									  obj["orderIds"] = orderIds
									  obj["orders"]   = newOrdersD 
									  obj["productQty"]   =  newQty
									} 
								} 
 								this.setState({ orders });  
 								this.setState({ isExecuting:false });
 							}
 
 
						toast.success("OK:"+data.success, {
							closeButton: true
						});
						
						 
 
					} else{ 
						for (var key in this.state.orders){
							let obj = this.state.orders[key];
							//console.log(obj);
							if (this.state.orders[key].product_id===eventId){

								obj["state"]= "error"
								console.log ("set err")
							}
							newOrders.push(obj)
						} 
						this.setState({ isExecuting:false });
						//this.setState({ orders:newOrders });
						toast.error("ERROR:"+data.error.warning, {
							closeButton: true
						});
 
					} 
				})
		}
	};


  handleEditQty = (product_idNew, productQtyNew,productNameNew, productStepNew) => {
		 
		var product_id=0;
		var orderIdsArr=0;
		var minimum=1;
		for (var key in this.state.orders){
			let obj = this.state.orders[key];
			//	console.log(obj);
			if (this.state.orders[key].product_id===product_idNew){
			//	obj["state"]= "deleting"
				product_id = this.state.orders[key].product_id
				minimum = this.state.orders[key].productMin
			  orderIdsArr = this.state.orders[key].orderIds.split(",")
				  
			}
			//newOrders.push(obj)
		}
		  
		if (orderIdsArr.length>1){ 
			 
				this.setState({
					...this.state,
				modal5: true, 
				accion: "editar", 
				mensaje:"Elija la orden y coloque la nueva cantidad", 
				 minimumNew:minimum,
					product_id: product_id,
					product_idNew:product_idNew, 
					productQtyNew: productQtyNew, 
					productNameNew:productNameNew, 
					productStepNew:productStepNew, 
					showQuatity:true,
					isMultiple:true
				//	currentOrderIdToDel:0
				});
		}else{
			 var orderIds=[]
			 for (var key in this.state.flowIds){  
						 	 
				 	orderIds.push({
				 		checked: this.state.flowIds[key].orderId== orderIdsArr[0]?true:false,
					 	text:  this.state.flowIds[key].orderId,
		        value: this.state.flowIds[key].orderId
			 		})
			 }  
			  
			 this.setState({ 
							product_id: product_id, 
							modal5: true, 
							accion: "editar", 
							mensaje:"Coloque la nueva cantidad", 
							orderIdsToDel:orderIds,
							currentOrderIdToDel: orderIdsArr[0],
							minimumNew:minimum,
							product_idNew:product_idNew, 
							productQtyNew: productQtyNew, 
							productNameNew:productNameNew, 
							productStepNew:productStepNew, 
							showQuatity:true,
							isMultiple:false
							});
		 
			 
		}
		 
	 
	}
	
	handleCommitQty = async (eventId,isMultiple  )   => { 
	 
	  
		var valido =true;
		var productStep = this.state.productStepNew;
		var productQtyNew = parseFloat(this.state.productQtyNew);
		var minimumNew = parseFloat(this.state.minimumNew);
		
		var isSingle=true;
		var  esStepDecimal = typeof productStep === 'number' && isFinite(productStep) && Math.floor(productStep) !== productStep;
		var  esQtyDecimal = typeof productQtyNew === 'number' && isFinite(productQtyNew) && Math.floor(productQtyNew) !== productQtyNew
	  var currentOrderIdToDel =0;
		 
		currentOrderIdToDel= this.state.currentOrderIdToDel;				 
			 
		let newOrders=[]
		let product_id;
		let productQty;
		let oldOrders = this.state.orders
		for (var key in  oldOrders){
				let obj = oldOrders[key]; 
				newOrders.push(obj)
		}

		let currentCustomerId=0;
		
		for (var key in  this.state.flowIds){ 
 			let obj = this.state.flowIds[key]; 
 			if (this.state.flowIds[key].orderId== currentOrderIdToDel){  
				currentCustomerId = this.state.flowIds[key].customerId;
 			}  
 		} 
 		
		if (currentCustomerId===0){
			
				toast.error("Elija una orden", {
						closeButton: true
					});
			  valido=false;
		} 
		 
		if (productQtyNew< minimumNew ){
			toast.error('No puede poner un valor menor al minimo', {
							closeButton: true
						});
		 	valido=false;
		}
		if( isNaN(this.state.productQtyNew) ) {			
			toast.error('Numero invalido, (usar punto "." para decimales)', {
							closeButton: true
						});
		 	valido=false;
	  }
	  
	  if( esStepDecimal===false && esQtyDecimal===true ) {			
			toast.error('Numero invalido, no se permiten decimales', {
							closeButton: true
						});
		 	valido=false;
	  }
	    
		if (valido){
			let neworders=[]
			let product_id; 
			let oldOrders = this.state.orders
			
			
			for (var key in  oldOrders){

				let obj = oldOrders[key];

				if (oldOrders[key].product_id===eventId){					
					product_id = oldOrders[key].product_id					
					//obj["productQty"] =  productQtyNew

				}
				neworders.push(obj)
			}
			var dataDelOrder= {
				"order_id": currentOrderIdToDel,
				"customer_id": currentCustomerId,
				"product_id": product_id,
				"user": "WAYSLO",
				"quantity": productQtyNew
			} 
			this.setState({ isExecuting:true });
		 
			await axios({
				method: 'post',
				crossdomain: true ,
				url: this.state.store_url+'api/editOrderProduct/key/'+OP_SECRET_KEY_KETAL,
				data: JSON.stringify(dataDelOrder)
			})

				.then(response => response.data)
				.then(async(data) => {

					if (data.success==="Order has been modified successfully" ){
						let monto_total=0;
						let sub_total=0;
						console.log(isMultiple)
						if(!isMultiple){
							
							let oldOrders = this.state.orders
							newOrders=[]
							for (var key in  oldOrders){
								let obj = oldOrders[key];
								if (oldOrders[key].product_id===eventId){
									product_id = oldOrders[key].product_id					
									obj["productQty"] =  productQtyNew
									obj["orders"] =   "Orden "+currentOrderIdToDel+" - cantidad: "+productQtyNew
								}
								newOrders.push(obj)
									
							  let productPrice   =  parseFloat(oldOrders[key].productPrice);
								let productQty =  parseFloat (productQtyNew);
								let total = productQtyNew*productPrice;
								monto_total=monto_total+total;
								sub_total=sub_total+total;
							}
		
							 
							monto_total = parseFloat( monto_total + parseFloat(this.state.costo_envio) - parseFloat(this.state.descuento) ).toFixed(2);
							this.setState({
								...this.state,
								monto_total: monto_total,
								sub_total: parseFloat(sub_total,2).toFixed(2)
							});
							 
							 
								let flowIds=this.state.flowIds;
								let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
							 
					 			for (var key in flowIds){
									await	axios({
											url: url,
											method: 'post',						
											data: {
												org: this.state.org,
												flowId:flowIds[key].flowId,
												params:[
													  {"monto_total":monto_total}
												]
											},
											headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
										}).then(response => response.data)
									.then(data => {
										console.log("upds:"+flowIds[key].flowId)
									})
					 			} 
 							}else{
 							   
 								const orders = this.state.orders; 
 								for (var key in this.state.orders){
									let obj = this.state.orders[key];
									//console.log(obj);
									if (this.state.orders[key].product_id===eventId){
										 
										obj["state"]= "loaded"
										 
										var flowIds = this.state.flowIds 
										
										var ordersD = this.state.orders[key].orders.split(",")				
										var newOrdersD=""						
										var newQty=0;
										for (var key2 in ordersD){
											 
												if(ordersD[key2].indexOf(currentOrderIdToDel)<0){
													newOrdersD = newOrdersD+","+ordersD[key2];
												 	var curQty = ordersD[key2].split(":")[1];
													 
													newQty =  parseFloat(newQty) + parseFloat(curQty)
												}else{													
													newOrdersD = newOrdersD+", Orden "+currentOrderIdToDel+" - cantidad: "+productQtyNew;												  
												 
													newQty =  parseFloat(newQty) + parseFloat(productQtyNew)
												}
										}
										 
										newOrdersD = newOrdersD.substr(1,newOrdersD.length ) 
									 
									  obj["orders"]   = newOrdersD 
									  obj["productQty"]   =  newQty
									} 
								} 
 								this.setState({ orders });  
 								 
 							}
 						this.setState({ isExecuting:false });
					} else{
						for (var key in this.state.orders){
							let obj = this.state.orders[key];

							if (this.state.orders[key].product_id===eventId){
								obj["state"]= "error"
								console.log ("set err")
							}
							neworders.push(obj)
						}
						this.setState({   isExecuting:false  });
						toast.error("ERROR:"+data.descResp, {
							closeButton: true
						});
					}

				})
			}
	};
	
	handleEdit = (producto, type) => {
		//console.log(producto)
		this.handleCommitEdit(producto, type);
		/*
		this.setState({
			accion:  type ,//  "editar"+type,
			modal5:true,
			mensaje: type+" la cantidad de este producto?",
			id_producto: producto
		});
		*/
	}
	
	handleCommitEdit = async (eventId, type)   => {
		 
		var neworders=[]
		let product_id;
		let order_id;
		let productQty=0;
		let oldOrders = this.state.orders
		let productStep=0
		let currentCustomerId=0;
	 
		 
	 	
		for (var key in  oldOrders){

			let obj = oldOrders[key];
		 neworders.push(obj)
			 
			if (oldOrders[key].product_id===eventId){
				//obj["state"]= "deleting"
			  
				product_id = oldOrders[key].product_id 
				productStep =  parseFloat(oldOrders[key].productStep)
				
				order_id = oldOrders[key].orderIds 
				product_id = oldOrders[key].product_id 
				
				
				
				var  esDecimal = typeof productStep === 'number' && isFinite(productStep) && Math.floor(productStep) !== productStep;
    		 
    		
    		if (esDecimal){
    			productQty =   type==="Add"? (parseFloat(oldOrders[key].productQty) + parseFloat(productStep)).toFixed(1)  : (parseFloat(oldOrders[key].productQty) - parseFloat(productStep)).toFixed(1) 
    		}else{
    			productQty =   type==="Add"? parseInt(oldOrders[key].productQty+productStep): parseInt(oldOrders[key].productQty-productStep)
    		}
    
				
				obj["productQty"]=  productQty

			}
			
		}
		 
		for (var key in  this.state.flowIds){
 		
 			let obj = this.state.flowIds[key];
 			 
 			if (this.state.flowIds[key].orderId== order_id){  
				currentCustomerId = this.state.flowIds[key].customerId;
 			}  
 		} 
 		if (currentCustomerId===0){
 			toast.error("Elija una orden", {
						closeButton: true
					});
 		}else{
			var dataDelOrder= {
				"order_id": order_id,
				"customer_id": currentCustomerId,
				"product_id": product_id,
				"user": "WAYSLO",
				"quantity": productQty
			} 
			this.setState({ isExecuting:true });

			await axios({
				method: 'post',
				crossdomain: true ,
				url: this.state.store_url+'api/editOrderProduct/key/'+OP_SECRET_KEY_KETAL,
				data: JSON.stringify(dataDelOrder)
			})

				.then(response => response.data)
				.then(data => {

					if (data.success==="Order has been modified successfully" ){

						let monto_total=0;
						let sub_total=0;
						for (var key in this.state.orders){
							let obj = this.state.orders[key];
							let productPrice   =  parseFloat(oldOrders[key].productPrice);
							let productQty =  parseFloat (oldOrders[key].productQty);
							let total = productQty*productPrice;
							monto_total=monto_total+total;
							sub_total=sub_total+total;
						}
						monto_total = parseFloat( monto_total + parseFloat(this.state.costo_envio) - parseFloat(this.state.descuento) ).toFixed(2);
						this.setState({
							...this.state,
							monto_total: monto_total,
							sub_total: parseFloat(sub_total,2).toFixed(2)
						});
						 
						this.setState({ isExecuting:false });
						//this.setState({ orders:neworders });
						//console.log(this.state.orders);
						toast.success("OK:"+data.success, {
							closeButton: true
						});

					} else{

						for (var key in this.state.orders){
							let obj = this.state.orders[key];

							if (this.state.orders[key].id===eventId){
								obj["state"]= "error"
								console.log ("set err")
								
								product_id = obj.product_id 
								productStep =  parseFloat(obj.productStep)
								
								order_id = obj.orderIds 
								product_id = obj.product_id 
								
								
								var  esDecimal = typeof productStep === 'number' && isFinite(productStep) && Math.floor(productStep) !== productStep;
				    		if (esDecimal){
				    			productQty =   type==="Add"? parseFloat(oldOrders[key].productQty-productStep): parseFloat(oldOrders[key].productQty+productStep)
				    		}else{
				    			productQty =   type==="Add"? parseInt(oldOrders[key].productQty-productStep): parseInt(oldOrders[key].productQty+productStep)
				    		}
				    		obj["productQty"]= productQty
    		
							}
						//	neworders.push(obj)
						}
						this.setState({ orders:neworders, isExecuting:false });
						toast.error("ERROR:"+data.descResp, {
							closeButton: true
						});


					}

				})
			}	
	};
	LoadTable=async() => { 		
		let WO_Id = this.props.match.params.WO_Id; 
		let WOTaskId = this.props.match.params.WOTaskId; 
		
		let CUSTOM_PARAM = this.props.match.params.CUSTOM_PARAM; 
		 
		if (CUSTOM_PARAM!==undefined){
			if (CUSTOM_PARAM==="ALLTASKS"){
				WO_Id=0;
			}
		}
		 
		let org=	this.props.match.params.org==undefined?this.state.org:this.props.match.params.org;
  	this.setState({ isLoadedTable: false,WO_Id:WO_Id, loadingData:true,org: org });		
  	
		let url = API_CONFIG.base_url+'/externalApi/getOrderProductsByFather/'+WO_Id+"/"+WOTaskId+"/"+org;
		
		let nombre_cliente;
	  let direccion_1;
	  let zona;
	   
  	await axios({
			 url: url, 
			 method: 'get',
			 headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
		})
			.then(response => response.data)
			.then(data => {
				 
				 var arr=[]
				 var i=0
			   let orderIds=[];
			   let currentOrderId=0;
				 if (data.resp==="OK"  && data.rows.length >0){  
				 	
					 for (var key in data.flowIds){  
						 	 
						 	if(key==="0"){
						 		currentOrderId=data.flowIds[key].orderId;
						 		console.log(currentOrderId)
						 	}
						 	orderIds.push({
						 		checked: key==="0"?true:false,
							 	text:  data.flowIds[key].orderId,
				        value: data.flowIds[key].orderId
					 		})
					 }
				 	   
				 		var arrayPicked = []
				 		
					 		if (data.picking_products!==null  ){
					 			arrayPicked = data.picking_products.split("|");
					 		}
					 	 
					 		for (var key in data.rows){
					 			
					 			//let isPicked = arrayPicked.indexOf(data.rows[key].product_id)
					 			
					 			var arrPicked = arrayPicked.filter(function (e) {
    							return e.substr(0, e.indexOf(","))  == (data.rows[key].product_id);
								});								
								var isPicked = arrPicked.length>0?1:-1;
					 			
					 			//let img = (data.rows[key].productImage).replace(".jpg","-60x60.jpg")
					 			let img = data.rows[key].image===null?"-": (data.rows[key].image).replace(".jpg","-60x60.jpg")
					 			
					 			var minimun = 1;
								if (data.rows[key].minimum && data.rows[key].minimum!=null  ){
									minimun = parseFloat(data.rows[key].minimum)
								}
						
					 			arr.push( 
								  	{ id:key,
											product_id: data.rows[key].product_id,
											productName:data.rows[key].name,
											productModel:data.rows[key].model,
											productPrice:parseFloat(data.rows[key].price.replace("Bs.","").replace(",",".")).toFixed(2),
											productQty: parseFloat(data.rows[key].quantity),
											productImage: data.store_url+"image/cache/"+img,
											productSKU : data.rows[key].sku,
											state: "loaded" ,
											productEan: data.rows[key].ean,
											isPicked:isPicked,
											productMin: minimun,
											productStep:parseFloat(data.rows[key].decimal_step), 
								  	  orderIds: data.rows[key].orderIds, 
											orders: data.rows[key].orders							
																			  		 
								 		});
				 			} 
				 } 
				 
				 
				 this.setState({
					  	...this.state,
					  	orders: arr ,
					  	store_url: data.store_url,
					  	isLoadedTable:true,
					  	currentOrderId:currentOrderId,
					  	currentOrderIdToDel:currentOrderId,
					  	flowIds:data.flowIds,
					  	orderIds: orderIds,
					  	orderIdsToDel: orderIds,
					  	customerName:data.nombre_cliente,
					  	customerAdd:data.direccion_1,
					  	customerZone:data.zone,
					  	customer_id:data.customer_id,
					  	loadingData:false,
					  	existingPreviousOrder: false,/*revisar*/
					  	isDeleted: false, /*revisar*/ 
					  
					  }); 
				  
				
			})
	}
 
	toggleModal = nr => () => {

	let modalNumber = 'modal' + nr
	this.setState({
		[modalNumber]: !this.state[modalNumber]
	});
	}
	
	render() {
		return (
		<>
			
			   
			   
			   {this.state.loadingData?
			   
			   	 <Loader idLoading={this.state.loadingData}/>
			     :
	 				 <>  
	 				 <Loader idLoading={this.state.isExecuting}/>
	 				  
	 				  <h3 style={{
			   	 	zIndex:"1000",
			   	 	fontWeight:"600",
			   	color:"#000"
			   	}}> Listado de Productos de los pedidos agrupados</h3>
			   	 		
	 				  {this.state.existingPreviousOrder?
	 				  	 
	 				 <MDBCard  style={{width: "98%"}} className="mb-3">
	 				 
	 				
			        <MDBRow className="align-items-left justify-content-left"> 
								<MDBCol md="12" >
								   <MDBAlert      className="text-left align-top">
			            <h3 className="h3-responsive font-weight-bold mr-3"> 
				          <MDBIcon icon="exclamation-circle" className="mr-1"/>   El cliente {this.state.customerName} tiene un pedido aun no entregado
				            </h3> 
				             </MDBAlert> 
				             </MDBCol>     
				      </MDBRow>
				   
				        <hr/>
				        <MDBRow className="text-right">
			        		<MDBCol md="12" > 
			            	<MDBBtn 
											size="sm"
											color="primary"
											onClick={() => {
											this.setState({modal4:true })
											}} 
											>		Revisar Pedido
											</MDBBtn> 
											
											<MDBBtn 
											size="sm"
											disabled={this.state.isDeleted}
											color="danger"
											onClick={() => {
										//	this.deleteShipping("1616");
											this.setState({modal5:true, mensaje:"eliminar la tarifa?", accion:"eliminar tarifa" })
											}} 
											>		Eliminar Tarifa de envio
											</MDBBtn> 
				           </MDBCol> 
				        </MDBRow>
				   
			     </MDBCard>
	 				  
	 				  :""}
	 				 
	 				 
	 				 <MDBRow className="text-right mt-1 mb-3 mr-1">
							<MDBCol md="12" >


								<MDBBtn
									size="sm"
									color="success"
									onClick={() => {
										this.setState({modal3:true, disabledAdd:true, /*SKUcode:"" , */ productName:"", productPrice:"", productQty:"1" })
									}}
								>	<MDBIcon icon="cart-plus" />	Agregar Producto
								</MDBBtn>
							</MDBCol>
						</MDBRow>
	 				  
					 	{this.state.orders.map(event => (
					 	<p  key ={"event1"+event.id} style={{width: "98%"}}>
			                    <Event
			                    
			                    id={event.id} 
			                    product_id={event.product_id}
			                    productName={event.productName}
			                    productQty={event.productQty}
			                    productStep ={event.productStep}
			                    productModel={event.productModel}
			                    productImage={event.productImage}
			                    productMin={event.productMin}			                    
			                    productSKU={event.productSKU}
			                    productEan={event.productEan}
			                    isPicked={event.isPicked}
			                    orderIds={event.orderIds}
			                    orders={event.orders} 
			                    state={event.state}
			                    onDelete={this.handleDelete}
			                    onEdit={this.handleEdit}
			                    onPick={this.handlePick}
			                    handleEditQty={this.handleEditQty}
			                    />
			                    </p>
			                ))}
			                
			      
					 <MDBModal cascading centered isOpen={this.state.modal4} agle={this.toggleModal(4)} size="lg">
						 
							<MDBModalBody >
								 
								 <span className="h2-responsive mb-2">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle del pedido Previo </span>
							
								 <p>Nombre: {this.state.customerName}</p>
								 <p>Direccion: {this.state.customerAdd}</p>
								 <p>Zona: {this.state.customerZone}</p>
								{this.state.ordersPre.map(event => (
					 	<p  key={"event2"+event.id} style={{width: "98%"}}>
			                    <Event
			                    
			                    id={event.id}
			                    product_id={event.product_id}
			                    productName={event.productName}
			                    productQty={event.productQty}
			                   
			                    productModel={event.productModel}
			                    productImage={event.productImage}
			                    productSKU={event.productSKU}
			                    isPicked={event.isPicked}
			                    state={event.state}
			                  	onDelete={null}
			                    onEdit={null}
			                  	onPick={null}
			                    />
			                    </p>
			                ))} 
			                
							</MDBModalBody> 
					</MDBModal>       
					
					
					<MDBModal cascading centered isOpen={this.state.modal3} toggle={this.toggleModal(3)} size="lg">

							<MDBModalBody >

								<span className="h2-responsive mb-4">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar Producto</span>

								<>
									<MDBRow className="text-right">
										<MDBCol md="9" >
											<MDBInput
												id="SKUcode"
												key="SKUcode"
												name="SKUcode"
												value={this.state.SKUcode}
												onChange={this.changeHandler}
												size="sm"
												label="Escriba el nombre o SKU"  />


										</MDBCol>
										<MDBCol md="3" >
											<MDBBtn
												color="secondary"
												onClick={() => {
													this.searchProduct();
												}}
												className="mt-4"
												size="sm"
											>
												Buscar
											</MDBBtn>
										</MDBCol>
									</MDBRow>


									{this.state.showProductsList?
										<MDBTable
											responsive
											className="customTable" bordered striped
										>
											<MDBTableHead >
												<tr>

													<th>Imagen</th>
													<th>Producto</th>
													<th>Precio</th>
													<th>Precio Promocional</th>
													<th>Cantidad Disponible</th>
													<th>Seleccionar</th>
												</tr>
											</MDBTableHead>
											<MDBTableBody>

												{this.state.searchResults.map(item => (
													<tr key={"tr"+item.key}>
														<td key={"td"+item.key}>

															<img src={item.thumb} alt="thumbnail" className="img-thumbnail" />

														</td>
														<td>
															{item.name}
														</td>
														<td>
															{item.price}
														</td>
														<td>
															{!item.special?item.price:item.special}
														</td>
														<td>
															{item.quantity}
														</td>
														<td style={{"textAlign":"center"}}>
															{item.selected}
														</td>

													</tr>
												))
												}

											</MDBTableBody>
										</MDBTable>
										:

										<>

											<MDBRow className="text-left">
												<MDBCol md="12" >
													<span className="h4-responsive"> <b>Detalle del producto seleccionado</b>  </span>
												</MDBCol>
											</MDBRow>

											<MDBRow className="text-right">
												<MDBCol md="7" >
													<img src={this.state.productImageBig} alt="thumbnail" className="img-thumbnail" />
												</MDBCol>
 

												<MDBCol md="5" >
												
												<MDBSelect
									          options={this.state.orderIds}
									          selected="Elija una orden"
									          label="Orden"
									          name="orderID"
									          getValue= {this.selectHandler}
									        /> 
													<MDBInput
														id="productName"
														label="Producto"
														readOnly={true}
														name="productName"
														value={this.state.productName} onChange={this.changeHandler} />

													<MDBInput
														id="productPrice"
														label="Precio"
														readOnly={true}
														name="productPrice"
														value={this.state.productPrice} onChange={this.changeHandler} />

													<MDBInput
														label="Cantidad"
														id="productQty"
														name="productQty"
														readonly
													 
														value={this.state.productQty} onChange={this.changeHandler} />



													<MDBBtn
														size="sm"
														outline
														color="danger"


														onClick={() => {
															this.setState({showProductsList:true,  /*SKUcode:"" , */ productName:"", productPrice:"", productQty:"1" })
														}}
													>	<MDBIcon icon="arrow-alt-circle-left" />	Volver
													</MDBBtn>

													<MDBBtn
														size="sm"
														color="success"
														disabled={this.state.disabledAdd}
														onClick={() => {
															this.addOrder();
														}}
													>	<MDBIcon icon="cart-plus" />	Agregar
													</MDBBtn>
												</MDBCol>

											</MDBRow>

										</>
									}

								</>
							</MDBModalBody>
						</MDBModal> 

		    <MDBModal cascading centered isOpen={this.state.modal5} toggle={this.toggleModal(5)} size="md">
						<MDBModalBody className="text-center"> 
							<span className="h4-responsive text-center" > {this.state.mensaje}</span>
							
							{this.state.isMultiple?
							
							<MDBSelect
									          options={this.state.orderIdsToDel}
									          selected="Elija una orden"
									          label="Orden"
									          name="orderIDdel"
									          getValue= {this.selectHandlerDel}
									        /> 
							:""} 
							{this.state.showQuatity?
								
								<MDBInput
												id="productQtyNew"
												key="productQtyNew"
												name="productQtyNew"
												value={this.state.productQtyNew}
												onChange={this.changeHandler}
												size="sm"
												label="Nueva Cantidad"  />
												
								:"" } 
							<hr/>
							 <MDBRow className="text-center">
			        		<MDBCol md="12" > 
			            	  
			            	  <MDBBtnGroup size="sm">
								        <MDBBtn outline  
								        onClick={() => {
												this.setState({modal5:false })
												}} 
								        color="danger" className="text-capitalize" > <span className="h4-responsive">Cancelar</span></MDBBtn>					        
								        <MDBBtn 
								        onClick={() => {
												this.setState({modal5:false });
												if (this.state.accion==="editar"){
													this.handleCommitQty( this.state.product_idNew, this.state.isMultiple); 
												} else{
													this.handleCommitDelete(this.state.product_id, true);
												}
												
											 
												
												}} 
										   	color="success" className="text-capitalize"> <span className="h4-responsive">{this.state.accion}</span> </MDBBtn>
								      </MDBBtnGroup>
				           </MDBCol> 
				        </MDBRow> 
							
							 
							</MDBModalBody> 
					</MDBModal>      
					 </>
					
					 
				 }
				 
				 <ToastContainer
					        hideProgressBar={true}
					        newestOnTop={false}
					      position={"bottom-right"}
					        autoClose={3000}
					      />	
			 
			</>
		);
	}
}

	class Event extends React.Component {
    render() {
	    return (
	    		this.props.state === "deleting"?
	    			<MDBCard
	    			style={{
								opacity: "0.4",
									   backgroundColor: "#999"
									   
									   
						  }}
	    			 className="align-items-center justify-content-center">
	    				 <p></p>
	    				<div className="media mt-4 align-middle">
			           
			             <div className="media-body mb-3 mb-lg-3">
			             	 
			              <MDBAnimation type="rotateOut" infinite >
			              <MDBIcon style={{
								zIndex:"1050"
									   
						  }} size="2x" far icon="trash-alt" /> 
			               </MDBAnimation>
			                
			             </div>
			         
			        </div> 
	    			 <p></p>
	    			</MDBCard>
	        	:<MDBCard className="align-items-left justify-content-left">
					<div className="media mt-1">
			            <span className="h4-responsive font-weight-bold mr-3">
					{ this.props.orderIds.indexOf(",") <0? 
			             <MDBBadge
							 color="danger"
							 style={{"cursor":"pointer"}}
							 className="ml-1 mt-1 mr-1"

							 onClick={() =>
								 this.props.productQty==this.props.productMin?
									 toast.error("ERROR:Cantidad minima alcanzada", {closeButton: true }):
									 this.props.onEdit (this.props.product_id, "Rem")

							 }


						 >  -
			            </MDBBadge>:""}
					 	{this.props.productQty}
							{ this.props.orderIds.indexOf(",") <0? 
							<MDBBadge
								color="success"
								style={{"cursor":"pointer"}}
								className="ml-2 mt-1"
								onClick={() => this.props.onEdit (this.props.product_id, "Add")}
							>  +
			            </MDBBadge>
			            :""}
			           <br/>

			             <img src={this.props.productImage} alt="thumbnail" className="img-thumbnail ml-1 mt-1 mr-1" />
			              {this.props.onDelete===null?"":
					 	<p className="ml-1">
								<MDBBadge
									color="danger"
									style={{"cursor":"pointer"}}
									
									onClick={() => this.props.onDelete(this.props.product_id)}
								>
									Eliminar
								</MDBBadge> 	</p>}
			            
								
			            </span>
						<div className="media-body mb-3 mb-lg-3">
						
							{this.props.onPick===null?"": 
								<> 
				            <MDBBadge
				                color= {this.props.isPicked=== -1 ?"grey":"success"  } 
				               
				                style={{"cursor":"pointer"}}
				                className="mr-1 float-right"
				                onClick={() => this.props.onPick(this.props.product_id)} 
				            >
				               <MDBIcon far={this.props.isPicked=== -1 ?false:true}  size="2x" icon={this.props.isPicked=== -1 ?"check-square":"check-square"} />
				               <span> Pick</span>
				            </MDBBadge>
				            </>
				            }
			            
							
							<span className="h6 mt-0 font-weight-bold">{this.props.productName} </span>{" "}
							<hr className="hr-bold my-2" />
							{this.props.product_id && (
								<React.Fragment>
									<p className="font-smaller mb-0">

										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12"  className="ml-1" >
												<MDBIcon icon="shopping-basket" /> Codigo: {this.props.productModel}
											</MDBCol>
										</MDBRow>
										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" className="ml-1">
												<MDBIcon icon="barcode" /> SKU: {this.props.productSKU}
											</MDBCol>
										</MDBRow>
										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" className="ml-1">
												<MDBIcon icon="barcode" /> EAN: {this.props.productEan}
											</MDBCol>
										</MDBRow>
										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12"  > 
													<MDBBtn
														size="sm"
														color="primary"
														onClick={() => this.props.handleEditQty(this.props.product_id, this.props.productQty, this.props.productName, this.props.productStep )} 
													>	<MDBIcon icon="edit" />	Editar Cantidad
												 	</MDBBtn> 
											</MDBCol>
										</MDBRow>

									</p>
								</React.Fragment>
							)}
						</div>
					</div>
			        {this.props.productQty && (<>
			            <p className="h4-responsive p-2 mb-1 blue-grey lighten-4">
			            Cantidad:  <b> {this.props.productQty}</b> 
			            </p>
			            <p className="h5-responsive p-2 mb-4 blue-grey lighten-5">
			           Detalle<b> {this.props.orders}  </b>
			            </p></>
			        )} 
			      </MDBCard>
	        
	    );
    }
	}

export default externalForm04;

import React from "react";
import { MDBIcon } from "mdbreact";


const loadMessageV3 = props => {


  return (
     <>
     {props.idLoading?
     	<>
     		<div  className="loadingBG">
					 
				 </div>
     	
				<div  className="loadingBG2">
					 <div  className="loading" >
					 	<MDBIcon icon="cog" spin size="3x" className="green-text" fixed /><span className="sr-only green-text">Loading...</span>
					
					 </div>
				 </div></>
			:""}
       </>
  );

};

export default loadMessageV3;
				 
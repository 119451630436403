import React from "react";
 
class index extends React.Component {
	
	 
  	 
	
	render() {
		return (
		<>
			Formularios xFlow
			   </>
	        
	    );
    }
	}

export default index;

import React from "react";
import { MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";
const PageHeader = props => {
	if (props.title){
	  return ( 
	   	<div className="page-header">
				<div className="row">
					<div className="col-sm-12">
						<h3 className="page-title">{props.title}</h3>
						<ul className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/main">Home</Link>
							</li>
							{props.subtitle?
								<li className="breadcrumb-item active">{""+props.subtitle}</li>
							:""}
						</ul>
					</div>
				</div>
			</div>
	  );
	}else{
		return ""
	}
};
export default PageHeader;
				 
import React from "react";
import {MDBSelect, MDBNotification,MDBInputGroup,MDBDatePicker,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_CONFIG} from '../../Config/config-env.js';
import axios from 'axios';

class externalForm02 extends React.Component {
	
	state = {
		id_producto:-1,
		mensaje:"",
		accion:"",
		executedTask:false,
		isDeleted:false,
		productQty:1,
		productName:"",
		formButtons:{
				type:"",
				buttonsList:[]
			},
		SKUcode:"3212567000066",
		productPrice:0,
		existingPreviousOrder:false,
		isExecuting:false,
		processing: "Procesando",
		costo_envio:0,
		monto_real:"",
		monto_2:"",		
		metodo_pago:"",
		metodo_pago_2:"",
		
		from_date: new Date(),
		descuento:0,
		sub_total:0,
	  observation:"",
	  motivo_no_entrega:"",
 
	  motivos:[
	  {text:"NO SE ENCUENTRA EN CASA|NO SE ENCUENTRA EN CASA",
				value: "NO SE ENCUENTRA EN CASA|NO SE ENCUENTRA EN CASA"  },
	  {text:"NO RESPONDE EL TELEFONO",
				value: "NO RESPONDE EL TELEFONO"  },
		{text:"CLIENTE PIDIO RE-PROGRAMACION  PARA OTRO DIA",
				value: "CLIENTE PIDIO RE-PROGRAMACION  PARA OTRO DIA"  },
		{text:"ERROR EN ENTREGA (PEDIDO NO CORRESPONDE AL CLIENTE)",
				value: "ERROR EN ENTREGA (PEDIDO NO CORRESPONDE AL CLIENTE)"  },
		{text:"CLIENTE DETECTO FALLAS EN EL PRODUCTO SE RECHAZO LA ENTREGA",
				value: "CLIENTE DETECTO FALLAS EN EL PRODUCTO SE RECHAZO LA ENTREGA"  },
		{text:"NO SE LLEGO  A TIEMPO ( FALLA MECANICA DE TRANSPORTE)",
				value: "NO SE LLEGO  A TIEMPO ( FALLA MECANICA DE TRANSPORTE)"  },
		{text:"NO SE LLEGO A TIEMPO (MUCHA TRANCADERA)",
				value: "NO SE LLEGO A TIEMPO (MUCHA TRANCADERA)"  },
		{text:"NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA LEJANA)",
				value: "NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA LEJANA)"  },
		{text:"NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA INCORRECTA)",
				value: "NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA INCORRECTA)"  }
	  ],
	  	metodos:[],
		metodos_orig:[
			{text:"EFECTIVO",
				value: "EFECTIVO" ,checked:false },
				{text:"TARJETA ATC",
				value: "TARJETA ATC" ,checked:false },
				{text:"TARJETA LINKSER",
				value: "TARJETA LINKSER" ,checked:false },
				{text:"DEPOSITO",
				value: "DEPOSITO" ,checked:false },
					{text:"TODOTIX",
				value: "TODOTIX" ,checked:false },
					{text:"PAGO ALTERNATIVO",
				value: "PAGO ALTERNATIVO" ,checked:false } ,
						{text:"PAGO QR",
				value: "QR" ,checked:false } ,
				{
					text: "QR DINÁMICO LIBÉLULA",
					value: "QR DINÁMICO LIBÉLULA",
					checked: false,
				  },
			
				  {
					text: "LINEAS DE CRÉDITO IISA",
					value: "LINEAS DE CRÉDITO IISA",
					checked: false,
				  },
		],
		
		metodos2:[
		{text:"NINGUNO",
				value: "" , checked:true },
			{text:"EFECTIVO",
				value: "EFECTIVO"  },
				{text:"TARJETA ATC",
				value: "TARJETA ATC"  },
				{text:"TARJETA LINKSER",
				value: "TARJETA LINKSER"  },
				{text:"DEPOSITO",
				value: "DEPOSITO"  },
					{text:"TODOTIX",
				value: "TODOTIX"  },
					{text:"PAGO ALTERNATIVO",
				value: "PAGO ALTERNATIVO"  } ,
				{text:"PAGO QR",
				value: " QR"  } ,
				{
					text: "QR DINÁMICO LIBÉLULA",
					value: "QR DINÁMICO LIBÉLULA",
					checked: false,
				  },
			
				  {
					text: "LINEAS DE CRÉDITO IISA",
					value: "LINEAS DE CRÉDITO IISA",
					checked: false,
				  },
		],
		 
		orders:[],
		WO_Id:"",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true
		
	};
	
	getPickerValueFrom = value=> { this.setState({ from_date: value }); }
	
	componentDidMount=()=>{
	 		this.LoadTable();
	}
	  
  changeHandler = event => { 
  	 
		this.setState({ ...this.state, [event.target.name]: event.target.value });
	};
	 
	selectHandler2 = event => { 	 		 
 		//console.log("dato"+event[0])
  		if ( event[0]){ 
  			this.setState({ ...this.state, metodo_pago_2:  event[0] });
  		}else{ 
  			this.setState({ ...this.state, metodo_pago_2: ""});
  		}
  		
  		if ( event[0]==""){
  			this.setState({ ...this.state, metodo_pago_2: "", monto_2: ""});
  		}
	};
	
	selectHandler1 = event => { 	 		 
  		if ( event[0]){
  			this.setState({ ...this.state, metodo_pago:  event[0] });
  		}else{
  			this.setState({ ...this.state, metodo_pago: ""});
  		}
	};
	
	selectHandler3 = event => { 	 		 
  		if ( event[0]){
  			this.setState({ ...this.state, motivo_no_entrega:  event[0] });
  		}else{
  			this.setState({ ...this.state, motivo_no_entrega: ""});
  		}
	};
	
	   
	LoadTable=async() => { 		
		let WO_Id = this.props.match.params.WO_Id; 
		let WOTaskId = this.props.match.params.WOTaskId; 
		let token = this.props.match.params.token;
		this.setState({  WO_Id:WO_Id, loadingData:true });
		//let idTask = this.props.match.params.idTask;
		
		let urlT = API_CONFIG.base_url+'/externalApi/decryptToken';
  
		await axios({
			url: urlT,
			method: 'post',
			data: { 'token': this.props.match.params.token },
		})
			.then(response => response.data)
			.then(data => {


			let org = data.token.userOrg;
			let userId= data.token.userId;
					
			let url = API_CONFIG.base_url+'/externalApi/getOrderProductsV2/'+WO_Id+"/"+WOTaskId+"/"+org+"/"+userId;
			
			let nombre_cliente;
		  let direccion_1;
		  let zona;
	  	 axios({
				 url: url, 
				 method: 'get',
				 headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
			})
				.then(response => response.data)
				.then(data => {
					 
				 var arr=[]
				 var i=0
			 
				 if (data.resp==="OK"  && data.rows.length >0){ 
				 	//console.log(data.resp); 
				 		var arrayPicked = []
				 		
					 		 
					 		for (var key in data.rows){
					 			
					 			 	let img = data.rows[key].image===null?"-": (data.rows[key].image).replace(".jpg","-60x60.jpg")
					 			arr.push( 
								  	{ id:key,
											product_id: data.rows[key].product_id,
											productName:data.rows[key].name,
											productModel:data.rows[key].model,
											productPrice:parseFloat( (data.rows[key].price.replace("Bs.","")).replace(".","").replace(",",".")).toFixed(2),
											productQty: parseInt(data.rows[key].quantity),
											productImage: data.rows[key].image, //data.store_url+"image/cache/"+img,
											productSKU : data.rows[key].sku,
											state: "loaded"  
								 		});
				 			} 
				 } 
				 var descuento = data.descuento? parseFloat(data.descuento ,2).toFixed(2):0;
				 var costo_envio = data.costo_envio? parseFloat(data.costo_envio ,2).toFixed(2):0;
				 this.setState({
					  	...this.state,
					  	orders: arr ,
					  	isLoadedTable:true,
					  	customerName:data.nombre_cliente,
					  	customerAdd:data.direccion_1,
					  	customerZone:data.zone,
					  	costo_envio: data.costo_envio,
					  	customer_id:data.customer_id, 
					  	descuento: descuento,
					  	existingPreviousOrder: false,/*revisar*/
					  	isDeleted: false, /*revisar*/ 
					  	order_id:data.order_id /*revisar*/
					  }); 
			
				let monto_total=0;
				let sub_total=0;
				for (var key in this.state.orders){
					
					let obj = this.state.orders[key];
					let productPrice  = (this.state.orders[key].productPrice);
					let productQty = (this.state.orders[key].productQty);
					let total = productQty*productPrice;
					monto_total=monto_total+total;
					sub_total=sub_total+total;
				}
			 
				monto_total = (parseFloat( monto_total) + parseFloat(costo_envio) -  parseFloat(descuento) ).toFixed(2);
				this.setState({
					...this.state,
					monto_total: monto_total,
					sub_total: parseFloat(sub_total,2).toFixed(2)
				});  
				
				
			}).then(async() => { 
				url = API_CONFIG.base_url+'/api/getTaskOptions/'+WOTaskId+'/true';

				await axios({
					url: url,
					headers: { 'Authorization': 'Bearer ' + token },
					method: 'get'
				})
					.then(response => response.data)
					.then(data => {
						this.setState({ formButtons: data });
						//	console.log(this.state.formButtons);
					})
				}).then(async() => { 
					var url = API_CONFIG.base_url+'/api/getTaskFields/'+0+"/"+WO_Id+"/"+WOTaskId;
				 
					//console.log(token);
					await axios({
						url: url,
						headers: { 'Authorization': 'Bearer ' + token },
						method: 'get'
					})
						.then(response => response.data)
						.then(data => {
							
							let metodo_pago = ""
							let monto = ""
							for(var k in data.formWorkflowData) {
								if(data.formWorkflowData[k].NAME==="metodo_pago"){
									metodo_pago= data.formWorkflowData[k].VALUE;
								}
								if(data.formWorkflowData[k].NAME==="monto_total"){
									monto= data.formWorkflowData[k].VALUE;
								}
							} 
							
							var metodos = this.state.metodos_orig 
							for (var k in metodos){
								let obj =  metodos[k]
								if (obj["value"]==metodo_pago){ 
									obj["checked"]=true
								} 
							} 
							this.setState({ ...this.state, metodos:metodos, metodo_pago: metodo_pago, loadingData:false }); 
							if (metodo_pago==="TODOTIX"){
								this.setState({  monto_real:monto });
							}
							
						})
				})
				
			})
	}
 
	toggleModal = nr => () => {

	let modalNumber = 'modal' + nr
	this.setState({
		[modalNumber]: !this.state[modalNumber]
	});
	}
	
	executeTask =async(option) => {
		var responseValidation =true;
		let WO_Id = parseInt(this.props.match.params.WO_Id) 
		let WOTaskId =parseInt(this.props.match.params.WOTaskId)
		let token = this.props.match.params.token 
		
		var f = new Date(this.state.from_date);
		var fecha =  ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) +"/" +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + "/"+(f.getFullYear()+"")
		
		console.log(fecha)		
		//var fecha = res [2]+"/"+res[1]+"/"+res[0] 
		this.setState({isExecuting: true, modal5:true});
		
		
		var monto_real     =  this.state.monto_real
		var monto_2        = this.state.monto_2
		var metodo_pago    =  this.state.metodo_pago
		var metodo_pago_2  =  this.state.metodo_pago_2
		var motivo_no_entrega =  this.state.motivo_no_entrega
		//console.log(this.state.formButtons.buttonsList)
		
		 
		
		if (option=="op1" && this.state.formButtons.buttonsList.length>1 ){
			if (motivo_no_entrega==""){
					var msj_validacion="Seleccione el motivo de no Entrega";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
			}
		}else{
			
			if (metodo_pago==""  ){
				var msj_validacion="Seleccion el campo metodo de pago";
				toast.warning(msj_validacion, {
							closeButton: true
						});
				responseValidation=false
			}
		
			if (monto_real==""){
					var msj_validacion="Complete el campo monto pagado";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
			}else{
				if (isNaN(monto_real)){
					var msj_validacion="El campo monto pagado debe ser numero";
					toast.warning(msj_validacion, {
								closeButton: true
							});
					responseValidation=false
				}
			}
			if (metodo_pago_2!=""  ){ 
				if (monto_2==""){
					var msj_validacion="Complete el campo monto pagado 2";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
				}else{
					if (isNaN(monto_2)){
						var msj_validacion="El campo monto pagado 2 debe ser numero";
						toast.warning(msj_validacion, {
									closeButton: true
								});
						responseValidation=false
					} 
				}
			}
		}
		
		if (!responseValidation){
			 this.setState({
							...this.state,
							responseTask: "NOK",
							isExecuting: false
						})
						 
		}else {
				this.setState({responseTask: null});
				let url = API_CONFIG.base_url+'/api/executeTask/';//executeTask
				let url_val = API_CONFIG.base_url+'/api/validateTask/';
				var fields=[]

				fields.push({"WOTaskId": WOTaskId})
				fields.push({"flowId": WO_Id})
				fields.push({"option": option})
				fields.push({"actorId": 0})


				fields.push({"observation": this.state.observation})
				fields.push({"fecha_pago": fecha})
				
				fields.push({"monto_real": this.state.monto_real})
				fields.push({"monto_2": this.state.monto_2})
				
				fields.push({"metodo_pago": this.state.metodo_pago})
				fields.push({"metodo_pago_2": this.state.metodo_pago_2})
	 			fields.push({"motivo_no_entrega": this.state.motivo_no_entrega})
	 

			
						await axios.post(url_val,	{ params: fields, tasks: this.state.arrItemsChecked} ,
							{	headers: { 'Authorization': 'Bearer ' + token }},
						)
					.then(response => response.data)
					.then(data => {
						
						if (data.state){

						  axios.post(url,	{ params: fields, tasks: this.state.arrItemsChecked} ,
							{	headers: { 'Authorization': 'Bearer ' + token }},
						)
							.then(response => response.data)
							.then(data => {

								let colorMessage;
								let message;
								if (data.response==="OK"){
								//if (data.state){
									toast.success("Tarea ejecutada con exito", {
										closeButton: true
									});
									this.setState({isExecuting: false}); 
						
									if(window.ReactNativeWebView){
									
										window.ReactNativeWebView.postMessage("OK");
									  
									}else{
										window.parent.location.reload();
										console.log("no") 
									}
									
									 
								}else {
									this.setState({isExecuting: false}); 
									toast.error("Error ejecutando tarea", {
														closeButton: true
													});
								}
								
							})
						}else{ 							
							toast.warning(data.message, {
														closeButton: true
													});
							this.setState({isExecuting: false});							
						}
					})
			 
		}
	};
	
	render() {
		return (
		<>
			 
			     
			   {this.state.loadingData?
			   
			   	 <Loader idLoading={this.state.loadingData}/>
			     :
	 				 <>  
	 				 <Loader idLoading={this.state.isExecuting}/>
	 				  
	 				  <h3 style={{
			   	 	zIndex:"1000",
			   	 	fontWeight:"600",
			   	color:"#000"
			   	}}> {"Pedido #"+this.state.order_id}</h3>
			   	 		
	 				  
						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">SubTotal</MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2" className="text-right"><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.sub_total+" Bs."}</h6> </MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">Descuento</MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2" className="text-right"><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.descuento+" Bs."}</h6> </MDBCol>
						</MDBRow>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">Costo de Envio</MDBCol>
							<MDBCol size="12" sm="6"  md="3" lg="2" xl="2" className="text-right" ><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.costo_envio+" Bs."}</h6> </MDBCol>
						</MDBRow>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">	<h5 style={{
								zIndex:"1000",
								fontWeight:"500",
								color:"#000"
							}}>  Total:</h5></MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2"  className="text-right">	<h5    style={{
								zIndex:"1000",
								fontWeight:"500",
								color:"#000"
							}}>  {this.state.monto_total+" Bs."}</h5></MDBCol>
						</MDBRow>

	 				  
					 	{this.state.orders.map(event => (
					 	<p className="ml-3" key ={"event1"+event.id} style={{width: "98%"}}>
			                    <Event
			                     
			                    id={event.id}
			                    product_id={".000"+event.product_id}
			                    productName={event.productName}
			                    productQty={event.productQty}
			                    productPrice={event.productPrice}
			                    productModel={event.productModel}
			                    productImage={event.productImage}
			                    productSKU={event.productSKU}
			                  
			                    state={event.state}
			                    onDelete={null}
			                    onEdit={null}
			                    onPick={this.handlePick}
			                    />
			                    </p>
			                ))}
			           <MDBRow className="ml-2 text-center center" style={{width: "99%"}}>
			             <MDBCol sm="12"   className="text-center center" style={{width: "98%"}}>      
			        <MDBCard>
			        
							        <MDBRow  >
							         
							     
		                    <MDBCol   sm="12" md="9" lg="4" className="mt-2 ml-2 text-left" >
		                    	<span style={{color:"#909090"}}>  Fecha de pago </span> <MDBDatePicker style={{"marginTop":"-1.5rem"}} key="from"  name="from" id="from" format='DD/MM/YYYY' value={this.state.from_date} cancelLabel="Cancelar" getValue={this.getPickerValueFrom} />
		                    </MDBCol>

			                </MDBRow>
			                
			               <MDBRow style={{"marginTop":"-2.5rem"}} >
		                    <MDBCol sm="12" md="9" lg="4" className="ml-2 mr-2 text-left left">

		                        <MDBSelect
		                            className="is-invalid"
		                            key= "metodo_pago"
		                            id="metodo_pago"
		                            color="primary" 
		                            getValue= {this.selectHandler1}
		                            options={this.state.metodos} 
		                            label="Metodo de Pago" />
		                    </MDBCol>
		                </MDBRow>
		                <MDBRow style={{"marginTop":"-1.5rem"}} >
		                    <MDBCol sm="12" md="9" lg="4" className="ml-2 mr-2  text-left left" >

		                        <MDBInput
		                            name="monto_real"
		                            onChange={this.changeHandler}
		                            type="text"
		                            id="monto_real"
		                            
		                            key="monto_real"
		                            value={this.state.monto_real}
		                            label="Monto Pagado"
		                            required
		                        >
		                        </MDBInput>
		                    </MDBCol>
		                 </MDBRow> 
		                 <MDBRow style={{"marginTop":"-1.5rem"}} >
		                    <MDBCol sm="12" md="9" lg="4" className="ml-2 mr-2 text-left left">

		                        <MDBSelect
		                            className="is-invalid"
		                            key= "metodo_pago_2"
		                            id="metodo_pago_2"
		                            color="primary" 
		                            getValue= {this.selectHandler2}
		                            options={this.state.metodos2} 
		                            label="Metodo de Pago 2" />
		                    </MDBCol>
		                </MDBRow>
		                <MDBRow style={{"marginTop":"-2.5rem"}} >
		                    <MDBCol sm="12" md="9" lg="4" className="ml-2 mr-2 text-left left" >
		                        <MDBInput
		                            name="monto_2"
		                            onChange={this.changeHandler}
		                            type="text"
		                            id="monto_2" 
		                            key="monto_2"
		                            disabled={this.state.metodo_pago_2==""?true:false}
		                            value={this.state.monto_2}
		                            label="Monto pagado 2"
		                            required
		                        >
		                        </MDBInput>
		                    </MDBCol>
		                </MDBRow>
		                
		                
		                <MDBRow style={{"marginTop":"-2.5rem"}} >
		                    <MDBCol sm="12" md="9" lg="4" className="ml-2 mr-2 text-left left">

		                        <MDBSelect
		                            className="is-invalid"
		                            key= "store"
		                            id="store"
		                            color="primary" 
		                            getValue= {this.selectHandler3}
		                            options={this.state.motivos} 
		                            label="Motivo no Entrega" />
		                    </MDBCol>
		                </MDBRow>
										<MDBRow style={{"marginTop":"-1.5rem"}} >
		                    <MDBCol sm="12" md="9" lg="4" className="ml-2 mr-2 text-left left" >

		                         <MDBInput
																type="textarea"
																id="observation"
																label="Comentario Adicional"
																rows="2"
																name="observation"
																key="observation"
																value={this.state.observation}
																onChange={this.changeHandler}
																icon="pencil-alt"
															/>
		                        
		                    </MDBCol>
		                </MDBRow>
		                
		                
		               
                 
							 	<MDBRow className="text-center">
									<MDBCol md="12">

										{this.state.formButtons.buttonsList.map(item => (

										<MDBBtn
												
												color="primary" 
												disabled={this.state.executedTask}
												size="sm"


												onClick={() => {
													this.setState({modal5:true, optionMessage:   "Confirma la opcion "+item.OPTIONNAME+"?", optionCode: item.OPTIONCODE });
												}} 
												key={item.OPTIONCODE}
											>		{item.OPTIONNAME}
											</MDBBtn>
										))
										}
									</MDBCol>
								</MDBRow>
								</MDBCard>
								 </MDBCol>
					  	</MDBRow>
					 </>
					
					 
				 }
				 
				 <MDBModal cascading centered isOpen={this.state.modal5} toggle={this.toggleModal(5)} size="md">
					<MDBModalBody className="text-center">
						<span className="h4-responsive text-center text-uppercase" > {this.state.optionMessage}</span>
						<hr/>
						<MDBRow className="text-center">
							<MDBCol md="12" >
								<MDBBtnGroup size="sm">
									<MDBBtn outline
											onClick={() => {
												this.setState({modal5:false })
											}}
											color="warning"   > <span className="h4-responsive">Cancelar</span></MDBBtn>
									<MDBBtn
										onClick={() => {
											this.setState({modal5:false });
											this.executeTask(this.state.optionCode);
										}}
										color="primary" > <span className="h5-responsive">Aceptar</span> </MDBBtn>
								</MDBBtnGroup>
							</MDBCol>
						</MDBRow>
					</MDBModalBody>
				</MDBModal>
										
				 <ToastContainer
					        hideProgressBar={true}
					        newestOnTop={false}
					      position={"bottom-right"}
					        autoClose={3000}
					      />	
			 
			</>
		);
	}
}

	class Event extends React.Component {
    render() {
	    return (
	    		this.props.state === "deleting"?
	    			<MDBCard
	    			style={{
								opacity: "0.4",
									   backgroundColor: "#999"
									   
									   
						  }}
	    			 className="align-items-center justify-content-center">
	    				 <p></p>
	    				<div className="media mt-4 align-middle">
			           
			             <div className="media-body mb-3 mb-lg-3">
			             	 
			              <MDBAnimation type="rotateOut" infinite >
			              <MDBIcon style={{
								zIndex:"1050"
									   
						  }} size="2x" far icon="trash-alt" /> 
			               </MDBAnimation>
			                
			             </div>
			         
			        </div> 
	    			 <p></p>
	    			</MDBCard>
	        	:<MDBCard className="">
			        <div className="media mt-1">
			            <span className="h4-responsive font-weight-bold mr-3 align-items-right justify-content-right">
			              {this.props.productQty}
			           <br/>
			           
			             
			         <img src={this.props.productImage} alt="thumbnail" height="60px" width="60px"  className="img-thumbnail" />
			           
			            </span>
			            <div className="media-body mb-3 mb-lg-3">
			            
			            <span className="h6 mt-0 font-weight-bold">{this.props.productName} </span>{" "}
			            <hr className="hr-bold my-2" />
			            {this.props.product_id && (
			                <React.Fragment>
			                <p className="font-smaller mb-0">
			               
			                   <MDBRow className="align-items-left justify-content-left"> 
													<MDBCol md="12" >
														<MDBIcon icon="shopping-basket" /> Codigo: {this.props.productModel} 
													</MDBCol>     
												</MDBRow>
												<MDBRow className="align-items-left justify-content-left"> 
													<MDBCol md="12" >
														<MDBIcon icon="barcode" /> SKU: {this.props.productSKU} 
													</MDBCol>     
												</MDBRow>
			            
			                </p>
			                </React.Fragment>
			            )}
			            </div>
			        </div> 
			        {this.props.productQty && (
			            <p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
			            {"Cantidad: "+this.props.productQty+", Precio unitario: "+this.props.productPrice +", Total "+  (parseInt( (this.props.productPrice).replace("Bs.","") ) *parseInt(this.props.productQty)) +"Bs." }
			            </p>
			        )} 
			      </MDBCard>
	        
	    );
    }
	}

export default externalForm02;

import React from "react";
import {MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_CONFIG, casaideas_credentials} from '../../Config/config-env.js';
import axios from 'axios';
 
class externalForm01 extends React.Component {
	
	state = {
		id_producto:-1,
		mensaje:"",
		accion:"",
		isDeleted:false,
		productQty:1,
		productName:"",
		SKUcode:"3212567000066",
		productPrice:0,
		existingPreviousOrder:false,
		isExecuting:false,
		processing: "Procesando",
		ordersPre:[
			{id:0,
				product_id: 111,
				productName:"Manta",
				productPrice:"50Bs.",
				productQty:"1",
			  state: "loaded" },
			{id:1,
				product_id: 112,
				productName:"Juego de Tazas",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded" 
			}
		],
		orders:[],
		orders2:[],
		WO_Id:"",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true
		
	};
	
	componentDidMount=()=>{
	 	this.LoadTable();
	}
	  
	changeHandler = event => { 
		this.setState({ ...this.state, [event.target.name]: [event.target.value] });
	};
	 
	  
	   
	LoadTable=async() => { 		
		let params = new URLSearchParams(this.props.location.search);
		let token     = params.get("token")
		let WO_Id = params.get("wfpflowid")
		let WOTaskId = params.get("wfptaskid")
		let officecode = params.get("officecode")

		var order_id= 0
		var customer_id= 0
		var nombre_cliente=""
		var pedido_modificado=""
		var costo_envio=""
		var descuento=""
		var direccion_1=""
		var zona=""
		var pedido_parcial= ""
		var mixed_skus=[]
		var flowcode
		this.setState({ isLoadedTable: false,WO_Id:WO_Id, loadingData:true });

		console.log(WOTaskId)
		await axios({
			headers: {
				'Access-Control-Allow-Origin': '*',
				'origin':'x-requested-with',
						'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+token
				},
				url: API_CONFIG.xflowurl+"/engine-users/get-task-details",
				//url : "http://localhost:4042/api/v1"+"/engine-users/get-task-details",
				data:{
					wfptaskid: WOTaskId
				} ,
				method: 'post'
			}).then(response => response.data).then(data => {
				
				flowcode= data.task.FLOWCODE
				if (data.status){

					for (let v in data.flowvars){
						let flowVar = data.flowvars[v]
						if (flowVar.VARNAME==="order_id"){
							order_id = flowVar.VARVALUE
						}
						if (flowVar.VARNAME==="customer_id"){
							customer_id = flowVar.VARVALUE
						}
						if (flowVar.VARNAME==="nombre_cliente"){
							nombre_cliente = flowVar.VARVALUE
						}						
						if (flowVar.VARNAME==="pedido_modificado"){
							pedido_modificado = flowVar.VARVALUE
						}
						if (flowVar.VARNAME==="costo_envio"){
							costo_envio = flowVar.VARVALUE
						}
						if (flowVar.VARNAME==="descuento"){
							descuento = flowVar.VARVALUE
						}
						if (flowVar.VARNAME==="direccion_1"){
							direccion_1 = flowVar.VARVALUE
						}
						if (flowVar.VARNAME==="zona"){
							zona = flowVar.VARVALUE
						} 
						if (flowVar.VARNAME==="pedido_parcial"){
							pedido_parcial = flowVar.VARVALUE
						} 

						if (flowVar.VARNAME==="mixed_skus"){
							try{
								mixed_skus = JSON.parse(flowVar.VARVALUE)
							}catch(ex){
								console.log("EX", ex)
							}
							
						} 
					}
				}
			});

		//let idTask = this.props.match.params.idTask;
		let url = API_CONFIG.casaideas+'/externalapi/execute/getorderById';		
		
  		await axios({
			 crossdomain: true ,
			 url: url, 
			 method: 'post',
			 data:{
				username    : casaideas_credentials.user,
				password    : casaideas_credentials.password,
				order_id    : order_id,
				customer_id : customer_id,
				adminpath   :"matrix"
			 }, 
		})
			.then(response => response.data)
			.then(data => {
				 var arr=[]
				 var arr2=[]
				 var i=0				 
				 if (data.success) {
					data = {
					  resp: 'OK',
					  rows: data.response[0].products,  
					  
					};
				  }			 
				 if (data.resp==="OK"  && data.rows.length >0){ 				 	
					if (pedido_parcial==="SI"){
						//console.log(mixed_skus)
						console.log("parcial")
						for (let k in  mixed_skus){
							let  sku= mixed_skus[k]
							//console.log("sku original ", sku.sku)

							let isValid=false
							for (let m in data.rows){
								let key = data.rows[m]
								if (sku.sku === key.sku){
									if(flowcode ==="flowBolCI07"){
										//console.log("tienda online", sku.sku , key.sku , officecode , sku.store)
										//pickup del restante de la otra tienda
										if (officecode !== sku.store ){
											
											arr.push({ id:key,
												product_id: key.product_id,
												productName:key.name,
												productModel:key.model,
												productPrice:key.price,
												productQty: key.quantity,
												productImage: key.image,
												productSKU : key.sku,
												state: "loaded" 
												})
										}else{
										 
											arr2.push({ id:key,
												product_id: key.product_id,
												productName:key.name,
												productModel:key.model,
												productPrice:key.price,
												productQty: key.quantity,
												productImage: key.image,
												productSKU : key.sku,
												state: "loaded" 
												})
										}

									}else{
										//console.log("tienda no online")
										//es la tienda original
										if (officecode === sku.store ){
											arr.push({ id:key,
												product_id: key.product_id,
												productName:key.name,
												productModel:key.model,
												productPrice:key.price,
												productQty: key.quantity,
												productImage: key.image,
												productSKU : key.sku,
												state: "loaded" 
												})
										}else{
											arr2.push({ id:key,
												product_id: key.product_id,
												productName:key.name,
												productModel:key.model,
												productPrice:key.price,
												productQty: key.quantity,
												productImage: key.image,
												productSKU : key.sku,
												state: "loaded" 
												})
										}
									}
								}
							}					
						}

					}else{
						console.log("no parcial")
						for (let key in data.rows){
							
								arr.push( 
									{ id:key,
										product_id: data.rows[key].product_id,
										productName:data.rows[key].name,
										productModel:data.rows[key].model,
										productPrice:data.rows[key].price,
										productQty: data.rows[key].quantity,
										productImage: data.rows[key].image,
										productSKU : data.rows[key].sku,
										state: "loaded" 
										});								
														
						} 
					}
				 } 
				 console.log("arr",arr)
				 console.log("arr2",arr2)
				 this.setState({
					  	...this.state,
					  	orders: arr ,
						orders2: arr2,
					  	isLoadedTable:true,
					  	customerName:nombre_cliente,
					  	customerAdd:direccion_1,
					  	customerZone:zona,
					  	customer_id:customer_id,
					  	loadingData:false,
					  	existingPreviousOrder: false,/*revisar*/
					  	isDeleted: false, /*revisar*/ 
					  	order_id:order_id /*revisar*/
					  }); 
				  
				
			})
	}
 
	toggleModal = nr => () => {

	let modalNumber = 'modal' + nr
	this.setState({
		[modalNumber]: !this.state[modalNumber]
	});
	}
	
	render() {
		return (
		<>
			
			   {this.state.loadingData?
			   
			   	 <Loader idLoading={this.state.loadingData}/>
			     :
	 				 <>  
	 				 <Loader idLoading={this.state.isExecuting}/>
	 				  
	 				  <h3 style={{
			   	 	zIndex:"1000",
			   	 	fontWeight:"600",
			   	color:"#000"
			   	}}> {"Pedido: "+this.state.order_id}</h3>
			   	 		
	 				  {this.state.existingPreviousOrder?
	 				  	 
	 				 <MDBCard  style={{width: "98%"}} className="mb-3">
	 				 
	 				
			        <MDBRow className="align-items-left justify-content-left"> 
								<MDBCol md="12" >
								   <MDBAlert      className="text-left align-top">
			            <h3 className="h3-responsive font-weight-bold mr-3"> 
				          <MDBIcon icon="exclamation-circle" className="mr-1"/>   El cliente {this.state.customerName} tiene un pedido aun no entregado
				            </h3> 
				             </MDBAlert> 
				             </MDBCol>     
				      </MDBRow>
				   
				        <hr/>
				        <MDBRow className="text-right">
			        		<MDBCol md="12" > 
			            	<MDBBtn 
											size="sm"
											color="primary"
											onClick={() => {
											this.setState({modal4:true })
											}} 
											>		Revisar Pedido
											</MDBBtn> 
											
											<MDBBtn 
											size="sm"
											disabled={this.state.isDeleted}
											color="danger"
											onClick={() => {
										//	this.deleteShipping("1616");
											this.setState({modal5:true, mensaje:"eliminar la tarifa?", accion:"eliminar tarifa" })
											}} 
											>		Eliminar Tarifa de envio
											</MDBBtn> 
				           </MDBCol> 
				        </MDBRow>
				   
			     </MDBCard>
	 				  
	 				  :""}
	 				 
	 				  
					 	{this.state.orders.map(event => (
					 	<p  key ={"event1"+event.id} style={{width: "98%"}}>
			                    <Event
			                    
			                    id={event.id}
			                    product_id={".000"+event.product_id}
			                    productName={event.productName}
			                    productQty={event.productQty}
			                    productPrice={event.productPrice}
			                    productModel={event.productModel}
			                    productImage={event.productImage}
			                    productSKU={event.productSKU}
			                  
			                    state={event.state}
			                    onDelete={null}
			                    onEdit={null}
			                    onPick={this.handlePick}
			                    />
			                    </p>
			                ))}


							{this.state.orders2.length>0 &&
								<>
									<MDBRow className="text-left">
										<MDBCol md="12" > 
										<h4 style={{
											zIndex:"1000",
											fontWeight:"600",
										color:"#000"
										}}> {"Productos a cargo de la otra tienda"} </h4>
										</MDBCol> 
									</MDBRow>
									{this.state.orders2.map(event => (
										<p  key ={"event1"+event.id} style={{width: "98%"}}>
											<Event
											
											id={event.id}
											product_id={".000"+event.product_id}
											productName={event.productName}
											productQty={event.productQty}
											productPrice={event.productPrice}
											productModel={event.productModel}
											productImage={event.productImage}
											productSKU={event.productSKU}
											background={"#FFD0D0"}
											state={event.state}
											onDelete={null}
											onEdit={null}
											onPick={this.handlePick}
											/>
										</p>
									))}
								</>
							}
			      
					 <MDBModal cascading centered isOpen={this.state.modal4} toggle={this.toggleModal(4)} size="lg">
						 
							<MDBModalBody >
								 
								 <span className="h2-responsive mb-2">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle del pedido Previo </span>
							
								 <p>Nombre: {this.state.customerName}</p>
								 <p>Direccion: {this.state.customerAdd}</p>
								 <p>Zona: {this.state.customerZone}</p>
								{this.state.ordersPre.map(event => (
					 	<p  key={"event2"+event.id} style={{width: "98%"}}>
			                    <Event
			                    
			                    id={event.id}
			                    product_id={event.product_id}
			                    productName={event.productName}
			                    productQty={event.productQty}
			                    productPrice={event.productPrice}
			                    productModel={event.productModel}
			                    productImage={event.productImage}
			                    productSKU={event.productSKU}
			              
			                    state={event.state}
			                  	onDelete={null}
			                    onEdit={null}
			                  	onPick={null}
			                    />
			                    </p>
			                ))} 
			                
							</MDBModalBody> 
					</MDBModal>       
					
					  <MDBModal cascading centered isOpen={this.state.modal3} toggle={this.toggleModal(3)} size="md">
							 
							<MDBModalBody > 
							
							 <span className="h2-responsive mb-4">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar Producto</span>
							
							
							 <MDBRow className="text-right">
			        		<MDBCol md="9" > 
			            	   <MDBInput 
			            	    id="SKUcode"
								          key="SKUcode"
								          name="SKUcode"
								          value={this.state.SKUcode}
													onChange={this.changeHandler}
													 size="sm"
			            	   label="SKU"  />
			            	  
			            	  
				           </MDBCol> 
				           <MDBCol md="3" > 
				            <MDBBtn
								              color="secondary"
								              onClick={() => {
															this.searchProduct();
															}} 
								              className="mt-4"
								              size="sm"
								            >
								              Buscar
								            </MDBBtn>
								             </MDBCol> 
				        </MDBRow> 
				        
				        <MDBRow className="text-right">
			        		<MDBCol md="12" > 
			            	 <MDBInput
			            	  id="productName"
			            	  label="Producto"
			            	  readOnly={true}
											name="productName"
			            	  value={this.state.productName}
													 onChange={this.changeHandler} />
				           </MDBCol> 
				        </MDBRow> 
				        
				         <MDBRow className="text-right">
			        		<MDBCol md="6" > 
			            	 <MDBInput
			            	  id="productPrice"
			            	  label="Precio"
			            	  readOnly={true}
											name="productPrice"
			            	  value={this.state.productPrice}
													 onChange={this.changeHandler} />
				           </MDBCol> 
				         
			        		<MDBCol md="6" > 
			            	 <MDBInput
			            	  label="Cantidad"
			            	  id="productQty"
											name="productQty"
											readonly
											type="number"
											min="1"
											max={this.state.productMaxQty}
			            	  value={this.state.productQty}
													 onChange={this.changeHandler} />
				           </MDBCol> 
				       
				       </MDBRow> 
				        
				         <MDBRow className="text-right">
				         
			        		<MDBCol md="12" > 
			            	<MDBBtn 
											size="sm"
											color="success"
											disabled={this.state.disabledAdd}
											onClick={() => {
												this.setState({modal5:true, mensaje:"Agregar este producto?", accion: "agregar" });
											}} 
											>	<MDBIcon icon="cart-plus" />	Agregar  
											</MDBBtn>  
				           </MDBCol> 
				        </MDBRow> 
							
							 
							</MDBModalBody> 
					</MDBModal>  


		    <MDBModal cascading centered isOpen={this.state.modal5} toggle={this.toggleModal(5)} size="md">
						<MDBModalBody className="text-center"> 
							<span className="h4-responsive text-center" >Esta seguro de {this.state.mensaje}</span>
							<hr/>
							
							 <MDBRow className="text-center">
			        		<MDBCol md="12" > 
			            	  
			            	  <MDBBtnGroup size="sm">
								        <MDBBtn outline  
								        onClick={() => {
												this.setState({modal5:false })
												}} 
								        color="danger" className="text-capitalize" > <span className="h4-responsive">Cancelar</span></MDBBtn>					        
								        <MDBBtn 
								        onClick={() => {
												this.setState({modal5:false });
												if (this.state.accion==="agregar"){
													this.addOrder();
												}else if (this.state.accion==="eliminar tarifa"){
													this.deleteShipping(this.state.order_id);
												}else if (this.state.accion==="aumentar"){
													this.handleCommitEdit(this.state.id_producto, "Add");
												}else if (this.state.accion==="disminuir"){
													this.handleCommitEdit(this.state.id_producto, "Rem");
												} else{
													this.handleCommitDelete(this.state.id_producto);
												}
												
												
												
												}} 
										   	color="success" className="text-capitalize"> <span className="h4-responsive">{this.state.accion}</span> </MDBBtn>
								      </MDBBtnGroup>
				           </MDBCol> 
				        </MDBRow> 
							
							 
							</MDBModalBody> 
					</MDBModal>      
					 </>
					
					 
				 }
				 
				 <ToastContainer
					        hideProgressBar={true}
					        newestOnTop={false}
					      position={"bottom-right"}
					        autoClose={3000}
					      />	
			 
			</>
		);
	}
}

	class Event extends React.Component {
    render() {
	    return (
	    		this.props.state === "deleting"?
	    			<MDBCard
	    			style={{
								opacity: "0.4",
								backgroundColor:  this.props.background? this.props.background: "#999"
									   
									   
						  }}
	    			 className="align-items-center justify-content-center">
	    				 <p></p>
	    				<div className="media mt-4 align-middle">
			           
			             <div className="media-body mb-3 mb-lg-3">
			             	 
			              <MDBAnimation type="rotateOut" infinite >
			              <MDBIcon style={{
								zIndex:"1050"
									   
						  }} size="2x" far icon="trash-alt" /> 
			               </MDBAnimation>
			                
			             </div>
			         
			        </div> 
	    			 <p></p>
	    			</MDBCard>
	        	:<MDBCard className="" style={{backgroundColor:  this.props.background? this.props.background: "#fff"}}>
			        <div className="media mt-1">
			            <span className="h4-responsive font-weight-bold mr-3 align-items-right justify-content-right">
			              {this.props.productQty}
			           <br/>
			           
			             
			         <img src={this.props.productImage} height="60px" width="60px"   alt="thumbnail" className="img-thumbnail" />
			           
			            </span>
			            <div className="media-body mb-3 mb-lg-3">
			            
			            <span className="h6 mt-0 font-weight-bold">{this.props.productName} </span>{" "}
			            <hr className="hr-bold my-2" />
			            {this.props.product_id && (
			                <React.Fragment>
			                <p className="font-smaller mb-0">
			               
			                   <MDBRow className="align-items-left justify-content-left"> 
													<MDBCol md="12" >
														<MDBIcon icon="shopping-basket" /> Codigo: {this.props.productModel} 
													</MDBCol>     
												</MDBRow>
												<MDBRow className="align-items-left justify-content-left"> 
													<MDBCol md="12" >
														<MDBIcon icon="barcode" /> SKU: {this.props.productSKU} 
													</MDBCol>     
												</MDBRow>
			            
			                </p>
			                </React.Fragment>
			            )}
			            </div>
			        </div> 
			        {this.props.productQty && (
			            <p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
			            {"Cantidad: "+this.props.productQty+", Precio unitario: "+this.props.productPrice +", Total "+  (parseInt( (this.props.productPrice).replace("Bs.","") ) *parseInt(this.props.productQty)) +"Bs." }
			            </p>
			        )} 
			      </MDBCard>
	        
	    );
    }
	}

export default externalForm01;

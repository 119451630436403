import React from "react";
import {
  MDBNotification,
  MDBInputGroup,
  toast,
  MDBFormInline,
  MDBBadge,
  MDBAnimation,
  ToastContainer,
  MDBIcon,
  MDBDataTable,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";
import { API_CONFIG, casaideas_credentials } from "../../Config/config-env.js";
import { OP_SECRET_KEY } from "../../Config/config-env.js";
import axios from "axios";
import Table from "../../Components/Table";

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class externalForm01 extends React.Component {
  state = {
    id_producto: -1,
    mensaje: "",
    montoEvento: 0,
    accion: "",
    isDeleted: false,
    quantity: 1,
    name: "",
    validOrders: [],
    formButtons: [],
    SKUcode: "3212567000066",
    productPrice: 0,
    existingPreviousOrder: false,
    isExecuting: false,
    processing: "Procesando",
    ordersPre: [
      {
        id: 0,
        product_id: 111,
        name: "Manta",
        productPrice: "50Bs.",
        quantity: "1",
        state: "loaded",
      },
      {
        id: 1,
        product_id: 112,
        name: "Juego de Tazas",
        productPrice: "50Bs.",
        quantity: "1",
        state: "loaded",
      },
    ],
    orders: [],
    customerinfo: [],
    addinfo: [],
    WO_Id: "",
    isLoadedTable: false,
    loadingData: true,
    modal4: false,
    modal3: false,
    order_id: "",
    orderName: "",
    orderPrice: "Bs.50",
    orderQty: "1",
    customerName: "",
    customerAdd: "",
    customerZone: "",
    productMaxQty: 0,
    disabledAdd: true,

    tableData: {
      columns: [
        { dataIndex: "EVENTID", enabled: true, title: "Codigo Evento" },
        { dataIndex: "ORDERID", enabled: true, title: "Pedido" },
        { dataIndex: "ORDERTYPE", enabled: true, title: "Tipo Pedido" },
        { dataIndex: "DATEADDED", enabled: true, title: "Fecha Pedido" },
        { dataIndex: "FIRSTNAME", enabled: true, title: "Nombre" },
        { dataIndex: "LASTNAME", enabled: true, title: "Apellido" },
        { dataIndex: "TOTAL", enabled: true, title: "Monto Pedido" },
        { dataIndex: "SKU", enabled: true, title: "SKU" },
        { dataIndex: "QUANTITY", enabled: true, title: "Cantidad" },
        { dataIndex: "PRICE", enabled: true, title: "Precio Unitario" },
      ],
      rows: [],
    },
  };

  componentDidMount = () => {
    require("antd/dist/antd.css");
    this.LoadTable();
  };

  changeHandler = (event) => {
    //console.log(event.target.name);
    //console.log(event.target.value);
    this.setState({ ...this.state, [event.target.name]: [event.target.value] });
  };

  handlePick = async (order) => {
    let neworders = [];
    let order_ids = "";
    let totalProd = this.state.orders.length;
    let totalPicked = 0;
    this.setState({ isExecuting: true });
    for (var key in this.state.orders) {
      let obj = this.state.orders[key];
      //console.log(this.state.orders[key])

      if (this.state.orders[key].orderid === order) {
        obj["isPicked"] = this.state.orders[key].isPicked === -1 ? 1 : -1;
      }
      neworders.push(obj);
    }

    for (var key in neworders) {
      //console.log(neworders[key])
      if (neworders[key].isPicked === 1) {
        order_ids = order_ids + "," + neworders[key].orderid;
        totalPicked++;
      }
    }
    let progress = Math.ceil((totalPicked / totalProd) * 100);

    let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
    await axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      url: url,
      method: "put",
      data: {
        WO_flowID: this.state.WO_Id,
        WO_TaskID: this.state.WOTaskId,
        params: [
          { var: "ordersPicked", value: order_ids },
          { var: "progress", value: progress },
        ],
      },
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({ isExecuting: false, progress: progress });
      });

    this.setState({ isExecuting: false });
    this.setState({ orders: neworders });
  };

  handleChange = async (event) => {
    let neworders = [];
    let order_ids = "";
    let totalProd = this.state.orders.length;
    let totalPicked = 0;

    for (var key in this.state.orders) {
      let obj = this.state.orders[key];
      if (obj.orderid === event.target.name) {
        obj["posicion_rack"] = event.target.value;
      }
      neworders.push(obj);
    }

    this.setState({ orders: neworders });
  };

  handleSetRack = async (orderid) => {
    //console.log(orderid);

    this.setState({ isExecuting: true });
    var posicion_rack = "";
    var flowId = "";
    for (var key in this.state.orders) {
      if (this.state.orders[key].orderid == orderid) {
        posicion_rack = this.state.orders[key]["posicion_rack"];
        flowId = this.state.orders[key]["flowId"];
      }
    }

    //console.log("flowId", flowId);
    let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
    await axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
      url: url,
      method: "put",
      data: {
        WO_flowID: flowId,
        WO_TaskID: 0,
        params: [{ posicion_rack: posicion_rack }],
      },
    })
      .then((response) => response.data)
      .then((data) => {});

    this.setState({ isExecuting: false });
  };

  LoadTable = async () => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    let WO_Id = params.get("wfpflowid");
    var flowcode;
    let formButtons = [];

    let officecode = params.get("officecode");
    if (params.get("options")) {
      let options = params.get("options").split(",");
      //console.log("options", options);
      for (let o in options) {
        formButtons.push({ optioncode: "op" + o, optionname: options[o] });
      }
    }

    //console.log("formButtons", formButtons);
    let popup = params.get("popup");
    let isParent = false;

    if (popup) {
      this.setState({ reload: true });
    }

    let _WOTaskId = params.get("wfptaskid").split("___");

    let WOTaskId = _WOTaskId[0];

    if (_WOTaskId.length === 2) {
      //console.log("es multiple");
      isParent = true;
    }

    var eventId = 0;
    var montoEvento = 0;
    var ordersPicked = [];
    var nombre_recepcion = "";
    var arr = [];
    var orderskus = [];
    var validOrders = [];
    var ordenes = [];
    var tipopedidos = [];
    var posicionracks = [];

    var customerinfo = [];
    var addinfo = [];
    var regalos = "";
    var isSupervisor, isAdmin, org, userId;

    var customer_id = 0;
    var picking_products = "";
    var progress = 0;
    let orders = [];

    this.setState({
      isLoadedTable: false,
      WO_Id: WO_Id,
      WOTaskId: WOTaskId,
      loadingData: true,
      isParent: isParent,
      token: token,
      formButtons: formButtons,
    });

    var isSupervisor, isAdmin;
    await axios({
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/decrypt-token",
      //url : "http://localhost:4042/api/v1"+"/engine-users/decrypt-token",
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("data", data);
        if (data.status) {
          userId = data.token.USERID;

          isSupervisor = data.token.USERGROUP === "SINGLEUSER" ? -1 : 1;
          isAdmin = data.token.USERGROUP === "ADMIN" ? 1 : -1;
          this.setState({
            ...this.state,
            username: data.token.USERNAME,
          });
        }
      });

    await axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/get-task-details",
      //url : "http://localhost:4042/api/v1"+"/engine-users/get-task-details",
      data: {
        wfptaskid: WOTaskId,
        isParent: isParent,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("data", data);

        if (data.task) {
          flowcode = data.task.FLOWCODE;
        }

        if (data.tasks) {
          flowcode = data.tasks[0].FLOWCODE;
        }

        if (data.status) {
          for (let v in data.flowvars) {
            let flowVar = data.flowvars[v];
            let order_id = "";
            let flowid = flowVar.WFPFLOWID;
            if (flowVar.VARNAME === "order_id") {
              order_id = flowVar.VARVALUE;

              let customer_id = "";
              let pedido_parcial = "";
              let mixed_skus = [];
              for (let v2 in data.flowvars) {
                let flowVar2 = data.flowvars[v2];

                this.setState({ [flowVar2.VARNAME]: flowVar2.VARVALUE });

                if (flowVar2.WFPFLOWID == flowid) {
                  if (flowVar2.VARNAME === "customer_id") {
                    customer_id = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME === "pedido_parcial") {
                    pedido_parcial = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME === "mixed_skus") {
                    try {
                      mixed_skus = JSON.parse(flowVar2.VARVALUE);
                    } catch (ex) {
                      console.log("EX", ex);
                    }
                  }

                  if (flowVar2.VARNAME === "eventId") {
                    eventId = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME == "nombre_recepcion") {
                    nombre_recepcion = flowVar2.VARVALUE;
                  }
                }
              }
              orders.push({
                order_id,
                customer_id,
                pedido_parcial,
                mixed_skus,
              });
              ordenes.push({
                ID_HFLUJO: flowid,
                NOMBRE: "order_id",
                VALOR: order_id,
              });
              validOrders.push(order_id);
            }

            if (flowVar.VARNAME === "tipo_pedido") {
              tipopedidos.push({
                ID_HFLUJO: flowid,
                NOMBRE: "tipo_pedido",
                VALOR: flowVar.VARVALUE,
              });
            }

            if (flowVar.VARNAME === "posicion_rack") {
              posicionracks.push({
                ID_HFLUJO: flowid,
                NOMBRE: "posicion_rack",
                VALOR: flowVar.VARVALUE,
              });
            }

            if (flowid == WO_Id) {
              if (flowVar.VARNAME === "ordersPicked") {
                ordersPicked = (flowVar.VARVALUE + "").split(",");
              }
              if (flowVar.VARNAME === "progress") {
                progress = parseInt(flowVar.VARVALUE);
                //console.log("set progress",progress);
              }
            }
          }
        }
      });
    /*
    console.log("progress", progress);
    console.log("orders", orders);
    console.log("validOrders", validOrders);
    console.log("ordenes", ordenes);
    console.log("posicionracks", posicionracks);
    console.log("tipopedidos", tipopedidos);
    */
    var dataE = {
      eventId: eventId,
      username: this.state.username,
    };

    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        API_CONFIG.casaideas +
        "/externalapi/execute/getOrderByEventId/key/" +
        OP_SECRET_KEY,
      data: dataE,
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.success) {
          var d = data.response.customer_info[0];
          customerinfo.push({ name: "nombre", value: d.firstname });
          customerinfo.push({ name: "apellido", value: d.alstname });
          customerinfo.push({ name: "Email", value: d.email });
          customerinfo.push({ name: "Telefono", value: d.phone });
          customerinfo.push({ name: "Documento", value: d.numdoc });

          var add = data.response.event_info.eventInfo.directions;
          regalos = data.response.event_info.eventInfo.regalosenvueltos;

          //console.log("add", add);
          addinfo.push({ name: "Ciudad", value: add.city });
          addinfo.push({ name: "Dirección", value: add.address });
          addinfo.push({ name: "Indicaciones", value: add.reference });
          addinfo.push({ name: "Latitud", value: add.latitude });
          addinfo.push({ name: "Longitud", value: add.longitude });

          for (var key in data.response.order) {
            let order = data.response.order[key];
            let isPicked =
              ordersPicked.indexOf(order.ORDERID + "") >= 0 ? 1 : -1;
            let products = [];
            montoEvento = montoEvento + (order.TOTAL ? order.TOTAL : 0);
            data.response.products.map(function (prod) {
              if (order.ORDERID == prod.order_id) {
                products.push(prod);
              }
            });
            if (validOrders.indexOf(order.ORDERID + "") >= 0) {
              arr.push({
                id: key,
                orderid: order.ORDERID + "",
                fecha: order.DATEADDED,
                total: order.TOTAL,
                nombre: order.FIRSTNAME ? order.FIRSTNAME : "-",
                apellido: order.LASTNAME ? order.LASTNAME : "-",
                isPicked: isPicked,
                products: products,
              });
            }
          }

          data.response.orderDetails.map(function (sku) {
            let ordersku = sku;
            for (var key in data.response.order) {
              if (sku.ORDERID == data.response.order[key].ORDERID) {
                ordersku["DATEADDED"] = data.response.order[key].DATEADDED;
                ordersku["FIRSTNAME"] = data.response.order[key].FIRSTNAME;
                ordersku["LASTNAME"] = data.response.order[key].LASTNAME;
                ordersku["TOTAL"] = data.response.order[key].TOTAL;
                ordersku["EVENTID"] = eventId;
              }
            }
            if (validOrders.indexOf(sku.ORDERID + "") >= 0) {
              orderskus.push(ordersku);
            }
          });
        }
      });
    //console.log(arr)

    arr = arr.sort(function (a, b) {
      return parseInt(a.orderid) - parseInt(b.orderid);
    });

    orderskus = orderskus.sort(function (a, b) {
      return parseInt(a.ORDERID) - parseInt(b.ORDERID);
    });
    var datosOrdenes = [];
    var datosRackOrdenes = [];
    tipopedidos.map(function (tipo) {
      var orderid;
      var tipopedido = tipo.VALOR;
      var flujo = tipo.ID_HFLUJO;

      ordenes.map(function (orden) {
        if (flujo == orden.ID_HFLUJO) {
          orderid = orden.VALOR;
        }
      });
      datosOrdenes.push({
        flujo: flujo,
        orderid: orderid,
        tipopedido: tipopedido,
      });
    });

    posicionracks.map(function (tipo) {
      var orderid;
      var posicion_rack = tipo.VALOR;
      var flujo = tipo.ID_HFLUJO;

      ordenes.map(function (orden) {
        if (flujo == orden.ID_HFLUJO) {
          orderid = orden.VALOR;
        }
      });
      datosRackOrdenes.push({
        flujo: flujo,
        orderid: orderid,
        posicion_rack: posicion_rack,
      });
    });

    //console.log("orderskus",orderskus,tipopedidos, ordenes )
    //console.log("datosOrdenes",datosOrdenes)
    orderskus.map(function (orden) {
      var tipoPedido = "";
      datosOrdenes.map(function (dorden) {
        if (orden.ORDERID == dorden.orderid) {
          tipoPedido = dorden.tipopedido;
        }
      });
      if (tipoPedido !== "") {
        orden["ORDERTYPE"] = tipoPedido;
      } else {
        orden["ORDERTYPE"] = "Pedido online";
      }
    });

    //console.log("ordenes", ordenes);
    //console.log("arr", arr);
    arr.map(function (orden) {
      var tipoPedido = "";
      datosOrdenes.map(function (dorden) {
        if (orden.orderid == dorden.orderid) {
          tipoPedido = dorden.tipopedido;
        }
      });
      orden["tipopedido"] = tipoPedido;

      var posicion_rack = "";
      datosRackOrdenes.map(function (dorden) {
        if (orden.orderid == dorden.orderid) {
          posicion_rack = dorden.posicion_rack;
        }
      });
      orden["posicion_rack"] = posicion_rack;

      var flowId = "";
      ordenes.map(function (dorden) {
        if (orden.orderid == dorden.VALOR) {
          flowId = dorden.ID_HFLUJO;
        }
      });
      orden["flowId"] = flowId;
    });

    const tableData = this.state.tableData;
    tableData.rows = orderskus;

    this.setState({
      ...this.state,
      loadingData: false,
      orders: arr,
      ordersPicked: ordersPicked,
      store_url: API_CONFIG.casaideas,
      validOrders: validOrders,
      montoEvento: montoEvento,
      customerinfo: customerinfo,
      addinfo: addinfo,
      progress: progress,

      regalos: regalos,
      token: token,
      WOTaskId: WOTaskId,
      WO_Id: WO_Id,
    });
  };

  toggleModal = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  executeTask = async (option, optionName) => {
    var responseValidation = true;

    this.setState({ isExecuting: true });

    //console.log(option, this.state.progress);
    if (option == "op0") {
      if (this.state.progress !== 100) {
        responseValidation = false;
        var msj_validacion = "Debe pickear todos las ordenes";

        toast.warning(msj_validacion, {
          closeButton: true,
        });
      }
    }

    if (!responseValidation) {
      this.setState({
        ...this.state,
        responseTask: "NOK",
        isExecuting: false,
        modal6: false,
      });
    } else {
      this.setState({ responseTask: null });

      await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        url: API_CONFIG.xflowurl + "/engine-users/execute-task",
        //url : "http://localhost:4042/api/v1"+"/engine-users/execute-task",
        data: {
          parentid: this.state.WOTaskId,
          id: this.state.WOTaskId,
          isParent: this.state.isParent,
          optionValue: optionName,
          params: {
            nota_sap: this.state.nota_sap,
          },
        },
        method: "put",
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.status) {
            toast.success("Procesado con exito", {
              closeButton: true,
            });

            if (this.state.reload) {
              //console.log("reload parent");
              try {
                //window.opener.location.reload();
              } catch (ex) {
                console.log("error closing");
              }
              window.close();
            }
          } else {
            toast.error("Error ejecutando tareas, mensaje:" + data.message, {
              closeButton: true,
            });
          }
          this.setState({
            ...this.state,
            isExecuting: false,
            modal6: false,
          });
        });
    }
  };

  render() {
    return (
      <>
        {this.state.loadingData ? (
          <Loader idLoading={this.state.loadingData} />
        ) : (
          <>
            <Loader idLoading={this.state.isExecuting} />

            <h3
              style={{
                zIndex: "1000",
                fontWeight: "600",
                color: "#000",
                rginLeft: "0.5rem",
              }}
            >
              {" "}
              Datos del Evento
            </h3>

            <MDBRow className="pl-2">
              <MDBCol
                sm="12"
                style={{ fontSize: "1.25rem", fontWeight: "bold" }}
              >
                Datos del Cliente
              </MDBCol>
            </MDBRow>
            <MDBRow className="pl-2">
              {this.state.customerinfo.map((c, index) => (
                <MDBCol key={"ci" + index} sm="6">
                  <MDBInput
                    key={"ci2" + index}
                    value={c.value}
                    disabled={true}
                    label={c.name}
                  ></MDBInput>
                </MDBCol>
              ))}
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                C&oacute;digo Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventId}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Nombre Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventName}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Fecha del Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventDate}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Tipo de Evento:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventType}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Fecha Fin Recepcion Regalos:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.eventEndDate}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pl-2">
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Cantidad de Pedidos:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.orders.length}
                </span>
              </MDBCol>
              <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
                Monto Total:
              </MDBCol>
              <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {this.state.montoEvento + "Bs."}
                </span>
              </MDBCol>
            </MDBRow>

            {/*
				   	<MDBRow className="pl-2" > 
				   		 
							<MDBCol sm="6" md="3" lg="2" style={{fontSize:"1.25rem"}} >
								Nombre de quien recibe los regalos:
							</MDBCol>  
							<MDBCol sm="6" md="3" lg="4" style={{fontSize:"1.25rem"}} >
							 
								<span style={{ 
											   	 	fontWeight:"600", 
											   	}}> 
								 {this.state.nombre_recepcion}
								</span>
							</MDBCol>  

							<MDBCol sm="6" md="3" lg="2" style={{fontSize:"1.25rem"}} >
								Regalos envueltos:
							</MDBCol>  
							<MDBCol sm="6" md="3" lg="4" style={{fontSize:"1.25rem"}} >
							 
								<span style={{ 
											   	 	fontWeight:"600", 
											   	}}> 
								 {this.state.regalos}
								</span>
							</MDBCol>  
						 
				   	</MDBRow> 
				   	 */}

            <MDBRow className="pl-2">
              <MDBCol
                sm="12"
                style={{ fontSize: "1.25rem", fontWeight: "bold" }}
              >
                Datos de Direccion
              </MDBCol>
            </MDBRow>
            <MDBRow className="pl-2">
              {this.state.addinfo.map((c) => (
                <MDBCol sm="12">
                  <MDBInput
                    value={c.value}
                    disabled={true}
                    label={c.name}
                  ></MDBInput>
                </MDBCol>
              ))}
            </MDBRow>

            {this.state.orders.map((event) => (
              <p key={"event_" + event.id} className="mt-3 mx-2">
                <Event
                  event={event}
                  handlePick={this.handlePick}
                  handleChange={this.handleChange}
                  handleSetRack={this.handleSetRack}
                />
              </p>
            ))}

            <MDBCol
              col="12"
              sm="12"
              md="12"
              lg="12"
              className="col-12 text-right"
            >
              {this.state.formButtons.map((item) => (
                <MDBBtn
                  color="primary"
                  onClick={() => {
                    this.setState({
                      modal6: true,
                      optionMessage:
                        "Confirma la opcion " + item.optionname + "?",
                      optionCode: item.optioncode,
                      optionName: item.optionname,
                    });
                  }}
                  key={item.optioncode}
                >
                  {" "}
                  {item.optionname}
                </MDBBtn>
              ))}
            </MDBCol>

            {/**
            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="text-right justify-content-right"
              >
                <ExcelFile
                  filename={"export-orders-evento." + this.state.eventId}
                  element={
                    <MDBBtn key="find" size="sm" color="success">
                      Exportar a Excel
                    </MDBBtn>
                  }
                >
                  <ExcelSheet
                    data={this.state.tableData.rows}
                    name="Lista de Pedidos"
                  >
                    <ExcelColumn label="Codigo Evento" value="EVENTID" />
                    <ExcelColumn label="Pedido" value="ORDERID" />
                    <ExcelColumn label="Fecha Pedido" value="DATEADDED" />
                    <ExcelColumn label="Nombre" value="FIRSTNAME" />
                    <ExcelColumn label="Apellido" value="LASTNAME" />
                    <ExcelColumn label="Monto Pedido" value="TOTAL" />
                    <ExcelColumn label="SKU" value="SKU" />
                    <ExcelColumn label="Cantidad" value="QUANTITY" />
                    <ExcelColumn label="Precio Unitario" value="PRICE" />
                  </ExcelSheet>
                </ExcelFile>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="text-left justify-content-left"
              >
                <Table
                  tableData={this.state.tableData}
                  scroll={{ x: 950, y: 300 }}
                />
              </MDBCol>
            </MDBRow>
*/}
          </>
        )}

        <MDBModal
          cascading
          centered
          isOpen={this.state.modal6}
          toggle={this.toggleModal(6)}
          size="md"
        >
          <MDBModalBody className="text-center">
            <span className="h4-responsive text-center text-uppercase">
              {" "}
              {this.state.optionMessage}
            </span>
            <hr />
            <MDBRow className="text-center">
              <MDBCol md="12">
                <MDBBtnGroup size="sm">
                  <MDBBtn
                    outline
                    onClick={() => {
                      this.setState({ modal6: false });
                    }}
                    color="warning"
                  >
                    {" "}
                    <span className="h4-responsive">Cancelar</span>
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => {
                      this.setState({ modal6: false });
                      this.executeTask(
                        this.state.optionCode,
                        this.state.optionName
                      );
                    }}
                    color="primary"
                  >
                    {" "}
                    <span className="h5-responsive">Aceptar</span>{" "}
                  </MDBBtn>
                </MDBBtnGroup>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={false}
          position={"bottom-right"}
          autoClose={3000}
        />
      </>
    );
  }
}

class Event extends React.Component {
  render() {
    return (
      <MDBCard className="">
        <div className="media m-2">
          <div className="media-body mb-3 mb-lg-3">
            <hr className="hr-bold my-2" />
            {this.props.event.orderid && (
              <>
                <div className="font-smaller mb-0">
                  <MDBRow>
                    <MDBCol
                      sm="11"
                      className="align-items-left justify-content-left"
                    >
                      <h5
                        style={{
                          zIndex: "1000",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        <MDBIcon icon="shopping-basket" />
                        <span>Pedido: {this.props.event.orderid}</span>
                      </h5>
                    </MDBCol>

                    <MDBCol style={{ textAlign: "center" }} sm="1">
                      <MDBBadge
                        color={
                          this.props.event.isPicked === -1 ? "grey" : "success"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.props.handlePick(this.props.event.orderid)
                        }
                      >
                        <MDBIcon
                          far={this.props.event.isPicked === -1 ? false : true}
                          size="2x"
                          icon={
                            this.props.event.isPicked === -1
                              ? "check-square"
                              : "check-square"
                          }
                        />
                      </MDBBadge>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <h5
                        style={{
                          zIndex: "1000",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {" "}
                        Nombre Cliente:{" "}
                        {this.props.event.nombre +
                          " " +
                          this.props.event.apellido}{" "}
                      </h5>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol sm="9" md="2">
                      <MDBInput
                        value={this.props.event.posicion_rack}
                        name={this.props.event.orderid}
                        onChange={this.props.handleChange}
                        type="text"
                        label={"Posici\u00F3n Rack"}
                      ></MDBInput>
                    </MDBCol>

                    <MDBCol sm="3" md="2">
                      <MDBBtn
                        color="secondary"
                        onClick={() => {
                          this.props.handleSetRack(this.props.event.orderid);
                        }}
                        className="mt-4"
                        size="sm"
                      >
                        Guardar
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>

                <MDBCard className="m-2">
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <h6
                        style={{
                          zIndex: "1000",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        {" "}
                        Productos
                      </h6>
                    </MDBCol>
                  </MDBRow>

                  {this.props.event.products.map((item) => (
                    <>
                      <div className="media m-1 p-1">
                        <span className="h4-responsive font-weight-bold mr-3">
                          {this.props.quantity}
                          <br />
                          <img
                            src={item.image}
                            alt="thumbnail"
                            height="60px"
                            width="60px"
                            className="img-thumbnail"
                          />
                        </span>
                        <div className="media-body mb-3 mb-lg-3">
                          <span className="h6 mt-0 font-weight-bold">
                            {item.name}{" "}
                          </span>{" "}
                          <hr className="hr-bold my-2" />
                          {item.product_id && (
                            <React.Fragment>
                              <p className="font-smaller mb-0">
                                <MDBRow className="align-items-left justify-content-left">
                                  <MDBCol md="12">
                                    <MDBIcon icon="shopping-basket" /> Codigo:{" "}
                                    {item.sku}
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="align-items-left justify-content-left">
                                  <MDBCol md="12">
                                    <MDBIcon icon="barcode" /> SKU: {item.sku}
                                  </MDBCol>
                                </MDBRow>
                              </p>
                            </React.Fragment>
                          )}
                        </div>
                      </div>

                      {item.quantity && (
                        <>
                          <p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
                            {"Cantidad: " +
                              item.quantity +
                              ", Precio unitario: " +
                              item.price +
                              "Bs. Total " +
                              item.total}
                          </p>
                        </>
                      )}
                    </>
                  ))}
                </MDBCard>
                <MDBRow>
                  <MDBCol sm="12" style={{ textAlign: "right" }}>
                    <h6
                      style={{
                        zIndex: "1000",
                        fontWeight: "600",
                        color: "#000",
                        marginRight: 15,
                      }}
                    >
                      <span>
                        Total Pedido:{" "}
                        {this.props.event.total ? this.props.event.total : 0}
                      </span>
                    </h6>
                  </MDBCol>
                </MDBRow>
              </>
            )}
          </div>
        </div>
      </MDBCard>
    );
  }
}

export default externalForm01;

import React from "react";
import {MDBSelect, MDBTable, MDBTableBody, MDBDatePicker, MDBTableHead,MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_OP_CONFIG} from '../../Config/config-env.js';
import {API_CONFIG} from '../../Config/config-env.js';
import {OP_SECRET_KEY} from '../../Config/config-env.js';

import axios from 'axios';

var f = new Date();
var defDate = (f.getDate()<10?"0"+f.getDate():f.getDate()) + "/" +((f.getMonth() +1)<10?"0"+(f.getMonth() +1):(f.getMonth() +1)) + "/" + f.getFullYear();


class externalManageSKU extends React.Component {

    state = {
				from:new Date(),
        options:[],
        motivos: [
        {text:"Falla de calidad",value:"Falla de calidad"},
        {text:"Sin imagen",value:"Sin imagen"},
        {text:"Error de imagen",value:"Error de imagen"},
        {text:"Producto sin stock",value:"Producto sin stock"},
        {text:"Traspaso",value:"Traspaso"},
        {text:"Otros",value:"Otros-"}
        ],
        
        
        storeId: "",
        motivo_otro:"",
        SKU:"",
        skuExists:"NO ENCONTRADO",
        skuState:"SIN INFORMACION",
        showResults:false,
        motivo:""
    };


    componentDidMount=()=>{
        this.LoadSections();
    }

		getPickerValueFrom = value=> { this.setState({ from : value }); }
	  
    changeState=async() => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true  });
        let action = ""
        let newState=""
        if (this.state.skuState==="ACTIVO"){
            action ="lock";
            newState="BLOQUEADO";
        } else if(this.state.skuState==="BLOQUEADO"){
            action ="unlock"
            newState="ACTIVO";
        }
        let url = API_CONFIG.base_url+'/externalApi/changeSKUState/';
        let datos = {
            storeId: this.state.storeId,
            SKU: this.state.SKU,
            action:action,
            from: this.state.from,
            motivo: this.state.motivo + this.state.motivo_otro
        }
        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'post',
            data: datos
        })
            .then(response => response.data)
            .then(data => {

                if (data.resp==="OK"  ){
                    toast.success("OK:"+data.descResp, {
                        closeButton: true
                    });
                    this.setState({ skuState: newState });
                }else {
                    toast.error("ERROR:"+data.descResp, {
                        closeButton: true
                    });

                }
                this.setState({ isExecuting: false });
            })
    }
    searchSKU=async() => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true, showResults:false  });

        let url = API_CONFIG.base_url+'/externalApi/getStateBySKUandStore/';
        let datos = {
            storeId: this.state.storeId,
            SKU: this.state.SKU          
        }
        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'post',
            data: datos
        })
            .then(response => response.data)
            .then(data => {

                if (data.resp==="OK" &&  data.rows.length>0){

                    this.setState({ ...this.state, skuState : data.rows[0].SKUSTATE, skuExists:"ENCONTRADO",  ITEMNAME: data.rows[0].ITEMNAME, showResults:true });
                    this.setState({ isExecuting: false });

                }else {
                    this.setState({ isExecuting: false, skuExists:"NO ENCONTRADO", showResults:true, skuState : "SIN INFORMACION",ITEMNAME: "NO ENCONTRADO"});
                }
            })
    }

    LoadSections=async() => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true });

        let url = API_CONFIG.base_url+'/api/admin/getSections/';

        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'get'
        })
            .then(response => response.data)
            .then(data => {
                if (data.rows.length>0){
                    var objSections = [];
                    for (var key in data.rows) {
                        if (data.rows[key].sectionCode!=="NAC01"){
                            let	dataObject={
                                value: data.rows[key].sectionCode,
                                text:data.rows[key].sectionName
                            }
                            objSections.push(dataObject);
                        }
                    }

                    this.setState({ options : objSections });

                    this.setState({ isExecuting: false });

                }else {
                    this.setState({ isExecuting: false });
                }
            })
    }


    selectHandler = event => {
        this.setState({ ...this.state, storeId: event[0] });
    };
    selectHandler2 = event => {
        this.setState({ ...this.state, motivo: event[0], motivo_otro:"" });
        
    };

    changeHandler = event => {
        //console.log(event.target.value);
        this.setState({ ...this.state, SKU: event.target.value });
    };

		changeHandler2= event => {
        //console.log(event.target.value);
        this.setState({ ...this.state, motivo_otro: event.target.value });
    };
    render() {
        return (

            <MDBContainer className="justify-content-left text-left" style={{"marginLeft": "0"}}>
                <Loader idLoading={this.state.isExecuting}/>
                <br></br>
                <h2 key={"h2"}	className={" h2-responsive text-left"}> <strong>Bloquear/Desbloquear SKU</strong></h2>

                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left">

                        <MDBSelect
                            className="is-invalid"
                            key= "store"
                            id="store"
                            color="primary"
                            options={this.state.options}
                            getValue= {this.selectHandler}
                            selected="Elija una tienda"
                            label="Tienda" />
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left">

                        <MDBInput
                            name="SKU"
                            onChange={this.changeHandler}
                            type="text"
                            id="SKU"
                            key="SKU"
                            value={this.state.SKU}
                            label="SKU"
                            required
                        >
                        </MDBInput>
                    </MDBCol>
                </MDBRow>


                <MDBRow>
                    <MDBCol  sm="12" md="9" lg="4" className="text-right justify-content-right">
                      <MDBBtn key="find" onClick={() => {this.searchSKU();}} size="sm"

                                    color="success"
                                    className="text-capitalize"
                            >Buscar SKU</MDBBtn>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol  sm="12" md="9" lg="4" className="text-left justify-content-left">

                        {this.state.showResults?
                            <>
                                <MDBRow className="mt-3">
                                    <MDBCol  sm="12" className="text-left justify-content-left">
                                        <strong>  Resultado de la busqueda:</strong>
                                    </MDBCol>

                                </MDBRow>
                        <MDBRow middle className="mt-1">
                            <MDBCol middle sm="3" style={{"marginBottom": "1rem"}} >
                                <strong> SKU: </strong>
                            </MDBCol>
                            <MDBCol middle sm="9"  >

                                <MDBAlert color= {this.state.skuExists==="ENCONTRADO"?"success":"danger"}>
                                    <strong>  {this.state.skuExists}</strong>
                                </MDBAlert>

                            </MDBCol>
                        </MDBRow>

                        <MDBRow middle className="mt-1">
                            <MDBCol middle sm="3" style={{"marginBottom": "1rem"}} >
                                <strong> Producto: </strong>
                            </MDBCol>
                            <MDBCol middle sm="9"  >

                                <MDBAlert color= {this.state.skuExists==="ENCONTRADO"?"success":"danger"}>
                                    <strong>  {this.state.ITEMNAME}</strong>
                                </MDBAlert>

                            </MDBCol>
                        </MDBRow>
                        <MDBRow middle className="mt-1">
                            <MDBCol middle sm="3"  style={{"marginBottom": "1rem"}}>
                               <strong> ESTADO: </strong>
                            </MDBCol>
                            <MDBCol  sm="9" >
                            <MDBAlert  color={this.state.skuState==="ACTIVO"?"success":"danger"}>
                               <strong> {this.state.skuState} </strong>
                            </MDBAlert>
                            </MDBCol>

                        </MDBRow>
                        
                         {this.state.skuState==="ACTIVO"?
                        <>
                         <MDBRow middle className="mt-1">
                            <MDBCol middle sm="3"  style={{"marginBottom": "1rem"}}>
                               <strong> Fecha: </strong>
                            </MDBCol>
                            <MDBCol  sm="9" >
                             	 
                             	 <MDBDatePicker value={this.state.from} key="from"  name="from" id="from" format='DD/MM/YYYY' cancelLabel="Cancelar" getValue={this.getPickerValueFrom} />
                            </MDBCol>

                        </MDBRow>
                        
                        <MDBRow middle className="mt-1">
                            <MDBCol middle sm="12"  style={{"marginBottom": "1rem"}}>                               
                             	 <MDBSelect										             
										              key= "mot"
										              id="mot"
										              color="primary"
										              options={this.state.motivos}
										              getValue= {this.selectHandler2}
										              selected="Elija un motivo"
										              label="Motivos" />		
                            </MDBCol>
                        </MDBRow>
                        
                        <MDBRow middle className="mt-1">
                            <MDBCol middle sm="12"  style={{"marginBottom": "1rem"}}>                               
                             	 <MDBInput
																 name="motivo_otro"
																 onChange={this.changeHandler2}
																 type="text"
																 disabled={this.state.motivo==="Otros-"?false:true}
																 id="motivo_otro"
																 key="motivo_otro"
									               value={this.state.motivo_otro}
																 label="Otro Motivo"
																 required
																>
																</MDBInput>
                            </MDBCol>
                        </MDBRow>
                        </>
                      :""}
                        
                        
                        

                        <MDBRow className='d-flex align-items-center mt-1'>
                            <div className='text-center  col-md-12'>
                                <MDBBtn
                                    onClick={() => {
                                         this.changeState();
                                    }}
                                    size="sm"
                                    disabled={this.state.skuExists==="ENCONTRADO"?false:true}

                                    color= {this.state.skuState==="ACTIVO"? "danger" : "success"}
                                    className="btn-block"
                                    value="Pagar">
                                    {this.state.skuState==="ACTIVO"?"Bloquear":"Desbloquear"}
                                </MDBBtn>
                            </div>
                        </MDBRow>

                            </>
                            :""}


                    </MDBCol>
                </MDBRow>

                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={false}
                    position={"bottom-right"}
                    autoClose={3000}
                />

            </MDBContainer>
        )
    }
}
export default externalManageSKU;

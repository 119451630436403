import React from "react";
import {MDBTable, MDBTableBody, MDBTableHead,MDBSelect, MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_OP_CONFIG} from '../../Config/config-env.js';
import {API_CONFIG} from '../../Config/config-env.js';
import {OP_SECRET_KEY} from '../../Config/config-env.js';

import axios from 'axios';

class externalForm07 extends React.Component {

	state = {
		modal5:false,
		id_producto:-1,
		mensaje:"",
		username:"",
		accion:"",
		isDeleted:false,
		showProductsList:true,
		productQty:1,
		productName:"",
		SKUcode:"", //"3212567000066",
		productPrice:0,
		productImage:"",
		existingPreviousOrder:false,
		isExecuting:false,
		searchResults:[],
		shippingTypes:[],
		paymentTypes:[],
		processing: "Procesando",
		ordersPre:[
			{id:0,
				product_id: 111,
				productName:"Manta",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded" },
			{id:1,
				product_id: 112,
				productName:"Juego de Tazas",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded"
			}
		],
		orders:[],
		isSupervisor:-1,
		isAdmin:-1,
		shippId:1,
		WO_Id:"",
		payId:"",
		store_url: "",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true,
		costo_envio:0,
		descuento:0,
		
		pre_costo_envio:0,
		pre_descuento:0,
		
		sub_total:0,
		org:"",
		store_id:0
	};

	componentDidMount=async ()=>{
		await this.LoadTable();
		 
	}

	selectHandler1 = event => { 	 		 
		if ( event[0]){
			this.setState({ ...this.state, shippId:  event[0] });
		}else{
			this.setState({ ...this.state, shippId: ""});
		}
		 
	};
	
	selectHandler2 = event => { 	 		 
		if ( event[0]){
			this.setState({ ...this.state, payId:  event[0] });
		}else{
			this.setState({ ...this.state, payId: ""});
		}
	 
	};
	
	changeHandler = event => {
		//	console.log(event.target.id)
		//	console.log(event.target.value)
		var targetname = event.target.name?event.target.name:event.target.id
		
		if ( targetname== "pre_descuento" || targetname=="pre_costo_envio"){
			this.setState({ ...this.state, [targetname]: event.target.value.replace(/[^0-9,.]/g, '').replace(/,/g, '.') });	
			
		}else{
			this.setState({ ...this.state, [targetname]: event.target.value });	
		}
		
		
	};
	
	handleChangePrice = event => {
		console.log(event.target.name)
		console.log(event.target.value)
			
		let oldOrders = this.state.orders
		
	//	console.log(oldOrders)
		
		for (var key in oldOrders){

			let obj = oldOrders[key];

			if (oldOrders[key].productSKU===event.target.name ){
				//obj["state"]= "deleting"
				console.log(oldOrders[key])
				oldOrders[key].productPrice =
				event.target.value.replace(/[^0-9,.]/g, '').replace(/,/g, '.');
			}
		}
		
		this.setState({
						...this.state,
						orders: oldOrders
					});
		
	};
	changePaymentType= async (event)   => {
		console.log("change",this.state.payId);
		
		
		var dataDelOrder= { 
			"order_id": this.state.order_id,
			"paymentTypeId": this.state.payId, 
			"user" : this.state.username
		}
		
		var tipo_pago=""
		var  tipo_pago_id= "directo"
		console.log( this.state.payId)
		 
		var payId  = this.state.payId 
		
		this.state.paymentTypes.map(function (s) {
			if (s.value == payId ){ 
				tipo_pago = s.text 
				if (s.value == 4 ){ 
					tipo_pago_id= "punto_fisico"
				}
			}
		}); 
				 
		await axios({
			method: 'post',
			// headers: {  'Access-Control-Allow-Origin':'*' },
			crossdomain: true ,

			url: this.state.store_url+'externalapi/execute/editPaymentTypeOrder/key/'+OP_SECRET_KEY,
			data: dataDelOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {
				console.log(data)
				if (data.success==="Order has been modified successfully" ){
					 
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  } , {"tipo_pago":tipo_pago} , {"metodo_pago":tipo_pago} , {"tipo_pago_id":tipo_pago_id} 
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})
 
					this.setState({ isExecuting:false });
					 
					toast.success("OK:"+data.success, {
						closeButton: true
					}); 
				} else{ 
					this.setState({   isExecuting:false });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					}); 
				} 
			})
	}
	
	changeShippingType= async (event)   => {
		console.log("change",this.state.shippId);
		 
		var dataDelOrder= { 
			"order_id": this.state.order_id,
			"shippingTypeId": this.state.shippId, 
			"user" : this.state.username
		}
		
		var tipo_envio_etiqueta, tipo_envio_code,tipo_envio
		console.log(this.state.shippingTypes)
		
		tipo_envio_code = this.state.shippId
		var shippId  = this.state.shippId 
		
		this.state.shippingTypes.map(function (s) {
			if (s.value == shippId ){
				
				tipo_envio_etiqueta = s.text
				if (s.type=="PICKUP"){
					tipo_envio= "xdelslot"
				}else{
					tipo_envio="weight"
				}
			}
		}); 
				
		console.log(tipo_envio_etiqueta, tipo_envio_code,tipo_envio)
		await axios({
			method: 'post',
			// headers: {  'Access-Control-Allow-Origin':'*' },
			crossdomain: true ,

			url: this.state.store_url+'externalapi/execute/editShippingTypeOrder/key/'+OP_SECRET_KEY,
			data: dataDelOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {
				console.log(data)
				if (data.success==="Order has been modified successfully" ){
					 
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  } , {"tipo_envio":tipo_envio}, {"tipo_envio_code":tipo_envio_code} , {"tipo_envio_etiqueta":tipo_envio_etiqueta} 
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})
  

					this.setState({ isExecuting:false });
					 
					toast.success("OK:"+data.success, {
						closeButton: true
					}); 
				} else{ 
					this.setState({   isExecuting:false });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					}); 
				} 
			})
	}
	
	changeShippingCost= async (event)   => {
		console.log("change",this.state.pre_costo_envio);
		
		var dataDelOrder= {

			"order_id": this.state.order_id,
			"shippingCost": this.state.pre_costo_envio, 
			"user" : this.state.username
		}
		
		await axios({
			method: 'post',
			// headers: {  'Access-Control-Allow-Origin':'*' },
			crossdomain: true ,

			url: this.state.store_url+'externalapi/execute/editShippingCostOrder/key/'+OP_SECRET_KEY,
			data: dataDelOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {
				console.log(data)
				if (data.success==="Order has been modified successfully" ){
					let oldOrders = this.state.orders
					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice   =  (oldOrders[key].productPrice);
						let productQty =  (oldOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.pre_costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					this.setState({
						...this.state,
						monto_total: monto_total,
						costo_envio:this.state.pre_costo_envio,
						sub_total: parseFloat(sub_total,2).toFixed(2),
					}); 
					 
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  } , {"monto_total":monto_total}, {"shipping_cost":parseFloat(this.state.pre_costo_envio,2).toFixed(2)} 
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})
 
					this.setState({ isExecuting:false });
					 
					toast.success("OK:"+data.success, {
						closeButton: true
					}); 
				} else{ 
					this.setState({   isExecuting:false });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					}); 
				} 
			})
			
	}
	
	changeDiscount= async (event)   => {
		console.log("change",this.state.pre_descuento);
		
		var dataDelOrder= {

			"order_id": this.state.order_id,
			"discount": this.state.pre_descuento, 
			"user" : this.state.username
		}
		
		await axios({
			method: 'post',
			// headers: {  'Access-Control-Allow-Origin':'*' },
			crossdomain: true ,

			url: this.state.store_url+'externalapi/execute/editDiscountOrder/key/'+OP_SECRET_KEY,
			data: dataDelOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {
				console.log(data)
				if (data.success==="Order has been modified successfully" ){
					let oldOrders = this.state.orders
					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice   =  (oldOrders[key].productPrice);
						let productQty =  (oldOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.pre_descuento) ).toFixed(2);
					this.setState({
						...this.state,
						monto_total: monto_total,
						descuento:this.state.pre_descuento,
						sub_total: parseFloat(sub_total,2).toFixed(2),
					}); 
					 
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  } , {"monto_total":monto_total}, {"discount":parseFloat(this.state.pre_descuento,2).toFixed(2)} 
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})
 
					this.setState({ isExecuting:false });
					 
					toast.success("OK:"+data.success, {
						closeButton: true
					}); 
				} else{ 
					this.setState({   isExecuting:false });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					}); 
				} 
			})
		
	}
	
	
	
	
	handleCommitPrice = async (eventId, type)   => {
		
		let neworders=[]
		let product_id;
		let productQty=0;
		let productPrice;
		let productSKU;
		let oldOrders = this.state.orders
		for (var key in  oldOrders){

			let obj = oldOrders[key];

			if (oldOrders[key].id===eventId){
				//obj["state"]= "deleting"
				console.log(oldOrders[key])

				product_id = oldOrders[key].product_id
				productQty =  parseInt(oldOrders[key].productQty)
				productSKU = oldOrders[key].productSKU
				productPrice = oldOrders[key].productPrice
				//obj["productQty"]=  type==="Add"? productQty+1:productQty-1

			}
			neworders.push(obj)
		}

		var dataDelOrder= {

			"order_id": this.state.order_id,
			"customer_id": this.state.customer_id,
			"product_id": product_id,
			"sku":productSKU,
			"user" : this.state.username,
			"price": productPrice
		}

		this.setState({ isExecuting:true });


		await axios({
			method: 'post',
			// headers: {  'Access-Control-Allow-Origin':'*' },
			crossdomain: true ,

			url: this.state.store_url+'externalapi/execute/editPriceOrderProduct/key/'+OP_SECRET_KEY,
			data: dataDelOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {

				if (data.success==="Order has been modified successfully" ){

					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice   =  (oldOrders[key].productPrice);
						let productQty =  (oldOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					}); 
					 
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  } , {"monto_total":monto_total},{"subtotal":parseFloat(sub_total,2).toFixed(2)}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})

					url = API_CONFIG.base_url+'/externalApi/updateProductsByOrderID/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							order_id: this.state.order_id,
							SKU: productSKU,
							quantity:type==="Add"? productQty+1:productQty-1,
							org:  this.state.org
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})

					
					this.setState({ isExecuting:false });
					//this.setState({ orders:neworders });
					//console.log(this.state.orders);
					toast.success("OK:"+data.success, {
						closeButton: true
					});
					
					
					

				} else{

					for (var key in this.state.orders){
						let obj = this.state.orders[key];

						if (this.state.orders[key].id===eventId){
							obj["state"]= "error"
							console.log ("set err")
						}
						neworders.push(obj)
					}
					this.setState({ orders:neworders, isExecuting:false });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					}); 
				} 
			})
	};


	handlePick =async (producto) => { 
	//	console.log(producto)
		let neworders =[]
		let product_ids="";
		let totalProd = this.state.orders.length
		let totalPicked =0
		
		this.setState({ isExecuting:true });
		
		for (var key in  this.state.orders){
 		
 			let obj = this.state.orders[key];
 			//console.log(this.state.orders[key])
 	
 			if (this.state.orders[key].id===producto){  
				obj["isPicked"]= this.state.orders[key].isPicked===-1?1:-1;
 			} 
 			neworders.push(obj)
 		} 
 		
 		for (var key in neworders){ 		 
 			 
 			if (neworders[key].isPicked>0){  
 				
				product_ids=product_ids+"|"+neworders[key].product_id+","+neworders[key].productQty+","+neworders[key].productQty
				totalPicked++
 			} 
 		}
 		if (product_ids.length>1){
 			product_ids= product_ids.substr(1,product_ids.length);
 		}
 	 
 		let progress = Math.ceil(totalPicked/totalProd*100)
 		let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
				await	axios({
						url: url,
						method: 'post',
						
						data: {
							org: this.state.org,
							flowId:this.props.match.params.WO_Id,
							params:[
								{ "picking_products": product_ids  }, {"progress":progress}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					}).then(response => response.data)
			.then(data => {
				 
				this.setState({ isExecuting:false });
			})
					
 			
 	 
 	this.setState({ orders:neworders  });
 		 
	} 
	
	deleteShipping=async (eventId)   => {
		let url =  API_CONFIG.base_url+'/externalApi/testRestApi/';
		this.setState({ isExecuting:true });
		await axios.post(url,	{ params: 1}
		)
			.then(response => response.data)
			.then(data => {
				//console.log(data);
				if (data.resp==="OK"){
					this.setState({isDeleted:true})
					toast.success("OK:"+"Se elimino el cobro de envio del pedido:"+eventId, {
						closeButton: true
					});
				}
				this.setState({ isExecuting:false });
			})
	}

	addOrder=async ()   => {
		let newOrders = this.state.orders;

		let newId= newOrders.length+1

		let dataNewOrder =
			{
				"order_id": this.state.order_id,
				"customer_id": this.state.customer_id,
				"product_id": this.state.product_id,
				"sku":this.state.productSKU,
				"user": this.state.username,
				"quantity": Array.isArray(this.state.productQty)?this.state.productQty[0]:this.state.productQty
			}
		//console.log(dataNewOrder)

		this.setState({ isExecuting:true });

		await axios({
			method: 'post',
			crossdomain: true ,
			// headers: { 'Content-Type': 'application/json' }, 
			url: this.state.store_url+'externalapi/execute/addOrderProduct/key/'+OP_SECRET_KEY,
			data: dataNewOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => { 
				//console.log(data);
				//console.log(newId);
				if (data.success==="Order has been modified successfully"){
				//	console.log(this.props.match.params.WO_Id);

					newOrders.push(
						{ id:newId,
							product_id: this.state.product_id,
							productName:this.state.productName,
							//productPrice:this.state.productPrice,
							//productQty:this.state.productQty,
							productPrice:parseFloat(this.state.productPrice ).toFixed(2),
							productQty: Array.isArray(this.state.productQty)?this.state.productQty[0]:this.state.productQty,
							productSKU:this.state.productSKU,
							isPicked:-1,
							productModel:this.state.productModel,
							productImage:this.state.productImage,
							state: "loaded"

						}
					);

					let monto_total=0;
					let sub_total=0;
					console.log(this.state.orders)
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice  = parseFloat(newOrders[key].productPrice);
						let productQty = (newOrders[key].productQty);
						let total = parseFloat(productQty)*parseFloat(productPrice);
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					
					var totalProd=0;
						var totalPicked=0;
						var product_ids=""
						for (var key in newOrders){ 		 
			 				totalProd++;
				 			if (newOrders[key].isPicked>0){  
				 				  
								product_ids=product_ids+"|"+newOrders[key].product_id+","+newOrders[key].productQty+","+newOrders[key].productQty
								totalPicked++
				 			} 
				 		} 
				 		
				 		if (product_ids.length>1){
				 			product_ids= product_ids.substr(1,product_ids.length);
				 		}
					let progress = Math.ceil(totalPicked/totalProd*100)
					
					
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					});
					//console.log(this.state.org)
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  }, {"monto_total":monto_total}, {"picking_products":product_ids},{"progress":progress} ,{"subtotal":parseFloat(sub_total,2).toFixed(2)}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})
					url = API_CONFIG.base_url+'/externalApi/updateProductsByOrderID/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							order_id: this.state.order_id,
							SKU: this.state.productSKU,
							quantity:Array.isArray(this.state.productQty)?this.state.productQty[0]:this.state.productQty,
							org:  this.state.org
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})


					toast.success("OK:"+data.success, {
						closeButton: true
					});

				}else{
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					});
				}

				this.setState({ isExecuting:false, orders:newOrders, modal3:false,  });
			})

	}

	handleDelete = (producto) => {
		//console.log(producto)
		this.setState({
			accion: "eliminar",
			modal5:true,
			mensaje:"Eliminar este producto?",
			id_producto: producto
		});
	}
	
	handlePrice = (producto) => {
		//console.log(producto)
		this.setState({
			accion: "cambiar",
			modal5:true,
			mensaje:"Cambiar el precio al producto?",
			id_producto: producto
		});
	}
	

	handleEdit = (producto, type) => {
		//console.log(producto)
		this.setState({
			accion:  type ,//  "editar"+type,
			modal5:true,
			mensaje: type+" la cantidad de este producto?",
			id_producto: producto
		});
	}


	handleCommitEdit = async (eventId, type)   => {
		console.log("edit"+eventId+"type:"+type);

		let neworders=[]
		let product_id;
		let productQty=0;
		let productSKU;
		let oldOrders = this.state.orders
		for (var key in  oldOrders){

			let obj = oldOrders[key];

			if (oldOrders[key].id===eventId){
				//obj["state"]= "deleting"
				console.log(oldOrders[key])

				product_id = oldOrders[key].product_id
				productQty =  parseInt(oldOrders[key].productQty)
				productSKU = oldOrders[key].productSKU

				obj["productQty"]=  type==="Add"? productQty+1:productQty-1

			}
			neworders.push(obj)
		}

		var dataDelOrder= {

			"order_id": this.state.order_id,
			"customer_id": this.state.customer_id,
			"product_id": product_id,
			"sku":productSKU,
			"user": this.state.username,
			"quantity": type==="Add"? productQty+1:productQty-1
		}

		this.setState({ isExecuting:true });


		await axios({
			method: 'post',
			// headers: {  'Access-Control-Allow-Origin':'*' },
			crossdomain: true ,

			url: this.state.store_url+'externalapi/execute/editOrderProduct/key/'+OP_SECRET_KEY,
			data: dataDelOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {

				if (data.success==="Order has been modified successfully" ){

					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice   =  (oldOrders[key].productPrice);
						let productQty =  (oldOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					});
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  } , {"monto_total":monto_total},{"subtotal":parseFloat(sub_total,2).toFixed(2)}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})

					url = API_CONFIG.base_url+'/externalApi/updateProductsByOrderID/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							order_id: this.state.order_id,
							SKU: productSKU,
							quantity:type==="Add"? productQty+1:productQty-1,
							org:  this.state.org
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})

					this.setState({ isExecuting:false });
					//this.setState({ orders:neworders });
					//console.log(this.state.orders);
					toast.success("OK:"+data.success, {
						closeButton: true
					});

				} else{

					for (var key in this.state.orders){
						let obj = this.state.orders[key];

						if (this.state.orders[key].id===eventId){
							obj["state"]= "error"
							console.log ("set err")
						}
						neworders.push(obj)
					}
					this.setState({ orders:neworders, isExecuting:false });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					});


				}

			})


	};

	handleCommitDelete =async (eventId)   => {
		//console.log("del"+eventId);
		let neworders=[]
		let product_id;
		let productQty;
		let productSKU;
		for (var key in this.state.orders){
			let obj = this.state.orders[key];
				//console.log(obj);
			if (this.state.orders[key].id===eventId){
				obj["state"]= "deleting"
				product_id = this.state.orders[key].product_id
				productQty = this.state.orders[key].productQty
				productSKU = this.state.orders[key].productSKU
			}
			neworders.push(obj)
		}

		var dataDelOrder= {

			"order_id": this.state.order_id,
			"customer_id": this.state.customer_id,
			"product_id": product_id,
			"user": this.state.username,
			"sku":productSKU,
			"quantity": productQty

		}
		//console.log(dataDelOrder)
		this.setState({ isExecuting:true });
		await axios({
			method: 'post',
			crossdomain: true ,
			// headers: { 'Content-Type': 'application/json' },
			url: this.state.store_url+'externalapi/execute/deleteOrderProduct/key/'+OP_SECRET_KEY,
			data: dataDelOrder //JSON.stringify(dataDelOrder)
		})

			.then(response => response.data)
			.then(data => {

				if (data.success==="Order has been modified successfully" ){

					const orders = this.state.orders.filter(e => e.id !== eventId);
					this.setState({ orders });

					this.setState({ isExecuting:false });

					let oldOrders = this.state.orders
					let monto_total=0;
					let sub_total=0;
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						let productPrice   =  (oldOrders[key].productPrice);
						let productQty =  (oldOrders[key].productQty);
						let total = productQty*productPrice;
						monto_total=monto_total+total;
						sub_total=sub_total+total;
					}					
					monto_total = (parseFloat( monto_total) + parseFloat(this.state.costo_envio) -  parseFloat(this.state.descuento) ).toFixed(2);
					var totalProd=0;
					var totalPicked=0;
					var product_ids=""
					for (var key in orders){ 		 
		 				totalProd++;
			 			if (orders[key].isPicked>0){  
			 				 
							
							product_ids=product_ids+"|"+orders[key].product_id+","+orders[key].productQty+","+orders[key].productQty
							totalPicked++
			 			} 
			 		} 
			 		if (product_ids.length>1){
			 			product_ids= product_ids.substr(1,product_ids.length);
			 		}

				 	let progress = Math.ceil(totalPicked/totalProd*100)
				 	
					this.setState({
						...this.state,
						monto_total: monto_total,
						sub_total: parseFloat(sub_total,2).toFixed(2)
					});
					let url = API_CONFIG.base_url+'/externalApi/setParamFlow/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							org:  this.state.org,
							params:[
								{ "pedido_modificado": "SI"  }, {"monto_total":monto_total} ,{"picking_products":product_ids}, {"progress":progress},{"subtotal":parseFloat(sub_total,2).toFixed(2)}
							]
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})

					url = API_CONFIG.base_url+'/externalApi/updateProductsByOrderID/';
					axios({
						url: url,
						method: 'post',
						data: {
							flowId:this.props.match.params.WO_Id,
							order_id: this.state.order_id,
							SKU: productSKU,
							quantity:0,
							org:  this.state.org
						},
						headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
					})



					toast.success("OK:"+data.success, {
						closeButton: true
					});



				} else{
					neworders=[]
					for (var key in this.state.orders){
						let obj = this.state.orders[key];
						//console.log(obj);
						if (this.state.orders[key].id===eventId){

							obj["state"]= "error"
							console.log ("set err")
						}
						neworders.push(obj)
					}
					this.setState({ orders:neworders });
					toast.error("ERROR:"+data.descResp, {
						closeButton: true
					});
					
						this.setState({ isExecuting:false });


				}

			})

	};

	LoadTable=async() => {

		let WO_Id = this.props.match.params.WO_Id;
		let WOTaskId = this.props.match.params.WOTaskId;
		var store_id =0;
		this.setState({isLoadedTable: false, WO_Id: WO_Id, loadingData: true});
		
		var tarkFields
	
	 
			await axios({
				url: API_CONFIG.base_url+'/api/getTaskFields/0/'+WO_Id+'/'+WOTaskId,
				method: 'get',
				headers: { 'Authorization': 'Bearer ' + this.props.match.params.token }
			}).then(response => response.data)
				.then(data => {
			//		console.log(data)
					tarkFields = data.formWorkflowData
			})
		var shippId= 0
		var paymentName= ""
		for (var t in tarkFields){
			if (tarkFields[t].NAME == "tipo_envio_code"){
				shippId= tarkFields[t].VALUE
			}
			if (tarkFields[t].NAME == "tipo_pago"){
				paymentName= tarkFields[t].VALUE
			}
		}
		//console.log("fin",tarkFields, shippId)
		
		this.setState({shippId: shippId});
		
		var _store_url=""
		let urlT = API_CONFIG.base_url+'/externalApi/decryptToken';
		var  isSupervisor, isAdmin;
		
		await axios({
			url: urlT,
			method: 'post',
			data: { 'token': this.props.match.params.token },
		}) 
			.then(response => response.data)
			.then(data => {


				let org = data.token.userOrg;
				let userId= data.token.userId;
				
				isSupervisor  = data.token.userType==="SINGLEUSER"?-1:1; 
				isAdmin  = data.token.userType==="ADMINISTRATOR"?1:-1;
				
				
				this.setState({
							...this.state,
						username: data.token.username})

				//let idTask = this.props.match.params.idTask;
				let url = API_CONFIG.base_url+'/externalApi/getOrderProductsV2/'+WO_Id+"/"+WOTaskId+"/"+org+"/"+userId;

				let nombre_cliente="";
				let direccion_1="";
				let zona="";
				axios({
					url: url,
					method: 'get',
					headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
				})
					.then(response => response.data)
					.then(data => {
						var arr=[]
						var i=0
/*
						let t1 = data.actors.indexOf("supervisor operaciones");
						let t2 = data.actors.indexOf("call center");
						if (t1>0 || t2>0){
							isSupervisor=1;
						}*/

						if (data.resp==="OK"  && data.rows.length >0){
							//console.log(data.resp);
							var arrayPicked = []

							if (data.picking_products!==null  ){
								arrayPicked = data.picking_products.split("|");
							}
							
							for (var key in data.rows){
								//let isPicked = arrayPicked.indexOf(data.rows[key].product_id)
								store_id =data.rows[0].store_id;	
								var arrPicked = arrayPicked.filter(function (e) {
    							return e.substr(0, e.indexOf(","))  == (data.rows[key].product_id);
								});								
								var isPicked = arrPicked.length>0?1:-1;
						
								let img = data.rows[key].image===null?"-": (data.rows[key].image).replace(".jpg","-60x60.jpg")


								arr.push(
									{ id:key,
										product_id: data.rows[key].product_id,
										productName:data.rows[key].name,
										productModel:data.rows[key].model,
										productPrice:parseFloat((data.rows[key].price.replace("Bs.","")).replace(".","").replace(",",".")).toFixed(2),
										productQty: parseInt(data.rows[key].quantity),
										productImage: data.rows[key].image, //data.store_url+"image/cache/"+img,
										productSKU : data.rows[key].sku,
										state: "loaded" ,
										isPicked:isPicked,
									});
							}
						}

					//	console.log(data.actors.indexOf("supervisor operaciones"));
						var costo_envio = data.costo_envio? parseFloat(data.costo_envio ,2).toFixed(2):0;
				    var descuento = data.descuento? parseFloat(data.descuento ,2).toFixed(2):0;
						this.setState({
							...this.state,
							orders: arr ,
							org: org,
							store_id:store_id,
							store_url: data.store_url,
							isLoadedTable:true,
							customerName:data.nombre_cliente,
							customerAdd:data.direccion_1,
							customerZone:data.zone,
							customer_id:data.customer_id, 
							existingPreviousOrder: false,
							isDeleted: false,
							order_id:data.order_id,
							costo_envio:costo_envio,
				    	descuento:descuento,
				    	pre_costo_envio:costo_envio,
				    	pre_descuento:descuento,
							isSupervisor: isSupervisor,
							isAdmin: isAdmin,
							//loadingData:false,
						});
						_store_url = data.store_url
						let monto_total=0;
						let sub_total=0;
						for (var key in this.state.orders){
							let obj = this.state.orders[key];
							let productPrice  = (this.state.orders[key].productPrice);
							let productQty = (this.state.orders[key].productQty);
							let total = productQty*productPrice;
							monto_total=monto_total+total;
							sub_total=sub_total+total;
						}
						monto_total = (parseFloat( monto_total) + parseFloat(costo_envio) -  parseFloat(descuento) ).toFixed(2);
						this.setState({
							...this.state,
							monto_total: monto_total,
							sub_total: parseFloat(sub_total,2).toFixed(2)
						});
						
						//console.log(this.state.store_url, _store_url)
						var dataSH= {

							"language_id": 3,
							"username":  this.state.username
						}
						
						axios({
							method: 'post',
							// headers: {  'Access-Control-Allow-Origin':'*' },
							crossdomain: true ,

							url: _store_url+'externalapi/execute/getShippingTypes/key/'+OP_SECRET_KEY,
							data: dataSH //JSON.stringify(dataDelOrder)
						}).then(response => response.data).then(data => {
								var shippingTypes=[]
								//console.log(data)
								for (var s in data.response){
									if (data.response[s].SHIPPINGTYPEID == shippId){
										shippingTypes.push ({ value : data.response[s].SHIPPINGTYPEID ,  text: data.response[s].SHIPPINGNAME, type: data.response[s].TYPE , checked:true})
									}else{
										shippingTypes.push ({ value : data.response[s].SHIPPINGTYPEID ,  text: data.response[s].SHIPPINGNAME , type: data.response[s].TYPE } )	
									}
								} 
								this.setState({
									...this.state,
									shippingTypes: shippingTypes, 
								});	 
								
								axios({
									method: 'post',
									// headers: {  'Access-Control-Allow-Origin':'*' },
									crossdomain: true ,

									url: _store_url+'externalapi/execute/getPaymentTypes/key/'+OP_SECRET_KEY,
									data: dataSH //JSON.stringify(dataDelOrder)
								}).then(response => response.data).then(data => {
										var paymentTypes=[]
										console.log("paymentName",paymentName)
										var payId = 1
										for (var s in data.response){
											if (data.response[s].PAYMENTNAME == paymentName){
												payId =data.response[s].PAYMENTTYPEID 
												paymentTypes.push ({ value : data.response[s].PAYMENTTYPEID ,  text: data.response[s].PAYMENTNAME, type: data.response[s].TYPE , checked:true})
											}else{
												paymentTypes.push ({ value : data.response[s].PAYMENTTYPEID ,  text: data.response[s].PAYMENTNAME , type: data.response[s].TYPE } )	
											}
										} 
										this.setState({
											...this.state,
											paymentTypes: paymentTypes,
											payId: payId,
											loadingData:false,
										});	 
								})
						
						})
			
					})
			})




	}

	setProduct =  async(event) => {

		var id = (event.target.id).substr(5, 10);

		var task = this.state.searchResults[id];

		console.log(task)

		this.setState({
			...this.state,
			product_id:this.state.searchResults[id].product_id,
			productName: this.state.searchResults[id].name,
			productImageBig: this.state.searchResults[id].productImage,
			productPrice:parseFloat(this.state.searchResults[id].price),
			productImage: this.state.searchResults[id].thumb, 
			productModel:this.state.searchResults[id].productModel,
			productSKU:this.state.searchResults[id].productSKU,
			productQty:1,
			productMaxQty:this.state.searchResults[id].quantity,
			isExecuting:false,
			showProductsList:false,
			disabledAdd:false
		})

	};


	searchProduct=async() => {
		this.setState({ showProductsList:true, isExecuting:true, searchResults:[], disabledAdd:true});
		let url =  this.state.store_url+'customerapi/getFilterProductsElasticByKey/'+this.state.store_id+'/'+this.state.SKUcode;


		await axios.get (
			this.state.store_url+'customerapi/getFilterProductsElasticByKey/'+this.state.store_id+'/'+this.state.SKUcode,


			//{crossdomain: true }

		)
			/*
          await axios({
                 url: url,
                 method: 'get',
                 headers: {
                      'Access-Control-Allow-Origin': '*'
                    },
                     crossdomain: true

            })*/
			.then(response => response.data)
			.then(data => {
				if (data.status==="success" ){

					var results=[]

					for (var i=0; i<data.response.length; i++){
						results.push( {
								id: i,
								key:"key"+data.response[i].product_id,
								product_id:data.response[i].product_id,
								name:data.response[i].name,
								quantity:data.response[i].quantity,
								thumb:data.response[i].thumb,
								productSKU:data.response[i].productSKU===undefined?"-":data.response[i].productSKU,
								productModel:data.response[i].productModel===undefined?"-":data.response[i].productModel,
								productImage: data.response[i].thumb.replace("60x60","400x400"),
								price: parseFloat(data.response[i].price),
								special:data.response[i].special,
								selected:
									<>

									  											 <span  key={"table_"+i} id={"table_"+i}
																						onClick={this.setProduct} className="tableLink">
								<MDBIcon icon="check-circle" key={"icon_"+i} id={"icon_"+i}  size="2x" style={{"cursor":"pointer"}} className="green-text" />

									  											 </span></>
							}
						)

					}

					this.setState({
						...this.state,
						searchResults: results,
						isExecuting:false,
					});
					//console.log(this.state.searchResults)


				}else{
					this.setState({
						...this.state,
						product_id:0,
						productName: "NO DATA",
						productPrice:0,
						productQty:10,
						productMaxQty:0,
						isExecuting:false,
						disabledAdd:true
					});

				}
			})
	}
	toggleModal = nr => () => {

		let modalNumber = 'modal' + nr
		this.setState({
			[modalNumber]: !this.state[modalNumber]
		});
	}

	render() {
		return (
			<>




				{this.state.loadingData?

					<Loader idLoading={this.state.loadingData}/>
					:
					<>
						<Loader idLoading={this.state.isExecuting}/>






						<h3 style={{
							zIndex:"1000",
							fontWeight:"600",
							color:"#000"
						}}>  {"Pedido #"+this.state.order_id}</h3>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">SubTotal</MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2" className="text-right"><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.sub_total+" Bs."}</h6> </MDBCol>
						</MDBRow>
						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">Descuento</MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2" className="text-right"><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.descuento+" Bs."}</h6> </MDBCol>
						</MDBRow>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">Costo de Envio</MDBCol>
							<MDBCol size="12" sm="6"  md="3" lg="2" xl="2" className="text-right" ><h6 style={{
								zIndex:"1000",
								fontWeight:"400",
								color:"#000"
							}}>  {this.state.costo_envio+" Bs."}</h6> </MDBCol>
						</MDBRow>

						<MDBRow>
							<MDBCol size="12" sm="6" md="3" lg="2">	<h5 style={{
								zIndex:"1000",
								fontWeight:"500",
								color:"#000"
							}}>  Total:</h5></MDBCol>
							<MDBCol size="12" sm="6" md="3" lg="2" xl="2"  className="text-right">	<h5    style={{
								zIndex:"1000",
								fontWeight:"500",
								color:"#000"
							}}>  {this.state.monto_total+" Bs."}</h5></MDBCol>
						</MDBRow>



						{this.state.existingPreviousOrder?

							<MDBCard  style={{width: "98%"}} className="mb-3">


								<MDBRow className="align-items-left justify-content-left">
									<MDBCol md="12" >
										<MDBAlert      className="text-left align-top">
											<h3 className="h3-responsive font-weight-bold mr-3">
												<MDBIcon icon="exclamation-circle" className="mr-1"/>   El cliente {this.state.customerName} tiene un pedido aun no entregado
											</h3>
										</MDBAlert>
									</MDBCol>
								</MDBRow>

								<hr/>
								<MDBRow className="text-right">
									<MDBCol md="12" >
										<MDBBtn
											size="sm"
											color="primary"
											onClick={() => {
												this.setState({modal4:true })
											}}
										>		Revisar Pedido
										</MDBBtn>

										<MDBBtn
											size="sm"
											disabled={this.state.isDeleted}
											color="danger"
											onClick={() => {
												//	this.deleteShipping("1616");
												this.setState({modal5:true, mensaje:"eliminar la tarifa?", accion:"eliminar tarifa" })
											}}
										>		Eliminar Tarifa de envio
										</MDBBtn>
									</MDBCol>
								</MDBRow>

							</MDBCard>

							:""}

						{this.state.isSupervisor>0?
							<MDBRow className="text-right mt-1 mb-3">
								<MDBCol md="12" >


									<MDBBtn
										size="sm"
										color="success"
										onClick={() => {
											this.setState({modal3:true, disabledAdd:true, /*SKUcode:"" , */ productName:"", productPrice:"", productQty:"1" })
										}}
									>	<MDBIcon icon="cart-plus" />	Agregar Producto
									</MDBBtn>
								</MDBCol>
							</MDBRow>
							:""}
							
						{this.state.isAdmin>0?	
							<>
								<MDBRow className="my-4">
		              <MDBCol col= "7" sm="7" md="6" lg="4"  className="col-7 ml-2 mr-2 text-left left"> 
	                 <MDBInput
										style={{margin:'0'}}
										id={"pre_descuento" }
										key={"pre_descuento" }
										name={"pre_descuento"}
											value={this.state.pre_descuento}
											onChange={this.changeHandler}
											size="sm"
											label="Descuento"  />
		              </MDBCol>
		              
		              <MDBCol col= "3" sm="3" md="3" lg="1"  className="col-3 ml-2 mr-2 text-left left"> 
		                 	<MDBBtn
		                 	style={{marginLeft: '-40px'}}
											size="sm"
											color="primary"
											onClick={() => {
																this.setState({modal5:true, mensaje:"Actualizar descuento?", accion: "actualizar descuento" });
															}}
										>	 Actualizar
										</MDBBtn>
		              </MDBCol>
		        	  </MDBRow>
		        	  
								<MDBRow className="my-4">
		              <MDBCol col= "7" sm="7" md="6" lg="4"  className="col-7 ml-2 mr-2 text-left left"> 
		                  <MDBSelect
		                      
		                      key= "store"
		                      style={{"margin":0}}
		                      id="paymentType"
		                      color="primary" 
		                      getValue= {this.selectHandler2}
		                      options={this.state.paymentTypes} 
		                      label="Tipo de Pago" />
		              </MDBCol>
		              
		              <MDBCol col= "3" sm="3" md="3" lg="1"  className="col-3 ml-2 mr-2 text-left left"> 
		                 	<MDBBtn
		                 	style={{marginLeft: '-40px'}}
											size="sm"
											color="primary"
											onClick={() => {
																this.setState({modal5:true, mensaje:"Cambiar Tipo de Pago?", accion: "cambiar pago" });
															}}
										>	 Actualizar
										</MDBBtn>
		              </MDBCol>
		        	  </MDBRow>
		        	  
		        	  
		        	  <MDBRow className="my-4">
		              <MDBCol col= "7" sm="7" md="6" lg="4"  className="col-7 ml-2 mr-2 text-left left"> 
		                  <MDBSelect 
		                      key= "store"
		                      style={{"margin":0}}
		                      id="shippingType"
		                      color="primary" 
		                      getValue= {this.selectHandler1}
		                      options={this.state.shippingTypes} 
		                      label="Tipo de Envio" />
		              </MDBCol>
		              
		              <MDBCol col= "3" sm="3" md="3" lg="1"  className="col-3 ml-2 mr-2 text-left left"> 
		                 	<MDBBtn
		                 	style={{marginLeft: '-40px'}}
											size="sm"
											color="primary"
											onClick={() => {
																this.setState({modal5:true, mensaje:"Cambiar Tipo de Envio?", accion: "cambiar envio" });
															}}
										>	 Actualizar
										</MDBBtn>
		              </MDBCol>
		        	  </MDBRow>
		        	  <MDBRow className="my-4">
		              <MDBCol col= "7" sm="7" md="6" lg="4" className="col-7 ml-2 mr-2 text-left left"> 
	                 <MDBInput
									 
										id={"pre_costo_envio" }
										key={"pre_costo_envio" }
										name={"pre_costo_envio"}
											value={this.state.pre_costo_envio}
											onChange={this.changeHandler}
											size="sm"
											label="Costo de envio"  />
		              </MDBCol>
		              
		              <MDBCol col= "3" sm="3" md="3" lg="1" className="col-3 ml-2 mr-2 text-left left"> 
		                 	<MDBBtn
		                 	style={{marginLeft: '-40px'}}
											size="sm"
											color="primary"
											onClick={() => {
																this.setState({modal5:true, mensaje:"Actualizar costo de envio?", accion: "actualizar costo de envio" });
															}}
										>	 Actualizar
										</MDBBtn>
		              </MDBCol>
		        	  </MDBRow>
	        	  </>
	        	:""
		        }        
						{ this.state.loadingData?"": this.state.orders.map(event => (
							<p  key ={"event1"+event.id} style={{width: "98%"}}>
								<Event
									
									id={event.id}
									product_id={event.product_id}
									productName={event.productName}
									productQty={event.productQty}
									productPrice={event.productPrice}
									productModel={event.productModel}
									productImage={event.productImage}
									productSKU={event.productSKU}
									onPrice={this.handlePrice}
									handleChangePrice={this.handleChangePrice}
									state={event.state}
									isPicked={event.isPicked}
									onDelete={this.handleDelete}
									onPrice={this.handlePrice}
									onEdit= {this.handleEdit}
									onPick={this.handlePick}
									isSupervisor = { this.state.isSupervisor}
									isAdmin = {this.state.isAdmin}
								/>
							</p>
						))}


						<MDBModal cascading centered isOpen={this.state.modal4} toggle={this.toggleModal(4)} size="lg">

							<MDBModalBody >

								<span className="h2-responsive mb-2">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle del pedido Previo </span>

								<p>Nombre: {this.state.customerName}</p>
								<p>Direccion: {this.state.customerAdd}</p>
								<p>Zona: {this.state.customerZone}</p>
								{ this.state.loadingData?"": this.state.ordersPre.map(event => (
									<p  key={"event2"+event.id} style={{width: "98%"}}>
										<Event
											
											id={event.id}
											product_id={event.product_id}
											productName={event.productName}
											productQty={event.productQty}
											productPrice={event.productPrice}
											productModel={event.productModel}
											productImage={event.productImage}
											productSKU={event.productSKU}
											state={event.state}
											onDelete={null}
											isSupervisor = { this.state.isSupervisor}
											isAdmin= { this.state.isAdmin}

										/>
									</p>
								))}

							</MDBModalBody>
						</MDBModal>

						<MDBModal cascading centered isOpen={this.state.modal3} toggle={this.toggleModal(3)} size="lg">

							<MDBModalBody >

								<span className="h2-responsive mb-4">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar Producto</span>

								<>
									<MDBRow className="text-right">
										<MDBCol md="9" >
											<MDBInput
												id="SKUcode"
												key="SKUcode"
												name="SKUcode"
												value={this.state.SKUcode}
												onChange={this.changeHandler}
												size="sm"
												label="Escriba el nombre o SKU"  />


										</MDBCol>
										<MDBCol md="3" >
											<MDBBtn
												color="secondary"
												onClick={() => {
													this.searchProduct();
												}}
												className="mt-4"
												size="sm"
											>
												Buscar
											</MDBBtn>
										</MDBCol>
									</MDBRow>


									{this.state.showProductsList?
										<MDBTable
											responsive
											className="customTable" bordered striped
										>
											<MDBTableHead >
												<tr>

													<th>Imagen</th>
													<th>Producto</th>
													<th>Precio</th>
													<th>Precio Promocional</th>
													<th>Cantidad Disponible</th>
													<th>Seleccionar</th>
												</tr>
											</MDBTableHead>
											<MDBTableBody>

												{this.state.searchResults.map(item => (
													<tr key={"tr"+item.key}>
														<td key={"td"+item.key}>

															<img src={item.thumb} height="60px" width="60px" alt="thumbnail" className="img-thumbnail" />

														</td>
														<td>
															{item.name}
														</td>
														<td>
															{item.price}
														</td>
														<td>
															{!item.special?item.price:item.special}
														</td>
														<td>
															{item.quantity}
														</td>
														<td style={{"textAlign":"center"}}>
															{item.selected}
														</td>

													</tr>
												))
												}

											</MDBTableBody>
										</MDBTable>
										:

										<>

											<MDBRow className="text-left">
												<MDBCol md="12" >
													<span className="h4-responsive"> <b>Detalle del producto seleccionado</b> </span>
												</MDBCol>
											</MDBRow>

											<MDBRow className="text-right">
												<MDBCol md="7" >
													<img src={this.state.productImageBig} alt="thumbnail" className="img-thumbnail" />
												</MDBCol>


												<MDBCol md="5" >
													<MDBInput
														id="productName"
														label="Producto"
														readOnly={true}
														name="productName"
														value={this.state.productName} onChange={this.changeHandler} />

													<MDBInput
														id="productPrice"
														label="Precio"
														readOnly={true}
														name="productPrice"
														value={this.state.productPrice} onChange={this.changeHandler} />

													<MDBInput
														label="Cantidad"
														id="productQty"
														name="productQty"
														readonly
														type="number"
														min="1"
														max={this.state.productMaxQty}
														value={this.state.productQty} onChange={this.changeHandler} />



													<MDBBtn
														size="sm"
														outline
														color="danger"


														onClick={() => {
															this.setState({showProductsList:true,  /*SKUcode:"" , */ productName:"", productPrice:"", productQty:"1" })
														}}
													>	<MDBIcon icon="arrow-alt-circle-left" />	Volver
													</MDBBtn>

													<MDBBtn
														size="sm"
														color="success"
														disabled={this.state.disabledAdd}
														onClick={() => {
															this.setState({modal5:true, mensaje:"Agregar este producto?", accion: "agregar" });
														}}
													>	<MDBIcon icon="cart-plus" />	Agregar
													</MDBBtn>
												</MDBCol>

											</MDBRow>

										</>
									}

								</>
							</MDBModalBody>
						</MDBModal>


						<MDBModal cascading centered isOpen={this.state.modal5} toggle={this.toggleModal(5)} size="md">
							<MDBModalBody className="text-center">
								<span className="h4-responsive text-center" >Esta seguro de {this.state.mensaje}</span>
								<hr/>

								<MDBRow className="text-center">
									<MDBCol md="12" >

										<MDBBtnGroup size="sm">
											<MDBBtn outline
													onClick={() => {
														this.setState({modal5:false })
													}}
													color="danger" className="text-capitalize" > <span className="h4-responsive">Cancelar</span></MDBBtn>
											<MDBBtn
												onClick={() => {
													this.setState({modal5:false });
													if (this.state.accion==="agregar"){
														this.addOrder();
													}else if (this.state.accion==="eliminar tarifa"){
														this.deleteShipping(this.state.order_id);
													}else if (this.state.accion==="aumentar"){
														this.handleCommitEdit(this.state.id_producto, "Add");
													}else if (this.state.accion==="disminuir"){
														this.handleCommitEdit(this.state.id_producto, "Rem");
													}else if (this.state.accion==="cambiar"){
														this.handleCommitPrice(this.state.id_producto);
													}else if (this.state.accion==="cambiar envio"){
														this.changeShippingType();
													}else if (this.state.accion==="actualizar costo de envio"){
														this.changeShippingCost();
													}else if (this.state.accion==="actualizar descuento"){
														this.changeDiscount();
													}else if (this.state.accion==="cambiar pago"){
														this.changePaymentType();	
													}else{
														this.handleCommitDelete(this.state.id_producto);
													}



												}}
												color="success" className="text-capitalize"> <span className="h4-responsive">{this.state.accion}</span> </MDBBtn>
										</MDBBtnGroup>
									</MDBCol>
								</MDBRow>


							</MDBModalBody>
						</MDBModal>
					</>


				}

				<ToastContainer
					hideProgressBar={true}
					newestOnTop={false}
					position={"bottom-right"}
					autoClose={3000}
				/>

			</>
		);
	}
}

class Event extends React.Component {
	render() {
		return (
			this.props.state === "deleting"?
				<MDBCard
					style={{
						opacity: "0.4",
						backgroundColor: "#999"


					}}
					className="align-items-center justify-content-center">
					<p></p>
					<div className="media mt-4 align-middle">

						<div className="media-body mb-3 mb-lg-3">

							<MDBAnimation type="rotateOut" infinite >
								<MDBIcon style={{
									zIndex:"1050"

								}} size="2x" far icon="trash-alt" />
							</MDBAnimation>

						</div>

					</div>
					<p></p>
				</MDBCard>
				:<MDBCard className="align-items-left justify-content-left">
					<div className="media mt-1">
			            <span className="h4-responsive font-weight-bold mr-3">
							{this.props.isSupervisor >0?
								<MDBBadge
									color="danger"
									style={{"cursor":"pointer"}}
									className="ml-2 mt-1 mr-2 ml-4"

									onClick={() =>
										this.props.productQty===1?
											this.props.onDelete(this.props.id):
											this.props.onEdit (this.props.id, "disminuir")

									}


								>  -
								</MDBBadge> :""}
							{this.props.productQty}

							{this.props.isSupervisor >0?
								<MDBBadge
									color="success"
									style={{"cursor":"pointer"}}
									className="ml-2 mt-2"
									onClick={() => this.props.onEdit (this.props.id, "aumentar")}
								>  +
								</MDBBadge>:""}
							<br/>

			             <img src={this.props.productImage} alt="thumbnail" height="60px" width="60px" className="img-thumbnail" />
			             {this.props.onDelete===null?"":
					 	<p className="ml-1">
								<MDBBadge
									color="danger"
									style={{"cursor":"pointer"}}
									
									onClick={() => this.props.onDelete(this.props.id)}
								>
									Eliminar
								</MDBBadge> 	</p>}
			            </span>
						<div className="media-body mb-3 mb-lg-3">
							{this.props.onPick===null?"": 
							<> 
			            <MDBBadge
			                color= {this.props.isPicked=== -1 ?"grey":"success"  } 
			               
			                style={{"cursor":"pointer"}}
			                className="mr-1 float-right"
			                onClick={() => this.props.onPick(this.props.id)} 
			            >
			               <MDBIcon far={this.props.isPicked=== -1 ?false:true}  size="2x" icon={this.props.isPicked=== -1 ?"check-square":"check-square"} />
			               <span> Pick</span>
			            </MDBBadge>
			            </>
			            }
							<span className="h6 mt-0 font-weight-bold">{this.props.productName} </span>{" "}
							<hr className="hr-bold my-2" />
							{this.props.product_id && (
								<React.Fragment>
									<p className="font-smaller mb-0">

										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" >
												<MDBIcon icon="shopping-basket" /> Codigo: {this.props.productModel}
											</MDBCol>
										</MDBRow>
										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" >
												<MDBIcon icon="barcode" /> SKU: {this.props.productSKU}
											</MDBCol>
										</MDBRow>

									</p>
								</React.Fragment>
							)}
						</div>
					</div>
					{this.props.productQty && (
						<>
						<p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
							{"Cantidad: "+this.props.productQty+", Precio unitario: "+ this.props.productPrice  +"Bs. Total "+ parseFloat ( this.props.productPrice * (this.props.productQty),2 ).toFixed(2) +"Bs." }
						</p>
						{this.props.isAdmin>0?	
							<MDBRow>
								<MDBCol size="6" sm="6" md="3" lg="3" style={{
									'display': 'flex',
									'align-items': 'center',
									 'width':'100%'
								}} >
									<MDBInput
										style={{width:'100%'}}
										id={"SKUPRICE_"+this.props.productSKU}
										key={"SKUPRICE_"+this.props.productSKU}
										name={""+this.props.productSKU}
											value={this.props.productPrice}
											onChange={this.props.handleChangePrice}
											size="sm"
											label="Actualizar Precio"  />
								</MDBCol>
								<MDBCol size="6" sm="6" md="6" lg="6" style={{
									'display': 'flex',
									'align-items': 'center'
								}} >
									<MDBBtn
										disabled={this.props.productPrice==""?true:false }
										color="success"
										onClick={() => this.props.onPrice (this.props.id, "cambiar")}
										size="sm" >
										Actualizar
									</MDBBtn>				
								</MDBCol>
							</MDBRow>
							:""
						}
							
							
													
						</>
					)}
				</MDBCard>

		)
	}
}

export default externalForm07;

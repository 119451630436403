import React from "react";
import {
  MDBSelect,
  MDBDatePicker,
  MDBAlert,
  MDBTableHead,
  MDBTable,
  ToastContainer,
  MDBTableBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";

import { API_CONFIG } from "../../Config/config-env.js";
import { OP_SECRET_KEY } from "../../Config/config-env.js";
import Pdf from "react-to-pdf";
import axios from "axios";
const ref = React.createRef();

const options = {
  orientation: "landscape",
  size: 18,
  fontSize: 18,
  fontsize: 18,
};

var f = new Date();
var fromDate = new Date();
fromDate.setDate(f.getDate() - 30);
var mes =
  f.getUTCMonth() + 1 < 10 ? "0" + (f.getUTCMonth() + 1) : f.getUTCMonth() + 1;
var dia = f.getUTCDate() < 10 ? "0" + f.getUTCDate() : f.getUTCDate();
var date_label = f.getUTCFullYear() + "-" + mes + "-" + dia;

class reportArqueo3 extends React.Component {
  state = {
    loadingData: true,
    optionsT: [
      { value: 47, checked: true, text: "Envio a Domicilio" },
      { value: 85, text: "Recojo en Tienda" },
    ],
    options: [],
    deliveryDate: date_label,
    sectionId: "",
    userType: 47,
    storeName: "",
    city: "",
    SKU: "",
    orderskus: [],
    orders: [],
    rows1: [],
    noData: false,
    rows2: [],
    skuExists: "NO ENCONTRADO",
    skuState: "SIN INFORMACION",
    showResults: false,
    data: [],
    customerinfo: [],
    addinfo: [],
    to_date: new Date(),
    from_date: new Date(),
    fecha: "",
    montoEvento: 0,
  };

  componentDidMount = async () => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    let WO_Id = params.get("wfpflowid");
    var flowcode;
    let formButtons = [];
    let progress = 0;
    let isParent = false;
    let officecode = params.get("officecode");
    let _WOTaskId = params.get("wfptaskid").split("___");
    let WOTaskId = _WOTaskId[0];
    if (_WOTaskId.length === 2) {
      console.log("es multiple");
      isParent = true;
    }
    var eventId = 0;
    var montoEvento = 0;
    var ordersPicked = [];
    var nombre_recepcion = "";
    var arr = [];
    var orderskus = [];
    var validOrders = [];
    var isSupervisor, isAdmin, org, userId;
    var customerinfo = [];
    var addinfo = [];
    var regalos = "";
    let orders = [];
    let ordenes = [];
    var tipopedidos = [];
    var posicionracks = [];

    await axios({
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/decrypt-token",
      //url : "http://localhost:4042/api/v1"+"/engine-users/decrypt-token",
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.status) {
          userId = data.token.USERID;
          isSupervisor = data.token.USERGROUP === "SINGLEUSER" ? -1 : 1;
          isAdmin = data.token.USERGROUP === "ADMIN" ? 1 : -1;
          this.setState({
            ...this.state,
            username: data.token.USERNAME,
          });
        }
      });
var flowvars =[]
    await axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/get-task-details",
      //url : "http://localhost:4042/api/v1"+"/engine-users/get-task-details",
      data: {
        wfptaskid: WOTaskId,
        isParent: isParent,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("data", data);
        flowvars = data.flowvars
        if (data.task) {
          flowcode = data.task.FLOWCODE;
        }

        if (data.tasks) {
          flowcode = data.tasks[0].FLOWCODE;
        }

        if (data.status) {
          for (let v in data.flowvars) {
            let flowVar = data.flowvars[v];
            let order_id = "";
            let flowid = flowVar.WFPFLOWID;
            if (flowVar.VARNAME === "order_id") {
              order_id = flowVar.VARVALUE;

              let customer_id = "";
              let pedido_parcial = "";
              let mixed_skus = [];
              for (let v2 in data.flowvars) {
                let flowVar2 = data.flowvars[v2];

                this.setState({ [flowVar2.VARNAME]: flowVar2.VARVALUE });

                if (flowVar2.WFPFLOWID == flowid) {
                  if (flowVar2.VARNAME === "customer_id") {
                    customer_id = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME === "pedido_parcial") {
                    pedido_parcial = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME === "mixed_skus") {
                    try {
                      mixed_skus = JSON.parse(flowVar2.VARVALUE);
                    } catch (ex) {
                      console.log("EX", ex);
                    }
                  }

                  if (flowVar2.VARNAME === "eventId") {
                    eventId = flowVar2.VARVALUE;
                  }

                  if (flowVar2.VARNAME == "nombre_recepcion") {
                    nombre_recepcion = flowVar2.VARVALUE;
                  }
                }
              }
              orders.push({
                order_id,
                customer_id,
                pedido_parcial,
                mixed_skus,
              });
              ordenes.push({
                ID_HFLUJO: flowid,
                NOMBRE: "order_id",
                VALOR: order_id,
              });
              validOrders.push(order_id);
            }

            if (flowVar.VARNAME === "tipo_pedido") {
              tipopedidos.push({
                ID_HFLUJO: flowid,
                NOMBRE: "tipo_pedido",
                VALOR: flowVar.VARVALUE,
              });
            }

            if (flowVar.VARNAME === "posicion_rack") {
              posicionracks.push({
                ID_HFLUJO: flowid,
                NOMBRE: "posicion_rack",
                VALOR: flowVar.VARVALUE,
              });
            }

            if (flowid == WO_Id) {
              if (flowVar.VARNAME === "ordersPicked") {
                ordersPicked = (flowVar.VARVALUE + "").split(",");
              }
              if (flowVar.VARNAME === "progress") {
                progress = parseInt(flowVar.VARVALUE);
              }
            }
          }
        }
      });

    var dataE = {
      eventId: eventId,
      username: this.state.username,
    };
    var storeName = "";
    var city = "";
    await axios({
      method: "post",
      // headers: {  'Access-Control-Allow-Origin':'*' },
      crossdomain: true,

      url:
        API_CONFIG.casaideas +
        "/externalapi/execute/getOrderByEventId/key/" +
        OP_SECRET_KEY,
      data: dataE,
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.success) {
          storeName = data.response.event_info.storeName;

          var d = data.response.customer_info[0];
          customerinfo.push({ name: "nombre", value: d.firstname });
          customerinfo.push({ name: "apellido", value: d.alstname });
          customerinfo.push({ name: "Email", value: d.email });
          customerinfo.push({ name: "Telefono", value: d.phone });
          customerinfo.push({ name: "Documento", value: d.numdoc });

          var add = data.response.event_info.eventInfo.directions;
          regalos = data.response.event_info.eventInfo.regalosenvueltos;

          addinfo.push({ name: "Ciudad", value: add.city });
          addinfo.push({ name: "Direccion", value: add.address });
          addinfo.push({ name: "Indicaciones", value: add.reference });
          addinfo.push({ name: "Latitud", value: add.latitude });
          addinfo.push({ name: "Longitud", value: add.longitude });

          try {
            city = data.response.event_info.eventInfo.directions.city;
          } catch (Exc) {
            console.log("error getting city", Exc);
          }

          console.log("#orde", data.response.order)
          for (var key in data.response.order) {
            let order = data.response.order[key];
            let isPicked =
              ordersPicked.indexOf(order.ORDERID + "") >= 0 ? 1 : -1;
            let products = [];
            montoEvento = montoEvento + (order.TOTAL ? order.TOTAL : 0);
            data.response.products.map(function (prod) {
              if (order.ORDERID == prod.order_id) {
                products.push(prod);
              }
            });
            if (validOrders.indexOf(order.ORDERID + "") >= 0) {
              arr.push({
                id: key,
                orderid: order.ORDERID + "",
                fecha: order.DATEADDED,
                total: order.TOTAL,
                storename : order.STORENAME,
                nombre: order.FIRSTNAME ? order.FIRSTNAME : "-",
                apellido: order.LASTNAME ? order.LASTNAME : "-",
                isPicked: isPicked,
                products: products,
              });
            }
          }
          var datosOrdenes = [];

          tipopedidos.map(function (tipo) {
            var orderid;
            var tipopedido = tipo.VALOR;
            var flujo = tipo.ID_HFLUJO;

            ordenes.map(function (orden) {
              if (flujo == orden.ID_HFLUJO) {
                orderid = orden.VALOR;
              }
            });
            datosOrdenes.push({
              flujo: flujo,
              orderid: orderid,
              tipopedido: tipopedido,
            });
          });
          //console.log("datosOrdenes", datosOrdenes);

          data.response.orderDetails.map(function (sku) {
            let ordersku = sku;
            for (var key in data.response.order) {
              if (sku.ORDERID == data.response.order[key].ORDERID) {
                ordersku["DATEADDED"] = data.response.order[key].DATEADDED;
                ordersku["FIRSTNAME"] = data.response.order[key].FIRSTNAME;
                ordersku["LASTNAME"] = data.response.order[key].LASTNAME;
                ordersku["TOTAL"] = data.response.order[key].TOTAL;
                ordersku["EVENTID"] = eventId;
              }
            }
            var tipoPedido = "";
            datosOrdenes.map(function (orden) {
              if (sku.ORDERID == orden.orderid) {
                tipoPedido = orden.tipopedido;
              }
            });
            ordersku["ORDERTYPE"] = tipoPedido;
            //console.log(ordersku)
            if (ordersku.ORDERTYPE.toUpperCase().indexOf("NO xflow") < 0) {
              if (validOrders.indexOf(sku.ORDERID + "") >= 0) {
                let pos = -1;
                orderskus.map(function (key, item) {
                  if (key["SKU"] == ordersku["SKU"]) {
                    pos = item;
                  }
                });
                if (pos >= 0) {
                  orderskus[pos]["QUANTITY"] =
                    orderskus[pos]["QUANTITY"] + ordersku["QUANTITY"];
                } else {
                  orderskus.push({
                    SKU: ordersku["SKU"],
                    PRODUCTNAME: ordersku["PRODUCTNAME"],
                    QUANTITY: ordersku["QUANTITY"],
                  });
                }
              }
            }
          });

          orderskus = [];
          data.response.orderDetails.map(function (sku) {
            let pos = data.response.order.findIndex( (item) => item.ORDERID === sku.ORDERID);
 
            let storename =data.response.order[pos].STORENAME
            let flowId = 0
            for (let i in flowvars){
              let v= flowvars[i]
              if (v.VARNAME ==="order_id" && v.VARVALUE.toString() === sku["ORDERID"].toString() ){
                 flowId = flowvars[i].WFPFLOWID                
              }
            }
 
            let trx=""
            for (let i in flowvars){
              let v= flowvars[i]
              if (v.WFPFLOWID  === flowId && (v.VARNAME).toUpperCase() === "TRX"){
                trx = v.VARVALUE
              }
            }

            let store=""
            for (let i in flowvars){
              let v= flowvars[i]
              if (v.WFPFLOWID  === flowId && v.VARNAME === "store"){
                store = v.VARVALUE
              }
            }

            let sendstore=""
            for (let i in flowvars){
              let v= flowvars[i]
              if (v.WFPFLOWID  === flowId && v.VARNAME === "sendstore"){
                sendstore = v.VARVALUE
              }
            }
            if (flowId===0){
              sendstore="CLIENTE"
            }

            orderskus.push({
              ORDERID: sku["ORDERID"],
              SKU: sku["SKU"],
              PRODUCTNAME: sku["PRODUCTNAME"],
              QUANTITY: sku["QUANTITY"],
              STORENAME:storename,
              STORE:store,
              TRX:trx,
              sendstore:sendstore
            });
          });
        }
      });

    orderskus = orderskus.sort(function (a, b) {
      return parseInt(a.SKU) - parseInt(b.SKU);
    });

    //console.log("orderskus",orderskus,tipopedidos, ordenes )

    //console.log("orderskus", orderskus);
    if (orderskus.length <= 0) {
      this.setState({ ...this.state, noData: true });
    } else {
      this.setState({ ...this.state, showResults: true });
    }

    this.setState({
      ...this.state,
      loadingData: false,
      orderskus: orderskus,
      store_url: API_CONFIG.casaideas,
      storeName: storeName,
      city: city,
      customerinfo: customerinfo,
      addinfo: addinfo,
      montoEvento: montoEvento,
      orders: arr,
      regalos: regalos,
    });
  };

  render() {
    return (
      <>
        <Loader idLoading={this.state.loadingData} />
        <MDBContainer
          fluid
          className="justify-content-left text-left "
          style={{ marginLeft: "0" }}
        >
          <Loader idLoading={this.state.isExecuting} />

          <h3
            style={{
              zIndex: "1000",
              fontWeight: "600",
              color: "#000",
              rginLeft: "0.5rem",
            }}
          >
            {" "}
            Datos del Evento
          </h3>

          <MDBRow className="pl-2">
            <MDBCol sm="12" style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              Datos del Cliente
            </MDBCol>
          </MDBRow>
          <MDBRow className="pl-2">
            {this.state.customerinfo.map((c) => (
              <MDBCol sm="6">
                <MDBInput
                  value={c.value}
                  disabled={true}
                  label={c.name}
                ></MDBInput>
              </MDBCol>
            ))}
          </MDBRow>

          <MDBRow className="pl-2">
            <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
              C&oacute;digo Evento:
            </MDBCol>
            <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {this.state.eventId}
              </span>
            </MDBCol>
          </MDBRow>

          <MDBRow className="pl-2">
            <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
              Nombre Evento:
            </MDBCol>
            <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {this.state.eventName}
              </span>
            </MDBCol>
            <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
              Fecha del Evento:
            </MDBCol>
            <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {this.state.eventDate}
              </span>
            </MDBCol>
          </MDBRow>

          <MDBRow className="pl-2">
            <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
              Tipo de Evento:
            </MDBCol>
            <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {this.state.eventType}
              </span>
            </MDBCol>
            <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
              Fecha Fin Recepcion Regalos:
            </MDBCol>
            <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {this.state.eventEndDate}
              </span>
            </MDBCol>
          </MDBRow>

          <MDBRow className="pl-2">
            <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
              Cantidad de Pedidos:
            </MDBCol>
            <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {/*this.state.orders.length*/}
              </span>
            </MDBCol>
            <MDBCol sm="6" md="3" lg="2" style={{ fontSize: "1.25rem" }}>
              Monto Total:
            </MDBCol>
            <MDBCol sm="6" md="3" lg="4" style={{ fontSize: "1.25rem" }}>
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                {this.state.montoEvento + "Bs."}
              </span>
            </MDBCol>
          </MDBRow>

          {/*
							   	<MDBRow className="pl-2" > 
							   		 
										<MDBCol sm="6" md="3" lg="2" style={{fontSize:"1.25rem"}} >
											Nombre de quien recibe los regalos:
										</MDBCol>  
										<MDBCol sm="6" md="3" lg="4" style={{fontSize:"1.25rem"}} >
										 
											<span style={{ 
														   	 	fontWeight:"600", 
														   	}}> 
											 {this.state.nombre_recepcion}
											</span>
										</MDBCol>  

										<MDBCol sm="6" md="3" lg="2" style={{fontSize:"1.25rem"}} >
											Regalos envueltos:
										</MDBCol>  
										<MDBCol sm="6" md="3" lg="4" style={{fontSize:"1.25rem"}} >
										
											<span style={{ 
																fontWeight:"600", 
															}}> 
											{this.state.regalos}
											</span>
										</MDBCol>  
									 
							   	</MDBRow> 
							   	*/}

          <MDBRow className="pl-2">
            <MDBCol sm="12" style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              Datos de Direccion
            </MDBCol>
          </MDBRow>
          <MDBRow className="pl-2">
            {this.state.addinfo.map((c) => (
              <MDBCol sm="12">
                <MDBInput
                  value={c.value}
                  disabled={true}
                  label={c.name}
                ></MDBInput>
              </MDBCol>
            ))}
          </MDBRow>

          <br></br>

          {this.state.noData ? (
            <>
              {" "}
              <br />
              <MDBAlert color="warning" dismiss>
                No se encontraron datos para el periodo seleccionado
              </MDBAlert>
            </>
          ) : (
            ""
          )}
          {this.state.showResults ? (
            <>
              <MDBRow>
                <MDBCol
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  className="text-left justify-content-left"
                >
                  <div
                    style={{
                      width: "1080px",
                    }}
                    className="justify-content-left text-left"
                  >
                    <Pdf
                      options={options}
                      targetRef={ref}
                      filename={
                        "NotaEntrega.Evento." + this.state.eventName + ".pdf"
                      }
                    >
                      {({ toPdf }) => (
                        <MDBBtn
                          key="find"
                          size="sm"
                          color="success"
                          onClick={toPdf}
                        >
                          Generar Formulario
                        </MDBBtn>
                      )}
                    </Pdf>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  className="text-left justify-content-left"
                >
                  <div
                    ref={ref}
                    style={{ width: "1100px", fontSize: "1rem" }}
                    className="py-5 px-5"
                  >
                    <MDBTable
                      id="table1"
                      small
                      responsive
                      className="tableBlue no-spacing"
                    >
                      <MDBTableHead style={{ fontSize: "1rem" }}>
                        <tr
                          style={{
                            backgroundColor: "#aa9f8e",
                            border: "1px solid #aa9f8e",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                        >
                          <td colSpan="2" style={{ fontSize: "1rem" }}>
                            {" "}
                            NOTA DE ENTREGA{" "}
                          </td>
                          <td colSpan="2" style={{ fontSize: "1rem" }}></td>
                          <td colSpan="2" style={{ fontSize: "1rem" }}>
                            CIUDAD
                          </td>
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "#aa9f8e",
                            border: "1px solid #aa9f8e",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                        >
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              border: "1px solid #aa9f8e",
                              color: "#fff",
                              fontSize: "1rem",
                            }}
                          >
                            EVENTO:
                          </td>
                          <td
                            style={{
                              backgroundColor: "#fff",
                              color: "#000",
                              fontWeight: "bold",
                              fontSize: "1rem",
                              textAlign: "left",
                            }}
                            colSpan="3"
                          >
                            {this.state.eventName}
                          </td>
                          <td
                            colSpan="2"
                            style={{
                              backgroundColor: "#fff",
                              color: "#000",
                              fontWeight: "bold",
                              fontSize: "1rem",
                              borderLeft: "1px solid #fff",
                              borderRight: "1px solid #e2e5e9",
                              textAlign: "center",
                              fontSize: "1rem",
                            }}
                          >
                            {this.state.city}
                          </td>
                        </tr>
                        <tr style={{ textAlign: "center" }}>
                          <td
                            colSpan="4"
                            style={{
                              backgroundColor: "#fff",
                              borderTop: "1px solid #e2e5e9",
                              borderLeft: "1px solid #e2e5e9",
                              borderRight: "1px solid #e2e5e9",
                            }}
                          ></td>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              border: "1px solid #aa9f8e",
                              color: "#fff",
                              fontSize: "1rem",
                            }}
                          >
                            Fecha Evento:
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid #e2e5e9",
                              fontSize: "1rem",
                            }}
                          >
                            {this.state.eventDate}
                          </td>
                        </tr>
                        <tr style={{ textAlign: "center" }}>
                          <td
                            colSpan="4"
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid #fff",
                              borderLeft: "1px solid #e2e5e9",
                              borderRight: "1px solid #e2e5e9",
                            }}
                          ></td>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              border: "1px solid #aa9f8e",
                              color: "#fff",
                              fontSize: "1rem",
                            }}
                          >
                            Fecha Entrega:
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid #e2e5e9",
                              fontSize: "1rem",
                            }}
                          >
                            {this.state.deliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "#aa9f8e",
                            border: "1px solid #aa9f8e",
                          }}
                        >
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              border: "1px solid #aa9f8e",
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "1rem",
                              fontSize: "1rem",
                            }}
                            colSpan="3"
                          >
                            INFORMACION ORIGEN DEL ENVIO
                          </td>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              border: "1px solid #aa9f8e",
                              color: "#fff",
                              textAlign: "left",
                              fontSize: "1rem",
                            }}
                            colSpan="3"
                          >
                            INFORMACION DESTINO DEL ENVIO
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              border: "1px solid #aa9f8e",
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "1rem",
                            }}
                          >
                            TIENDA:
                          </td>
                          <td
                            style={{
                              fontSize: "1rem",
                              display: "flex",
                              height: 50,
                              alignItems: "center",
                            }}
                          >
                            {this.state.storeName}
                          </td>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              border: "1px solid #aa9f8e",
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "1rem",
                            }}
                          >
                            DIRECCION:
                          </td>
                          <td
                            style={{
                              fontSize: "1rem",
                              border: "1px solid #e2e5e9",
                            }}
                            colSpan="3"
                          >
                            {" "}
                            {this.state.eventAddress}
                          </td>
                        </tr>

                        <tr>
                          <td
                            colSpan="6"
                            style={{ backgroundColor: "#fff", height: 10 }}
                          ></td>
                        </tr>
                        <tr
                          style={{
                            backgroundColor: "#aa9f8e",
                            border: "1px solid #aa9f8e",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                        >
                          <td style={{ width: "10%", fontSize: "1rem" }}>
                            Tienda Registro:
                          </td>


                          <td style={{ width: "10%", fontSize: "1rem" }}>
                            ORDERID:
                          </td>
                          
                          <td style={{ width: "5%", fontSize: "1rem" }}>
                            TRX:
                          </td>
                          <td style={{ width: "15%", fontSize: "1rem" }}>
                            SKU:
                          </td>
                          <td style={{ width: "30%", fontSize: "1rem" }}>
                            Descripcion
                          </td>
                          <td style={{ width: "5%", fontSize: "1rem" }}>
                            Unidades
                          </td>
                          <td
                            style={{ width: "30%", fontSize: "1rem" }}
                            colSpan="2"
                          >
                            {" "}
                            Observaciones
                          </td>
                          <td style={{ width: "10%", fontSize: "1rem" }}>
                            Check
                          </td>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {this.state.orderskus.map((sku) => (sku.sendstore !=="CLIENTE" &&
                          <tr>


                            <td style={{ border: "1px solid #000" }}>
                              {" "}
                              {sku.STORE || sku.STORENAME}{" "}
                            </td>
                            <td style={{ border: "1px solid #000" }}>
                              {" "}
                              {sku.ORDERID}{" "}
                            </td>

                            <td style={{ border: "1px solid #000" }}>
                              {" "}
                              {sku.TRX}{" "}
                            </td>

                            <td style={{ border: "1px solid #000" }}>
                              {" "}
                              {sku.SKU}{" "}
                            </td>
                            <td style={{ border: "1px solid #000" }}>
                              {" "}
                              {sku.PRODUCTNAME}
                            </td>
                            
                            <td
                              style={{
                                border: "1px solid #000",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              {sku.QUANTITY}
                            </td>
                            <td
                              style={{ border: "1px solid #000" }}
                              colSpan="2"
                            >
                              {" "}
                            </td>
                            <td style={{ border: "1px solid #000" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  height: 25,
                                }}
                              >
                                <input
                                  checked={false}
                                  value="YES"
                                  type="checkbox"
                                />{" "}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </MDBTableBody>
                    </MDBTable>

                    <MDBTable
                      id="table1"
                      small
                      responsive
                      style={{ border: "0" }}
                      nobordered
                      className="transparent"
                    >
                      <MDBTableHead style={{ fontSize: "1rem" }}>
                        <tr style={{ height: 20 }}>
                          <td style={{ width: "16.6%", fontSize: "1rem" }}>
                            {" "}
                          </td>
                          <td style={{ width: "16.6%", fontSize: "1rem" }}>
                            {" "}
                          </td>
                          <td style={{ width: "16.6%", fontSize: "1rem" }}>
                            {" "}
                          </td>
                          <td style={{ width: "16.6%", fontSize: "1rem" }}>
                            {" "}
                          </td>
                          <td style={{ width: "16.6%", fontSize: "1rem" }}>
                            {" "}
                          </td>
                          <td style={{ width: "16.6%", fontSize: "1rem" }}>
                            {" "}
                          </td>
                        </tr>

                        <tr>
                          <td>ENTREGADO POR: </td>
                          <td
                            colSpan="2"
                            style={{ borderBottom: "2px solid #000" }}
                          ></td>
                          <td>RECIBIDO POR: </td>
                          <td
                            colSpan="2"
                            style={{ borderBottom: "2px solid #000" }}
                          ></td>
                        </tr>

                        <tr>
                          <td> </td>
                          <td colSpan="2" style={{ textAlign: "center" }}>
                            Firma
                          </td>
                          <td> </td>
                          <td colSpan="2" style={{ textAlign: "center" }}>
                            Firma
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              color: "#fff",
                              border: "1px solid #000",
                            }}
                          >
                            NOMBRE:{" "}
                          </td>
                          <td
                            colSpan="2"
                            style={{ border: "1px solid #000" }}
                          ></td>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              color: "#fff",
                              border: "1px solid #000",
                            }}
                          >
                            NOMBRE:{" "}
                          </td>
                          <td
                            colSpan="2"
                            style={{ border: "1px solid #000" }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              color: "#fff",
                              border: "1px solid #000",
                            }}
                          >
                            C.I.{" "}
                          </td>
                          <td
                            colSpan="2"
                            style={{ border: "1px solid #000" }}
                          ></td>
                          <td
                            style={{
                              backgroundColor: "#aa9f8e",
                              color: "#fff",
                              border: "1px solid #000",
                            }}
                          >
                            C.I.{" "}
                          </td>
                          <td
                            colSpan="2"
                            style={{ border: "1px solid #000" }}
                          ></td>
                        </tr>
                      </MDBTableHead>
                    </MDBTable>
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          ) : (
            ""
          )}

          <ToastContainer
            hideProgressBar={true}
            newestOnTop={false}
            position={"bottom-right"}
            autoClose={3000}
          />
        </MDBContainer>
      </>
    );
  }
}
export default reportArqueo3;

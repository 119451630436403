import React from "react";
import {MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_CONFIG} from '../../Config/config-env.js';
import axios from 'axios';

class externalForm01 extends React.Component {

	state = {
		id_producto:-1,
		mensaje:"",
		accion:"",
		isDeleted:false,
		productQty:1,
		productName:"",
		SKUcode:"3212567000066",
		productPrice:0,
		existingPreviousOrder:false,
		isExecuting:false,
		processing: "Procesando",
		ordersPre:[
			{id:0,
				product_id: 111,
				productName:"Manta",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded" },
			{id:1,
				product_id: 112,
				productName:"Juego de Tazas",
				productPrice:"50Bs.",
				productQty:"1",
				state: "loaded"
			}
		],
		orders:[],
		WO_Id:"",
		isLoadedTable:false,
		loadingData:true,
		modal4: false,
		modal3: false,
		order_id:"",
		orderName:"",
		orderPrice:"Bs.50",
		orderQty:"1",
		customerName:"",
		customerAdd:"",
		customerZone:"",
		productMaxQty:0,
		disabledAdd:true

	};

	componentDidMount=()=>{
		this.LoadTable();
	}

	changeHandler = event => {
		console.log(event.target.name)
		console.log(event.target.value)
		this.setState({ ...this.state, [event.target.name]: [event.target.value] });
	};



	LoadTable=async() => {
		let WO_Id = this.props.match.params.WO_Id;
		let WOTaskId = this.props.match.params.WOTaskId;
		let org ="CASA_IDEAS";// this.props.match.params.org;
		this.setState({ isLoadedTable: false,WO_Id:WO_Id, loadingData:true });
		//let idTask = this.props.match.params.idTask;
		let url = API_CONFIG.base_url+'/externalApi/getOrderProductsV2/'+WO_Id+"/"+WOTaskId+"/"+org;

		let nombre_cliente;
		let direccion_1;
		let zona;
		await axios({
			url: url,
			method: 'get',
			headers: { 'apikey': 'wayslo1506103c404d6dccaf787e44cf123456789' },
		})
			.then(response => response.data)
			.then(data => {

				var arr=[]
				var i=0

				if (data.resp==="OK"  && data.rows.length >0){
					console.log(data.resp);
					var arrayPicked = []


					for (var key in data.rows){

						let img = data.rows[key].image===null?"-": (data.rows[key].image).replace(".jpg","-60x60.jpg")
						arr.push(
							{ id:key,
								product_id: data.rows[key].product_id,
								productName:data.rows[key].name,
								productModel:data.rows[key].model,
								productPrice:data.rows[key].price,
								productQty: data.rows[key].quantity,
								productImage: data.store_url+"image/cache/"+img,
								productSKU : data.rows[key].sku,
								state: "loaded"
							});
					}
				}
				this.setState({
					...this.state,
					orders: arr ,
					isLoadedTable:true,
					customerName:data.nombre_cliente,
					customerAdd:data.direccion_1,
					customerZone:data.zone,
					customer_id:data.customer_id,
					loadingData:false,
					existingPreviousOrder: false,/*revisar*/
					isDeleted: false, /*revisar*/
					order_id:data.order_id /*revisar*/
				});


			})
	}

	toggleModal = nr => () => {

		let modalNumber = 'modal' + nr
		this.setState({
			[modalNumber]: !this.state[modalNumber]
		});
	}

	render() {
		return (
			<>




				{this.state.loadingData?

					<Loader idLoading={this.state.loadingData}/>
					:
					<>
						<Loader idLoading={this.state.isExecuting}/>

						<h3 style={{
							zIndex:"1000",
							fontWeight:"600",
							color:"#000"
						}}> {"Pedido #"+this.state.order_id}</h3>

						{this.state.existingPreviousOrder?

							<MDBCard  style={{width: "98%"}} className="mb-3">


								<MDBRow className="align-items-left justify-content-left">
									<MDBCol md="12" >
										<MDBAlert      className="text-left align-top">
											<h3 className="h3-responsive font-weight-bold mr-3">
												<MDBIcon icon="exclamation-circle" className="mr-1"/>   El cliente {this.state.customerName} tiene un pedido aun no entregado
											</h3>
										</MDBAlert>
									</MDBCol>
								</MDBRow>

								<hr/>
								<MDBRow className="text-right">
									<MDBCol md="12" >
										<MDBBtn
											size="sm"
											color="primary"
											onClick={() => {
												this.setState({modal4:true })
											}}
										>		Revisar Pedido
										</MDBBtn>

										<MDBBtn
											size="sm"
											disabled={this.state.isDeleted}
											color="danger"
											onClick={() => {
												//	this.deleteShipping("1616");
												this.setState({modal5:true, mensaje:"eliminar la tarifa?", accion:"eliminar tarifa" })
											}}
										>		Eliminar Tarifa de envio
										</MDBBtn>
									</MDBCol>
								</MDBRow>

							</MDBCard>

							:""}


						{this.state.orders.map(event => (
							<p  key ={"event1"+event.id} style={{width: "98%"}}>
								<Event

									id={event.id}
									product_id={".000"+event.product_id}
									productName={event.productName}
									productQty={event.productQty}
									productPrice={event.productPrice}
									productModel={event.productModel}
									productImage={event.productImage}
									productSKU={event.productSKU}

									state={event.state}
									onDelete={null}
									onEdit={null}
									onPick={this.handlePick}
								/>
							</p>
						))}


						<MDBModal cascading centered isOpen={this.state.modal4} toggle={this.toggleModal(4)} size="lg">

							<MDBModalBody >

								<span className="h2-responsive mb-2">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Detalle del pedido Previo </span>

								<p>Nombre: {this.state.customerName}</p>
								<p>Direccion: {this.state.customerAdd}</p>
								<p>Zona: {this.state.customerZone}</p>
								{this.state.ordersPre.map(event => (
									<p  key={"event2"+event.id} style={{width: "98%"}}>
										<Event

											id={event.id}
											product_id={event.product_id}
											productName={event.productName}
											productQty={event.productQty}
											productPrice={event.productPrice}
											productModel={event.productModel}
											productImage={event.productImage}
											productSKU={event.productSKU}

											state={event.state}
											onDelete={null}
											onEdit={null}
											onPick={null}
										/>
									</p>
								))}

							</MDBModalBody>
						</MDBModal>

						<MDBModal cascading centered isOpen={this.state.modal3} toggle={this.toggleModal(3)} size="md">

							<MDBModalBody >

								<span className="h2-responsive mb-4">  <MDBIcon icon="cart-arrow-down" className="mr-1" /> Agregar Producto</span>


								<MDBRow className="text-right">
									<MDBCol md="9" >
										<MDBInput
											id="SKUcode"
											key="SKUcode"
											name="SKUcode"
											value={this.state.SKUcode}
											onChange={this.changeHandler}
											size="sm"
											label="SKU"  />


									</MDBCol>
									<MDBCol md="3" >
										<MDBBtn
											color="secondary"
											onClick={() => {
												this.searchProduct();
											}}
											className="mt-4"
											size="sm"
										>
											Buscar
										</MDBBtn>
									</MDBCol>
								</MDBRow>

								<MDBRow className="text-right">
									<MDBCol md="12" >
										<MDBInput
											id="productName"
											label="Producto"
											readOnly={true}
											name="productName"
											value={this.state.productName}
											onChange={this.changeHandler} />
									</MDBCol>
								</MDBRow>

								<MDBRow className="text-right">
									<MDBCol md="6" >
										<MDBInput
											id="productPrice"
											label="Precio"
											readOnly={true}
											name="productPrice"
											value={this.state.productPrice}
											onChange={this.changeHandler} />
									</MDBCol>

									<MDBCol md="6" >
										<MDBInput
											label="Cantidad"
											id="productQty"
											name="productQty"
											readonly
											type="number"
											min="1"
											max={this.state.productMaxQty}
											value={this.state.productQty}
											onChange={this.changeHandler} />
									</MDBCol>

								</MDBRow>

								<MDBRow className="text-right">

									<MDBCol md="12" >
										<MDBBtn
											size="sm"
											color="success"
											disabled={this.state.disabledAdd}
											onClick={() => {
												this.setState({modal5:true, mensaje:"Agregar este producto?", accion: "agregar" });
											}}
										>	<MDBIcon icon="cart-plus" />	Agregar
										</MDBBtn>
									</MDBCol>
								</MDBRow>


							</MDBModalBody>
						</MDBModal>


						<MDBModal cascading centered isOpen={this.state.modal5} toggle={this.toggleModal(5)} size="md">
							<MDBModalBody className="text-center">
								<span className="h4-responsive text-center" >Esta seguro de {this.state.mensaje}</span>
								<hr/>

								<MDBRow className="text-center">
									<MDBCol md="12" >

										<MDBBtnGroup size="sm">
											<MDBBtn outline
													onClick={() => {
														this.setState({modal5:false })
													}}
													color="danger" className="text-capitalize" > <span className="h4-responsive">Cancelar</span></MDBBtn>
											<MDBBtn
												onClick={() => {
													this.setState({modal5:false });
													if (this.state.accion==="agregar"){
														this.addOrder();
													}else if (this.state.accion==="eliminar tarifa"){
														this.deleteShipping(this.state.order_id);
													}else if (this.state.accion==="aumentar"){
														this.handleCommitEdit(this.state.id_producto, "Add");
													}else if (this.state.accion==="disminuir"){
														this.handleCommitEdit(this.state.id_producto, "Rem");
													} else{
														this.handleCommitDelete(this.state.id_producto);
													}



												}}
												color="success" className="text-capitalize"> <span className="h4-responsive">{this.state.accion}</span> </MDBBtn>
										</MDBBtnGroup>
									</MDBCol>
								</MDBRow>


							</MDBModalBody>
						</MDBModal>
					</>


				}

				<ToastContainer
					hideProgressBar={true}
					newestOnTop={false}
					position={"bottom-right"}
					autoClose={3000}
				/>

			</>
		);
	}
}

class Event extends React.Component {
	render() {
		return (
			this.props.state === "deleting"?
				<MDBCard
					style={{
						opacity: "0.4",
						backgroundColor: "#999"


					}}
					className="align-items-center justify-content-center">
					<p></p>
					<div className="media mt-4 align-middle">

						<div className="media-body mb-3 mb-lg-3">

							<MDBAnimation type="rotateOut" infinite >
								<MDBIcon style={{
									zIndex:"1050"

								}} size="2x" far icon="trash-alt" />
							</MDBAnimation>

						</div>

					</div>
					<p></p>
				</MDBCard>
				:<MDBCard className="">
					<div className="media mt-1">
			            <span className="h4-responsive font-weight-bold mr-3 align-items-right justify-content-right">
			              {this.props.productQty}
							<br/>


			         <img src={this.props.productImage} alt="thumbnail" className="img-thumbnail" />

			            </span>
						<div className="media-body mb-3 mb-lg-3">

							<span className="h6 mt-0 font-weight-bold">{this.props.productName} </span>{" "}
							<hr className="hr-bold my-2" />
							{this.props.product_id && (
								<React.Fragment>
									<p className="font-smaller mb-0">

										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" >
												<MDBIcon icon="shopping-basket" /> Codigo: {this.props.productModel}
											</MDBCol>
										</MDBRow>
										<MDBRow className="align-items-left justify-content-left">
											<MDBCol md="12" >
												<MDBIcon icon="barcode" /> SKU: {this.props.productSKU}
											</MDBCol>
										</MDBRow>

									</p>
								</React.Fragment>
							)}
						</div>
					</div>
					{this.props.productQty && (
						<p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
							{"Cantidad: "+this.props.productQty+", Precio unitario: "+this.props.productPrice +", Total "+  (parseInt( (this.props.productPrice).replace("Bs.","") ) *parseInt(this.props.productQty)) +"Bs." }
						</p>
					)}
				</MDBCard>

		);
	}
}

export default externalForm01;

const API_CONFIG =
  process.env.NODE_ENV === "production"
    ? {
        base_url: process.env.REACT_APP_API_CONFIG,
        xflowurl: process.env.REACT_APP_API_CONFIG,
        casaideas: process.env.REACT_APP_BACKEND_CONFIG,
      }
    : {
        base_url: process.env.REACT_APP_API_CONFIG,
        xflowurl: process.env.REACT_APP_API_CONFIG,
        casaideas: process.env.REACT_APP_BACKEND_CONFIG,
      };

const casaideas_credentials =
  process.env.NODE_ENV === "production"
    ? { user: "yambert.vasquez", password: "yambert.vasquez" }
    : { user: "yambert.vasquez", password: "yambert.vasquez" };

const OP_SECRET_KEY =
  process.env.NODE_ENV === "production" ? "12345678" : "12345678";
const OP_SECRET_KEY_KETAL =
  process.env.NODE_ENV === "production" ? "16844890" : "16844890";

export { API_CONFIG, OP_SECRET_KEY, OP_SECRET_KEY_KETAL, casaideas_credentials };

import React from "react";
import {MDBSelect, MDBTable, MDBTableBody, MDBDatePicker, MDBTableHead,MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import {API_OP_CONFIG} from '../../Config/config-env.js';
import {API_CONFIG} from '../../Config/config-env.js';
import {OP_SECRET_KEY} from '../../Config/config-env.js';
import { searchHandler, callexternalApi, callApi, filterHandler} from '../../Config/functions';
import Table from '../../Components/Table';
import axios from 'axios';
import PageLoading from '../../Components/PageLoading';
var f = new Date();
var defDate = (f.getDate()<10?"0"+f.getDate():f.getDate()) + "/" +((f.getMonth() +1)<10?"0"+(f.getMonth() +1):(f.getMonth() +1)) + "/" + f.getFullYear();



class externalManageSKU extends React.Component {

    state = {
				filterConfig: {
					SPECIFICNAME : { label:"SKU Name" ,  type:"TEXT"   , value:""} ,
					SKU          : { label:"SKU" ,  type:"TEXT"   , value:""} ,
					STORE        : { label:"Store"        ,  type:"SELECT" , value:"" , values:[] },
					TAGNAME      : { label:"Tag Name" ,  type:"TEXT"   , value:""} ,
				},
				modal1:false,
				originalTableData:{},
				isLoading:true,
				api_url:"",
				sku:"",
				quantity:0,
				storeid:0,
				store:"",
				tableData: {
				columns : [
					{ dataIndex:"PRODUCTNAME", title:"Product Name",
						sorter: (a, b) => a.PRODUCTNAME.length - b.PRODUCTNAME.length },
						
					{ dataIndex:"SPECIFICNAME", title:"SKU Name",
						sorter: (a, b) => a.SPECIFICNAME.length - b.SPECIFICNAME.length },
					{ dataIndex:"SKU", title:"SKU",
						sorter: (a, b) => a.SKU.length - b.SKU.length },
					{ dataIndex:"STORE", title:"Store",
						sorter: (a, b) => a.STORE.length - b.STORE.length },
					{ dataIndex:"QUANTITY", title:"Stock",
						sorter: (a, b) => a.QUANTITY.length - b.QUANTITY.length },
					
					{ dataIndex:"PRICE", title:"Price",
						sorter: (a, b) => a.PRICE.length - b.PRICE.length },
						
					{ dataIndex:"TAGNAME", title:"Tag Name"},
						 
					{ dataIndex:"TAGLABEL", title:"Tag Label"},
						 
							
					{ dataIndex: "SKUID", title: "Action",
		        render: (text, record) => ( 
		         <button className="btn btn-sm btn-info" id={record.SKU+"__"+record.STOREID+"__"+record.STORE+"__"+record.QUANTITY} name="update"
		         onClick={this.loadFormData}
		          type="submit">Editar Cantidad</button>
		          
		        )
		      }
				],
		    rows: [], 
			},
			username:"",
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
    };


    componentDidMount=async()=>{
    	try{
	  		var token =	this.props.match.params.token;
	      let respT = await callApi ( "/externalApi/decryptToken", 'post' ,{token:token}  )
	      var api_url = respT.token.url_custom_menu[0].API_casaideas
	      
	      var username = respT.token.username
	      var data ={
	      	"language_id": 3,
	      	username:"guest"
	      }
	      let resp = await callexternalApi ( api_url, '/externalapi/execute/getProductsQty/key/'+OP_SECRET_KEY, "post", data )
	       
		 		const tableData = this.state.tableData
		 		tableData.rows = resp.rows
		 		this.setState({originalTableData:this.state.tableData, username:username, api_url:api_url});
		 	   
		 	 	
		 	 	let resp3 = await callexternalApi ( api_url, "/api/frontend/getStores", 'post' )	
		 	 	  
		 		var filterConfig = this.state.filterConfig
		 		
		 		filterConfig.STORE.values.push ("")
		 		resp3.rows.map(function (item) {
					 filterConfig.STORE.values.push (item.STRING_VALUE )
				}); 
				this.setState({ filterConfig:filterConfig});
				 
			}catch(err){
				console.log(">>>>>>>>>>",err)
			}finally{
				this.setState({isLoading:false});
			}
    }
    
    loadFormData=async (event) => {				 
			try{	  	
				var itemId = event.target.id
	  		var data = itemId.split("__")
	  		var sku     = data[0]
	  		var storeid = data[1]
	  		var store   = data[2]
	  		var quantity    = data[3]
	 
	  	}catch(err){
				console.log(">>>>>>>>>>"+err)
			}finally{
				this.setState({...this.state,modal1:true,isLoading:false, sku :sku, storeid: storeid, quantity:quantity,store:store, modal1:true  });
			}
	  }

		//filter table
	  filterHandler = async(event) => {
			let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
			if (resp.filterConfig){
				this.setState({ filterConfig:resp.filterConfig});
			}
			if (resp.tableData){
				this.setState({ tableData:resp.tableData});
			}
		} 
		getPickerValueFrom = value=> { this.setState({ from : value }); }
	  
    editQuantity=async() => {
     
    	try{
	  		this.setState({isLoading:true}); 
	      var data ={
	      	username  : this.state.username ,
	      	storeid   : this.state.storeid ,
	      	sku       : this.state.sku ,
	      	store     : this.state.store,
	      	quantity  : this.state.quantity ,
	      }
	      let resp = await callexternalApi ( this.state.api_url, '/externalapi/execute/updateProductsQty/key/'+OP_SECRET_KEY, "post", data )
	      
	      if (resp.success=="OK"){
	      	toast.success("Cantidad Actualizada Correctamente", {
								closeButton: true
							}); 
					
					const tableData = this.state.tableData
			 		
			 		 
			 		tableData.rows.map(function (item) {
			 			if (item.SKU == data.sku && item.STORE == data.store ){
							item["QUANTITY"] = data.quantity
						}
					}); 
					const originalTableData = this.state.originalTableData
					originalTableData.rows.map(function (item) {
			 			if (item.SKU == data.sku && item.STORE == data.store ){
							item["QUANTITY"] = data.quantity
						}
					}); 
					
					this.setState({reloadTable:true});  
					setTimeout( async function() {
						
					 this.setState({reloadTable:false});  
					}.bind(this),1	)		
					
					
	      }else{
	      	toast.error("Error Actualizando SKU, Mensaje:"+resp.descResp, {
								closeButton: true
					}); 
	      }
	      console.log(resp)
				 
			}catch(err){
				console.log(">>>>>>>>>>",err)
			}finally{
				this.setState({isLoading:false, modal1:false});
			}
          
    }
    toggleModal = nr => () => {
			let modalNumber = 'modal' + nr
			this.setState({
				[modalNumber]: !this.state[modalNumber]
			});
		}

    changeHandler = event => {
       console.log(event.target.name);
       this.setState({ ...this.state, [event.target.name]: event.target.value });
    };
 
    render() {
        return (
					<>
					 <PageLoading isLoading={this.state.isLoading}/>
           <div className="page-wrapper">
			    	<div className="content container-fluid">
		         	<div className="row">
			    			<div className="col-12 col-md-8 col-lg-4">
					    		<h2  className={"h2-responsive text-left"}>Editar Inventario</h2>
			    			</div>	
			    		</div>	
			    		<div className="row mt-3">
								<div className="col-12 col-lg-12" style={{paddingTop:10}}>
									<div className="card card-table flex-fill">
										{!this.state.reloadTable?
											<Table tableData={this.state.tableData} scroll={{}} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
										:""}
									</div>
								</div> 
							</div>		
			    	</div> 
		    	</div>
		    	
		    	<MDBModal cascading top isOpen={this.state.modal1} toggle={this.toggleModal(1)} size="md">

						<MDBModalBody >
							
							<h5 className="font-weight-bolder">
							<MDBIcon fas icon="edit" />
							Editar Cantidad </h5>
							<hr />
							<h6>
							<p>
								SKU: <b> {this.state.sku}</b>
							</p>
							<p>
								Tienda:<b> {this.state.store}</b>
							</p>
							</h6>
							<MDBInput
								type="text"
								label="Cantidad"
								key="quantity"
								name="quantity"
								onChange={this.changeHandler}
								value={this.state.quantity}
							/>
							<MDBRow className="text-center mt-3">
								<MDBCol md="12">
									<MDBBtn										
										color="primary"
										size="sm"

										key="comment"
										onClick={this.editQuantity}>		
										Editar
									</MDBBtn>

								</MDBCol>
							</MDBRow>
						</MDBModalBody>
					</MDBModal>
		    	
		    	<ToastContainer
		        closeButton={false}
		        autoClose={3000}
		        hideProgressBar={true}
		        position={"bottom-right"} />
      	</>  
    	)
    }
}
export default externalManageSKU;

import React from "react";
import {MDBSelect,   MDBTableHead,MDBNotification,MDBInputGroup,toast,MDBFormInline,MDBBadge,MDBAnimation, ToastContainer, MDBIcon , MDBDataTable,MDBAlert, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardHeader,MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn,MDBBtnGroup } from "mdbreact";
import Loader from '../../Components/Loader';
import axios from 'axios';
import {API_CONFIG} from '../../Config/config-env.js';
import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class reportBloqueos extends React.Component {

    state = {

        options:[],
        warehouse: 0,
        SKU:"",
        skuExists:"NO ENCONTRADO",
        skuState:"SIN INFORMACION",
        showResults:false,
        data:[]
    };


    componentDidMount=()=>{
        this.LoadWharehouses();
    }


    generateReport=async() => {
    	
    	if(this.state.warehouse ===undefined){
    		toast.error("Seleccione una sucursal", {
                        closeButton: true
                    });
    	}else{
	        let token = this.props.match.params.token;
	        this.setState({ isExecuting: true, showResults:false  });

	        let url = API_CONFIG.base_url+'/externalApi/getLockedSKUsbyStore/';
	        let datos = {
	            warehouse:  this.state.warehouse
	        }
	        await axios({
	            url: url,
	            headers: { 'Authorization': 'Bearer ' + token },
	            method: 'post',
	            data: datos
	        })
	            .then(response => response.data)
	            .then(data => {

	                if (data.resp==="OK" &&  data.rows.length>0){


	                    let	dataObject= {
	                        columns:data.columns,
	                        rows:data.rows
	                    };

	                    this.setState({ ...this.state, data : dataObject, showResults:true });
	                    this.setState({ isExecuting: false });

	                }else {
	                    this.setState({ isExecuting: false,  showResults:false , data:[]  });
	                }
	            })
    	}
		}	
    LoadWharehouses=async() => {
        let token = this.props.match.params.token;
        this.setState({ isExecuting: true });

        let url = API_CONFIG.base_url+'/api/admin/getSections/';

        await axios({
            url: url,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'get'
        })
            .then(response => response.data)
            .then(data => {
                if (data.rows.length>0){
                    var objSections = [];
                    
                    let	dataObject={
                                value:  0,
                                text: "TODOS",
                                checked:true
                            }
                            objSections.push(dataObject);
                            
                    for (var key in data.rows) {
                        if (data.rows[key].sectionCode!=="NAC01"){
                            let	dataObject={
                                value: data.rows[key].sectionId,
                                text:'MD-'+data.rows[key].sectionCode+" "+data.rows[key].sectionName
                            }
                            objSections.push(dataObject);
                        } 
                    }

                    this.setState({ options : objSections });

                    this.setState({ isExecuting: false });

                }else {
                    this.setState({ isExecuting: false });
                }
            })
    }


    selectHandler = event => { 
				this.setState({ ...this.state, warehouse: event[0] });
    };

    changeHandler = event => {
        //console.log(event.target.value);
        this.setState({ ...this.state, SKU: event.target.value });
    };


    render() {
        return (

            <MDBContainer className="" style={{"marginLeft": "0"}}>
                <Loader idLoading={this.state.isExecuting}/>
                <br></br>
                <h2 key={"h2"}	className={" h2-responsive text-left"}> <strong>Reporte SKUs bloqueados</strong></h2>

                <MDBRow>
                    <MDBCol sm="12" md="9" lg="4" className="text-left">

                        <MDBSelect
                            className="is-invalid"
                            key= "store"
                            id="store"
                            color="primary"
                            options={this.state.options}
                            getValue= {this.selectHandler}
                            selected="Elija un Almacen"
                            label="Almacen" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol  sm="12" md="9" lg="4" className="text-right justify-content-right">
                        <MDBBtn key="find" onClick={() => {this.generateReport();}} size="sm"
                                color="success"
                                className="text-capitalize"
                        >Generar Reporte</MDBBtn>
                    </MDBCol>
                </MDBRow>



                        {this.state.showResults?
                            <>
                                <MDBRow>
                                    <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-right justify-content-right">
                                    <ExcelFile element={
                                        <MDBBtn key="find"   size="sm"
                                                color="success"
                                        >Exportar a Excel</MDBBtn>
                                    }>
                                        <ExcelSheet data={this.state.data.rows} name="SKUS">
                                        
                                        {this.state.data.columns.map(event => (
                                        	 <ExcelColumn label={event.field} value={event.field}/>
                                         ))}	
                                             
                                        </ExcelSheet>

                                    </ExcelFile>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol  sm="12" md="12" lg="12" xl="12" className="text-left justify-content-left">
                                    <MDBDataTable
                                        small
                                        striped
                                        bordered
                                        responsive
                                        responsiveSm
                                        hover
                                        btn
                                        searchLabel={"Buscar"}
                                        paginationLabel={[ "Anterior" , "Siguiente"]}
                                        infoLabel={["Mostrando", "Hasta",  "De",  "registros"]}
                                        paging = {true}
                                        displayEntries={false}
                                        sortable={true}
                                        entries={"20"}
                                        data={this.state.data} />
                                    </MDBCol>
                                </MDBRow>
                            </>
                            :""}




                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={false}
                    position={"bottom-right"}
                    autoClose={3000}
                />

            </MDBContainer>
        )
    }
}
export default reportBloqueos;
